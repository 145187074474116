import {NgForOf, NgIf, UpperCasePipe} from '@angular/common';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { FaqGroupService } from '../../faq-group/service/faq-group.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastrService } from 'ngx-toastr';
import { FaqService } from '../service/faq.service';
import { ErrorStatusModel } from 'src/app/core/enum/error-status.model';
import {ActivatedRoute, Router} from '@angular/router';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {QuillEditorComponent} from 'ngx-quill';
import {EditorComponent} from '@tinymce/tinymce-angular';
import {finalize, last} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {LocalStorageRefService} from '../../../../pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../../../../../core/enum/storage-keys.enum';
import {MatTabsModule} from '@angular/material/tabs';
import {LoadingRowComponent} from '../../../../../core/components/loading-row/loading-row.component';
import {UploadFileAwsService} from "../../../../../core/services/upload-file-aws.service";

@Component({
  selector: 'vex-faq-create-update',
  templateUrl: './faq-create-update.component.html',
  styleUrls: ['./faq-create-update.component.scss'],
  imports: [
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgForOf,
    MatButtonModule,
    NgIf,
    MatProgressSpinnerModule,
    PageLayoutModule,
    MatSlideToggleModule,
    QuillEditorComponent,
    EditorComponent,
    MatTabsModule,
    UpperCasePipe,
    LoadingRowComponent
  ],
  standalone: true
})
export class FaqCreateUpdateComponent implements OnInit{
  faqId;
  static id = 100;
  numbers: number[] = Array.from({ length: 20 }, (_, i) => i + 1);
  form: UntypedFormGroup;
  mode: 'create' | 'update' = 'create';
  groups = [];
  loading=false;
  getLoading=true;
  private description: any;
  formDesc;
  defaults;
  source: string = '';
  config: EditorComponent['init'] = {
    selector: '#editor',
    plugins: 'preview  casechange importcss tinydrive searchreplace autolink autosave save ' +
      'directionalityadvcode visualblocks visualchars code fullscreen image link media mediaembed codesample' +
      ' table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist ' +
      'wordcount  editimage help formatpainter permanentpen pageembed charmap' +
      ' quickbars linkchecker emoticons advtable export footnotes mergetags  code' +
      '  advtemplate',
    toolbar:
      'undo redo | aidialog aishortcuts | blocks fontsizeinput | bold italic |' +
      ' align numlist bullist | link image | table media pageembed | lineheight  outdent indent | ' +
      'strikethrough forecolor backcolor formatpainter removeformat | charmap emoticons checklist | ' +
      'code fullscreen preview |save print export | pagebreak anchor codesample footnotes mergetags |' +
      ' addtemplate inserttemplate  | ltr rtl casechange |  code',
    file_picker_types: 'image',
    image_advtab: false,
    image_description: false,
    image_dimensions: false,
    block_unsupported_drop: true,
    placeholder: 'Once upon a time...',
    content_css: 'writer',
    content_style: 'img{max-width:100%;height:auto;}',
    images_reuse_filename: true,
    paste_data_images: false,
    height: 'calc(100vh - 88px)',
    images_upload_handler: (blobInfo) => {
      const file = blobInfo.blob();
      return this.awsService.uploadfile(file);
    }
  };
  locale;
  defLocale;
  loadingForm=false;
  constructor(
  private fb: UntypedFormBuilder,
  private groupService:FaqGroupService,
  private service:FaqService,
  private toastr: ToastrService,
  private router:Router,
  private route:ActivatedRoute,
  private storage: AngularFireStorage,
  private localStorageRefService : LocalStorageRefService,
  private cdr:ChangeDetectorRef,
  private awsService : UploadFileAwsService

  ) {
  }
  onTabChanged(event){
    this.cdr.detectChanges();
    this.loadingForm =true;

    this.form.get('order').setValue(this.form.value.order);
    this.form.get('status').setValue(this.form.value.status);
    this.form.get('faq_group_id').setValue(this.form.value.faq_group_id);
    setTimeout(()=>{
      this.loadingForm =false;
    },200);
  }
  ngOnInit() {
    this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
    this.defLocale = this.locale.filter(loc=>loc.default==true)[0];
    this.faqId = this.route.snapshot.paramMap.get('id');
    if (this.faqId) {
      this.getData();
      this.mode = 'update';
    } else {
      this.mode = 'create';
      this.getLoading=false;
    }

    this.form = this.fb.group({
      id: [null],
      order: [1 ],
      status: [1 ],
      faq_group_id :[null ,Validators.required ]
    });

    this.locale.forEach(lang => {

      this.form.addControl(lang.locale+'_question', this.fb.control('',lang.default?Validators.required:[]));
      this.form.addControl(lang.locale+'_answer', this.fb.control('',lang.default?Validators.required:[]));
    });
   this.getGroup();
  }
  getData() {
    this.service.get(this.faqId).subscribe((res) => {
      this.defaults = res['data'];
      this.initForm();
      this.getLoading=false;
    });
  }
  initForm() {

    this.form = this.fb.group({
      id: [this.defaults.id],
      order: [this.defaults.order || 1 ],
      status: [this.defaults.status || 1 ],
      faq_group_id :[this.defaults.faq_group_id ,Validators.required ]
    });
    this.locale.forEach(lang => {
      const value = this.defaults.translations.filter(brand => brand.locale == lang.locale);

      this.form.addControl(lang.locale+'_question', this.fb.control(value[0]?.question??'',lang.default?Validators.required:[]));
      this.form.addControl(lang.locale+'_answer', this.fb.control(value[0]?.answer??'',lang.default?Validators.required:[]));
    });
    this.description = this.defaults?.description;
    this.formDesc = new UntypedFormControl(this.defaults?.description);
  }
  getGroup(){
    this.groupService.get().subscribe((res) => {
      this.groups = res['data'];

    });
  }




  save() {
    this.loading=true;
    const formData = new FormData();
    if(this.mode == 'update')
    formData.append('id',this.defaults.id);
    formData.append('order',this.form.value.order);
    formData.append('faq_group_id',this.form.value.faq_group_id);
    formData.append('answer',this.form.value[this.defLocale.locale + '_answer']);
    formData.append('question',this.form.value[this.defLocale.locale + '_question']);
    this.locale.filter(lang => lang.default==0 ).forEach(lang =>{

      if(this.form.value[lang.locale + '_answer']!='')
        formData.append(lang.locale +'[answer]',this.form.value[lang.locale + '_answer']);
      if(this.form.value[lang.locale + '_question']!='')
        formData.append(lang.locale +'[question]',this.form.value[lang.locale + '_question']);
    });
    if (formData) {
      this.service.create(formData).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Faq Created Successfully!');
          this.loading=false;

        }

      }, error => {
        this.loading=false;
        this.toastr.error(error['errors']['message']);
      });
    }




  }

  create() {

    const type = {
      'en':{
        answer:this.form.value.answer,
        question:this.form.value.question
      },

      'order': this.form.value.order,
      'faq_group_id': this.form.value.faq_group_id


    };
  }
  onContentChanged(event) {
    this.description = event.html;
  }
  onCancel(){
    this.router.navigate(['/faq']).then();
  }
  update() {

    const type = {
      'id':this.defaults.id,
      'en':{
        answer:this.form.value.answer,
        question:this.form.value.question
      },
      'order': this.form.value.order,
      'faq_group_id': this.form.value.faq_group_id
    };

  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
}
