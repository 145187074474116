import {Component, Input} from '@angular/core';
import {DatePipe, JsonPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {LiveShowChatComponent} from "../live-show-chat/live-show-chat.component";
import {LoadingRowComponent} from "../../../../../../core/components/loading-row/loading-row.component";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatTabsModule} from "@angular/material/tabs";
import {OngoingDetailsComponent} from "../ongoing-details/ongoing-details.component";

@Component({
  selector: 'vex-proxy-bid',
  templateUrl: './proxy-bid.component.html',
  styleUrls: ['./proxy-bid.component.scss'],
  imports: [
    JsonPipe,
    CdkFixedSizeVirtualScroll,
    CdkVirtualScrollViewport,
    LiveShowChatComponent,
    LoadingRowComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    NgForOf,
    NgIf,
    OngoingDetailsComponent,
    NgClass,
    DatePipe
  ],
  standalone: true
})
export class ProxyBidComponent {
  @Input() bids;
}
