import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup, Validators
} from '@angular/forms';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {LoadingRowComponent} from '../../../../../core/components/loading-row/loading-row.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {filter, Observable, of, ReplaySubject} from 'rxjs';
import {AuctionModel} from '../../../models/auction.model';
import {TableColumn} from '../../../../../../@vex/interfaces/table-column.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {AuctionService} from '../service/auction.service';
import {ToastrService} from 'ngx-toastr';
import {MethodsUtils} from '../../../../../core/utils/method-utils';
import {AuctionTypeEnum} from '../../../../../core/enum/auction-type.enum';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {RingModel} from '../../../models/ring.model';
import {ActivatedRoute, Router} from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'vex-rings',
  templateUrl: './rings.component.html',
  styleUrls: ['./rings.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    BreadcrumbsModule,
    MatCheckboxModule,
    NgClass,
    MatSlideToggleModule,
    MatMenuModule,
    MatIconModule,
    MatPaginatorModule,
    FormsModule,
    MatTableModule,
    ReactiveFormsModule,
    PageLayoutModule,
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSelectModule,
    LoadingRowComponent,
    MatDatepickerModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class RingsComponent implements OnInit, AfterViewInit {

  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<RingModel[]> = new ReplaySubject<RingModel[]>(1);
  data$: Observable<RingModel[]> = this.subject$.asObservable();
  selectedFile='assets/img/demo/missing-picture.jpeg';
  numbers: number[] = Array.from({ length: 10 }, (_, i) => i + 1);

  @Input()
  columns: TableColumn[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Name', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Description', property: 'description', type: 'text', visible: false, cssClasses: ['font-medium'] },
    { label: 'Status', property: 'status', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }


  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<RingModel> | null;
  selection = new SelectionModel<RingModel>(true, []);
  searchCtrl = new UntypedFormControl();
  form: UntypedFormGroup;
  isPanelOpen = false;
  loading = false;
  getLoading = true;
  defaults ?: RingModel;
  mode: 'create' | 'update' = 'create';
  auctionId;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private service:AuctionService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private elementRef: ElementRef,
    private methods: MethodsUtils,
    private route : ActivatedRoute,
    private router : Router

  ) {
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }


  getData() {
    this.auctionId = this.route.snapshot.paramMap.get('id');

    this.service.getRings(this.auctionId).subscribe((res)=>{
      of(res['data'].map(type=>new RingModel(type))).subscribe(types=>{
        this.subject$.next(types);
        this.getLoading=false;
      });
    });
  }

  ngOnInit() {
    this.getData();
    this.clearForm();
    this.dataSource = new MatTableDataSource();

    this.data$.pipe(
      filter<RingModel[]>(Boolean)
    ).subscribe(types => {

      this.dataSource.data = types;
    });

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  create() {
    this.clearForm();
    this.isPanelOpen = true;
    this.scrollToForm();
  }
  initForm(app:RingModel){
    this.isPanelOpen=true;
    this.mode='update';
    this.form = this.fb.group({
      id: [app?.id || null],
      name: [app?.name || '',Validators.required],
      description: [app?.description || '',Validators.required]



    });
  }
  clearForm(){
    this.isPanelOpen = false;
    this.mode='create';

    this.form = this.fb.group({
      id: [null],
      name: ['',Validators.required],
      description: ['',Validators.required]


    });
  }

  update(type: RingModel) {
    this.initForm(type);
    this.scrollToForm();
  }
  scrollToForm() {
    const formElement = this.elementRef.nativeElement.querySelector('#form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  isCreateMode() {
    return this.mode === 'create';
  }
  delete(type: RingModel) {
    this.service.deleteRing(type.id).subscribe((res)=>{
      if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
        this.toastr.success('Ring Updated Successfully!');
        this.ngOnInit();
        this.selection.deselect(type);
      }
    }, () => {
      this.toastr.error('error !');
    });
  }

  deleteAll(types: RingModel[]) {
    types.forEach(c => this.delete(c));
  }
  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }
  save(){
    this.loading=true;



    let formData = new FormData();
    if(this.mode === 'update') {
      formData.append('id', this.form.value?.id);
    }
    formData.append('name',this.form.value.name);
    formData.append('description',this.form.value.description);
    formData.append('auction_id',this.auctionId);



    if (formData) {
      this.service.saveRing(formData).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Ring '+this.mode+'d Successfully!');
          this.ngOnInit();
          this.loading=false;

        }
      }, error => {
        this.loading=false;
        this.toastr.error(error);
      });
    }}
  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
      this.form.patchValue({cover: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedFile = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }


  }

  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }

  back(){
    this.router.navigate(['auction']);
  }
  getItems(id){
    this.router.navigate(['auction/'+id+'/items']);
  }

}



