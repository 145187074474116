import { RatingStandardModel } from './rating-standard.model';

export class OwnerReviewModel {
  product_id: number;
  id: number;
  pros: string;
  cons: string;
  submitted_by: string;
  stars:number;
  ratings : [
    {
      rating_standard_id : number;
      value : number;
      rating_standard:RatingStandardModel
    }
  ];

  constructor(app) {
      this.product_id = app.product_id;
      this.id = app.id;
      this.pros = app.pros;
      this.cons = app.cons;
      this.submitted_by = app.submitted_by;
      this.stars = app.stars;
      this.ratings = app.ratings;
      this.ratings = app.ratings;
  }
}

