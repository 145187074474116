export class PrivacyModel {
  id: number;
  title: string;
  description : string;
  order : number;
  status: number;
  translations :[
    {
      locale:string;
      title:string;
      description:string;
    }
  ];



  constructor(term) {
    this.id = term.id;
    this.title = term.title;
    this.description = term.description;
    this.order = term.order;
    this.status = term.status;
    this.translations = term.translations;



  }
}



