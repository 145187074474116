import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {AuctionService} from '../service/auction.service';
import {ToastrService} from 'ngx-toastr';
import {MethodsUtils} from '../../../../../core/utils/method-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageRefService} from '../../../../pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../../../../../core/enum/storage-keys.enum';
import {AuctionTypeEnum} from '../../../../../core/enum/auction-type.enum';
import {AuctionModel} from '../../../models/auction.model';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {LoadingRowComponent} from '../../../../../core/components/loading-row/loading-row.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSortModule} from '@angular/material/sort';
import {ReplaceUnderscoresPipe} from '../../../../../core/pipes/replace-underscore.pipe';
import {MatTabsModule} from '@angular/material/tabs';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {ThemePalette} from '@angular/material/core';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {TinyEditorComponent} from '../../../../../core/components/tiny-editor/tiny-editor.component';

@Component({
  selector: 'vex-auction-create-update',
  templateUrl: './auction-create-update.component.html',
  styleUrls: ['./auction-create-update.component.scss'],
  standalone: true,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ],
  imports: [
    BreadcrumbsModule,
    MatCheckboxModule,
    NgClass,
    MatSlideToggleModule,
    MatMenuModule,
    MatIconModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    PageLayoutModule,
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSelectModule,
    LoadingRowComponent,
    MatDatepickerModule,
    MatSortModule,
    ReplaceUnderscoresPipe,
    MatTabsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatDialogModule,
    TinyEditorComponent
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class AuctionCreateUpdateComponent implements OnInit, AfterViewInit{

  form: UntypedFormGroup;
  locale;
  types = [];
  mode: 'create' | 'update' = 'create';
  defaults ?: AuctionModel;
  public date: moment.Moment;
  public disabled = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public color: ThemePalette = 'primary';
  selectedFile = 'assets/img/demo/missing-picture.jpeg';
  loading = false;
  auctionId;
  transValues =[];
  editorVal='';
  loadingForm=false;
  constructor(
    private dialog: MatDialog,
    private service: AuctionService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private elementRef: ElementRef,
    private methods: MethodsUtils,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private localStorageRefService: LocalStorageRefService
  ) {
  }

  ngAfterViewInit(): void {

    }
  ngOnInit() {
    this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES)) ?? [];
    this.clearForm();
    this.types = this.methods.enumToArray(AuctionTypeEnum);
    this.auctionId = this.route.snapshot.paramMap.get('id');

    if (this.auctionId!='save') {
      this.getData();
      this.mode = 'update';

    } else {
      this.mode = 'create';
      this.defaults = {} as AuctionModel;
      this.selectedFile='assets/img/demo/missing-picture.jpeg';

    }
  }
  getData() {
    this.service.get(this.auctionId).subscribe((res) => {
      this.defaults = res['data'];
      this.initForm(this.defaults);
    });
  }
  clearForm() {
    this.mode = 'create';
    this.selectedFile = 'assets/img/demo/missing-picture.jpeg';
    this.form = this.fb.group({
      id: [null],
      bid_extension_time: [0, Validators.required],
      sniper_protection_seconds: [0, Validators.required],
      start_datetime: ['', Validators.required],
      end_datetime: ['', Validators.required],
      type: ['', Validators.required],
      cover: ['', Validators.required]
    });
    this.locale.forEach(lang => {

      this.form.addControl(lang.locale + '_name', this.fb.control('', lang.default ? Validators.required : []));
      this.form.addControl(lang.locale + '_description', this.fb.control('', lang.default ? Validators.required : []));
      this.form.addControl(lang.locale + '_location', this.fb.control('', lang.default ? Validators.required : []));
      this.form.addControl(lang.locale + '_details', this.fb.control('', lang.default ? Validators.required : []));
    });
    this.form.get('type').valueChanges.subscribe(x => {
      if(x=='TIMED'){
        this.form.get('bid_extension_time').setValidators([Validators.required]);
        this.form.get('sniper_protection_seconds').setValidators([Validators.required]);
      }
    });
  }
  initForm(app: AuctionModel) {
    this.mode = 'update';
    this.selectedFile = app.cover ?? 'assets/img/demo/missing-picture.jpeg';
    this.form = this.fb.group({
      id: [app?.id || null],
      bid_extension_time: [Number.parseInt(app?.bid_extension_time)/60 || null],
      sniper_protection_seconds: [Number.parseInt(app?.sniper_protection_seconds)/60 || null],

      start_datetime: [new Date(app?.start_datetime) || '', Validators.required],
      end_datetime: [new Date(app?.end_datetime) || '', Validators.required],
      type: [app?.type || '', Validators.required],
      cover: [this.selectedFile, Validators.required]

    });
    this.locale.forEach(lang => {
      const value = app.translations.filter(obj => obj.locale == lang.locale);

      this.form.addControl(lang.locale + '_name', this.fb.control(value[0]?.name ?? '', lang.default ? Validators.required : []));
      this.form.addControl(lang.locale + '_description', this.fb.control(value[0]?.description ?? '', lang.default ? Validators.required : []));
      this.form.addControl(lang.locale + '_location', this.fb.control(value[0]?.location ?? '', lang.default ? Validators.required : []));
      this.form.addControl(lang.locale + '_details', this.fb.control(value[0]?.details ?? '', lang.default ? Validators.required : []));
    });
    this.form.get('type').valueChanges.subscribe(x => {
      if(x=='TIMED'){
        this.form.get('bid_extension_time').setValidators([Validators.required]);
        this.form.get('sniper_protection_seconds').setValidators([Validators.required]);
      }
    });
  }
  isCreateMode() {
    return this.mode === 'create';
  }
  save() {
    this.loading = true;
    let formData = new FormData();
    if (this.mode === 'update') {
      formData.append('id', this.form.value?.id);
    }
    formData.append('name', this.form.value.en_name);
    formData.append('description', this.form.value.en_description);
    formData.append('details', this.form.value.en_details);
    formData.append('type', this.form.value.type);
    formData.append('location', this.form.value.en_location);
        if(this.form.value.type=='TIMED'){
      // @ts-ignore
      formData.append('bid_extension_time', this.form.value.bid_extension_time * 60);
      // @ts-ignore
      formData.append('sniper_protection_seconds', this.form.value.sniper_protection_seconds * 60);
    }

    // formData.append('start_datetime', this.form.value.start_datetime.toISOString().slice(0, 19).replace('T', ' '));
    formData.append('start_datetime', this.form.value.start_datetime.toUTCString());
    formData.append('end_datetime', this.form.value.end_datetime.toUTCString());

    if (this.form.value.cover instanceof File)
      formData.append('cover', this.form.value.cover);
    this.locale.filter(lang => lang.default == 0).forEach(lang => {

      if (this.form.value[lang.locale + '_name'] != '')
        formData.append(lang.locale + '[name]', this.form.value[lang.locale + '_name']);
      if (this.form.value[lang.locale + '_description'] != '')
        formData.append(lang.locale + '[description]', this.form.value[lang.locale + '_description']);
      if (this.form.value[lang.locale + '_location'] != '')
        formData.append(lang.locale + '[location]', this.form.value[lang.locale + '_location']);
      if(this.form.value[lang.locale + '_details']!='')
        formData.append(lang.locale +'[details]',this.form.value[lang.locale + '_details']);
    });

    if (formData) {
      this.service.save(formData).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Auction ' + this.mode + 'd Successfully!');
          this.loading = false;
          this.ngOnInit();


        }
      }, error => {
        this.loading = false;
        this.toastr.error(error);
      });
    }
  }

  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
      this.form.patchValue({cover: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedFile = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }


  }

  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }
  onCancel(){
    this.router.navigate(['/auction']).then();
  }
  onTabChanged(event){
    this.loadingForm =true;
    this.form.get('type').setValue(this.form.value.type);
    this.form.get('start_datetime').setValue(this.form.value.start_datetime);
    this.form.get('end_datetime')?.setValue(this.form.value.end_datetime);
    this.form.get('bid_extension_time')?.setValue(this.form.value.bid_extension_time);
    this.form.get('sniper_protection_seconds')?.setValue(this.form.value.sniper_protection_seconds);
    setTimeout(()=>{
      this.editorVal = this.getTranslationField(this.locale[event.index]?.locale);
      this.loadingForm =false;
    },200);
  }
  setEditorControl(event:any , lang:string , field : string){
    this.form.get(lang + '_' + field).setValue(event);
    const index = this.transValues.findIndex(item => item.lang === lang);
    if (index !== -1) {
      this.transValues[index] = { ...this.transValues[index], value: event };
    } else {
      this.transValues.push({ lang, value: event });
    }
  }

  getTranslationField(locale : string){
    return this.transValues?.find(obj => obj?.['lang'] === locale)?.['value'];
  }









}
