import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UnitService {

  constructor(private http:HttpClient) { }


  getAllUnits(){
    return this.http.get(environment.baseUrl + 'unit/units');
  }
  create(unit:any){
    return this.http.post(environment.baseUrl + 'unit/units',unit);
  }
  delete(id){
    return this.http.delete(environment.baseUrl + 'unit/units/'+id);
  }

}
