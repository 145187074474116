<form [formGroup]="form" class="w-full">
<div class="flex-row justify-center items-center">
  <div class="flex flex-auto p-2">
    <mat-slide-toggle formControlName="has_auto_closing" (click)="save()">Auto Closing</mat-slide-toggle>

  </div>

  <div class="flex flex-auto justify-center items-center p-4" *ngIf="closing_after!=null">
    <p>Closing After : <span>{{closing_after}}</span> </p>
  </div>

</div>
</form>
