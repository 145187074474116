<div class="grid grid-cols-12 gap-3 p-4">
    <div class="col-span-12 md:col-span-5 ">
        <div class="drop-shadow-md bg-white  rounded-lg p-4 flex justify-between">
         <span class="font-semibold ">Inspection Date : {{methods.getYMDformat(report?.inspection_date)}} </span>
          <button class="bg-red text-white rounded p-1.5" (click)="openPdf()">Download PDF</button>
        </div>
        <div class="drop-shadow-md bg-white  rounded-lg mt-2 p-4 ">
          <div class="flex justify-center items-center h-[56px]">
            <mat-form-field class="flex-auto">
              <mat-label>New Group</mat-label>
              <select matNativeControl required [formControl]="newGroup">
                <option *ngFor="let item of avilableGroup" [ngValue]="item.id" >{{item['name']}}</option>
              </select>
            </mat-form-field>
            <div class="h-[56px] self-center items-center justify-center flex">
              <button  type="submit" (click)="addGroup()" [disabled]="newGroup.invalid"
                       class="h-fit  flex items-center justify-center bg-[#d7e512] text-black px-3 py-1 rounded-sm w-fit" >
                <span *ngIf="!loadingAddGroup"> Add  </span>

                <mat-progress-spinner *ngIf="loadingAddGroup"  diameter="30" mode="indeterminate"></mat-progress-spinner>
              </button>
            </div>
          </div>
            <div class="flex items-center justify-content-between">
                <span class="font-semibold">Inspection Groups</span>

<!--                <div  class="mx-1  cursor-pointer p-1 rounded-full bg-[#d7e512] justify-center items-center flex" mat-flat-button  (click)="addGroup()">-->
<!--                    <mat-icon  svgIcon="mat:playlist_add"></mat-icon>-->
<!--                </div>-->

            </div>
            <div class="flex flex-col gap-2 mt-2">
                <div *ngIf="isTemplate && template as templates; else checkReportGroups">
                    <vex-scrollbar [@stagger]="templates.length" class="flex-auto">
                        <a *ngFor="let group of templates"
                           @fadeInUp
                           class="border-b px-4 py-6 hover:bg-hover cursor-pointer relative select-none block no-underline text-dark"
                           matRipple
                           [ngClass]="{'active': activeGroup?.id == group?.id  }"
                           routerLinkActive="bg-hover">
                            <div class="flex justify-content-between"  >
                                <div class="flex items-center gap-4">
                                    <div class="relative flex-none">
                                        <span> {{group.name}} </span>

                                    </div>
                                </div>
                                <div class="flex gap-2 flex-wrap">
<!--                                    <mat-icon  svgIcon="mat:edit" class="cursor-pointer" (click)="editGroup(group)"></mat-icon>-->
                                    <mat-icon  svgIcon="mat:keyboard_arrow_right" class="cursor-pointer" (click)="setActiveGroup(group)"></mat-icon>
                                </div>
                            </div>

                        </a>
                    </vex-scrollbar>
                </div>
                <ng-template #checkReportGroups>
                    <div *ngIf="!isTemplate && report?.groups as groups">
                        <a *ngFor="let group of groups"
                           @fadeInUp
                           class="border-b px-4 py-6 hover:bg-hover cursor-pointer relative select-none block no-underline text-dark"
                           matRipple
                           [ngClass]="{'active': activeGroup?.id == group?.id  }"
                           routerLinkActive="bg-hover">
                            <div class="flex justify-content-between"  >
                                <div class="flex items-center gap-4">
                                    <div class="relative flex-none">
                                        <span> {{group.name}} </span>
                                        <!--<p> {{group?.['description']}}</p>-->
                                    </div>
                                </div>
                                <div class="flex gap-2 flex-wrap">
                                  <mat-icon  svgIcon="mat:delete_outline" class="cursor-pointer" (click)="deleteGroup(group.id)"></mat-icon>
                                  <!--                                    <mat-icon  svgIcon="mat:edit" class="cursor-pointer" (click)="editGroup(group)"></mat-icon>-->
                                  <mat-icon  svgIcon="mat:keyboard_arrow_right" class="cursor-pointer" (click)="setActiveGroup(group)"></mat-icon>
                                </div>
                            </div>

                        </a>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="drop-shadow-md bg-white  rounded-lg mt-2 p-4 "  *ngIf="activeGroup?.parts.length>0">
            <span class="font-semibold ">Parts </span>
            <vex-scrollbar *ngIf="activeGroup?.parts as parts" [@stagger]="parts" class="flex-auto">
                <a *ngFor="let part of parts"
                   @fadeInUp
                   (click)="setActivePart(part)"
                   [ngClass]="{'active': activePart?.id == part?.id  }"
                   class="border-b px-4 py-6 hover:bg-hover cursor-pointer relative select-none block no-underline text-dark"
                   matRipple
                   routerLinkActive="bg-hover">
                    <div class="flex flex-column  gap-1"  >
                        <div class="flex justify-content-between items-center">
                            <h1 class="font-bold">{{part?.name}}</h1>
                            <div class="flex gap-2 flex-wrap">
                                <div *ngIf="part?.['inspection_type']" class="bg-[#d7e512]  rounded-xl px-2 py-1 flex justify-content-center items-center">
                                    {{part?.['inspection_type']}}</div>
                                <mat-icon  svgIcon="mat:delete_outline" class="cursor-pointer" (click)="deleteProductPart(part.id)"></mat-icon>
                            </div>
                        </div>
                        <ngb-rating [max]="5" [(rate)]="part.rating" [readonly]="true"></ngb-rating>
<!--                        <p>{{part.description}}</p>-->
                        <div class="h-[1px] bg-[#F3F4F6] w-full self-center mt-2"></div>
                        <p>{{part.inspection_description}}</p>
                        <ul class="pl-2 list-disc" *ngFor="let note of part.notes">
                            <li>{{note}}</li>
                        </ul>
                        <div class="flex justify-content-end gap-1 text-blue-600 items-center text-decoration-underline cursor-pointer" (click)="showImages(part)">
                            <mat-icon  color="#0B62E4" svgIcon="mat:collections_bookmark"></mat-icon>
                            view media
                        </div>
                    </div>
                    <div *ngIf="activeMedia &&  activeMediaPart == part?.name && part?.media" class="flex flex-wrap">

                        <img *ngIf="part?.has_inline_images" (error)="handleImageError($event)" class="w-[200px]" [src]="part?.media?.['url']" >
                        <video [src]="part?.['video']?.['url']" *ngIf="part?.has_video" class="h-[100px]" height="50" autoplay muted></video>

                         <ng-container *ngIf="!part?.has_video && !part?.has_inline_images" class="hi">
                             <div   *ngFor="let media of part?.media;let j = index" class="mx-2  list-col rounded-lg w-fit  h-fit overflow-scroll  ">
                                     <img  (error)="handleImageError($event)" class="w-[100px]" [src]="media?.['url']" >
                             </div>
                         </ng-container>

                    </div>
                </a>
            </vex-scrollbar>
        </div>
    </div>
    <div class="col-span-12 md:col-span-7 drop-shadow-md bg-white rounded-lg p-3">
        <div class="flex justify-content-between  flex-wrap">
            <h1 class="font-semibold text-lg mb-4" *ngIf="activePart?.name">Edit Part  <span class="underline">{{activePart?.name}} </span> </h1>
            <button  color="primary" class="flex items-center justify-center self-end mb-2"
                    mat-flat-button type="submit" (click)="addPart()"  >
              <span *ngIf="!loadingAddPart">  save  </span>

              <mat-progress-spinner *ngIf="loadingAddPart"  diameter="30" mode="indeterminate"></mat-progress-spinner>

            </button>
        </div>
            <form  class="flex flex-col my-5 gap-1 mx-5" [formGroup]="form" >
              <mat-form-field class="flex-auto" *ngIf="!activePart">
                <mat-label>Part</mat-label>
                <select matNativeControl required formControlName="part_id">
                  <option *ngFor="let item of avilablePart" [ngValue]="item.id" >{{item['name']}}</option>
                </select>
              </mat-form-field>
                <div class="flex gap-3 self-end mb-3">
                    <mat-label> Rating</mat-label>
                    <ngb-rating [max]="5" [(rate)]="starRating" [readonly]="false"></ngb-rating>
                </div>
                <div class="flex justify-content-between gap-2">

                    <mat-form-field class="flex-auto">
                        <mat-label>Inspection Type</mat-label>
                        <select matNativeControl required formControlName="inspection_type">
                            <option *ngFor="let item of types" [ngValue]="item['id']">{{item['name']}}</option>
                        </select>
                    </mat-form-field>
                </div>
                    <mat-label> Description</mat-label>
              <div *ngIf="!updateEditor">
                <editor
                  formControlName="description"
                  apiKey="6tb9ekfidko0nolwuntzi5j5sdmynss8gsee3l8r9rmwu4ee"
                  [init]="config"

                ></editor>
<!--                <app-tiny-editor  (fieldVal)="setEditorControl($event)"  [value]="desc" lang="en" field="description" [editorLoading]="updateEditor"-->
<!--                ></app-tiny-editor>-->
              </div>

                <mat-form-field class="flex-auto mt-4">
                    <mat-label> Inspection Description</mat-label>
                    <textarea formControlName="inspection_description" matInput></textarea>
                </mat-form-field>
                <div class="flex gap-3 justify-between items-center">
                    <mat-form-field class="flex-auto">

                        <mat-label>Notes</mat-label>
                        <input cdkFocusInitial formControlName="note" placeholder="add notes" matInput>
                    </mat-form-field>
                    <div class="underline italic mx-2 text-primary cursor-pointer p-2 rounded-full bg-[#d7e512] justify-center items-center flex" mat-flat-button  (click)="addNote()">
                        <mat-icon svgIcon="mat:playlist_add"></mat-icon>
                    </div>
                </div>
                <ul *ngIf="notes.length>0" class="mt-3 py-3 border flex-wrap border-gray border-solid rounded-2xl p-2 flex gap-2 mb-4">
                    <li *ngFor="let item of notes; let i = index" (click)="removeNote(i)" class="flex gap-2">
                        <label class="px-4 bg-[#E8F1FF] text-[#0B62E4] py-2 rounded-xl">{{item}}</label>
                    </li>
                </ul>
                <div class="flex gap-3" *ngIf="!activePart">
                    <mat-slide-toggle formControlName="has_inline_images" (change)="hasImages($event.checked)" >images</mat-slide-toggle>
                    <mat-slide-toggle formControlName="has_video" (change)="hasVideos($event.checked)" > video</mat-slide-toggle>
                </div>
              <div  *ngIf="activePart" class="flex categories">
                <ng-container *ngFor="let item of activePart.media;let i=index">
                  <div class="flex items-center justify-center">
                    <div class="my-2 media-card w-[200px] h-[200px] border border-black-200 m-0.5">
                      <img (error)="handleImageError($event)" class="w-[200px]" [src]="item?.['original_url']" alt="{{ item?.['custom_properties']?.['alt']??'' }}">
                      <div class="overlay">
                        <div class="button flex w-[40px]"  >
                          <div class="button "  (click)="deleteMediaFile(item['id'])">
                            <mat-icon svgIcon="mat:delete" class="light"></mat-icon>
                          </div>
                          <div class='flex-auto' (click)="updateMedia(item)">
                            <mat-icon svgIcon="mat:edit" class="light"></mat-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div  *ngIf="activePart?.video?.length>0" class="flex">
<!--                <ng-container *ngFor="let item of activePart.video;let i=index">-->
                  <div class="flex items-center justify-center">
                    <div class="my-2 media-card w-[200px] h-[200px] border border-black-200 m-0.5" *ngFor="let video of activePart.video">
                      <div class="flex items-center justify-center">
                        <video   [src]="video?.['original_url']"   class="h-[100px] p-1" height="50" autoplay [muted]="true"></video>
                      </div>
                      <div class="overlay">
                        <div class="button flex w-[40px]"  >
                          <div class="button "  (click)="deletevideo(video['id'])">
                            <mat-icon svgIcon="mat:delete" class="light"></mat-icon>
                          </div>
                          <div class='flex-auto' (click)="updateMedia(video)">
                            <mat-icon svgIcon="mat:edit" class="light"></mat-icon>
                          </div>
                        </div>
                      </div>
<!--                      <div class="overlay flex items-center justify-center ">-->
<!--                        -->
<!--                      </div>-->

                    </div>
                  </div>
<!--                </ng-container>-->
              </div>
<!--                <label *ngIf="form.value?.['has_video'] == false" for="file-upload" class="flex flex-col justify-center items-center p-3 border-dashed border-gray rounded-lg border cursor-pointer hover:shadow-lg mt-4">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-cloud-upload" viewBox="0 0 16 16">-->
<!--                        <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383"/>-->
<!--                        <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708z"/>-->
<!--                    </svg>-->
<!--                    <h4 class="mt-2 text-center">Upload one image </h4>-->
<!--                    <input accept="image/*" id="file-upload" type="file" (change)="onFileSelected($event)"  style="display: none;" />-->
<!--                </label>-->
<!--              -->
              <label *ngIf="form.value?.['has_video'] == false" class="flex flex-col justify-center items-center p-3 border-dashed border-gray rounded-lg border cursor-pointer hover:shadow-lg mt-4" (click)="onFileSelected($event)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-cloud-upload" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383"/>
                        <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708z"/>
                    </svg>
                    <h4 class="mt-2 text-center">Upload gallery images </h4>
<!--                    <input accept="image/*" id="file-upload-gallery" type="file" (change)="onFileSelected($event)" multiple style="display: none;" />-->
                </label>
                <label  *ngIf="form.value?.['has_video'] == true && activePart?.video==null"  for="file-upload-video" class="flex flex-col justify-center items-center p-3 border-dashed border-gray rounded-lg border cursor-pointer hover:shadow-lg mt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-cloud-upload" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383"/>
                        <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708z"/>
                    </svg>
                    <h4 class="mt-2 text-center">Upload video</h4>
                    <input accept="video/*" id="file-upload-video" type="file" (change)="onFileSelected($event)"  style="display: none;" />
                    <video  [src]="videoURL" *ngIf=" videoURL"  class="h-[100px]" height="50" autoplay muted></video>
                </label>
                <span class="text-center text-blue-600" *ngIf="createdMedia">{{createdMedia['count']}} file selected</span>

            </form>
    </div>
    <div class="col-span-12  w-full rounded-lg bg-red items-center">
<!--        <app-report-pdf [report]="report" class="w-full "/>-->

    </div>
</div>

