import {Product} from './product.model';

export class AuctionItemModel {
  id: number;
  lot_identifier: string;
  product_id: string;
  ring_id: string;
  status: string;
  estimated_time: string;
  start_price: number;
  minimum_price : number;
  estimated_price_min : number;
  estimated_price_max : number;
  buy_now_price : string;
  start_datetime : string;
  end_datetime : string;
  winner_id : string;
  auction_id : string;
  product_name : string;
  product:Product;


    constructor(obj) {
        this.id = obj.id;
        this.lot_identifier = obj.lot_identifier;
        this.product_id = obj.product_id;
        this.start_datetime = obj.start_datetime;
        this.ring_id = obj.ring_id;
        this.status = obj.status;
        this.start_price = obj.start_price;
        this.estimated_time = obj.estimated_time;
        this.minimum_price = obj.minimum_price;
        this.buy_now_price = obj.buy_now_price;
        this.end_datetime = obj.end_datetime;
        this.winner_id = obj.winner_id;
        this.auction_id = obj.auction_id;
        this.estimated_price_min = obj.estimated_price_min;
        this.estimated_price_max = obj.estimated_price_max;
        this.product_name = obj.product.title;
        this.product = obj.product;

    }
}
