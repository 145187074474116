<div (mouseenter)="collapseOpenSidenav()"
     (mouseleave)="collapseCloseSidenav()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col">
  <div class="vex-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex items-center">
      <img [src]="imageUrl$ | async" alt="Logo" class="w-32 select-none flex-none"/>
      <button (click)="toggleCollapse()"
              *ngIf="showCollapsePin$ | async"
              class="-mr-4 leading-none flex-none hidden lg:block"
              mat-icon-button
              type="button">
        <mat-icon *ngIf="!collapsed"
                  class="icon-xs"
                  svgIcon="mat:radio_button_checked"></mat-icon>
        <mat-icon *ngIf="collapsed"
                  class="icon-xs"
                  svgIcon="mat:radio_button_unchecked"></mat-icon>
      </button>
    </div>

<!--    <div *ngIf="searchVisible$ | async" class="vex-sidenav-search__container">-->
<!--      <div class="vex-sidenav-search relative"-->
<!--           (click)="openSearch()"-->
<!--           matRipple-->
<!--           matRippleColor="var(&#45;&#45;sidenav-item-ripple-color)">-->
<!--        <mat-icon class="flex-none vex-sidenav-search__icon" svgIcon="mat:search"></mat-icon>-->
<!--        <div class="flex-auto vex-sidenav-search__placeholder">Quick Search</div>-->
<!--        <div class="flex-none vex-sidenav-search__keybind">Ctrl K</div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="vex-sidenav-toolbar__divider"></div>
  </div>

  <vex-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></vex-sidenav-item>
    </div>

    <div class="vex-sidenav-footer flex items-center justify-center opacity-20 px-6 py-6 select-none flex-none">
      <img [src]="imageUrl$ | async" alt="Logo" class="w-8 select-none"/>
    </div>
  </vex-scrollbar>

  <div *ngIf="userVisible$ | async"
       class="vex-sidenav-user__container flex-none">
    <div #userProfileMenuOriginRef
         (click)="openProfileMenu(userProfileMenuOriginRef)"
         [class.vex-sidenav-user--open]="userMenuOpen$ | async"
         class="vex-sidenav-user flex items-center"
         matRipple
         matRippleColor="var(--sidenav-item-ripple-color)">
      <svg alt="User Avatar" class=" bi bi-person-gear vex-sidenav-user__image flex-none" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"viewBox="0 0 16 16">
        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
      </svg>
      <div class="vex-sidenav-user__content flex-auto">
        <div class="vex-sidenav-user__title">{{admin?.full_name ?? ''}}</div>
        <div class="vex-sidenav-user__subtitle">Project Manager</div>
      </div>
      <mat-icon class="vex-sidenav-user__dropdown-icon flex-none" svgIcon="mat:arrow_drop_down"></mat-icon>
    </div>
  </div>
</div>
