import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatIconModule} from '@angular/material/icon';
import {PageLayoutModule} from '../../../../../../../@vex/components/page-layout/page-layout.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BreadcrumbsModule} from '../../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LoadingRowComponent} from '../../../../../../core/components/loading-row/loading-row.component';
import {MatTabsModule} from '@angular/material/tabs';
import {PageLayoutDemoModule} from '../../../../../ui/page-layouts/page-layout-demo/page-layout-demo.module';
import {RouterLinkActive} from '@angular/router';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {AppModule} from '../../../../../../app.module';
import {BusinessService} from '../../../business-setting/service/business.service';
import {of} from 'rxjs';
import {BusinessModel} from '../../../../models/business.model';
import {

  Validators
} from '@angular/forms';
import {AuctionService} from '../../../auction/service/auction.service';
import {SvgRendererComponent} from '../../../../../../core/components/svg-icon/svg-icon.component';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'vex-add-bids',
  templateUrl: './add-bids.component.html',
  styleUrls: ['./add-bids.component.scss'],
  standalone:true,
  imports: [
    ScrollingModule,
    MatIconModule,
    PageLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    NgClass,
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbsModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    LoadingRowComponent,
    MatTabsModule,
    PageLayoutDemoModule,
    RouterLinkActive,
    MatRippleModule,
    MatOptionModule,
    MatSelectModule,
    SvgRendererComponent
  ]
})
export class AddBidsComponent implements OnInit{

  auction_increment;
  @Input() current;
  @Input() listing_id;
  loading=true;
  bidLoading=false;
  // @ts-ignore
  amount = new FormControl(0, [Validators.required]);
  constructor(
    private serviceBusiness:BusinessService,
    private auctionService : AuctionService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.getSetting();
    }
  getSetting(){

    this.serviceBusiness.get().subscribe((res)=>{
      of(res['data'].map(type=>new BusinessModel(type))).subscribe(types=>{
        this.auction_increment = types.filter(type=>type.key=='auction_increment')[0]?.value?.map((item) => {
          return {
            min: +item.min,
            max: +item.max,
            step: +item.step
          };
        });
          this.calcAmount();
      });
    });
  }
    calcAmount(){
      this.amount.setValidators([Validators.required, Validators.min(Number.parseInt(this.current) + this.getBiddingStep)]);
      this.amount.setValue( Number.parseInt(this.current) + this.getBiddingStep);
      this.amount.updateValueAndValidity();

      this.loading=false;

    }
  get getBiddingStep() {
    let step;
    if (this.auction_increment?.length > 0) {
      step = this.auction_increment.find((item) => item.min <= this.current && this.current < item.max)
        ?.step;
    }
    return step ? step : 1;
  }
    increaseAmount() {
    if (this.amount.value) {
      this.amount.setValue(Number.parseInt(String(this.amount.value)) + this.getBiddingStep);
    }
  }

  decreaseAmount() {
    if (this.amount.value && this.amount.value > this.current + this.getBiddingStep) {
      this.amount.setValue(this.amount.value - this.getBiddingStep);
    }
  }
  bid(){
    this.bidLoading=true;
    const formdata = new FormData();
    formdata.append('listing_id',this.listing_id);
    formdata.append('amount',this.amount.value.toString());
    this.auctionService.addBid(formdata).subscribe((res)=>{
      this.bidLoading = false;
    }, error => {
      this.bidLoading=false;
      this.toastr.error(error);
    });
  }
  ngOnChanges(changes: SimpleChanges): void {

    if(changes.current){
      this.calcAmount();
    }
  }
}
