export class HttpErrorModel {
    message: string;
    errorMessages: Array<string>;
    statusCode: number;

    constructor(message: string,
                errorMessages: Array<string>,
                statusCode: number) {
        this.message = message;
        this.errorMessages = errorMessages;
        this.statusCode = statusCode;
    }
}
