<div class="grid grid-cols-12 ">
    <div class="col-span-12  md:col-span-8  card p-3 m-3 ">
      <div class="flex">
        <div class=" col-span-8  md:col-span-6 flex-auto w-full p-2 m-2 h-[75vh]" *ngIf="!loading">

          <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="history-container">

            <mat-tab-group class="max-w-full">
              <mat-tab label="Details">
                  <vex-ongoing-details [model]="activeModel" [from_details]="true"></vex-ongoing-details>
              </mat-tab>
              <mat-tab label="Bids History">
                <vex-bids-history [bids]="activeModel?.bids"></vex-bids-history>
              </mat-tab>
              <mat-tab label="Proxy Bid">
                <vex-proxy-bid [bids]="activeModel?.settings"></vex-proxy-bid>
              </mat-tab>
            </mat-tab-group>
          </cdk-virtual-scroll-viewport>

        </div>
        <div class="flex-auto">
      </div >
        <div *ngIf="!loading && activeModel?.ring?.auction_type=='LIVE'">
          <vex-auto-closing-auction [ring_id]="id"></vex-auto-closing-auction>
        </div>
      </div>
        <vex-loading-row [diameter]="40" *ngIf="loading"></vex-loading-row>
    </div>
    <div class="col-span-12  md:col-span-4">
        <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport">
            <div class="card p-3 m-3 w-[350px] " [ngClass]="item?.id==activeModel?.id?activeClass:''"
                 *ngFor="let item of items">
                <div class="flex gap-2 rounded w-full" (click)="selectActiveItems(item?.id)">
                    <img src="{{item?.product.cover?.url}}" class="w-[100px] h-full ">
                    <div class="flex flex-col justify-content-between">
                        <h2 class="font-medium	">{{item.product.title}}</h2>
                        <label>
                            {{ item?.product?.listing?.listing_status }}
                        </label>

                    </div>

                </div>
            </div>
        </cdk-virtual-scroll-viewport>
        <!--    </div>-->
    </div>

</div>



