import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Product} from '../../../models/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) {
  }
  paginate(perPage:number,page:number){
    // return this.http.get(environment.baseUrl + 'product/products/paginate?per_page='+perPage+'&page='+page+'&filters[kind]=PRODUCT');
   return this.http.get(environment.baseUrl + 'product/products/paginate?per_page='+perPage+'&page='+page);
  }
  get() {
    // return this.http.get(environment.baseUrl + 'product/products?filters[reserved]=false');
    return this.http.get(environment.baseUrl + 'product/products');
  }

  getMedia(id) {
    return this.http.get(environment.baseUrl + 'product/products/' + id + '/media');
  }

  getInspectionReport(id) {
    return this.http.get(environment.baseUrl + 'product/products/' + id + '/inspection-report');
  }

  addMedia(product, id) {
    return this.http.post(environment.baseUrl + 'product/products/' + id + '/media/all', product);
  }

  getProduct(id) {
    return this.http.get(environment.baseUrl + 'product/products/' + id);
  }

  create(type: Product) {
    return this.http.post(environment.baseUrl + 'product/products', type);
  }

  update(type: any, id) {
    return this.http.post(environment.baseUrl + 'product/products/' + id, type);
  }

  updateMedia(media: any, id, productId) {
    return this.http.post(environment.baseUrl + 'product/products/' + productId + '/media/'+ id, media);
  }
  publish( productId) {
    return this.http.put(environment.baseUrl + 'product/products/' + productId + '/publish', {});
  }
  unpublish( productId) {
    return this.http.put(environment.baseUrl + 'product/products/' + productId + '/un-publish' , {});
  }

  delete(type: Product) {
    return this.http.delete(environment.baseUrl + 'product/products/' + type.id);
  }

  deleteMedia(id, productId) {
    return this.http.delete(environment.baseUrl + 'product/products/' + productId + '/media/' + id);
  }

  getSingleMedia( productId , id,) {
    return this.http.get(environment.baseUrl + 'product/products/' + productId + '/media/' + id);
  }
  deleteMediaCollection( productId , name) {
    return this.http.delete(environment.baseUrl + 'product/products/' + productId + '/media/all/' + name);
  }
  updateSpecification(productId,data:FormData) {
    return this.http.post(environment.baseUrl + 'product/products/' + productId + '/specifications',data);
  }
  deleteDocs(productId :any) {
    return this.http.delete(environment.baseUrl + 'product/products/' + productId + '/documents' );
  }
  uplodeThreeSixty(productId :any,data) {
    return this.http.post(environment.baseUrl + 'product/products/' + productId + '/media/save-three-sixty-collection',data );
  }
  uplodePanorama(productId :any, mediaId: any , data : any) {
    return this.http.post(environment.baseUrl + 'product/products/' + productId + '/media/' + mediaId + '/metadata' ,data );
  }
}
