import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {fadeInUp400ms} from '../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../@vex/animations/stagger.animation';
import {PageLayoutModule} from '../../../../../@vex/components/page-layout/page-layout.module';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup, Validators
} from '@angular/forms';
import {BreadcrumbsModule} from '../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LoadingRowComponent} from '../../../../core/components/loading-row/loading-row.component';
import {MatTabsModule} from '@angular/material/tabs';
import {PageLayoutDemoModule} from '../../../ui/page-layouts/page-layout-demo/page-layout-demo.module';
import {Router, RouterLinkActive} from '@angular/router';
import {MatOptionModule, MatRippleModule, ThemePalette} from '@angular/material/core';
import {filter, Observable, of, ReplaySubject} from 'rxjs';
import {Brand} from '../../models/brand.model';
import {TableColumn} from '../../../../../@vex/interfaces/table-column.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {BrandModel} from '../../models/brand-model.model';
import {MatSort} from '@angular/material/sort';
import {BrandsService} from '../brands/service/brands.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageRefService} from '../../../pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../../../../core/enum/storage-keys.enum';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ErrorStatusModel} from '../../../../core/enum/error-status.model';
import {LiveShowModel} from '../../models/live-show.model';
import {LiveshowService} from './service/liveshow.service';
import {SpecificationTypeEnum} from '../../../../core/enum/specification-type.enum';
import {LiveShowTypeEnum} from '../../../../core/enum/live-show-type.enum';
import {MethodsUtils} from '../../../../core/utils/method-utils';
import {ProductService} from '../products/service/product.service';
import {Product} from '../../models/product.model';
import {MatSelectModule} from '@angular/material/select';
import {object} from '@angular/fire/database';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {ReviewsService} from '../expert-reviews/service/reviews.service';
@UntilDestroy()
@Component({
  selector: 'vex-live-show',
  templateUrl: './live-show.component.html',
  styleUrls: ['./live-show.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    PageLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    NgClass,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbsModule,
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    LoadingRowComponent,
    MatTabsModule,
    PageLayoutDemoModule,
    RouterLinkActive,
    MatRippleModule,
    MatOptionModule,
    MatSelectModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class LiveShowComponent implements OnInit, AfterViewInit {

  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<LiveShowModel[]> = new ReplaySubject<LiveShowModel[]>(1);
  data$: Observable<LiveShowModel[]> = this.subject$.asObservable();
  customers: LiveShowModel[];

  @Input()
  columns: TableColumn[] = [
    {label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true},
    {label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Image', property: 'cover', type: 'image', visible: true},
    {label: 'Name', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Status', property: 'status', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Start Datetime', property: 'start_datetime', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Actions', property: 'actions', type: 'button', visible: true}

  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<LiveShowModel> | null;
  selection = new SelectionModel<LiveShowModel>(true, []);
  searchCtrl = new UntypedFormControl();
  form: UntypedFormGroup;
  isPanelOpen = false;
  loading = false;
  getLoading = true;
  isAuctionType = false;
  defaults ?: LiveShowModel;
  mode: 'create' | 'update' = 'create';
  selectedFile: string = '';
  locale;
  defLocale;
  types;
  products=[];
  prod;
  experts=[];
  expe;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public color: ThemePalette = 'primary';

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private service: LiveshowService,
    private productService: ProductService,
    private expertService: ReviewsService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private localStorageRefService : LocalStorageRefService,
    private methods: MethodsUtils,
    private route:Router
  ) {

  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }


  getData() {

    this.service.all().subscribe((res) => {


      of(res['data'].map(obj => new LiveShowModel(obj))).subscribe(objects => {

        this.subject$.next(objects);


        this.getProduct();
        this.getExpert();

        this.getLoading=false;
      });

    });
  }
  getProduct() {

    this.productService.get().subscribe((res) => {

     this.prod =res;
     this.products = this.prod.filter(item =>item.deleted_at==null);

    });
  }
  getExpert() {

    this.expertService.get().subscribe((res) => {

      this.expe =res['data'];
      this.experts = this.expe.filter(item =>item.deleted_at==null);

    });
  }

  ngOnInit() {
    this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
    this.defLocale = this.locale.filter(loc=>loc.default==true)[0];

    this.types = this.methods.enumToArray(LiveShowTypeEnum);
    this.getData();

    this.clearForm();
    this.dataSource = new MatTableDataSource();
    this.selectedFile='assets/img/demo/poster_placeholder.jpg';
    this.data$.pipe(
      filter<LiveShowModel[]>(Boolean)
    ).subscribe(customers => {
      this.customers = customers;
      this.dataSource.data = this.customers;
    });

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  createBrand() {
    this.clearForm();
    this.isPanelOpen = true;
    this.scrollToForm();
  }
  initForm(app:LiveShowModel){
    this.isPanelOpen=true;
    this.isAuctionType=app?.type=='AUCTION';
    this.mode='update';
    this.selectedFile = app.cover===null?'assets/img/demo/poster_placeholder.jpg':app.cover;
    this.form = this.fb.group({
      id: [app?.id || null],
      type: [app?.type || '' ,Validators.required],
      start_datetime: [new Date(app?.start_datetime) || '' ],
      products: [app?.products.map(data =>data.id)??[]],
      is_trending: [app?.is_trending??false],
      is_featured: [app?.is_featured??false],

      cover: [null]

    });
    this.locale.forEach(lang => {
      const value = app.translations.filter(brand => brand.locale == lang.locale);

      this.form.addControl(lang.locale+'_name', this.fb.control(value[0]?.name??'',lang.default?Validators.required:[]));
      this.form.addControl(lang.locale+'_description', this.fb.control(value[0]?.description??'',lang.default?Validators.required:[]));
    });

  }
  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
      this.form.patchValue({cover: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedFile = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }

  }
  create(){
    this.clearForm();
    this.isPanelOpen = true;
    this.scrollToForm();
  }
  clearForm(){
    this.isPanelOpen = false;
    this.isAuctionType = false;
    this.mode='create';
    this.selectedFile='assets/img/demo/poster_placeholder.jpg';
    this.form = this.fb.group({
      id: [ null],
      type: ['PRODUCT' ,Validators.required],
      cover: [null,Validators.required],
      products: [null ,Validators.required],
      start_datetime: ['' ,Validators.required],
      is_trending: [false ,Validators.required],
      is_featured: [false ,Validators.required]

    });


    this.locale.forEach(lang => {

      this.form.addControl(lang.locale+'_name', this.fb.control('',lang.default?Validators.required:[]));
      this.form.addControl(lang.locale+'_description', this.fb.control('',lang.default?Validators.required:[]));
    });

  }
  updateBrand(brand: LiveShowModel) {
    this.initForm(brand);
    this.scrollToForm();
  }
  isCreateMode() {
    return this.mode === 'create';
  }
  scrollToForm() {
    const formElement = this.elementRef.nativeElement.querySelector('#form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  deleteBrand(obj: LiveShowModel) {
    this.service.delete(obj.id).subscribe((res) => {
      if(res['status'] == ErrorStatusModel.SUCCESS) {
        this.toastr.success('Show Deleted Successfully!');
        this.ngOnInit();
        this.selection.deselect(obj);
      }
    },error =>
    {
      this.toastr.error(error['message']);

    });
  }

  deleteBrands(brands: LiveShowModel[]) {
    brands.forEach(c => this.deleteBrand(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }

  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/poster_placeholder.jpg';
  }
  save(){
    this.loading=true;
    const formData=new FormData();
    if(this.mode === 'update') {
      formData.append('id', this.form.value?.id);
    }
    if (this.form.value.cover instanceof File)
      formData.append('media[image]', this.form.value.cover);

    this.form.value.products.forEach((p,index) =>{
      formData.append(`liveables[${index}][liveable_id]`, p);
      formData.append(`liveables[${index}][order]`, index);
      formData.append(`liveables[${index}][status]`, index==0?'ACTIVE':'INACTIVE');
    });


    if(this.form.value.type!='AUCTION'){
      formData.append('type',this.form.value.type);
      formData.append('start_datetime', this.form.value.start_datetime.toUTCString());

    }

    formData.append('is_trending', this.form.value.is_trending?'1':'0');
    formData.append('is_featured', this.form.value.is_featured?'1':'0');
    formData.append('name',this.form.value[this.defLocale.locale + '_name']);
    formData.append('description',this.form.value[this.defLocale.locale + '_description']);

    this.locale.filter(lang => lang.default==0 ).forEach(lang =>{
      if(this.form.value[lang.locale + '_name']!='')
        formData.append(lang.locale +'[name]',this.form.value[lang.locale + '_name']);
    });
    if (formData) {
      this.service.save(formData).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Show '+this.mode+'d Successfully!');
          this.ngOnInit();
          this.loading=false;

        }
      }, error => {
        this.loading=false;
        this.toastr.error(error);
      });
    }}
  activeTab = 'en';
  setActiveTab(tabNumber: string) {
    this.activeTab = tabNumber;
    this.cdr.detectChanges();
  }

  showDetails(id){
    this.route.navigate(['live-show/'+id]);
  }
}

