<h2 class="font-semibold ">Three-Sixty</h2>
<!--<input type="file" (change)="onFileSelected($event)">-->
<div class="flex mt-5">
  <input type="file" id="fileInput" accept=".zip" (change)="onFileSelected($event)" hidden>
  <label for="fileInput" class="file-upload-label">Select File</label>
  <div  class="file-details flex-auto border">
    <p><strong>Selected File:</strong> {{ selectedFile?.name??'' }}</p>
  </div>
</div>

<div class="p-4 flex justify-end">
  <button color="primary" class="flex items-center justify-center" mat-flat-button type="submit" (click)="save()" [disabled]="selectedFile==null" ><span  *ngIf="!loading">Upload</span>
    <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>
  </button>
</div>
