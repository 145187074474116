import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Specification } from '../../../models/specification.model';

@Injectable({
  providedIn: 'root'
})
export class SpecificationService {

  constructor(private http:HttpClient) { }

  get(){
    return this.http.get(environment.baseUrl + 'specification/specification');
   }
   getByBase(id:any){
    return this.http.get(environment.baseUrl + 'base-product/base-products/get-specifications/'+ id);
   }
   create(type:FormData){
     return this.http.post(environment.baseUrl + 'specification/specification',type);
    }
    update(type:Specification,id : number){
     return this.http.put(environment.baseUrl + 'specification/specification/'+id,type);
    }
    delete(type:Specification){
     return this.http.delete(environment.baseUrl + 'specification/specification/'+type.id);
    }
}
