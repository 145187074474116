import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {BreadcrumbsModule} from '../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup, Validators
} from '@angular/forms';
import {MatTable, MatTableDataSource, MatTableModule} from '@angular/material/table';
import {PageLayoutModule} from '../../../../../@vex/components/page-layout/page-layout.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatFormFieldDefaultOptions,
    MatFormFieldModule
} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {LoadingRowComponent} from '../../../../core/components/loading-row/loading-row.component';
import {filter, Observable, of, ReplaySubject} from 'rxjs';
import {TableColumn} from '../../../../../@vex/interfaces/table-column.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ErrorStatusModel} from '../../../../core/enum/error-status.model';
import {AuctionModel} from '../../models/auction.model';
import {AuctionService} from './service/auction.service';
import {MethodsUtils} from '../../../../core/utils/method-utils';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CreateRingComponent} from './create-ring/create-ring.component';
import {RingModel} from '../../models/ring.model';
import {AuctionTypeEnum} from '../../../../core/enum/auction-type.enum';
import {ReplaceUnderscoresPipe} from '../../../../core/pipes/replace-underscore.pipe';
import {MatTabsModule} from '@angular/material/tabs';
import {StorageKeysEnum} from '../../../../core/enum/storage-keys.enum';
import {LocalStorageRefService} from '../../../pages/auth/service/local-storage-ref.service';
import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {ThemePalette} from '@angular/material/core';
import {Brand} from '../../models/brand.model';

@UntilDestroy()
@Component({
    selector: 'vex-auction',
    templateUrl: './auction.component.html',
    styleUrls: ['./auction.component.scss'],
    standalone: true,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            )
        ])
    ],
    imports: [
        BreadcrumbsModule,
        MatCheckboxModule,
        NgClass,
        MatSlideToggleModule,
        MatMenuModule,
        MatIconModule,
        MatPaginatorModule,
        FormsModule,
        MatTableModule,
        ReactiveFormsModule,
        PageLayoutModule,
        CommonModule,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatSelectModule,
        LoadingRowComponent,
        MatDatepickerModule,
        MatSortModule,
        ReplaceUnderscoresPipe,
        MatTabsModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            } as MatFormFieldDefaultOptions
        }
    ]
})
export class AuctionComponent implements OnInit, AfterViewInit {

    layoutCtrl = new UntypedFormControl('boxed');

    /**
     * Simulating a service with HTTP that returns Observables
     * You probably want to remove this and do all requests in a service with HTTP
     */
    subject$: ReplaySubject<AuctionModel[]> = new ReplaySubject<AuctionModel[]>(1);
    data$: Observable<AuctionModel[]> = this.subject$.asObservable();
    numbers: number[] = Array.from({length: 10}, (_, i) => i + 1);

    @ViewChild('outerSort', {static: true}) sort: MatSort;
    @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
    @ViewChildren('innerTables') innerTables: QueryList<MatTable<RingModel>>;

    // data: AuctionModel[] = this.data$;

    dataSource: MatTableDataSource<AuctionModel>;
    usersData: AuctionModel[] = [];
    columnsToDisplay = ['cover', 'name', 'description', 'start_datetime', 'end_datetime', 'status', 'type', 'location', 'actions'];
    innerDisplayedColumns = ['name', 'start_datetime', 'actions'];
    expandedElements: any[] = [];

    @Input()
    columns: TableColumn[] = [
        {label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true},
        {label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'Name', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'Description', property: 'description', type: 'text', visible: false, cssClasses: ['font-medium']},
        {label: 'Start Time', property: 'start_datetime', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'Status', property: 'status', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'Type', property: 'type', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'Actions', property: 'actions', type: 'button', visible: true}


    ];
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 20, 50];
    selection = new SelectionModel<AuctionModel>(true, []);
    searchCtrl = new UntypedFormControl();


    getLoading = true;


    groups = [];


    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    // @ViewChild(MatSort, { static: true }) sort: MatSort;


    constructor(
        private dialog: MatDialog,
        private service: AuctionService,
        private toastr: ToastrService,
        private fb: UntypedFormBuilder,
        private elementRef: ElementRef,
        private methods: MethodsUtils,
        private router: Router,
        private cd: ChangeDetectorRef,
        private localStorageRefService: LocalStorageRefService
    ) {
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }


    getData() {
        this.service.all().subscribe((res) => {
            of(res['data'].map(type => new AuctionModel(type))).subscribe(types => {
                this.subject$.next(types);
                this.getLoading = false;
            });
        });
    }

    ngOnInit() {
        this.getData();
        this.dataSource = new MatTableDataSource();
        this.data$.pipe(
            filter<AuctionModel[]>(Boolean)
        ).subscribe(types => {

            this.dataSource.data = types;
        });

        this.searchCtrl.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(value => this.onFilterChange(value));
        this.dataSource.data.forEach((AuctionModel) => {
            if (
                AuctionModel.rings &&
                Array.isArray(AuctionModel.rings) &&
                AuctionModel.rings.length
            ) {
                this.usersData = [
                    ...this.usersData,
                    {...AuctionModel, rings: new MatTableDataSource(AuctionModel.rings)}
                ];
            } else {
                this.usersData = [...this.usersData, AuctionModel];
            }
        });
        this.dataSource = new MatTableDataSource(this.usersData);
        this.dataSource.sort = this.sort;
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    create() {
      this.router.navigate(['/auction/save']).then();
    }





    update(type: AuctionModel) {
      this.router.navigate(['/auction/'+type.id]).then();
    }





    delete(type: AuctionModel) {
        this.service.delete(type.id).subscribe((res) => {
            if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
                this.toastr.success('Auction Updated Successfully!');
                this.ngOnInit();
                this.selection.deselect(type);
            }
        }, () => {
            this.toastr.error('error !');
        });
    }

    deleteRing(type: RingModel) {
        this.service.deleteRing(type.id).subscribe((res) => {
            if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
                this.toastr.success('Ring Updated Successfully!');
                this.ngOnInit();
            }
        }, () => {
            this.toastr.error('error !');
        });
    }

    deleteAll(types: AuctionModel[]) {
        types.forEach(c => this.delete(c));
    }

    onFilterChange(value: string) {
        if (!this.dataSource) {
            return;
        }
        value = value.trim();
        value = value.toLowerCase();
        this.dataSource.filter = value;
    }

    toggleColumnVisibility(column, event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        column.visible = !column.visible;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }


    trackByProperty<T>(index: number, column: TableColumn) {
        return column.property;
    }

    setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }

    showRings(id) {
        this.router.navigate(['auction/' + id + '/ring']);
    }

    addRing(auction: AuctionModel) {
        this.dialog.open(CreateRingComponent, {
            data: {auctionId: auction.id}
        }).afterClosed().subscribe((res: any) => {
            if (res)
                this.getData();
        });
    }

    editRing(ring: RingModel) {
        this.dialog.open(CreateRingComponent, {
            data: {data: ring, auctionId: ring.auction_id, id: ring.id}
        }).afterClosed().subscribe((res: any) => {
            if (res)
                this.getData();
        });
    }

    toggleElement(row: any) {
        const index = this.expandedElements.findIndex((x) => x.name == row.name);
        if (index === -1) {
            this.expandedElements.push(row);
        } else {
            this.expandedElements.splice(index, 1);
        }

    }


    isExpanded(row: AuctionModel): string {
        const index = this.expandedElements.findIndex((x) => x.name == row.name);
        if (index !== -1) {
            return 'expanded';
        }
        return 'collapsed';
    }

    applyFilter(filterValue: string) {
        this.innerTables.forEach(
            (table, index) =>
                ((table.dataSource as MatTableDataSource<RingModel>).filter = filterValue
                    .trim()
                    .toLowerCase())
        );
    }

    toggleRow(element: AuctionModel) {
        element.rings &&
        (element.rings as MatTableDataSource<RingModel>).data.length
            ? this.toggleElement(element)
            : null;
        this.cd.detectChanges();
        this.innerTables.forEach(
            (table, index) =>
                ((table.dataSource as MatTableDataSource<RingModel>).sort =
                    this.innerSort.toArray()[index])
        );
    }

    getItems(id: number) {
        this.router.navigate(['auction/' + id + '/items']);
    }

    goToRingDetails(id) {
        this.router.navigate(['auction/' + id + '/ring-details']);
    }

  resetAuction() {
    this.getLoading=true;
    this.service.reset().subscribe((res) => {

      this.ngOnInit();
    });
  }

}


