

export enum FilterTypeEnum {
  DATE = 'DATE',
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  RADIO = 'RADIO',

}
