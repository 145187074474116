
  <div  *ngIf="!loadingMedia">
    <mat-tab-group class="custom-tab-group">
      <mat-tab *ngFor="let collection of groupedMediaArray;let j = index" [label]="collection.collection_name">
    <div  class=" bg-gray-50 list-col rounded-lg w-full  h-fit overflow-scroll  relative shadow shadow-2xl">
          <div class="bg-white sticky top-0 z-[2] p-2 flex items-center justify-center">
            <button   class="px-3 py-2 self-center justify-center items-center   border border-gray rounded-lg cursor-pointer" (click)="DeleteMediaCollection(collection.collection_name)">
              Delete {{ collection.collection_name }}
            </button>
            <a href="{{environment.generalUrl}}v1/website/product/products/{{productID}}/download-collection/{{collection.collection_name}}" target="_blank"  class="px-3 py-2 self-center justify-center items-center border border-gray rounded-lg cursor-pointer">Download collection</a>
            <button   class="px-3 py-2 self-center justify-center items-center   border border-gray rounded-lg cursor-pointer" (click)="AddMedia(collection.collection_name)">
              Upload Media
            </button>
          </div>
<!--      *ngIf="collection.type=='image'|| collection.type=='360'"-->
          <div #contenedor class="categories gap-2 " cdkDropListGroup >
            <ng-container *ngFor="let item of collection?.media;let i=index">
              <div class="flex items-center justify-center" cdkDropList  cdkDropListOrientation="horizontal"
                   [cdkDropListData]="{item:item,index:i}" (cdkDropListDropped)="drop($event ,j)">
                <div class="my-0.5 media-card border border-gray-400" cdkDrag>
                  <div class="  " *cdkDragPlaceholder></div>
                  <img *ngIf="!isVideo(item?.['mime_type'])" (error)="handleImageError($event)" class="w-[200px]" [src]="item?.['original_url']" alt="{{ item?.['custom_properties']?.['alt']??'' }}">
                  <video autoplay muted *ngIf="isVideo(item?.['mime_type'])" [src]="item?.['original_url']" class="w-[200px]"></video>
                  <div class="overlay"></div>
                  <div class="button flex w-[40px]"  >
                    <div class='flex-auto' (click)="DeleteMedia(item['id'])">
                      <mat-icon svgIcon="mat:delete" class="light"></mat-icon>
                    </div>
                    <div class='flex-auto' (click)="updateMedia(item)">
                      <mat-icon svgIcon="mat:edit" class="light"></mat-icon>
                      </div>
                      <div *ngIf="collection?.['collection_name'] =='panorama'" class='flex-auto' (click)="updateMediaHotspots(item , collection)">
                        <mat-icon svgIcon="mat:panorama_horizontal" class="light"></mat-icon>

                    </div>
                  </div>
                </div>

              </div>
            </ng-container>
          </div>

    </div>
      </mat-tab>
<!--      <mat-tab label="New Collection">-->

<!--      </mat-tab>-->
    </mat-tab-group>
  </div>
  <mat-spinner *ngIf="loadingMedia" [diameter]="25" class="mat-spinner-color"></mat-spinner>
  <div class="h-[500px]"></div>
