<vex-page-layout>

  <vex-secondary-toolbar current="Product">
    <mat-spinner *ngIf="loadingData" [diameter]="25" class="mat-spinner-color"></mat-spinner>

    <div class="w-full justify-end flex">
      <button color="primary" class="flex items-center justify-center" mat-flat-button type="submit" (click)="onSubmit()" [disabled]="productForm.invalid " ><span  *ngIf="!loading"> Save Changes </span>
        <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>
      </button>
    </div>
  </vex-secondary-toolbar>

    <form class="flex container flex-col my-5 gap-3 " [formGroup]="productForm" >

      <div class="container rounded-lg shadow bg-white  p-5 ">
      <mat-tab-group>
        <mat-tab  *ngFor="let lang of locale" [label]="lang.locale | uppercase">

      <div class=" flex flex-col gap-2">
          <div class="flex gap-3">
            <div class="w-full">
              <mat-form-field class="flex-auto w-full">
                <mat-label>Product Title ( {{lang.locale | uppercase }} )</mat-label>
                <input cdkFocusInitial formControlName="{{lang.locale}}_title" matInput>
              </mat-form-field>
            </div>

          </div>
          <div class="card">
            <h3 class="mb-2 pt-2 pl-2">Product Description ( {{lang.locale | uppercase }} )</h3>
            <quill-editor class="min-h-[200px] "  @fadeInUp  formControlName="{{lang.locale}}_description"
                         [styles]="{ flex: '1 1 auto' }"></quill-editor>
          </div>
        </div>
        </mat-tab>
      </mat-tab-group>
    </div>
      <div class="container rounded-lg shadow bg-white  p-5 ">
        <div class="flex gap-2 items-center mb-4">
          <mat-icon svgIcon="mat:settings"></mat-icon>
          <h2 class="font-semibold ">General Setup</h2>
        </div>
      <div class="flex flex-col gap-2" >
       <div class="flex gap-3">
         <mat-form-field class="flex-auto">
           <mat-label>Sku</mat-label>
           <input required formControlName="sku" matInput>
         </mat-form-field>
         <mat-form-field class="flex-auto">
           <mat-label>Price</mat-label>
           <input formControlName="price" matInput>
         </mat-form-field>
         <mat-form-field class="flex-auto">
           <mat-label>Product Type</mat-label>
           <select matNativeControl required formControlName="type">
             <option *ngFor="let item of types" [ngValue]="item['id']">{{item['name'].toLowerCase()}}</option>
           </select>
         </mat-form-field>
       </div>
        <div class="flex gap-3">
          <mat-form-field class="flex-auto">
            <mat-label>Model</mat-label>
            <select matNativeControl required formControlName="base_product_id" (change)="getSpecifications()" >
              <option *ngFor="let item of bases" [ngValue]="item['id']">{{item['name']}}</option>
            </select>
          </mat-form-field>
          <mat-form-field class="flex-auto">
            <mat-label>Applications</mat-label>
            <mat-select matNativeControl multiple  formControlName="applications">
              <mat-option *ngFor="let item of applications" [value]="item['id']">{{item['name']}}</mat-option>
            </mat-select>
          </mat-form-field>
<!--          <mat-form-field class="flex-auto">-->
<!--            <mat-label>Offers</mat-label>-->
<!--            <mat-select matNativeControl multiple  formControlName="offers" (selectionChange)="selectOffers()">-->
<!--              <mat-option *ngFor="let item of offers" [value]="item['id']">{{item['name']}}</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->

        </div>
        <div class="flex gap-3">
          <mat-form-field class=" w-1/3">
            <mat-label>Assistants</mat-label>
            <select matNativeControl  formControlName="assistant_id">
              <option *ngFor="let item of assistants" [value]="item['id']">{{item['first_name'] }} {{item['last_name']}}</option>
            </select>
          </mat-form-field>
          <mat-form-field class=" w-1/3">
            <mat-label>Tag</mat-label>
            <select matNativeControl  formControlName="tagProduct">
              <option *ngFor="let item of tagProduct" [ngValue]="item['name'].toUpperCase()">{{item['id']}}</option>
            </select>
          </mat-form-field>
          <mat-form-field class="flex-auto">
            <mat-label>Show In </mat-label>
            <mat-select matNativeControl multiple  formControlName="tagsProduct" >
              <mat-option *ngFor="let item of tagsProduct" [value]="item['name']">{{item['id']}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex gap-3" >
          <mat-slide-toggle formControlName="isPopular">Popular Product</mat-slide-toggle>
          <mat-slide-toggle formControlName="canFullAmount"> Full Amount Available</mat-slide-toggle>
          <mat-slide-toggle formControlName="hide_price">Hide Price</mat-slide-toggle>
          <mat-slide-toggle formControlName="has_shipping">Has Shipping Options</mat-slide-toggle>
        </div>
        </div>
    </div>
      <div  *ngIf="productForm.value?.['has_shipping']"
        class="grid grid-cols-12 gap-3 w-full " >
        <label for="file-shipping" class="col-span-12 md:col-span-2 rounded-lg shadow bg-white h-fit  p-3 w-full relative">
          <div class="relative z-10 flex flex-col justify-center items-center p-3 md:p-12 border-dashed border-gray rounded-lg border cursor-pointer hover:shadow-lg " >
            <app-svg-renderer [svgContent]="'Upload'" />
            <h4 class="mt-2 text-center">Upload Media</h4>
            <input id="file-shipping" type="file" (change)="onImagePicked($event)"  style="display: none;" />
            <img class='max-w-full object-fit-contain ' [src]="selectedShippingMediaFile" >
          </div>

        </label>
        <div class="col-span-12 md:col-span-10 flex flex-col bg-white w-full rounded-lg p-8  items-center justify-center">
          <div class="flex flex-wrap gap-2 w-full">
            <mat-form-field class="flex-auto">
              <mat-label>length</mat-label>
              <input  formControlName="length" matInput>
              <span matSuffix>mm</span>
            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>width</mat-label>
              <input  formControlName="width" matInput>
              <span matSuffix>mm</span>
            </mat-form-field>

          </div>

          <div class="flex flex-wrap gap-2 w-full">
            <mat-form-field class="flex-auto">
              <mat-label>height</mat-label>
              <input  formControlName="height" matInput>
              <span matSuffix>mm</span>
            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>weight</mat-label>
              <input  formControlName="weight" matInput>
              <span matSuffix>kg</span>
            </mat-form-field>

          </div>

        </div>
      </div>
      <div class="container rounded-lg shadow bg-white  p-5 ">
        <div class="flex gap-2 items-center mb-4 ">
          <mat-icon svgIcon="mat:vpn_key"></mat-icon>
          <h2 class="font-semibold ">Key Features</h2>
        </div>

        <div class="flex gap-3 justify-between items-center">
          <mat-form-field class="flex-auto">
            <mat-label>Feature</mat-label>
            <input cdkFocusInitial formControlName="tag" placeholder="Add Feature" matInput>

          </mat-form-field>
          <div class="underline italic mx-2 text-primary cursor-pointer p-2 rounded-full bg-[#d7e512] justify-center items-center flex" mat-flat-button  (click)="addTag()">
            <mat-icon svgIcon="mat:playlist_add"></mat-icon>
          </div>

        </div>
        <ul *ngIf="tags.length>0" class="py-3 border flex-wrap border-gray border-solid rounded-2xl p-2 flex gap-2 mb-4">
          <li *ngFor="let item of tags; let i = index" (click)="removeTag(i)" class="flex gap-2">
            <label class="px-4 bg-[#E8F1FF] text-[#0B62E4] py-2 rounded-xl">{{item}}</label>
          </li>
        </ul>

        <vex-save-threesixty [productId]="productID" *ngIf="mode=='update'" ></vex-save-threesixty>


      </div>
      <div class="container rounded-lg shadow bg-white  p-5 ">
        <div class="flex gap-2 items-center mb-4">
          <mat-icon svgIcon="mat:playlist_add_check"></mat-icon>
          <h2 class="font-semibold ">Purchase Options</h2>
        </div>
      <div class="flex flex-col gap-3">
        <mat-slide-toggle formControlName="can_lease">Open to Lease</mat-slide-toggle>
        <div *ngIf="productForm.value['can_lease']==1">
          <mat-form-field class="w-full">
            <mat-label>Lease Months</mat-label>
            <input  formControlName="lease_months" matInput>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Monthly Lease Amount</mat-label>
            <input  formControlName="monthly_lease_amount" matInput>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Down Payment Amount</mat-label>
            <input  formControlName="down_payment_amount" matInput>
          </mat-form-field>
        </div>
        <mat-slide-toggle formControlName="canDownPayment">Down Payment Available</mat-slide-toggle>
        <mat-slide-toggle formControlName="canOffer">Open to Offers</mat-slide-toggle>
        <div *ngIf="productForm.value['canOffer']==1">
          <mat-form-field class="w-full">
            <mat-label>Min Offer Amount</mat-label>
            <input  formControlName="min_offer_amount" matInput>
          </mat-form-field>
        </div>
        <mat-slide-toggle formControlName="canAuction">Available for Auction</mat-slide-toggle>
      </div>

    </div>
      <div class="container rounded-lg bg-white shadow py-5 ">
      <div class=" flex gap-2 items-center mb-4">
        <mat-icon svgIcon="mat:star"></mat-icon>
        <h2 class="font-semibold ">Product Specifications</h2>
      </div>
      <div >
        <h3 *ngIf="specifications?.length==0"  class="text-gray italic"> Select a Model first to load related Specifications</h3>
        <mat-spinner *ngIf="loadingSpecs" [diameter]="25" class="mat-spinner-color"></mat-spinner>
        <form [formGroup]="dynamicFormSpec"
              *ngIf="specifications?.length>0">
          <div class="flex flex-wrap gap-3 " cdkDropListGroup>
            <ng-container *ngFor="let item of specifications; let i = index" >
              <div cdkDropList  cdkDropListOrientation="horizontal"
                   [cdkDropListData]="{item:item,index:i}" (cdkDropListDropped)="drops($event ,i)">

               <div class="my-2 media-card" cdkDrag>
                 <div class="  " *cdkDragPlaceholder></div>
                 <div class="flex gap-3  items-center pt-2 px-2" [ngClass]="{ 'hidden': item?.['key'] == 'Features' }">
                   <mat-form-field    class="flex-auto " *ngIf="item?.['type']==secificationTypeEnum.NUMBER || item?.['type']==secificationTypeEnum.YEAR || item?.['type']== secificationTypeEnum.TEXT || item?.['type']== secificationTypeEnum.DECIMAL">
                     <mat-label>{{ item?.['key'] }}</mat-label>
                     <input [type]="item?.['type']"  matInput [formControlName]="item?.['key']" [id]="item?.['key']">
                   </mat-form-field>
                   <mat-form-field class="flex-auto" *ngIf="item?.['type']== secificationTypeEnum.SELECT ">
                     <mat-label>{{ item?.['key'] }}</mat-label>
                     <select matNativeControl required [formControlName]="item?.['key']" >
                       <option *ngFor="let option of item?.['options']?.['options']" [ngValue]="option">{{option}}</option>
                     </select>
                   </mat-form-field>
                   <div class="flex-auto" *ngIf="item?.['type']== secificationTypeEnum.BOOLEAN ">
                     <mat-label>{{ item?.['key'] }}</mat-label>
                     <mat-checkbox   [formControlName]="item?.['key']"  [checked]="false" color="primary"></mat-checkbox>
                   </div>
                   <span *ngIf="item?.['pivot']?.['unit']!=null">  {{item?.['pivot']?.['unit']?.['symbol']}} </span>
                   <!--                <mat-form-field   [ngClass]="{ 'hidden': ! item?.['unit_type_id']  }" class="flex-auto w-[150px]" >-->
                   <!--                  <mat-select placeholder="unit" matNativeControl  [formControlName]="item?.['key']+'_unit_id'" >-->
                   <!--                    <mat-option *ngFor="let item of units[i]" [value]="item['id']">{{item['name']}} ({{item['symbol']}})</mat-option>-->
                   <!--                  </mat-select>-->
                   <!--                </mat-form-field>-->
                 </div>
               </div>

              </div>
                  <ng-container >
              </ng-container>
            </ng-container>
          </div>
          <div class="card mt-4" *ngIf="hasFeatures && specifications?.length>0 ">
            <h3 class="mb-2 pt-2 pl-2">Features</h3>
            <quill-editor  class="min-h-[200px] " (onContentChanged)="onContentFeatureChanged($event)" @fadeInUp [formControl]="formFeatureText"
                           [formGroup]="formFeature" [styles]="{ flex: '1 1 auto' }"></quill-editor>
          </div>
        </form>

      </div>
    </div>
      <div class="container rounded-lg bg-white shadow py-5 ">
      <div class=" flex gap-2 items-center mb-4">
        <mat-icon svgIcon="mat:star"></mat-icon>
        <h2 class="font-semibold ">Product Location</h2>
      </div>
<!--      <mat-form-field class="flex-auto">-->
      <div class="flex items-center gap-3 mb-4">
        <mat-label>Search : </mat-label>
        <input class="border px-2 py-1 border-lg" #placesRef="ngx-places" ngx-gp-autocomplete  (onAddressChange)="handleAddressChange($event)"/>

      </div>
      <google-map height="500px" width="100%"
                  [center]="center"
                  [zoom]="zoom"
                  (mapClick)="addMarker($event)"
                  (mapMousemove)="move($event)">
          <map-marker *ngIf="markerPositions" [position]="markerPositions" [options]="markerOptions" />
      </google-map>
    </div>
      <div
        class="grid grid-cols-12 gap-3  w-full  " >
        <label for="file-shipping" class="col-span-12 md:col-span-2 rounded-lg shadow bg-white h-fit  p-3 w-full relative">
          <div class="relative z-10 flex flex-col justify-center items-center  p-12 border-dashed border-gray rounded-lg border cursor-pointer hover:shadow-lg " >
            <app-svg-renderer [svgContent]="'Upload'" />
            <h4 class="mt-2 text-center">Upload Documents</h4>
            <input id="docs" type="file" (change)="onDocFileSelected($event)"  multiple style="display: none;" />
          </div>

        </label>
        <div class="col-span-12 md:col-span-10 flex flex-col bg-white w-full rounded-lg p-8 gap-3">
          <button   class="px-3 py-2 self-end justify-center items-center   border border-gray rounded-lg cursor-pointer" (click)="deleteFiles()">
            Delete Documents
          </button>
          <div class="flex gap-4 ">
            <div class="my-2  media-card h-fit" *ngFor="let file of productDocs">
              <div class="  " *cdkDragPlaceholder></div>
              <a    target="_blank" [href]="file?.['document']" class="h-fit p-2 max-w-[4rem] border-1  flex justify-center items-center border-lg  ">
               <app-svg-renderer [svgContent]="'PDF'" />
              </a>
              <div class="overlay"></div>
              <div class="button z-[99]">
                <a [href]="file?.document" target="_blank">
                  <mat-icon svgIcon="mat:open_in_new" class="light" ></mat-icon>
                </a>
              </div>

            </div>
          </div>

        </div>
      </div>
      <app-upload-single-media  [selectedCover]="selectedCover" (getFile)="getCover($event)"  title="''" [type]="MediaTypeEnum.IMAGE"></app-upload-single-media>
      <div class="flex items-center gap-2 mb-2" >
        <mat-icon svgIcon="mat:add_photo_alternate"></mat-icon>
        <h2 class="font-semibold ">Product Media Collections</h2>
      </div>
      <div class="flex  w-full flex-wrap items-center gap-3">
        <div  class="w-1/3 ">
          <label *ngIf="mode=='update'" class=" rounded-lg shadow bg-white h-fit  p-3 w-fit relative">
            <div class="relative z-10 flex flex-col justify-center items-center p-12 border-dashed border-gray rounded-lg border cursor-pointer hover:shadow-lg " (click)="AddMedia()" >
              <app-svg-renderer [svgContent]="'Upload'" />
              <h4 class="mt-2 text-center">Upload New Collection</h4>
            </div>
          </label>
        </div>

      </div>
      <div  class="container flex justify-between gap-2 p-0 ">
        <app-product-media (loadMedia)="getMedia()" [loadingMedia]="loadingMedia"
          class="w-full" [groupedMediaArray]="groupedMediaArray" [productID]="productID"></app-product-media>
      </div>
      <div class="container  p-2 h-fit " *ngIf="mode=='create'">
        <div class=" flex gap-2 items-center ">
          <mat-icon svgIcon="mat:360"></mat-icon>
          <h2 class="font-semibold ">View 360 *</h2>
        </div>
      </div>
      <div class="flex gap-3  w-full justify-between"  *ngIf="mode =='create'">

        <label for="file-upload" class=" rounded-lg shadow bg-white h-fit  p-3 w-fit ">
          <div class="flex flex-col justify-center items-center p-12 border-dashed border-gray rounded-lg border cursor-pointer hover:shadow-lg " >
            <app-svg-renderer [svgContent]="'Upload'" />
            <h4 class="mt-2 text-center">Upload New Collection</h4>
            <input id="file-upload" type="file" (change)="onFileSelected($event)" multiple style="display: none;" />

          </div>
        </label>
        <div class=" flex flex-col bg-white w-full rounded-lg p-8  items-center justify-center">
          <div class="swiper w-full max-w-[500px]">
            <div class="swiper-container ">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let image of fileArray">
                  <img class="swiper-img" [src]="image" [alt]="'Image'">
                </div>
              </div>
            </div>
          </div>
          <input
            *ngIf="fileArray.length>0"
            type="range"
            [formControl]="control"
            [max]="fileArray.length - 1"
            (change)="click($event)"
            class="card-range-slider swiper-no-swiping mb-[20px] self-center"
          />
        </div>
      </div>
      <app-upload-single-media (getVideos)="getVideos($event)" *ngIf="mode=='create'" [type]="MediaTypeEnum.VIDEO"></app-upload-single-media>
    </form>


</vex-page-layout>


