<div class="container p-5 ">

 <div class="card">
   <form (ngSubmit)="save()" [formGroup]="form" class="flex flex-col gap-3" >
     <div class="flex items-center justify-between" mat-dialog-title>

       <div class="flex ">
         <button class="text-secondary" mat-icon-button type="button" (click)="onCancel()">
           <mat-icon svgIcon="mat:arrow_back"></mat-icon>
         </button>
         <h2 *ngIf="form.value[defLocale + '_name'] || form.value[defLocale + '_name']"
             class="headline m-0 flex-auto">{{ form.value[defLocale + '_name'] }}</h2>
         <h2 *ngIf="!form.value[defLocale + '_name'] && !form.value[defLocale + '_name']"
             class="headline m-0 flex-auto">Zone</h2>
       </div>

       <div align="end">
         <button mat-button type="button" (click)="onCancel()">Cancel</button>
         <button *ngIf="isCreateMode()" color="primary" mat-flat-button type="submit" [disabled]="this.form.invalid && this.classes.length!=0 && this.points.length!=0">
           Create
         </button>
         <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit" [disabled]="this.form.invalid && this.classes.length!=0 && this.points.length!=0">
           Update
         </button>
       </div>
     </div>
     <mat-divider class="text-border"></mat-divider>

     <div class="flex flex-col bg-white p-4">

       <div class="flex gap-3 items-center">
         <mat-tab-group class="w-full">
           <mat-tab  *ngFor="let lang of locale" [label]="lang.locale | uppercase">
             <div class="py-6">
               <mat-form-field class="w-full" >
                 <mat-label > Name ( {{lang.locale | uppercase }} )</mat-label>
                 <input cdkFocusInitial formControlName="{{lang.locale}}_name" matInput>
               </mat-form-field>
             </div>
           </mat-tab>
         </mat-tab-group>

         <mat-slide-toggle formControlName="status">Active</mat-slide-toggle>
       </div>
       <div>
         <div class="flex container w-full h-[500px] " id='map'></div>
         <div class="float-end">
           <button mat-button type="button" (click)="initMap()">Clear Point</button>
         </div>
       </div>

     </div>
   </form>
   <div class="card m-5 p-3">
     <div class=" flex gap-2 items-center mb-4">

       <h2 class="font-semibold "> <h1>Weight Classes</h1></h2>
     </div>
     <!--    <div  class="divide-y">-->
     <!--      <div class="flex m-1">-->
     <!--        <div class="w-1/3">-->
     <!--          <h1>From</h1>-->
     <!--        </div>-->
     <!--        <div class="w-1/3">-->
     <!--          <h1>To</h1>-->
     <!--        </div>-->
     <!--        <div class="w-1/3">-->
     <!--          <h1>Cost</h1>-->
     <!--        </div>-->
     <!--      </div>-->
     <!--    </div>-->
     <table class="table-auto border-collapse border border-slate-500 ">

       <thead>
       <tr class="border border-slate-500">
         <th class="border border-slate-500">From</th>
         <th class="border border-slate-500">To</th>
         <th class="border border-slate-500">Cost</th>
         <th class="border border-slate-500"> </th>
       </tr>
       </thead>
       <tbody>
       <tr *ngFor="let class of classes , let index" class="border border-slate-500">
         <td class="border border-slate-500">{{class.from}} t</td>
         <td class="border border-slate-500">{{class.to}} t</td>
         <td class="border border-slate-500">{{class.cost?.amount}}</td>
         <td class="border border-slate-500"><mat-icon (click)="deleteClassRow(index)" svgIcon="mat:delete"></mat-icon></td>
       </tr>
       </tbody>
     </table>
     <!--    <div class="divide-y">-->
     <!--      <div class="flex " *ngFor="let class of classes , let index">-->
     <!--        <div class="w-1/3">-->
     <!--          <p>{{class.from}}</p>-->
     <!--        </div>-->
     <!--        <div class="w-1/3">-->
     <!--          <p>{{class.to}}</p>-->
     <!--        </div>-->
     <!--        <div class="w-1/3">-->
     <!--          <p>{{class.cost}}</p>-->
     <!--        </div>-->
     <!--        <mat-icon (click)="deleteClassRow(index)" svgIcon="mat:delete"></mat-icon>-->
     <!--      </div>-->
     <!--    </div>-->

     <form [formGroup]="classForm" class="pt-2" (ngSubmit)="addClassess()">
       <div>
         <mat-form-field class="flex-auto w-1/3 p-1" >
           <mat-label>From</mat-label>
           <input cdkFocusInitial formControlName="from" matInput type="number">
         </mat-form-field>
         <mat-form-field class="flex-auto w-1/3 p-1">
           <mat-label>To</mat-label>
           <input cdkFocusInitial formControlName="to" matInput type="number">
         </mat-form-field>
         <mat-form-field class="flex-auto w-1/3 p-1">
           <mat-label>Cost</mat-label>
           <input cdkFocusInitial formControlName="cost" matInput type="number">
         </mat-form-field>
       </div>
       <div>
         <button  color="primary" mat-flat-button type="submit" [disabled]="classForm.invalid" >
           Add
         </button>
       </div>
     </form>
   </div>

 </div>


</div>
