import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, ReactiveFormsModule} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import {CategoryService} from '../service/category.service';

import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorStatusModel } from 'src/app/core/enum/error-status.model';
import { ToastrService } from 'ngx-toastr';
import {MatSelectModule} from '@angular/material/select';
import {ProductService} from '../../products/service/product.service';
import {CdkDrag, CdkDragDrop, CdkDropList, DragDropModule, moveItemInArray} from '@angular/cdk/drag-drop';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';

@Component({
  selector: 'vex-categories-create-update',
  templateUrl: './categories-create-update.component.html',
  styleUrls: ['./categories-create-update.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatListModule,
    MatIconModule,
    MatInputModule,
    CommonModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    CdkDropList,
    CdkDrag,
    DragDropModule
  ],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ]
})
export class CategoriesCreateUpdateComponent implements OnInit {

   id ;

  form: UntypedFormGroup;
  mode: 'create' | 'update' = 'create';
  loading = false;
  products = [];
  feature = [];
  selectedProducts = [];

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private categoryService: CategoryService,
              private productService : ProductService
              ) {
  }
  getProducts(){
    this.categoryService.getFeatured(this.defaults?.id).subscribe((res) => {
        // @ts-ignore
      this.selectedProducts=[];
      this.products = res['data']['products'];
      this.feature = res['data']['featured_products'];
      this.selectedProducts=this.feature.map(data=>data);
      this.form = this.fb.group({
        products: [ this.feature.map(data =>data.id)??[] ]
      });
      this.detectProductsChanges();
      // @ts-ignore


    });
  }
  ngOnInit() {
    this.getProducts();
    if (this.defaults) {
      this.mode = 'update';
      this.id = this.defaults?.id;

    }
    this.form = this.fb.group({
      products: [ this.feature.map(data =>data.id)??[] ]
    });



  }

  detectProductsChanges(){
    this.form.get('products').valueChanges.subscribe(value => {
      this.selectedProducts=[];

      value?.forEach((id) => {
        const product = this.products?.find(obj => obj.id == id) || null;
        this.selectedProducts.push(product);
      });
    });
  }
  getCover(product: any){
    return product?.media?.find(obj => obj.collection_name === 'cover')?.['original_url'];
  }
  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }


  save() {
    this.loading=true;

    const formData = new FormData();
    this.selectedProducts.forEach((product, index) => {
      formData.append(`featured[${index}][product_id]`, product.id );
      formData.append(`featured[${index}][order]`, (index+1).toString() );
    });
    formData.append('_method', 'patch');
    this.categoryService.updateFeatured(formData, this.id).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
        this.toastr.success('Product Updated Successfully!');
      }
    }, error => {
      this.loading = false;
      this.toastr.error(error);
    });
    this.loading = false;
  }

  create() {
    this.loading=true;
    const formData = new FormData();


  }

  update() {
    this.loading=true;
    const formData = new FormData();

  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
  drop(event: CdkDragDrop<any> ) {
    moveItemInArray(this.selectedProducts, event.previousIndex, event.currentIndex);
  }

}


