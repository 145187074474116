
  <vex-page-layout>

    <vex-secondary-toolbar current="Research">
      <mat-spinner *ngIf="loadingData" [diameter]="25" class="mat-spinner-color"></mat-spinner>

      <div class="w-full justify-end flex">

        <button color="primary" class="flex items-center justify-center" mat-flat-button type="submit" (click)="onSubmit()" [disabled]="reviewsForm.invalid" ><span  *ngIf="!loading"> Save Changes </span>
          <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>
        </button>
        <button color="warn" class="flex items-center justify-center mx-1" mat-flat-button (click)="cancel()" ><span> Cancel </span>

        </button>
      </div>
    </vex-secondary-toolbar>

    <form class="flex container flex-col my-5 gap-3" [formGroup]="reviewsForm" >
      <div class="container rounded-lg shadow bg-white  py-5 ">
        <div class="flex ">
         <div>
           <div class="flex gap-2 items-center mb-4 justify-between">
             <div class="flex gap-2 items-center">
               <mat-icon svgIcon="mat:settings"></mat-icon>
               <h2 class="font-semibold ">General Info</h2>
             </div>
             <div class="flex gap-3">

                 <mat-label>Makana Rating</mat-label>
                 <ngb-rating [max]="5" [(rate)]="starRating" [readonly]="false"></ngb-rating>


             </div>

           </div>
           <div class="flex flex-col " >
             <div class="flex gap-3 flex-wrap justify-between">
               <mat-form-field class="flex-auto">
                 <mat-label>Price</mat-label>
                 <input type="number" formControlName="price" matInput>
               </mat-form-field>
               <mat-form-field class="flex-auto">
                 <mat-label>Market Average</mat-label>
                 <input formControlName="market_average" matInput>
               </mat-form-field>
               <mat-form-field class="flex-auto">
                 <mat-label>Model</mat-label>
                 <select matNativeControl required formControlName="base_product_id" (change)="getSpecifications()">
                   <option *ngFor="let item of basesAll" [ngValue]="item['id']">{{item['name']}}</option>
                 </select>
               </mat-form-field>
               <div class="flex justify-between items-center w-full" >
                 <div class="flex gap-2">
                   <mat-form-field class="flex-auto">
                     <mat-label>From</mat-label>
                     <input type="number" formControlName="from" matInput >
                   </mat-form-field>
                   <mat-form-field class="flex-auto">
                     <mat-label>To</mat-label>
                     <input type="number" formControlName="to" matInput >
                   </mat-form-field>
                 </div>
                 <span class="italic font-semibold text-lg mx-12 text-[#0B62E4]">or</span>
                 <mat-form-field class="flex-auto">
                   <mat-label>Year</mat-label>
                   <input type="number" formControlName="year" matInput >
                 </mat-form-field>

               </div>
               <div class="flex w-full">
                 <mat-form-field class="flex-auto">
                   <mat-label>Compare with </mat-label>
                       <mat-select matNativeControl multiple  formControlName="compare_with_generations">
                       <mat-option *ngFor="let item of bases" [value]="item['id'].toString()">{{item['name']}}</mat-option>
                   </mat-select>
                 </mat-form-field>
               </div>
               <mat-slide-toggle formControlName="show_home">Show in home</mat-slide-toggle>
             </div>
           </div>
         </div>
          <div class="w-1/3">
            <div  class="file-input-wrapper flex flex-col  w-full h-fit justify-center items-center"  >

              <img  class='image object-contain mb-3 w-[150px] h-[150px] justify-center items-center border border-gray-400'  [src]="selectedFileCover" (error)="setDefaultImage($event)">
              <div class="relative flex items-center cursor-pointer select-none rounded-full px-4  hover:bg-blue-100 trans-ease-out"
                   matRipple>
                <div class="p-2 bg-blue-100 text-blue-600 rounded-full ltr:mr-4 rtl:ml-4">
                  <mat-icon class="block" svgIcon="mat:add_a_photo"></mat-icon>
                </div>
                <input id="imageInput" type="file" #filePicker (change)="onImagePicked($event)" />
                <p for="imageInput" class="font-medium text-blue-600">Select Cover</p>
              </div>

            </div>
          </div>

        </div>

      </div>
      <div class="container rounded-lg shadow bg-white  py-5 ">
        <div class="flex gap-2 items-center mb-4 justify-between">
          <div class="flex gap-2 items-center">
            <mat-icon svgIcon="mat:settings"></mat-icon>
            <h2 class="font-semibold ">Technical Specifications</h2>
          </div>
        </div>
        <div class="flex flex-col " >
          <div class="flex gap-3 flex-wrap justify-between">
            <mat-form-field class="flex-auto">
              <mat-label>Working Height</mat-label>
              <input  formControlName="working_height" matInput>
            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>Platform Length</mat-label>
              <input  formControlName="platform_length" matInput>
            </mat-form-field>
            <mat-form-field class="flex-auto">
              <mat-label>Platform Width</mat-label>
              <input  formControlName="platform_width" matInput>
            </mat-form-field>

          </div>
        </div>
      </div>
      <div class="container rounded-lg bg-white shadow py-5 ">
        <div class=" flex gap-2 items-center mb-4">
          <mat-icon svgIcon="mat:format_align_left"></mat-icon>
          <h2 class="font-semibold "> Specifications</h2>
        </div>
        <div >
          <h3 *ngIf="specifications?.length==0" class="text-gray italic"> Select a Model first to load related Specifications</h3>
          <mat-spinner *ngIf="loadingSpecs" [diameter]="25" class="mat-spinner-color"></mat-spinner>
          <form [formGroup]="dynamicFormSpec"
                *ngIf="specifications?.length>0">
            <div class="flex flex-wrap gap-3">
              <ng-container *ngFor="let item of specifications; let i = index" >
                <div class="flex gap-3 pt-2 px-2 items-center" [ngClass]="{ 'hidden': item?.['key'] == 'Features' }">
                  <mat-form-field    class="flex-auto " *ngIf="item?.['type']==secificationTypeEnum.NUMBER || item?.['type']== secificationTypeEnum.TEXT || item?.['type']== secificationTypeEnum.YEAR  || item?.['type']== secificationTypeEnum.DECIMAL">
                    <mat-label>{{ item?.['key'] }}</mat-label>
                    <input [type]="item?.['type']"  matInput [formControlName]="item?.['key']" [id]="item?.['key']">
                  </mat-form-field>
                  <mat-form-field class="flex-auto" *ngIf="item?.['type']== secificationTypeEnum.SELECT ">
                    <mat-label>{{ item?.['key'] }}</mat-label>
                    <select matNativeControl required [formControlName]="item?.['key']" >
                      <option *ngFor="let option of item?.['options']?.['options']" [ngValue]="option">{{option}}</option>
                    </select>
                  </mat-form-field>
                  <span *ngIf="item?.['pivot']?.['unit']!=null">  {{item?.['pivot']?.['unit']?.['symbol']}} </span>
                  <mat-checkbox  [ngClass]="{ 'hidden': !dynamicFormSpec?.value?.[item?.['key']] }" [formControlName]="item?.['key']+'_is_featured'"
                                 color="primary"></mat-checkbox>
                </div>
                <ng-container >
                </ng-container>
              </ng-container>
            </div>
            <div class="card mt-4" *ngIf="hasFeatures && specifications?.length>0 ">
              <h3 class="mb-2 pt-2 pl-2">Features</h3>
              <quill-editor  class="min-h-[200px] " (onContentChanged)="onContentFeatureChanged($event)" @fadeInUp [formControl]="formFeatureText"
                             [formGroup]="formFeature" [styles]="{ flex: '1 1 auto' }"></quill-editor>

            </div>
          </form>

        </div>
      </div>


<!--      <div class="grid grid-cols-12  gap-3">-->
<!--        <div class="col-span-12 md:col-span-6 w-full">-->
<!--          <app-upload-single-media   [selectedCover]="selectedFileCover" (getFile)="getCover($event)"  [type]="MediaTypeEnum.IMAGE" title="Cover"></app-upload-single-media>-->
<!--        </div>-->
<!--      </div>-->
      <div  *ngIf="mode=='update'" class="container flex justify-between gap-2 p-0">
        <div class="flex items-center gap-2 " >
          <app-svg-renderer [svgContent]="'Upload'" />
          <h2 class="font-semibold ">Product Media Collections</h2>
        </div>
        <div class="flex items-center gap-2 border-dashed border-gray rounded-lg border cursor-pointer  hover:shadow-lg bg-white p-1.5" (click)="AddMedia()">
          <mat-icon svgIcon="mat:add_photo_alternate"></mat-icon>
          <h2 class="font-semibold ">Upload New Collection</h2>
        </div>
      </div>

<!--      <app-upload-single-media (getVideos)="getVideos($event)" *ngIf="mode=='create'" [type]="MediaTypeEnum.VIDEO"></app-upload-single-media>-->
      <div  class="container flex justify-between gap-2 p-0 " *ngIf="mode=='update' && groupedMediaArray?.length>0" >
        <app-product-media (loadMedia)="getMedia()" [loadingMedia]="loadingMedia" class="w-full" [groupedMediaArray]="groupedMediaArray" [productID]="reviewDetails?.id" type="EXPERT"></app-product-media>
      </div>
    </form>

<!--    </div>-->
  </vex-page-layout>


