import {BaseProduct} from './baseProduct.model';


export class SellYourMachoneModel {
  id: number;
  full_name:string;
  email:string;
  phone_number:string;
  company:string;
  location: string;
  working_hours:string;
  year_of_manufacturing:string;
  brand: {
    id:number;
    name:string;
  };
  category : [
    {
      id:string,
      name:string
    }
  ];
  status: string;
  title: string;
  start_price: string;
  description: string;
  owner: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    full_name: string;
    role_name: string | null;
  };
  base_product: BaseProduct;

  specifications: any[];
  media: any[];

  constructor(machine) {
    this.id = machine.id;
    this.full_name = machine.full_name;
    this.phone_number = machine.phone_number;
    this.email = machine.email;
    this.company = machine.company;
    this.location = machine.location;
    this.brand = machine.brand;
    this.working_hours=machine.working_hours;
    this.year_of_manufacturing=machine.year_of_manufacturing;
    this.status = machine.status;
    this.start_price = machine.start_price;
    this.title = machine.title;
    this.description = machine.description;
    this.owner = machine.owner;
    this.base_product = machine.base_product;
    this.category = machine.category;
    this.specifications = machine.specifications;
    this.media = machine.media;
  }
}
