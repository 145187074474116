import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Category } from '../../../models/category.model';
import { CategorySpecification } from '../../../models/specification-category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get(environment.baseUrl + 'category/categories');
  }

  getTemplate(id:any) {
    return this.http.get(environment.baseUrl + 'category/categories/'+id+'/get-inspection-report-template');
  }
  createTemplate(id:any) {
    return this.http.get(environment.baseUrl + 'category/categories/'+id+'/save-inspection-report-template');
  }
  getAll() {
    return this.http.get(environment.baseUrl + 'category/all/categories');
  }
  create(obj: FormData) {
    return this.http.post(environment.baseUrl + 'category/categories', obj);
  }
  update(obj: Category) {
    return this.http.put(environment.baseUrl + 'category/categories/' + obj.id, obj);
  }
  getFeatured( id : number) {
    return this.http.get(environment.baseUrl + 'category/categories/' + id + '/featured');
  }
  updateFeatured(cat : any, id : number) {
    return this.http.post(environment.baseUrl + 'category/categories/' + id + '/featured', cat);
  }
  delete(obj: Category) {
    return this.http.delete(environment.baseUrl + 'category/categories/' + obj.id);
  }
  getSpecification(id:any) {
    return this.http.get(environment.baseUrl + 'category/categories/'+ id + '/specifications');
  }
  createSpecification(obj: CategorySpecification) {
    return this.http.post(environment.baseUrl + 'category/category-specification/save', obj);
  }
  updateSpecification(obj: CategorySpecification) {
    return this.http.put(environment.baseUrl + 'category/category-specification/' + obj.id, obj);
  }
  deleteSpecification(obj: CategorySpecification) {
    return this.http.delete(environment.baseUrl + 'category/category-specification/' + obj.id);
  }
}
