import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {TableColumn} from 'src/@vex/interfaces/table-column.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule} from '@angular/material/form-field';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ReplaySubject, Observable, of, filter} from 'rxjs';
import {fadeInUp400ms} from 'src/@vex/animations/fade-in-up.animation';
import {stagger40ms} from 'src/@vex/animations/stagger.animation';
import {Category} from '../../models/category.model';
import {CategoryService} from './service/category.service';
import {PageLayoutModule} from '../../../../../@vex/components/page-layout/page-layout.module';
import {BreadcrumbsModule} from '../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {ErrorStatusModel} from '../../../../core/enum/error-status.model';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MethodsUtils } from 'src/app/core/utils/method-utils';
import { SpecificationService } from '../specifications/service/specification.service';
import { CategoryTypeEnum } from 'src/app/core/enum/category-type.enum';
import { MatSelectModule } from '@angular/material/select';
import {UnitService} from '../units/service/unit.service';
import {SpecificationTypeEnum} from '../../../../core/enum/specification-type.enum';
import {QuillEditorComponent} from 'ngx-quill';

import {CategoriesCreateUpdateComponent} from './categories-create-update/categories-create-update.component';
import {LoadingRowComponent} from '../../../../core/components/loading-row/loading-row.component';
import {LocalStorageRefService} from '../../../pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../../../../core/enum/storage-keys.enum';
import {MatTabsModule} from '@angular/material/tabs';
import {InspectionReportService} from '../inspection-report/service/inspection-report.service';


@UntilDestroy()
@Component({
  selector: 'vex-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    PageLayoutModule,
    BreadcrumbsModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    MatPaginatorModule,
    NgClass,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSelectModule,
    QuillEditorComponent,
    LoadingRowComponent,
    MatTabsModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class CategoriesComponent implements OnInit, AfterViewInit {

  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<Category[]> = new ReplaySubject<Category[]>(1);
  data$: Observable<Category[]> = this.subject$.asObservable();
  types: Category[];
  @Input()
  columns: TableColumn[] = [
    {label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true},
    {label: 'Image', property: 'img', type: 'image', visible: true},
    {label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Name', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Type', property: 'type', type: 'text', visible: true},
    {label: 'Slug', property: 'slug', type: 'text', visible: false},
    {label: 'Parent', property: 'parent', type: 'text', visible: true},
    {label: 'Popular', property: 'is_popular', type: 'text', visible: true},
    {label: 'Specifications', property: 'specifications', type: 'text', visible: false},

    {label: 'Actions', property: 'actions', type: 'button', visible: true}


  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<Category> | null;
  selection = new SelectionModel<Category>(true, []);
  searchCtrl = new UntypedFormControl();
  form: UntypedFormGroup;
  isPanelOpen = false;
  currentCat : Category;
  loading = false;
  getLoading = true;
  isSubCategory = false;
  defaults ?: Category;
  mode: 'create' | 'update' = 'create';
  selectedFile: string = '';
  selectedPopularFile: string = '';
  categoryType = [];
  categories = [];
  specs = [];
  groups = [];
  selectedSpecs = [];
  selectedGroups = [];
  dynamicFormSpec: FormGroup;
  dynamicFormGroups: FormGroup;
  units=[];
  parts=[];
  locale;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(
    private dialog: MatDialog,
    private service: CategoryService,
    private route: Router,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private elementRef: ElementRef,
    private specificationService: SpecificationService,
    private localStorageRefService : LocalStorageRefService,
    private unitService: UnitService,
    private inspectionReportService: InspectionReportService,
    private methods: MethodsUtils,
    private ref: ChangeDetectorRef,



    private router: Router


    ) {
      this.categoryType = this.methods.enumToArray(CategoryTypeEnum);
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }


  getData() {
    this.service.get().subscribe((res) => {
      of(res['data'].map(type => new Category(type))).subscribe(types => {
        this.subject$.next(types);
        this.getLoading=false;
      });

    });
  }
  getUnits() {
    this.unitService.getAllUnits().subscribe((res) => {
      this.units = res?.['data'];
    });
  }
  getParts() {
    this.inspectionReportService.getParts().subscribe((res) => {
      this.parts = res?.['data'];
    });
  }
  isCategories(){
    if(this.router.url == '/categories'){
      return true;
    }
      return false;

  }
  ngOnInit() {
    this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
    this.getParts();
    this.getGroups();
    this.getSpecifications();
    this.getData();
    this.getUnits();
    this.clearForm();
    this.dataSource = new MatTableDataSource();
    this.selectedFile='assets/img/demo/missing-picture.jpeg';

    this.data$.pipe(
      filter<Category[]>(Boolean)
    ).subscribe(types => {


      if(this.isCategories()){

        this.types = types.filter(prod=> prod?.parent_id == null && prod?.deleted_at == null);

        this.dataSource.data = this.types;
      }else{
        this.types = types.filter(prod=> prod?.deleted_at == null && prod.parent_id == null);
        this.dataSource.data = types.filter(prod=> prod?.deleted_at == null && prod.parent_id != null);
      }

      this.categories = this.types || [];



    });

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));



  }

  detectSpecificationsChanges(){
    this.form.get('specifications').valueChanges.subscribe(value => {
      this.selectedSpecs=[];
      value?.forEach((spec) => {
        const specification = this.specs?.find(obj => obj.id == spec) || null;
        this.selectedSpecs.push(specification);
      });
      this.addunit();

    });
  }
  detectPopularChanges(){
    this.form.get('is_popular').valueChanges.subscribe(value => {
      if(value){
        this.form.get('imagePopular').setValidators([Validators.required]);
      }else{
        this.form.get('imagePopular').clearValidators(); // Clear validators
      }
      this.form.get('imagePopular').updateValueAndValidity();
    });
  }
  detectGroupsChanges(){
    this.form.get('groups').valueChanges.subscribe(value => {
      this.selectedGroups=[];
      value?.forEach((g) => {
        const group = this.groups?.find(obj => obj.id == g) || null;
        this.selectedGroups.push(group);
      });
      this.addPart();

    });
  }
  addunit(){
    const formGroup = {};
    this.selectedSpecs?.forEach((item) => {
        const spec = this.currentCat?.specifications?.filter((spec: any) => spec.id == item.id)[0];
        formGroup[item?.['key']] = [spec?.['pivot']?.['unit_id'] ?? ''];
    });
    this.dynamicFormSpec = this.fb.group(formGroup);
  }
  addPart(){
    const formGroup = {};
    this.selectedGroups?.forEach((item) => {
        const group = this.currentCat?.template?.filter((group: any) => group.id == item.id)[0];
        formGroup[item?.['name']] = [group?.parts?.map((app: any) => app.id) ?? []];
    });
    this.dynamicFormGroups = this.fb.group(formGroup);

  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  create() {
    this.clearForm();
    this.isPanelOpen = true;
    this.scrollToForm();
  }
  initForm(app:Category){
    this.currentCat = app;
    this.isPanelOpen=true;
    this.mode='update';
    this.selectedFile = app.img===null?'assets/img/demo/missing-picture.jpeg':app.img;
    this.selectedPopularFile = app.popular_img===null?'assets/img/demo/missing-picture.jpeg':app.popular_img;
    this.form = this.fb.group({
      image: [app.img||null,Validators.required],
      imagePopular: [app.popular_img||null,app.is_popular?[Validators.required]:[]],
      image_alt: [app.image_alt],
      id: [app.id],
      description: [app.description || ''],
      type: [app.type || '', Validators.required],
      parent_id: [app.parent_id || ''],
      is_popular: [app.is_popular || false],
      is_featured: [app.is_featured || false],
      media_id: [app.media?.id || false],
      // specifications: [app?.specifications?.map((app: any) => app.id) ?? [],Validators.required]
      specifications: [app?.specifications?.map((app: any) => app.id) ?? []],
      groups: [app?.template?.map((app: any) => app.id) ?? []]
    });
    this.locale.forEach(lang => {
      const value = app.translations?.filter(brand => brand.locale == lang.locale);

      this.form.addControl(lang.locale+'_name', this.fb.control(value[0]?.name??''));
    });
    this.detectSpecificationsChanges();
    this.detectGroupsChanges();
    this.detectPopularChanges();
    this.selectedSpecs= app?.specifications;
    this.selectedGroups= app?.template;
    this.addunit();
    this.addPart();

  }
  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
        this.form.patchValue({image: file});
        const reader = new FileReader();
        reader.onload = (e) => {
            this.selectedFile = e.target.result as string;
        };
        reader.readAsDataURL(file);
    }


}
  onPopularImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
        this.form.patchValue({imagePopular: file});
        const reader = new FileReader();
        reader.onload = (e) => {
            this.selectedPopularFile = e.target.result as string;
        };
        reader.readAsDataURL(file);
    }


}
  clearForm(){
    this.isPanelOpen = false;
    this.mode='create';
    this.selectedFile='assets/img/demo/missing-picture.jpeg';
    this.form = this.fb.group({
      image: [null,Validators.required],
      imagePopular: [null],
      image_alt: [''],
      id: [null],
      description: [''],
      type: ['', Validators.required],
      parent_id: [''],
      is_featured: [''],
      is_popular: [false],
      // specifications: [ [] ,Validators.required]
      specifications: [ [] ],
      groups: [ [] ]
    });
    this.locale.forEach(lang => {

      this.form.addControl(lang.locale+'_name', this.fb.control(''));
    });
    this.selectedSpecs=[];
    this.detectSpecificationsChanges();
    this.selectedGroups=[];
    this.detectGroupsChanges();
    this.detectPopularChanges();


  }
  addFeaturedProducts(category : Category){
    this.dialog.open(CategoriesCreateUpdateComponent, {
      data: category
    }).afterClosed().subscribe(updatedBrand => {

    });
  }
  update(type: Category) {
    this.initForm(type);
    this.scrollToForm();
  }
  isCreateMode() {
    return this.mode === 'create';
  }
  scrollToForm() {
    const formElement = this.elementRef.nativeElement.querySelector('#form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  delete(type: Category) {
    this.service.delete(type).subscribe((res) => {
      if(res['status'] == ErrorStatusModel.SUCCESS) {
        this.toastr.success('Category Deleted Successfully!');
        this.ngOnInit();
        this.selection.deselect(type);
      }
    },error =>
    {
      this.toastr.error(error['message']);

    });
  }

  deleteAll(types: Category[]) {
    types.forEach(c => this.delete(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }

  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }
  save(){
    this.loading=true;
    const formData=new FormData();
    if(this.mode === 'update') {
      formData.append('id', this.form.value?.id);
    }
    const isCover=this.form.value.image instanceof File;
    if (this.form.value.image instanceof File){
      formData.append('media[0][files][0][file]', this.form.value.image);
      formData.append('media[0][collection_name]', 'image');
    }
    if (this.form.value.imagePopular instanceof File){
      formData.append(`media[${isCover?1:0}][files][0][file]`, this.form.value.imagePopular);
      formData.append(`media[${isCover?1:0}][collection_name]`, 'popular_image');
    }

    formData.append('name',this.form.value.en_name);
    formData.append('is_popular', this.form.value.is_popular ? '1' : '0');
    formData.append('is_featured', this.form.value.is_featured ? '1' : '0');

    formData.append('type', this.form.value.type);
    if(this.form.value.parent_id!=null){
      formData.append('parent_id', this.form.value.parent_id);

    }
    this.form.value?.specifications?.forEach((item, index) => {
      const specification = this.specs?.find(obj => obj.id == item) || null;
        formData.append(`specifications[${index}][specification_id]`, item.toString());
        if(this.dynamicFormSpec.value[specification?.['key']]!=''){
        formData.append(`specifications[${index}][unit_id]`, this.dynamicFormSpec.value[specification?.['key']] ?? null );
        }
    });
    this.form.value?.groups?.forEach((item, index) => {
      const group = this.groups?.find(obj => obj.id == item) || null;
        formData.append(`template[${index}][inspection_report_group_id]`, item.toString());
        if(this.dynamicFormGroups.value[group?.['name']]!=''){
          this.dynamicFormGroups.value?.[group?.['name']].forEach((item, j) => {
            formData.append(`template[${index}][parts][${j}]`, item ?? null);
          });
        }
    });


    this.locale.filter(lang => lang.default==0 ).forEach(lang =>{

      if(this.form.value[lang.locale + '_name']!='')
        formData.append(lang.locale +'[name]',this.form.value[lang.locale + '_name']);
    });
    if (formData) {
      this.service.create(formData).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Category '+this.mode+'d Successfully!');
          this.ngOnInit();
          this.loading=false;

        }
      }, error => {
        this.loading=false;
        this.toastr.error(error);
      });
    }}

  getSpecifications(){
      this.specificationService.get().subscribe((res) => {
          this.specs = res?.['data'];
      });
    }

    getGroups(){
      this.inspectionReportService.getGroups().subscribe((res) => {
        this.groups = res?.['data'];
      });
    }

  protected readonly SpecificationTypeEnum = SpecificationTypeEnum;
}

