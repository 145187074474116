import {SelectionModel} from '@angular/cdk/collections';
import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ReplaySubject, Observable, of, filter} from 'rxjs';
import {fadeInUp400ms} from 'src/@vex/animations/fade-in-up.animation';
import {stagger40ms} from 'src/@vex/animations/stagger.animation';
import {TableColumn} from 'src/@vex/interfaces/table-column.interface';
import {Product} from '../../models/product.model';
import {ProductService} from './service/product.service';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule, NgClass} from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BreadcrumbsModule} from '../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {PageLayoutModule} from '../../../../../@vex/components/page-layout/page-layout.module';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ToastrService} from 'ngx-toastr';
import {ErrorStatusModel} from '../../../../core/enum/error-status.model';
import {Category} from '../../models/category.model';
import {Router} from '@angular/router';
import {PublishStatusEnum} from '../../../../core/enum/publish-status.enum';
import {LoadingRowComponent} from '../../../../core/components/loading-row/loading-row.component';
import {UpdateMediaFileComponent} from './update-media-file/update-media-file.component';
import {
    InspectionReportCreateComponent
} from '../inspection-report/inspection-report-create/inspection-report-create.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {InspectionReportService} from '../inspection-report/service/inspection-report.service';


@UntilDestroy()

@Component({
    selector: 'vex-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
    standalone: true,
    animations: [
        fadeInUp400ms,
        stagger40ms
    ],
  imports: [
    MatMenuModule,
    NgClass,
    MatCheckboxModule,
    BreadcrumbsModule,
    PageLayoutModule,
    MatIconModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    MatSlideToggleModule,
    CommonModule,
    LoadingRowComponent,
      MatDatepickerModule,
      MatNativeDateModule
  ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            } as MatFormFieldDefaultOptions
        }
    ]
})
export class ProductsComponent implements OnInit, AfterViewInit {

    layoutCtrl = new UntypedFormControl('boxed');

    /**
     * Simulating a service with HTTP that returns Observables
     * You probably want to remove this and do all requests in a service with HTTP
     */
    subject$: ReplaySubject<Product[]> = new ReplaySubject<Product[]>(1);
    data$: Observable<Product[]> = this.subject$.asObservable();
    types: Product[];
    protected readonly PublishStatusEnum = PublishStatusEnum;

    @Input()
    columns: TableColumn[] = [
        {label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true},
        {label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'SKU', property: 'sku', type: 'text', visible: true, cssClasses: ['font-medium']},
        {label: 'Cover', property: 'cover', type: 'image', visible: false},
        {label: 'Title', property: 'title', type: 'text', visible: true},
        {label: 'Description', property: 'description', type: 'text', visible: false},
        {label: 'Price', property: 'price', type: 'text', visible: true},
        {label: 'Publish Status', property: 'publish_status', type: 'text', visible: true},
        {label: 'User', property: 'owner_id', type: 'text', visible: false, cssClasses: ['font-medium']},
        {label: 'Product Type', property: 'type', type: 'text', visible: false},
        {label: 'Category', property: 'category', type: 'text', visible: true},
        {label: 'Slug', property: 'slug', type: 'text', visible: false},
        {label: 'Working Hours', property: 'workingHours', type: 'text', visible: true},
        {label: 'Year of manufacturing', property: 'yearmanufacturing', type: 'text', visible: true},
        {label: 'Operating weight', property: 'operatingweight', type: 'text', visible: true},
        {label: 'Kind', property: 'kind', type: 'text', visible: false},
        {label: 'Deleted', property: 'deleted_at', type: 'text', visible: false},
        {label: 'Actions', property: 'actions', type: 'button', visible: true}
    ];
    modelLength = 0;
    pageIndex = 0;
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 20, 50, 100];
    dataSource: MatTableDataSource<Product> | null;
    selection = new SelectionModel<Product>(true, []);
    searchCtrl = new UntypedFormControl();
    getLoading:boolean=true;


    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(private dialog: MatDialog, private service: ProductService,
                private inspectionService: InspectionReportService,
                private toastr: ToastrService,
                private route: Router) {
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }


    getData() {

        this.service.paginate(this.pageSize,this.pageIndex+1).subscribe((res:any) => {
          this.pageSize = res['pagination']['per_page'];
          this.modelLength = res['pagination']['total'];
            of(res['data'].map(type => new Product(type))).subscribe(types => {
                this.subject$.next(types);

                this.getLoading=false;
            });

        });
    }

    ngOnInit() {
        this.getData();

        this.dataSource = new MatTableDataSource();

        this.data$.pipe(
            filter<Product[]>(Boolean)
        ).subscribe(types => {
            this.types = types.filter(prod=> prod?.deleted_at == null);
            this.dataSource.data = this.types;

        });

        this.searchCtrl.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(value => this.onFilterChange(value));
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    create() {
      this.route.navigate(['/products/create' ]).then();

    }


  publish(id){
    this.service.publish(id).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS) {
        this.toastr.success('Product publish Successfully!');
        this.ngOnInit();
      }
    }, error => {
      this.toastr.error(error);
    });
  }
  unpublish(id){
    this.service.unpublish(id).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS) {
        this.toastr.success('Product un publish Successfully!');
        this.ngOnInit();
      }
    }, error => {
      this.toastr.error(error);
    });
  }
    delete(type: Product) {
        this.service.delete(type).subscribe((res) => {
            if (res['status'] == ErrorStatusModel.SUCCESS) {
                this.toastr.success('Product Deleted Successfully!');
                this.ngOnInit();
                this.selection.deselect(type);
            }
        }, error => {
            this.toastr.error(error['message']);
        });
    }

    deleteAll(types: Product[]) {
        types.forEach(c => this.delete(c));
    }

    onFilterChange(value: string) {
        if (!this.dataSource) {
            return;
        }
        value = value.trim();
        value = value.toLowerCase();
        this.dataSource.filter = value;
    }

    toggleColumnVisibility(column, event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        column.visible = !column.visible;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    trackByProperty<T>(index: number, column: TableColumn) {
        return column.property;
    }

    setDefaultImage(event: any) {
        event.target.src = 'assets/img/demo/missing-picture.jpeg';
    }

    showDetails(product: Product) {
        this.route.navigate(['/products/' , product.id]).then();
    }
    showInspectionReport(product: Product) {

        this.route.navigate(['/products' , product.id , 'inspection-report']).then();
    }
    addInspectionReport(product: Product){
        this.dialog.open(InspectionReportCreateComponent, {
            data: {productID: product.id}
        }).afterClosed().subscribe((report: any) => {
            if(report)
            this.showInspectionReport(product);
        });
    }

    DeleteInspectionReport(product){
        this.inspectionService.delete(product?.id).subscribe((res) => {
            if (res['status'] == ErrorStatusModel.SUCCESS) {
                this.toastr.success('inspection Report Deleted Successfully!');
            }
        }, error => {
            this.toastr.error(error['message']);
        });
    }
  pageEvent: PageEvent;



    onPageChange(e: PageEvent) {
      this.pageEvent = e;
      this.pageSize = e.pageSize;
      this.pageIndex = e.pageIndex;



      this.getLoading=true;
      this.ngOnInit();
    }
}


