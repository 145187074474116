import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule, NgClass} from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatIconModule} from '@angular/material/icon';
import { MediaTypeEnum } from 'src/app/core/enum/media-type.enum';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule, UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import {PageLayoutDemoModule} from '../../../../ui/page-layouts/page-layout-demo/page-layout-demo.module';
import {SecondaryToolbarModule} from '../../../../../../@vex/components/secondary-toolbar/secondary-toolbar.module';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {QuillEditorComponent} from 'ngx-quill';
import {CdkDragDrop, DragDropModule, moveItemInArray} from '@angular/cdk/drag-drop';
import {ScrollbarModule} from '../../../../../../@vex/components/scrollbar/scrollbar.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgbRating} from '@ng-bootstrap/ng-bootstrap';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {EditorComponent} from '@tinymce/tinymce-angular';
import {UploadSingleMediaComponent} from '../../products/upload-single-media/upload-single-media.component';
import {SvgRendererComponent} from '../../../../../core/components/svg-icon/svg-icon.component';
import {TinyEditorComponent} from '../../../../../core/components/tiny-editor/tiny-editor.component';
import {ProductMediaComponent} from '../../products/product-media/product-media.component';
import {ExpertReviewsModel} from '../../../models/expert-reviews.model';
import {BaseProduct} from '../../../models/baseProduct.model';
import Swiper from 'swiper';
import {SpecificationTypeEnum} from '../../../../../core/enum/specification-type.enum';
import {Editor} from 'ngx-editor';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {MethodsUtils} from '../../../../../core/utils/method-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ReviewsService} from '../../expert-reviews/service/reviews.service';
import {CurrenciesService} from '../../currencies/service/currencies.service';
import {RatingStandardsService} from '../../rating-standards/rating-standards/rating-standards.service';
import {UnitService} from '../../units/service/unit.service';
import {BaseProductsService} from '../../base-products/service/base-products.service';
import {SpecificationService} from '../../specifications/service/specification.service';
import {ApplicationService} from '../../application/service/application.service';
import {MatDialog} from '@angular/material/dialog';
import {LocalStorageRefService} from '../../../../pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../../../../../core/enum/storage-keys.enum';
import {ProductTypeEnum} from '../../../../../core/enum/product-type.enum';
import {CreateMediaComponent} from '../../products/create-media/create-media.component';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {ResearchService} from '../service/research.service';
import {GenerationModel} from '../../../models/generation';

@Component({
  selector: 'vex-save-generation',
  templateUrl: './save-generation.component.html',
  styleUrls: ['./save-generation.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
    imports: [
        MatMenuModule,
        NgClass,
        MatCheckboxModule,
        BreadcrumbsModule,
        PageLayoutModule,
        MatIconModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        FormsModule,
        MatSlideToggleModule,
        CommonModule,
        MatButtonModule,
        MatTabsModule,
        PageLayoutDemoModule,
        SecondaryToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        QuillEditorComponent,
        DragDropModule,
        ScrollbarModule,
        MatTooltipModule,
        NgbRating,
        CKEditorModule,
        EditorComponent,
        UploadSingleMediaComponent,
        SvgRendererComponent,
        TinyEditorComponent,
        ProductMediaComponent,
        MatRippleModule
    ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class SaveGenerationComponent implements OnInit {
  reviewsForm: FormGroup;
  types = [];
  reviewsID: string;
  productID: any;
  reviewDetails: GenerationModel;
  bases: BaseProduct[];
  basesAll: BaseProduct[];
  specifications = [];
  applications = [];
  media = [];
  allMedia = [];
  groupedMediaArray: any;
  text = '';
  feature = '';
  formText;
  form: UntypedFormGroup;
  formFeatureText;
  formFeature: UntypedFormGroup;
  productSpecForm: FormGroup;
  dynamicFormSpec: FormGroup;
  dynamicFormRate: FormGroup;
  loadingSpecs: boolean = false;
  loadingMedia: boolean = false;
  loading: boolean = false;
  loadingData: boolean = false;
  hasFeatures: boolean = false;
  mode: 'create' | 'update' = 'create';
  selectedFiles: File[] = [];
  files = [];
  videos = [];
  units=[];
  currencies=[];
  ratings=[];
  allUnits=[];
  swiper?: Swiper;
  secificationTypeEnum=SpecificationTypeEnum;
  years =[];
  year = '';
  editor: Editor;
  selectedFile: string = '';
  selectedFileCover: string = '';
  selectedFileExpert: string = '';
  locale;
  defLocale;
  source: string = '';
  title = 'images-tinymce';
  readonly MediaTypeEnum = MediaTypeEnum ;
  editorLoading=false;
  editorVal='';
  activeTabIndex = -1 ;
  starRating=1;
  transValues =[];
  relatedExpert =[];
  // protected readonly data = data;
  constructor(private fb: FormBuilder,
              private storage: AngularFireStorage,
              private methods: MethodsUtils,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private router: Router,
              private service: ResearchService,
              private currenciesService: CurrenciesService, private ratingStandardsService: RatingStandardsService, private unitService: UnitService,
              private baseService: BaseProductsService,
              private specificationService: SpecificationService,
              private dialog: MatDialog,
              private localStorageRefService : LocalStorageRefService
  ) {


  }
  control: FormControl<number> = new FormControl(0, { nonNullable: true });



  ngOnInit() {
    this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
    this.defLocale = this.locale.filter(loc=>loc.default==true)[0];
    this.editor = new Editor();
    this.types = this.methods.enumToArray(ProductTypeEnum);
    this.reviewsID = this.route.snapshot.paramMap.get('id');
    this.getGenerations();
    this.getAllUnits();
    this.getModel();
    this.getCurrencies();
    this.getRatingStandars();


    if (this.reviewsID == 'create') {
      this.reviewDetails = {} as GenerationModel;

    } else {
      this.mode = 'update';
      this.getData();
    }
    this.reviewsForm = this.fb.group({
      market_average: ['',Validators.required],
      base_product_id: [null],
      price: ['',Validators.required],
      currency_id: [null],
      years: [[]],
      year: [null],
      from: [null],
      to: [null],
      show_home: [null],
      image: [null],
      cover: [null,Validators.required],
      media: [null],
      compare_with_generations: [null],
      working_height: ['',Validators.required],
      platform_length:['',Validators.required],
      platform_width: ['',Validators.required]

    });
    this.productSpecForm = this.fb.group({
      specifications: []
    });
    this.reviewsForm.get('from').valueChanges.subscribe((val) => {
      if(val)
      {
        this.reviewsForm.get('year').setValue(null);
      }

    });

    this.reviewsForm.get('to').valueChanges.subscribe((val) => {
      if(val)
      {
        this.reviewsForm.get('year').setValue(null);
      }

    });

    this.reviewsForm.get('year').valueChanges.subscribe((val) => {
      if(val)
      {
        this.reviewsForm.get('from').setValue(null);
        this.reviewsForm.get('to').setValue(null);
      }

    });
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.editorLoading =true;
    setTimeout(()=>{
      this.editorVal = this.getTranslationField(this.locale[event.index]?.locale);
      this.editorLoading =false;
    },50);

  }
  initForm() {
    // this.selectedFileExpert=this.reviewDetails.media.length!=0?this.reviewDetails.media[0]['original_url']:'assets/img/demo/missing-picture.jpeg';

    this.reviewsForm= this.fb.group({
      market_average: [this.reviewDetails?.market_average?.['amount'] || ''],
      base_product_id: [this.reviewDetails?.['base_product_id'] || null],
      price: [this.reviewDetails?.['price']['amount'] || ''],
      currency_id: [null],
      years: [this.reviewDetails?.years || []],
      compare_with_generations: [this.reviewDetails?.compare_with_generations || []],
      show_home: [this.reviewDetails?.show_home],
      year:[],
      from:[],
      cover:[null ],
      to:[],
      image:[],
      media: [null],
      working_height:[this.reviewDetails?.technical_generation_specification?.working_height||'',Validators.required],
      platform_length:[this.reviewDetails?.technical_generation_specification?.platform_length||'',Validators.required],
      platform_width:[this.reviewDetails?.technical_generation_specification?.platform_width||'',Validators.required]

    });

    this.editorLoading =true;
    // this.selectedFile = this.reviewDetails.cover===null?'assets/img/demo/missing-picture.jpeg':this.defaults.img;
    this.years = this.reviewDetails?.years;
    if(Object.values(this.reviewDetails?.years).length>1)
    {
      this.reviewsForm.controls.from.setValue( Object.values(this.reviewDetails?.years)[0]);
      this.reviewsForm.controls.to.setValue( Object.values(this.reviewDetails?.years)[Object.values(this.reviewDetails?.years).length-1]);
    }
    else {
      this.reviewsForm.controls.year.setValue( this.reviewDetails?.years[0]);
    }
    this.starRating = parseInt(this.reviewDetails?.makana_rating);
  }
  addYear(){
    if(this.reviewsForm.value.year != ''){
      this.years.push(this.reviewsForm.value.year);
    }
  }
  removeYear(index : number){
    this.years.splice(index,1);
  }
  getData() {
    this.loadingData = true;
    this.service.get(this.reviewsID).subscribe((res) => {
      this.reviewDetails = res['data'];
      this.loadingData = false;
      this.initForm();
      this.getSpecifications();
      this.getMedia();
    });
  }
  getModel() {
    this.baseService.get().subscribe((res) => {
      this.basesAll = res['data'];
    });
  }
  getGenerations() {
    this.service.all().subscribe((res) => {
      this.bases = res['data'];
    });
  }
  getCurrencies() {
    this.currenciesService.get().subscribe((res) => {
      this.currencies = res['data'];
    });
  }
  getRatingStandars() {
    this.ratingStandardsService?.get()?.subscribe((res) => {
      this.ratings = res['data'];
    });
  }
  getAllUnits() {
    this.unitService.getAllUnits().subscribe((res) => {
      // @ts-ignore
      this.allUnits = res?.data;
    });

  }
  getRelatedExpert() {
    //
    // this.service.getRelated(this.reviewsForm?.value?.base_product_id,this.reviewDetails?.id).subscribe((res) => {
    //   // @ts-ignore
    //   this.relatedExpert = res?.data;
    // });

  }
  getSpecifications() {
    this.loadingSpecs = true;
    if (this.reviewsForm?.value?.base_product_id) {
      this.getRelatedExpert();
      this.specificationService.getByBase(this.reviewsForm?.value?.base_product_id).subscribe((res) => {
        // @ts-ignore
        this.specifications = res.data;
        const formGroup = {};
        this.specifications?.forEach((item,index) => {
          const value = this.reviewDetails?.['specifications']?.find(obj => obj?.['specification_id'] === item.id)?.['value'] || null;
          const unit = this.reviewDetails?.['specifications']?.find(obj => obj?.['specification_id'] === item.id)?.['unit_id'] || null;
          let is_feature = false;
          const specId = this.reviewDetails?.['specifications']?.find(obj => obj?.['specification_id'] === item.id)?.['specification']?.['id'] || null;
          if(this.reviewDetails?.specifications?.length>0 && specId != null) {
            // @ts-ignore
            is_feature = this.reviewDetails?.specifications?.filter((t)=>t.specification_id == specId)[0]?.['is_featured'];
            console.log('is_feature');
            console.log(is_feature);
            console.log('is_feature');
            formGroup[item?.['key']+'_is_featured'] = [is_feature];
          }else{
            formGroup[item?.['key']+'_is_featured'] = [false];
          }

          if (value && item?.['key'] !='Features') {
            formGroup[item?.['key']] = [value, item?.['options']?.required ?'' : ''];
          }
          else if (value && item?.['key'] =='Features') {
            this.hasFeatures=true;
            formGroup[item?.['key']] = [value, item?.['options']?.required ? '' : ''];
            this.formFeatureText = new UntypedFormControl(value);
          }
          else if(!value && item?.['key'] =='Features'){
            this.hasFeatures=true;
            formGroup[item?.['key']] = ['', item?.['options']?.required ? '' : ''];
          }
          else {
            formGroup[item?.['key']] = ['', item?.['options']?.required ? '' : ''];
          }
          // if(item?.['unit_type_id']) {
          //     const units = this.allUnits.filter(unit=> unit?.['type']?.['id'] ==  item?.['unit_type_id'])
          //     this.units.push(units)
          //     formGroup[item?.['key']+'_unit_id'] = [unit ];
          //
          // }else {
          //     this.units.push([])
          //     formGroup[item?.['key']+'_unit_id'] = ['' ];
          // }
        });
        this.dynamicFormSpec = this.fb.group(formGroup);
      });
      this.loadingSpecs = false;
    }
  }

  onContentChanged(event) {
    this.text = event.html;
  }
  onContentFeatureChanged(event) {
    this.feature = event.html;
  }

  getMedia() {
    this.loadingMedia = true;
    this.service.getMedia(this.reviewDetails?.id).subscribe((res) => {
      // @ts-ignore
      this.media = res.filter(data => data['collection_name']!='cover');
      // @ts-ignore
      this.allMedia = res;
      this.groupedMedia();
      this.selectedFileCover=this.allMedia.length!=0?this.allMedia.filter(coll=>
        coll['collection_name']=='cover'
      )[0]['original_url']:'assets/img/demo/missing-picture.jpeg';
    });


  }

  groupedMedia() {
    const grouped = this.media?.reduce((acc, med) => {
      const key = med.collection_name;
      const existingCollection = acc.find(collection => collection.collection_name === key);
      if (existingCollection) {
        existingCollection.media.push(med);
      } else {
        acc.push({id: med.id, collection_name: key, media: [med] , type:med.mime_type.substring(0,med.mime_type.lastIndexOf('/'))});
      }
      return acc;
    }, []);
    this.groupedMediaArray = grouped;
    this.loadingMedia = false;
  }




  AddMedia() {
    this.dialog.open(CreateMediaComponent, {
      data: {id: this.reviewDetails?.id, name: '',type:'EXPERT',mode:'create'}
    }).afterClosed().subscribe((res: any) => {
      if (res)
        this.getMedia();
    });
  }


  onSubmit() {

    this.loading = true;
    let product = new FormData();

    // if (this.reviewsForm.value.expert instanceof File)
    product.append('base_product_id', this.reviewsForm.value?.base_product_id);
    product.append('price', this.reviewsForm.value?.price);
    product.append('makana_rating', this.starRating?.toString());
    product.append('market_average', this.reviewsForm.value?.market_average);
    product.append('technicalSpecifications[working_height]', this.reviewsForm.value?.working_height);
    product.append('technicalSpecifications[platform_length]', this.reviewsForm.value?.platform_length);
    product.append('technicalSpecifications[platform_width]', this.reviewsForm.value?.platform_width);
    product.append('show_home', this.reviewsForm.value?.show_home?'1':'0');
    if(this.reviewsForm.value.year){
      product.append(`years[${0}]`, this.reviewsForm.value.year);
    }
    if(this.reviewsForm.value.compare_with_generations!=null){
      this.reviewsForm.value.compare_with_generations.forEach((data,index)=>{
        product.append(`compare_with_generations[${index}]`, data);

      });
    }
    if(this.reviewsForm.value.from) {
      let yearsInBetween = [];
      for (let year = this.reviewsForm.value.from; year <= this.reviewsForm.value.to; year++) {
        yearsInBetween.push(year);
        product.append(`years[${yearsInBetween.length-1}]`, year);
      }
    }
    this.specifications.forEach((item, index) => {
      if (this.dynamicFormSpec?.value[item?.['key']]!='' && item?.['key'] !='Features') {
        product.append(`specifications[${index}][specification_id]`, item.id);
        product.append(`specifications[${index}][value]`, this.dynamicFormSpec.value[item?.['key']]);
        if(this.dynamicFormSpec.value[item?.['key']+'_is_featured'] ) {
          product.append(`specifications[${index}][is_featured]`, '1' );
        }
        else {
          product.append(`specifications[${index}][is_featured]`, '0');

        }
        if(item?.['pivot']?.['unit_id']!=null) {
          product.append(`specifications[${index}][unit_id]`, item?.['pivot']?.['unit_id']);
        }
      }
      if( item?.['key'] =='Features' && this.feature ) {
        product.append(`specifications[${index}][specification_id]`, item.id);
        product.append(`specifications[${index}][value]`, this.feature);
      }
    });
    // this.ratings.forEach((item, index) => {
    //   if (this.dynamicFormRate?.value[item?.['key']] ) {
    //     product.append(`rating[${index}][rating_standard_id]`, item.id);
    //     product.append(`rating[${index}][value]`, this.dynamicFormRate.value[item?.['key']]);
    //   }
    //
    // });


    if (this.mode == 'create' ) {
      let index =0 ;
      if (this.videos.length > 0) {
        product.append(`media[${index}][collection_name]`, 'collection_videos');
        product.append(`media[${index}][type]`, 'video');

        for (let j = 0; j < this.videos.length; j++) {
          product.append(`media[${index}][files][${j}][file]`, this.videos[j]);
        }
        index++;
      }
      if(this.reviewsForm.value.cover instanceof File){
        product.append(`media[${index}][files][0][file]`, this.reviewsForm.value.cover);
        product.append(`media[${index}][collection_name]`, 'cover');
        product.append(`media[${index}][type]`, 'image');
        index++;
      }

      // if (this.selectedFile) {
      //   product.append(`media[${index}][files][0][file]`, this.reviewsForm.value.cover, 'cover');
      //   product.append(`media[${index}][collection_name]`, 'cover');
      //   product.append(`media[${index}][type]`, 'image');
      //   index++;
      // }
      // if (this.selectedFile) {
      //   product.append(`media[${index}][files][0][file]`, this.reviewsForm.value.cover, 'cover');
      //   product.append(`media[${index}][collection_name]`, 'cover');
      //   product.append(`media[${index}][type]`, 'image');
      //   index++;
      // }
      if (this.files.length > 0) {
        product.append(`media[${index}][collection_name]`, 'view_360');
        product.append(`media[${index}][type]`, 'image');
        for (let j = 0; j < this.files.length; j++) {
          product.append(`media[${index}][files][${j}][file]`, this.files[j]);
        }
      }
      this.create(product);
    }

    if (this.mode == 'update' ) {
      product.append('id', this.reviewDetails.id.toString());
      let index=0;
      if(this.groupedMediaArray?.length>0) {
        this.groupedMediaArray.forEach((collection, index) => {
          product.append(`media_order[${index}][collection_name]`, collection.collection_name);
          for (let j = 0; j < collection.media.length; j++) {
            product.append(`media_order[${index}][orders][${j}][id]`, collection.media[j]?.id );
            product.append(`media_order[${index}][orders][${j}][order]`, (j+1).toString() );
            if(collection.media[j]?.['custom_properties']['description']){
              product.append(`media_order[${index}][orders][${j}][description]`, collection.media[j]?.['custom_properties']['description'] );

            }
          }
          index++;
        });
      }
      if(this.reviewsForm.value.cover instanceof File){
        product.append(`media[${index}][files][0][file]`, this.reviewsForm.value.cover);
        product.append(`media[${index}][collection_name]`, 'cover');
        product.append(`media[${index}][type]`, 'image');
        index++;
      }

      this.update(product);
    }

  }

  update(product) {
    this.service.update(product, this.reviewDetails.id).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
        this.toastr.success('Review Updated Successfully!');
        this.router.navigate(['/research/', res?.['data'].id]).then(() => {

        });
        this.loading = false;
      }
    }, error => {
      this.loading = false;
      this.toastr.error(error);
    });
    this.loading = false;
  }

  create(product) {
    this.service.create(product).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS || ErrorStatusModel.SUCCESSCREATE) {
        this.toastr.success('Review Created Successfully!');
        this.router.navigate(['/research/', res?.['data'].id]).then(() => {
          this.loading = false;
          location.reload();
        });
      }

    }, error => {
      this.loading = false;
      this.toastr.error(error);

    });
  }


  uploadFiles() {
    this.files = [];
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      if (file) {
        this.files.push(file);
      }
    }
  }




  drop(event: CdkDragDrop<any> , index) {
    moveItemInArray(this.groupedMediaArray[index].media, event.previousContainer.data.index, event.container.data.index);

  }


  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
      this.reviewsForm.patchValue({cover: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedFileCover = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }


  }



  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }
  getCover(event: any){
    this.reviewsForm.patchValue({cover: event});
  }
  getExpertImage(event: any){
    this.reviewsForm.patchValue({expert: event});
  }
  getVideos(event: any){
    this.videos=event;
  }
  setEditorControl(event:any , lang:string , field : string){
    this.reviewsForm.get(lang + '_' + field).setValue(event);
    const index = this.transValues.findIndex(item => item.lang === lang);
    if (index !== -1) {
      this.transValues[index] = { ...this.transValues[index], value: event };
    } else {
      this.transValues.push({ lang, value: event });
    }
  }
  getTranslationField(locale : string){
    return this.transValues?.find(obj => obj?.['lang'] === locale)?.['value'];
  }
  cancel(){
    this.router.navigate(['/research']).then();
  }

}
