import {Component, Input} from '@angular/core';
import {ProductService} from '../../service/product.service';
import {ErrorStatusModel} from '../../../../../../core/enum/error-status.model';
import {ToastrService} from 'ngx-toastr';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgIf} from '@angular/common';

@Component({
  selector: 'vex-save-threesixty',
  templateUrl: './save-threesixty.component.html',
  styleUrls: ['./save-threesixty.component.scss'],
  standalone:true,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    NgIf

  ]
})
export class SaveThreesixtyComponent {

  selectedFile;
  @Input() productId;
  loading=false;

  constructor(
    private service:ProductService,
    private toastr: ToastrService
  ) {
  }
  onFileSelected(event: Event): void {
    // @ts-ignore
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];

    }
  }

  save(): void{
    this.loading=true;
    // @ts-ignore
    const formData = new FormData();
    formData.append('zip_file', this.selectedFile);
    this.service.uplodeThreeSixty(this.productId, formData).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
        this.loading = false;
        this.selectedFile=null;
        this.toastr.success('Upload Successfully!');
      }
    }, error => {
      this.loading = false;
      this.toastr.error(error);
    });
  }
}
