import { SelectionModel } from '@angular/cdk/collections';
import { NgClass, CommonModule } from '@angular/common';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { ReactiveFormsModule, FormsModule, UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Observable, of, filter } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { LoadingRowComponent } from 'src/app/core/components/loading-row/loading-row.component';
import { FilterTypeEnum } from 'src/app/core/enum/filter-type.enum';
import { StorageKeysEnum } from 'src/app/core/enum/storage-keys.enum';
import { MethodsUtils } from 'src/app/core/utils/method-utils';
import { LocalStorageRefService } from 'src/app/pages/pages/auth/service/local-storage-ref.service';
import { UnitTypeService } from '../../unit-type/service/unit-type.service';
import { OwnerReviewModel } from '../../../models/owner-review.model';
import { ReviewsService } from '../service/reviews.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from 'ngx-editor';
import { CapitalizePipe } from '../../../../../core/pipes/capitalize.pipe';
import { NgbRating } from '@ng-bootstrap/ng-bootstrap';
import { ErrorStatusModel } from 'src/app/core/enum/error-status.model';
import {ResearchService} from "../../research/service/research.service";

@UntilDestroy()
@Component({
    selector: 'vex-owner-review',
    templateUrl: './owner-review.component.html',
    styleUrls: ['./owner-review.component.scss'],
    standalone: true,
    animations: [
        fadeInUp400ms,
        stagger40ms
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            } as MatFormFieldDefaultOptions
        }
    ],
    imports: [
        PageLayoutModule,
        BreadcrumbsModule,
        MatIconModule,
        MatMenuModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        NgClass,
        MatTableModule,
        MatPaginatorModule,
        FormsModule,
        CommonModule,
        MatLegacyButtonModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatExpansionModule,
        LoadingRowComponent,
        MatTabsModule,
        CapitalizePipe,
        NgbRating
    ]
})
export class OwnerReviewComponent implements OnInit, AfterViewInit {

  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<OwnerReviewModel[]> = new ReplaySubject<OwnerReviewModel[]>(1);
  data$: Observable<OwnerReviewModel[]> = this.subject$.asObservable();
  OwnerReviewModels: OwnerReviewModel[];
  expertId;
  locale;
  @Input()
  columns: TableColumn[] = [
    {label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true},
    {label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Submitted By', property: 'submitted_by', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Stars', property: 'stars', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Actions', property: 'actions', type: 'button', visible: true}


  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<OwnerReviewModel> | null;
  selection = new SelectionModel<OwnerReviewModel>(true, []);
  searchCtrl = new UntypedFormControl();

  form: UntypedFormGroup;
  mode: 'create' | 'update' = 'create';
  loading = false;
  getLoading = true;
  isPanelOpen = false;
  defaults ?: OwnerReviewModel;
  groups;
  review;
  productId ;
  filterTypes = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(private localStorageRefService : LocalStorageRefService,
              private service: ResearchService,
              private toastr: ToastrService,
              private fb: UntypedFormBuilder,
              private methods: MethodsUtils,
              private elementRef: ElementRef,
              private unitService: UnitTypeService,
              private route : ActivatedRoute,
              private router : Router,
              private cdRef: ChangeDetectorRef
               ) {
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }


  async getData() {
    this.expertId = this.route.snapshot.paramMap.get('id');
    this.service.getOwnerReview(this.expertId).subscribe((res) => {



      of(res['data']['user_reviews'].map(type => new OwnerReviewModel(type))).subscribe(async types => {
        this.subject$.next(types);
        this.review = await res['data']['expert'];

        this.getLoading = false;
      });

    });
  }

  async ngOnInit() {

    this.productId = this.route.snapshot.queryParams.productId;
    this.loading=false;
    await this.getData();
    await this.getGroup();


    this.filterTypes = this.methods.enumToArray(FilterTypeEnum);
    this.dataSource = new MatTableDataSource();
    // this.form = this.fb.group({

    // });
    this.data$.pipe(
      filter<OwnerReviewModel[]>(Boolean)
    ).subscribe(data => {
      this.OwnerReviewModels = data;
      this.dataSource.data = data;
    });

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));


  }

  async getGroup(){
    this.clearForm(true);
    this.service.getRatingStandard().subscribe(async (res) => {
      this.groups = await res['data'];

      this.groups.forEach((group, index) => {
        this.form.addControl(group['key'], this.fb.control(0));

      });
    });


  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  create() {
    this.clearForm();
    this.isPanelOpen = true;
    this.scrollToForm();
  }
  initForm(obj:OwnerReviewModel){
    this.isPanelOpen=true;
    this.mode='update';
    this.form.reset;
    this.form = this.fb.group({
      id: [obj.id],
      pros: [obj.pros , Validators.required],
      cons: [obj.cons , Validators.required],
      submitted_by: [obj.submitted_by , Validators.required],
      rating: [obj.stars , Validators.required],
      product_id: [this.productId ]
    });
    const firstLoopPromise = new Promise<void>((resolve, reject) => {
    this.groups.forEach((group , index) => {
      this.form.addControl(group['key'],this.fb.control(0));
      resolve();
    });

    });
    firstLoopPromise.then(() => {

      obj.ratings.forEach(rating => {
        // this.form.addControl(rating.rating_standard.key, this.fb.control(rating.value));
        const control = this.form.get(rating.rating_standard.key);
        if (control) {
          control.setValue(rating.value);
        }
        this.cdRef.detectChanges();
      });
    });


    }
  clearForm(fromGroup:boolean=false){

    this.isPanelOpen = false;
    this.mode='create';
    // this.form.reset;


  if(!fromGroup){
    this.form = this.fb.group({

      pros: ['' , Validators.required],
      cons: ['' , Validators.required],
      submitted_by: ['' , Validators.required],
      rating: [1 , Validators.required]

    });
    this.groups.forEach((group , index) => {
      this.form.addControl(group['key'],this.fb.control(0));
    });
  }else {
    this.form = this.fb.group({

      pros: ['' , Validators.required],
      cons: ['' , Validators.required],
      submitted_by: ['' , Validators.required],
      rating: [1 , Validators.required]
    });
  }



  }
  update(spec: OwnerReviewModel) {
    this.initForm(spec);
    this.scrollToForm();
  }

  scrollToForm() {
    const formElement = this.elementRef.nativeElement.querySelector('#form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  delete(review: OwnerReviewModel) {
    this.service.deleteOwnerReview(review.id).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS|| res['status'] == ErrorStatusModel.SUCCESSCREATE) {
        this.toastr.success('Owner Review Deleted Successfully!');
        this.ngOnInit();
        this.selection.deselect(review);
      }

    }, error => {
      this.toastr.error(error);

    });
  }

  deleteAll(data: OwnerReviewModel[]) {
    data.forEach(c => this.delete(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }


  isCreateMode() {
    return this.mode === 'create';
  }
  save(){
    this.loading=true;
    const formData = new FormData();
    if(this.mode == 'update'){
      formData.append('id',this.form.value.id);
    }else{
      formData.append('generation_id',this.expertId);

    }
    formData.append('pros',this.form.value.pros);
    formData.append('cons',this.form.value.cons);
    formData.append('submitted_by',this.form.value.submitted_by);
    formData.append('stars',this.form.value.rating);
    let count=0;
    this.groups.forEach((group,index) => {
      if(this.form.value[group['key']]){
        formData.append('rating['+ count +'][rating_standard_id]',group['id']);
        formData.append('rating['+ count +'][value]',this.form.value[group['key']]);
        count++;
      }
    });

    if (formData) {
      if(this.mode == 'update'){
        this.service.updateOwnerReview(formData,this.form.value.id).subscribe((res) => {
          if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
            this.toastr.success('Review Updated Successfully!');
            this.ngOnInit();
            this.clearForm(false);

          }

        }, error => {
          this.loading=false;
          this.toastr.error(error);
        });
      }else {
        this.service.createOwnerReview(formData).subscribe((res) => {
          if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
            this.toastr.success('Review Created Successfully!');
            this.ngOnInit();
            this.clearForm(false);
          }

        }, error => {
          this.loading=false;
          this.toastr.error(error);
        });
      }

    }



  }
  onCancel(){
    this.router.navigate(['/research']).then();
  }

}


