export class NewsModel {
    id: number;
    news_category_id: number;
    slug: string;
    reading_duration : number;
    tags : any;
    media : any;
    title :string;
    content : string;
    description : string;
    show_home : number;
    related_news:any;
    translations:[
      {
        title:string;
        content:string;
        description:string;
        locale:string;
      }
    ];
    constructor(news) {
        this.id = news.id;
        this.news_category_id = news.news_category_id;
        this.slug = news.slug;
        this.reading_duration = news.reading_duration;
        this.tags = news.tags;
        this.show_home = news.show_home;
        this.related_news = news.related_news;
        this.media = news.media;
        this.content = news.content;
        this.description = news.description;
        this.title = news.title;
        this.translations = news.translations;
    }
}



