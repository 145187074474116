
export enum SpecificationTypeEnum {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    SELECT = 'SELECT',
    DATE = 'DATE',
    BOOLEAN = 'BOOLEAN',
    YEAR = 'YEAR',
    DECIMAL = 'DECIMAL',
    // CHECKBOX = 'CHECKBOX',
}
