<div class="container p-5 relative" >

  <form (ngSubmit)="save()" *ngIf="!getLoading"  [formGroup]="form" class="flex flex-col gap-3">
    <div class="flex items-center" mat-dialog-title>
      <button class="text-secondary" mat-icon-button type="button" (click)="onCancel()">
        <mat-icon svgIcon="mat:arrow_back"></mat-icon>
      </button>
      <h2 *ngIf="form.get('en_question').value || form.get('en_question').value"
          class="headline m-0 flex-auto">{{ form.get('en_question').value }}</h2>
      <h2 *ngIf="!form.get('en_question').value && !form.get('en_question').value"
          class="headline m-0 flex-auto">New Faq</h2>

    </div>
    <mat-divider class="text-border"></mat-divider>
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
      <mat-tab  *ngFor="let lang of locale" [label]="lang.locale | uppercase">

    <div class="flex flex-col bg-white p-4" >

      <div class="flex gap-3 items-center">
        <mat-form-field class="flex-auto">
          <mat-label>Question ( {{lang.locale | uppercase }} )</mat-label>
          <input cdkFocusInitial formControlName="{{lang.locale}}_question" matInput>
        </mat-form-field>

      </div>
      <div class="flex gap-3 items-center">
        <mat-form-field class="flex-auto">
          <mat-label>Faq Group</mat-label>
          <select matNativeControl cdkFocusInitial required formControlName="faq_group_id">
            <option *ngFor="let group of groups" [ngValue]="group['id']" >{{group['name']}}</option>
          </select>
        </mat-form-field>
        <mat-form-field class="flex-auto">
          <mat-label>Order</mat-label>
          <select matNativeControl cdkFocusInitial required formControlName="order">
            <option *ngFor="let number of numbers" >{{number}}</option>
          </select>
        </mat-form-field>
        <mat-slide-toggle formControlName="status">Active</mat-slide-toggle>
      </div>
      <div class="card "*ngIf="!loadingForm">
        <h3 class="mb-2 pt-2 pl-2">Answer ( {{lang.locale | uppercase }} )</h3>
        <editor
          formControlName="{{lang.locale}}_answer"
          apiKey="6tb9ekfidko0nolwuntzi5j5sdmynss8gsee3l8r9rmwu4ee"
          [init]="config"
        ></editor>      </div>


    </div>
      </mat-tab>
    </mat-tab-group>
    <div align="end">
      <button mat-button type="button" (click)="onCancel()">Cancel</button>
      <button  color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
        <span *ngIf="!loading && isCreateMode() "> Create  </span>
        <span *ngIf="!loading && !isCreateMode() "> Update  </span>
        <mat-progress-spinner *ngIf="loading"  diameter="30" mode="indeterminate"></mat-progress-spinner>
      </button>

    </div>
  </form>

  <div  *ngIf="getLoading">
      <vex-loading-row  [diameter]="40" ></vex-loading-row>
  </div>
</div>

