import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FaqGroupModel } from '../../../models/faq-group.model';

@Injectable({
  providedIn: 'root'
})
export class FaqGroupService {

  constructor(private http:HttpClient) { }
  get(){
    return this.http.get(environment.baseUrl + 'static-page/faq-group/');
   }
  create(model:FormData){
    return this.http.post(environment.baseUrl + 'static-page/faq-group/',model);
   }
  delete(model:FaqGroupModel){
    return this.http.delete(environment.baseUrl + 'static-page/faq-group/'+model.id);
   }
}
