import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {AuthService} from '../../../../pages/auth/service/auth.service';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'; // Ensure this import is correct

// Declare Pusher as a global variable to avoid reference errors
declare global {
  interface Window {
    Pusher: any;
  }
}

window.Pusher = Pusher;
@Injectable({
    providedIn: 'root'
})
export class PusherService {
  public echo: Echo;

  constructor() {

  }

  public listen(channelName: string, eventName: string, callback: Function) {
    this.echo.channel(channelName).listen(eventName, (data: any) => {
      callback(data);
    });
  }


  getSockets(): Echo {
    return new Echo({
      broadcaster: 'pusher',
      key: environment.pusherKey,
      cluster: 'ap2',
      forceTLS: true,
      authEndpoint: 'https:/devpi.makana.com/api/v1/website/broadcasting/auth'
    });
  }

  public leave(channelName: string) {
    this.echo.leave(channelName);
  }


}
