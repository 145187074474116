import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule, NgClass} from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LoadingRowComponent} from '../../../../../core/components/loading-row/loading-row.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {
    CdkDrag,
    CdkDragPlaceholder,
    CdkDropList,
    CdkDropListGroup
} from '@angular/cdk/drag-drop';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProductService} from '../service/product.service';
import {ToastrService} from 'ngx-toastr';
import {SvgRendererComponent} from '../../../../../core/components/svg-icon/svg-icon.component';
import {MediaTypeEnum} from '../../../../../core/enum/media-type.enum';

@Component({
    selector: 'app-upload-single-media',
    templateUrl: './upload-single-media.component.html',
    styleUrls: ['./upload-single-media.component.scss'],
    standalone: true,
    animations: [
        fadeInUp400ms,
        stagger40ms
    ],
    imports: [
        MatMenuModule,
        NgClass,
        MatCheckboxModule,
        BreadcrumbsModule,
        PageLayoutModule,
        MatIconModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        FormsModule,
        MatSlideToggleModule,
        CommonModule,
        LoadingRowComponent,
        MatDatepickerModule,
        MatNativeDateModule,
        CdkDropList,
        MatProgressSpinnerModule,
        CdkDropListGroup,
        CdkDrag,
        CdkDragPlaceholder,
        SvgRendererComponent
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            } as MatFormFieldDefaultOptions
        }
    ]
})
export class UploadSingleMediaComponent implements OnInit {
    @Input() type ='image';
    @Input() title = '' ;
    @Input() selectedCover ;
    @Output() getFile = new EventEmitter<any>();
    @Output() getVideos = new EventEmitter<any>();
    readonly MediaTypeEnum = MediaTypeEnum;
    videos = [];
    videoURL:any;

    ngOnInit() {
    }
    constructor() {
    }
    setDefaultImage(event: any) {
        event.target.src = '';
    }
    handleImageError(event: any) {
        event.target.src = 'assets/img/demo/no-img.jpeg';
    }
    onVideoSelected(event: any) {
        const file = event.target.files && event.target.files[0];
        if (file) {
            this.videos.push(file);
            this.getVideos.emit(this.videos);
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                this.videoURL = (<FileReader>event.target).result;
            };
        }
    }
    onImagePickedCover(event: Event) {
        const file = (event.target as HTMLInputElement).files[0];
        if (file) {
            // this.productForm.patchValue({cover: file});
            this.getFile.emit(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                this.selectedCover = e.target.result as string;
            };
            reader.readAsDataURL(file);
        }

    }

}
