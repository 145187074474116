import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {TermModel} from '../../../models/term.model';

@Injectable({
    providedIn: 'root'
})
export class TermsService {

    constructor(private http: HttpClient) {
    }

    get() {
        return this.http.get(environment.baseUrl + 'static-page/term');
    }

    create(term: any) {
        return this.http.post(environment.baseUrl + 'static-page/term', term);
    }

    update(term: any, id) {
        return this.http.post(environment.baseUrl + 'static-page/term/' + id, term);
    }

    delete(model: TermModel) {
        return this.http.delete(environment.baseUrl + 'static-page/term/' + model.id);
    }

    getTerm(id) {
        return this.http.get(environment.baseUrl + 'static-page/term/' + id);
    }
}
