import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {InspectionReportModel} from '../../../models/inspection-report.model';

@Injectable({
  providedIn: 'root'
})
export class InspectionReportService {

  constructor(private http: HttpClient) {

  }

  get(productId: any) {
    return this.http.get(environment.baseUrl + 'product/products/' + productId + '/inspection-report');
  }

  getGroups() {
    return this.http.get(environment.baseUrl + 'inspection-report/inspection-report-group');
  }

  getParts() {
    return this.http.get(environment.baseUrl + 'inspection-report/inspection-report-part');
  }

  create(report: any, productId: any) {
    return this.http.post(environment.baseUrl + 'product/products/' + productId + '/inspection-report', report);
  }

  createGroupFromReport(group:any , reportId : any){
    return this.http.post(environment.baseUrl + 'product/inspection-report-group/'+ reportId,group);
  }
  createGroup(group: any) {
    return this.http.post(environment.baseUrl + 'inspection-report/inspection-report-group/', group);
  }

  createPartFromReport(part: any, groupId: any) {
    return this.http.post(environment.baseUrl + 'product/inspection-report-part/' + groupId, part);
  }
   createPart(part: any) {
    return this.http.post(environment.baseUrl + 'inspection-report/inspection-report-part' , part);
   }

  update(app: any) {
    return this.http.put(environment.baseUrl + 'application/application-group/' + app.get('id'), app);
  }

  delete(productId: any) {
    return this.http.delete(environment.baseUrl + 'product/products/' + productId + '/inspection-report');
  }

  deleteGroup(groupId: any) {
    return this.http.delete(environment.baseUrl + 'inspection-report/inspection-report-group/' + groupId);
  }

  deletePart( partId: any) {
    return this.http.delete(environment.baseUrl + 'inspection-report/inspection-report-part/' + partId);
  }
  addGroup(report_id,form){
    return this.http.post(environment.baseUrl + 'product/inspection-report-group/' + report_id,form);

  }
  deleteProductPart( partId: any) {
    return this.http.delete(environment.baseUrl + 'product/inspection-report-part/' + partId);
  }
  deleteProductGroup( groupId: any) {
    return this.http.delete(environment.baseUrl + 'product/inspection-report-group/' + groupId);
  }

}
