import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { ScrollbarModule } from 'src/@vex/components/scrollbar/scrollbar.module';
import { CalendarRoutingModule } from 'src/app/pages/apps/calendar/calendar-routing.module';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { BookingFreeTourComponent } from './booking-free-tour.component';
import { ShowEventComponent } from './show-event/show-event.component';
import { BooingTourTableComponent } from './booing-tour-table/booing-tour-table.component';
import {BreadcrumbsModule} from "../../../../../@vex/components/breadcrumbs/breadcrumbs.module";
import {LoadingRowComponent} from "../../../../core/components/loading-row/loading-row.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatMenuModule} from "@angular/material/menu";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatTableModule} from "@angular/material/table";
import {MatTabsModule} from "@angular/material/tabs";
import {NgxMatDatetimePickerModule} from "@angular-material-components/datetime-picker"; // Verify import path



@NgModule({

  // declarations: [BookingFreeTourComponent],
  imports: [
    CommonModule,
    CalendarRoutingModule,
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ScrollbarModule,
    MatSnackBarModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    PageLayoutModule,
    MatNativeDateModule,
    BreadcrumbsModule,
    LoadingRowComponent,
    MatCheckboxModule,
    MatExpansionModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    NgxMatDatetimePickerModule,
    FormsModule
  ],
  exports: [
  ],

  declarations: [


    ]
})
export class BookingFreeTourModule { }
