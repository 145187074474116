import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {fadeInUp400ms} from '../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../@vex/animations/stagger.animation';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {PageLayoutModule} from '../../../../../@vex/components/page-layout/page-layout.module';
import {BreadcrumbsModule} from '../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {LoadingRowComponent} from '../../../../core/components/loading-row/loading-row.component';
import {filter, Observable, of, ReplaySubject} from 'rxjs';
import {TableColumn} from '../../../../../@vex/interfaces/table-column.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {ErrorStatusModel} from '../../../../core/enum/error-status.model';
import {ZoneModel} from '../../models/zone.model';
import {ZoneService} from './service/zone.service';
@UntilDestroy()
@Component({
  selector: 'vex-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ],
  imports: [
    PageLayoutModule,
    BreadcrumbsModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgClass,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    CommonModule,
    LoadingRowComponent
  ]
})
export class ZonesComponent implements OnInit, AfterViewInit{
  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<ZoneModel[]> = new ReplaySubject<ZoneModel[]>(1);
  data$: Observable<ZoneModel[]> = this.subject$.asObservable();
  types: ZoneModel[];

  @Input()
  columns: TableColumn[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Name', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Status', property: 'status', type: 'text', visible: true, cssClasses: ['font-medium'] },
    {label: 'Actions', property: 'actions', type: 'button', visible: true}

  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<ZoneModel> | null;
  selection = new SelectionModel<ZoneModel>(true, []);
  searchCtrl = new UntypedFormControl();
  getLoading:boolean=true;



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(
    private dialog: MatDialog,
    private service:ZoneService,
    private toastr: ToastrService,
    private route : Router
  ) {
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }


  getData() {

    this.service.all().subscribe((res)=>{
      of(res['data'].map(type=>new ZoneModel(type))).subscribe(types=>{
        this.subject$.next(types);
        this.getLoading=false;
      });

    });
  }

  ngOnInit() {
    this.getData();

    this.dataSource = new MatTableDataSource();

    this.data$.pipe(
      filter<ZoneModel[]>(Boolean)
    ).subscribe(types => {
      this.types = types;
      this.dataSource.data = types;
    });

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }






  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }

  create(){
    this.route.navigate(['/zones/save']).then();
  }
  update(data: any) {
    this.route.navigate(['/zones/' + data.id]).then();
  }
  delete(data: any){
    this.service.delete(data.id).subscribe((res) => {
      if(res['status'] == ErrorStatusModel.SUCCESS) {
        this.toastr.success('Zone Deleted Successfully!');
        this.ngOnInit();
        this.selection.deselect(data);
      }
    },error =>
    {
      this.toastr.error(error['errors']['message']);

    });
  }
  deleteall(brands: ZoneModel[]) {
    brands.forEach(c => this.delete(c));
  }
}
