import {Injectable} from '@angular/core';
import {svgList} from './svg-list';


@Injectable({
    providedIn: 'root'
})

export class MethodsUtils {
   enumToArray(enumObject: any): { id: string; name: string }[] {
    const enumKeys = Object.keys(enumObject);
    const enumArray = enumKeys
      .filter(key => isNaN(Number(enumObject[key]))) // Filter out numeric keys
      .map(key => ({ id: enumObject[key], name: key }));
    return enumArray;
  }

  getYMDformat(dateString: string){
          const date = new Date(dateString);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          return `${year}-${month}-${day}`;
  }
}
