import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule, NgClass} from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatIconModule} from '@angular/material/icon';
import {
    FormBuilder, FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import {PageLayoutDemoModule} from '../../../../ui/page-layouts/page-layout-demo/page-layout-demo.module';
import {SecondaryToolbarModule} from '../../../../../../@vex/components/secondary-toolbar/secondary-toolbar.module';
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatFormFieldDefaultOptions,
    MatFormFieldModule
} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProductTypeEnum} from '../../../../../core/enum/product-type.enum';
import {SpecificationTypeEnum} from '../../../../../core/enum/specification-type.enum';
import {MethodsUtils} from '../../../../../core/utils/method-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseProductsService} from '../../base-products/service/base-products.service';
import {BaseProduct} from '../../../models/baseProduct.model';
import {SpecificationService} from '../../specifications/service/specification.service';
import {QuillEditorComponent} from 'ngx-quill';
import {ApplicationService} from '../../application/service/application.service';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {
    ConfirmationDialogComponent
} from '../../../../../core/components/confirmation-dialog/confirmation-dialog.component';
import Swiper from 'swiper';
import {CdkDragDrop, DragDropModule, moveItemInArray} from '@angular/cdk/drag-drop';
import {ScrollbarModule} from '../../../../../../@vex/components/scrollbar/scrollbar.module';
import {UnitService} from '../../units/service/unit.service';
import {CreateMediaComponent} from '../../products/create-media/create-media.component';
import { UpdateMediaFileComponent } from '../../products/update-media-file/update-media-file.component';
import {ReviewsService} from '../service/reviews.service';
import {ExpertReviewsModel} from '../../../models/expert-reviews.model';
import {CurrenciesService} from '../../currencies/service/currencies.service';
import {RatingStandardsService} from '../../rating-standards/rating-standards/rating-standards.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgbRating} from '@ng-bootstrap/ng-bootstrap';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ChangeEvent, CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {data} from 'autoprefixer';
import {EditorComponent} from '@tinymce/tinymce-angular';
import {Editor} from 'ngx-editor';
import {finalize, last} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {LocalStorageRefService} from '../../../../pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../../../../../core/enum/storage-keys.enum';
import {UploadSingleMediaComponent} from '../../products/upload-single-media/upload-single-media.component';
import { MediaTypeEnum } from 'src/app/core/enum/media-type.enum';
import {SvgRendererComponent} from '../../../../../core/components/svg-icon/svg-icon.component';
import {TinyEditorComponent} from '../../../../../core/components/tiny-editor/tiny-editor.component';
import {ProductMediaComponent} from '../../products/product-media/product-media.component';

@Component({
    selector: 'vex-expert-reviews',
    templateUrl: './expert-reviews-create-edit.component.html',
    styleUrls: ['./expert-reviews-create-edit.component.scss',
        '../../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../../@vex/styles/partials/plugins/quill/_quill.scss'
    ],
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    animations: [
        fadeInUp400ms,
        stagger40ms
    ],
    imports: [
        MatMenuModule,
        NgClass,
        MatCheckboxModule,
        BreadcrumbsModule,
        PageLayoutModule,
        MatIconModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        FormsModule,
        MatSlideToggleModule,
        CommonModule,
        MatButtonModule,
        MatTabsModule,
        PageLayoutDemoModule,
        SecondaryToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        QuillEditorComponent,
        DragDropModule,
        ScrollbarModule,
        MatTooltipModule,
        NgbRating,
        CKEditorModule,
        EditorComponent,
        UploadSingleMediaComponent,
        SvgRendererComponent,
        TinyEditorComponent,
        ProductMediaComponent,
        MatRippleModule
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            } as MatFormFieldDefaultOptions
        }
    ]
})
export class ExpertReviewsCreateEditComponent implements OnInit {
    reviewsForm: FormGroup;
    types = [];
    reviewsID: string;
    generationID: string;
    productID: any;
    reviewDetails: ExpertReviewsModel;
    bases: BaseProduct[];
    specifications = [];
    applications = [];
    media = [];
    allMedia = [];
    groupedMediaArray: any;
    text = '';
    feature = '';
    formText;
    form: UntypedFormGroup;
    formFeatureText;
    formFeature: UntypedFormGroup;
    productSpecForm: FormGroup;
    dynamicFormSpec: FormGroup;
    dynamicFormRate: FormGroup;
    loadingSpecs: boolean = false;
    loadingMedia: boolean = false;
    loading: boolean = false;
    loadingData: boolean = false;
    hasFeatures: boolean = false;
    mode: 'create' | 'update' = 'create';
    selectedFiles: File[] = [];
    files = [];
    videos = [];
    units=[];
    currencies=[];
    ratings=[];
    allUnits=[];
    swiper?: Swiper;
    secificationTypeEnum=SpecificationTypeEnum;
    years =[];
    year = '';
    editor: Editor;
    selectedFile: string = '';
    selectedFileCover: string = '';
    selectedFileExpert: string = '';
    locale;
    defLocale;
    source: string = '';
    title = 'images-tinymce';
    readonly MediaTypeEnum = MediaTypeEnum ;
    editorLoading=false;
    editorVal='';
    activeTabIndex = -1 ;
    starRating=1;
    transValues =[];
    relatedExpert =[];
    protected readonly data = data;
  constructor(private fb: FormBuilder,
                private storage: AngularFireStorage,
                private methods: MethodsUtils,
                private route: ActivatedRoute,
                private toastr: ToastrService,
                private router: Router,
                private service: ReviewsService,
                private currenciesService: CurrenciesService, private ratingStandardsService: RatingStandardsService, private unitService: UnitService,
                private baseService: BaseProductsService,
                private specificationService: SpecificationService,
                private appService: ApplicationService,
                private dialog: MatDialog,
                private localStorageRefService : LocalStorageRefService,
                private cdr : ChangeDetectorRef
                ) {


    }
    control: FormControl<number> = new FormControl(0, { nonNullable: true });



    ngOnInit() {
      this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
      this.defLocale = this.locale.filter(loc=>loc.default==true)[0];
      this.editor = new Editor();
        this.types = this.methods.enumToArray(ProductTypeEnum);
        this.reviewsID = this.route.snapshot.paramMap.get('id');
        this.generationID = this.route.snapshot.paramMap.get('generation');
        // this.getBaseProduct();
        // this.getAllUnits();

        // this.getCurrencies();
        // this.getRatingStandars();


      if (this.reviewsID == 'create') {
            this.reviewDetails = {} as ExpertReviewsModel;

        } else {
            this.mode = 'update';
            this.getData();
        }
        this.reviewsForm = this.fb.group({
            // market_average: ['',Validators.required],
            // base_product_id: [''],
            // price: ['',Validators.required],
            // currency_id: [null],
            // years: [[]],
            compare_with_experts: [null],
            // year: [null],
            // from: [null],
            // to: [null],
            // image: [null],
            // cover: [null,Validators.required],
            expert: [null],
            // external_url:['',Validators.required],
            // media: [null]

        });
      this.locale.forEach(lang => {
        this.reviewsForm.addControl(lang.locale+'_expert_name', this.fb.control(''));
        this.reviewsForm.addControl(lang.locale+'_text', this.fb.control(''));
      });
        this.productSpecForm = this.fb.group({
            specifications: []
        });
      // this.reviewsForm.get('from').valueChanges.subscribe((val) => {
      //   if(val)
      //   {
      //     this.reviewsForm.get('year').setValue(null);
      //   }
      //
      // });

      // this.reviewsForm.get('to').valueChanges.subscribe((val) => {
      //   if(val)
      //   {
      //     this.reviewsForm.get('year').setValue(null);
      //   }
      //
      // });

      // this.reviewsForm.get('year').valueChanges.subscribe((val) => {
      //   if(val)
      //   {
      //     this.reviewsForm.get('from').setValue(null);
      //     this.reviewsForm.get('to').setValue(null);
      //   }
      //
      // });
    }

  onTabChanged(event: MatTabChangeEvent) {
    this.editorLoading =true;
      setTimeout(()=>{
          this.editorVal = this.getTranslationField(this.locale[event.index]?.locale);
          this.editorLoading =false;
      },50);

  }
    initForm() {
      this.selectedFileExpert=this.reviewDetails.media.length!=0?this.reviewDetails.media[0]['original_url']:'assets/img/demo/missing-picture.jpeg';

      this.reviewsForm= this.fb.group({
            // market_average: [this.reviewDetails?.market_average || ''],
            // expert_name: [this.reviewDetails?.expert_name || ''],
            // compare_with_experts: [this.reviewDetails?.compare_with_experts?.map((data:any)=>Number.parseInt(data)) || null],
            compare_with_experts: [this.reviewDetails?.compare_with_experts || null],
            // base_product_id: [this.reviewDetails?.['base_product_id'] || ''],
            // price: [this.reviewDetails?.['price']['amount'] || ''],
            // currency_id: [null],
            text: [this.reviewDetails?.['text'] || ''],
            // years: [this.reviewDetails?.years || []],
            // year:[],
            // from:[],
            // cover:[null ],
            expert:[],
            // to:[],
            // image:[],
            // external_url:[this.reviewDetails?.['external_url']],
            // media: [null]

        });
      // this.getRelatedExpert();
      this.locale.forEach(lang => {
        const value = this.reviewDetails.translations.filter(trans => trans.locale == lang.locale);
        this.reviewsForm.addControl(lang.locale+'_expert_name', this.fb.control(value[0]?.expert_name??''));
        this.reviewsForm.addControl(lang.locale+'_text', this.fb.control(value[0]?.text??''));

        const textValues = {
            lang : lang.locale,
            value : value[0]?.text??' '
        };
        this.transValues.push(textValues);
      });
        this.editorLoading =true;
        setTimeout(()=>{
            this.editorVal = this.getTranslationField(this.locale[0]?.['locale']);
            this.editorLoading =false;
        },50);

        // this.selectedFile = this.reviewDetails.cover===null?'assets/img/demo/missing-picture.jpeg':this.defaults.img;
      // this.years = this.reviewDetails?.years;
      // if(Object.values(this.reviewDetails?.years).length>1)
      //   {
      //       this.reviewsForm.controls.from.setValue( Object.values(this.reviewDetails?.years)[0]);
      //       this.reviewsForm.controls.to.setValue( Object.values(this.reviewDetails?.years)[Object.values(this.reviewDetails?.years).length-1]);
      //   }
      //   else {
      //       this.reviewsForm.controls.year.setValue( this.reviewDetails?.years[0]);
      //   }
      //   this.text = this.reviewDetails?.text;
      //   this.starRating = parseInt(this.reviewDetails?.makana_rating);
        this.formText = new UntypedFormControl(this.reviewDetails?.text);
    }
    addYear(){
        // if(this.reviewsForm.value.year != ''){
        //     this.years.push(this.reviewsForm.value.year);
        // }
    }
    removeYear(index : number){
        this.years.splice(index,1);
    }
    getData() {
        this.loadingData = true;
        this.service.getReview(this.reviewsID).subscribe((res) => {
            this.reviewDetails = res['data'];
            this.productID = this.reviewDetails?.product_id;
            this.loadingData = false;
            this.initForm();
            // this.getSpecifications();
            // this.getMedia();

        });
    }

    getBaseProduct() {
        this.baseService.get().subscribe((res) => {
            this.bases = res['data'];
        });
    }
   getCurrencies() {
        this.currenciesService.get().subscribe((res) => {
            this.currencies = res['data'];
        });
    }
    getRatingStandars() {
        this.ratingStandardsService?.get()?.subscribe((res) => {
            this.ratings = res['data'];
        });
    }
    getAllUnits() {
        this.unitService.getAllUnits().subscribe((res) => {
            // @ts-ignore
            this.allUnits = res?.data;
        });

    }
  getRelatedExpert() {

    this.service.getRelated(this.reviewsForm?.value?.base_product_id,this.reviewDetails?.id).subscribe((res) => {
      // @ts-ignore
      this.relatedExpert = res?.data;
    });

  }
    getSpecifications() {
        this.loadingSpecs = true;
        if (this.reviewsForm?.value?.base_product_id) {
          this.getRelatedExpert();
            this.specificationService.getByBase(this.reviewsForm?.value?.base_product_id).subscribe((res) => {
                // @ts-ignore
                this.specifications = res.data;
                const formGroup = {};
                this.specifications?.forEach((item,index) => {
                    const value = this.reviewDetails?.['specifications']?.find(obj => obj?.['specification_id'] === item.id)?.['value'] || null;
                    const unit = this.reviewDetails?.['specifications']?.find(obj => obj?.['specification_id'] === item.id)?.['unit_id'] || null;
                    let is_feature = false;
                    const specId = this.reviewDetails?.['specifications']?.find(obj => obj?.['specification_id'] === item.id)?.['specification']?.['id'] || null;
                   if(this.reviewDetails?.featured_specifications?.length>0 && specId != null) {
                   // @ts-ignore
                     is_feature = this.reviewDetails?.featured_specifications?.indexOf(specId.toString())!== -1;
                    formGroup[item?.['key']+'_is_feature'] = [is_feature];
                  }else{
                     formGroup[item?.['key']+'_is_feature'] = [false];
                   }

                  if (value && item?.['key'] !='Features') {
                        formGroup[item?.['key']] = [value, item?.['options']?.required ?'' : ''];
                    }
                    else if (value && item?.['key'] =='Features') {
                        this.hasFeatures=true;
                        formGroup[item?.['key']] = [value, item?.['options']?.required ? '' : ''];
                        this.formFeatureText = new UntypedFormControl(value);
                    }
                    else if(!value && item?.['key'] =='Features'){
                        this.hasFeatures=true;
                        formGroup[item?.['key']] = ['', item?.['options']?.required ? '' : ''];
                    }
                    else {
                        formGroup[item?.['key']] = ['', item?.['options']?.required ? '' : ''];
                    }
                  // if(item?.['unit_type_id']) {
                    //     const units = this.allUnits.filter(unit=> unit?.['type']?.['id'] ==  item?.['unit_type_id'])
                    //     this.units.push(units)
                    //     formGroup[item?.['key']+'_unit_id'] = [unit ];
                    //
                    // }else {
                    //     this.units.push([])
                    //     formGroup[item?.['key']+'_unit_id'] = ['' ];
                    // }
                });
                this.dynamicFormSpec = this.fb.group(formGroup);
            });
            this.loadingSpecs = false;
        }
    }

    onContentChanged(event) {
        this.text = event.html;
    }
    onContentFeatureChanged(event) {
        this.feature = event.html;
    }

    getMedia() {
        this.loadingMedia = true;
        this.service.getMedia(this.reviewDetails?.id).subscribe((res) => {
            // @ts-ignore
            this.media = res.filter(data => data['collection_name']!='cover');
          // @ts-ignore
            this.allMedia = res;
            this.groupedMedia();
          this.selectedFileCover=this.allMedia.length!=0?this.allMedia.filter(coll=>
            coll['collection_name']=='cover'
          )[0]['original_url']:'assets/img/demo/missing-picture.jpeg';
        });


    }

    groupedMedia() {
        const grouped = this.media?.reduce((acc, med) => {
            const key = med.collection_name;
            const existingCollection = acc.find(collection => collection.collection_name === key);
            if (existingCollection) {
                existingCollection.media.push(med);
            } else {
                acc.push({id: med.id, collection_name: key, media: [med] , type:med.mime_type.substring(0,med.mime_type.lastIndexOf('/'))});
            }
            return acc;
        }, []);
        this.groupedMediaArray = grouped;
        this.loadingMedia = false;
    }




    AddMedia() {
        this.dialog.open(CreateMediaComponent, {
            data: {id: this.reviewDetails?.id, name: '',type:'EXPERT'}
        }).afterClosed().subscribe((res: any) => {
            if (res)
                this.getMedia();
        });
    }


    onSubmit() {

      this.loading = true;
      let product = new FormData();
      product.append('generation_id', this.generationID);
      if (this.reviewsForm.value.expert instanceof File)
      product.append('expert_reviewer_image', this.reviewsForm.value.expert);
        product.append('expert_name', this.reviewsForm.value[this.defLocale.locale + '_expert_name']);
        product.append('text', this.reviewsForm.value[this.defLocale.locale + '_text']);
      this.reviewsForm.value.compare_with_experts?.forEach((item, index) => {
        product.append(`compare_with_experts[${index}]`, item.toString());
      });
      this.locale.filter(lang => lang.default==0 ).forEach(lang =>{
        if(this.reviewsForm.value[lang.locale + '_expert_name']!='')
          product.append(lang.locale +'[expert_name]',this.reviewsForm.value[lang.locale + '_expert_name']);
        if(this.reviewsForm.value[lang.locale + '_text']!='')
          product.append(lang.locale +'[text]',this.reviewsForm.value[lang.locale + '_text']);
      });
        if (this.mode == 'create' ) {
          this.create(product);
        }
        if (this.mode == 'update' ) {
            product.append('id', this.reviewDetails.id.toString());
            this.update(product);
        }

    }

    update(product) {
        this.service.update(product, this.reviewDetails.id).subscribe((res) => {
            if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
              this.toastr.success('Review Updated Successfully!');
              this.router.navigate(['research/'+res?.['data']?.generation_id+'/expert-reviews/', res?.['data'].id]).then(() => {
              });
              this.loading = false;
            }
        }, error => {
            this.loading = false;
            this.toastr.error(error);
        });
        this.loading = false;
    }

    create(product) {
        this.service.create(product).subscribe((res) => {
            if (res['status'] == ErrorStatusModel.SUCCESS || ErrorStatusModel.SUCCESSCREATE) {
              this.toastr.success('Review Created Successfully!');
              this.router.navigate(['research/'+res?.['data']?.generation_id+'/expert-reviews/', res?.['data'].id]).then(() => {
                this.loading = false;
                location.reload();
              });
            }
        }, error => {
            this.loading = false;
            this.toastr.error(error);

        });
    }
    uploadFiles() {
        this.files = [];
        for (let i = 0; i < this.selectedFiles.length; i++) {
            const file = this.selectedFiles[i];
            if (file) {
                this.files.push(file);
            }
        }
    }
    drop(event: CdkDragDrop<any> , index) {
        moveItemInArray(this.groupedMediaArray[index].media, event.previousContainer.data.index, event.container.data.index);

    }
  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
      this.reviewsForm.patchValue({expert: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedFileExpert = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }


  }



  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }
    getCover(event: any){
        this.reviewsForm.patchValue({cover: event});
    }
    getExpertImage(event: any){
        this.reviewsForm.patchValue({expert: event});
    }
    getVideos(event: any){
        this.videos=event;
    }
    setEditorControl(event:any , lang:string , field : string){
        this.reviewsForm.get(lang + '_' + field).setValue(event);
        const index = this.transValues.findIndex(item => item.lang === lang);
        if (index !== -1) {
            this.transValues[index] = { ...this.transValues[index], value: event };
        } else {
            this.transValues.push({ lang, value: event });
        }
    }
   getTranslationField(locale : string){
        return this.transValues?.find(obj => obj?.['lang'] === locale)?.['value'];
  }

  cancel(){
    this.router.navigate(['/research']).then();
  }

  }
