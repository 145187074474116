import { ErrorExceptionType } from 'src/app/core/enum/error-exception-type.enum';

export class ErrorExceptionModel {
  errorType: ErrorExceptionType;
  errorMessages: string[];

  constructor(errorType: ErrorExceptionType, errorMessages: string[]) {
    this.errorType = errorType;
    this.errorMessages = errorMessages;
  }
}
