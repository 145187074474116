
export class GenerationModel {
  id: any;
  base_product_id: number;
  currency_id:number;
  makana_rating:string;
  deleted_at: string;
  name: string;
  show_home:number;
  price: number;
  slug: string;
  media : any[];
  years : any[];
  market_average : string;
  expert:any;
  specifications : [];
  compare_with_generations : [];
  featured_specifications : [];
  technical_generation_specification : {
    working_height : string
    platform_length : string
    platform_width : string
  };
  translations : [
    {
      name:string;
      locale:string;
    }
  ];

  constructor(review : GenerationModel ) {
    this.id = review.id;
    this.base_product_id = review.base_product_id;
    this.name = review.name;
    this.slug = review.slug;
    this.deleted_at = review.deleted_at;
    this.currency_id = review.currency_id;
    this.makana_rating = review.makana_rating;
    this.price = Number.parseInt(String(review.price));
    this.market_average = review.market_average;
    this.media = review.media;
    this.years = review.years;
    this.show_home = review.show_home;
    this.specifications = review.specifications;
    this.featured_specifications = review.featured_specifications;
    this.technical_generation_specification = review.technical_generation_specification;
    this.translations = review.translations;
    this.expert = review.expert;
    this.compare_with_generations = review.compare_with_generations;


  }
}

