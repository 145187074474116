import {Component, Inject, Input, OnInit} from "@angular/core";
import {DOCUMENT, NgForOf, NgIf} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {UpdateHotspotComponent} from "../component/update-hotspot/update-hotspot.component";
import {MatButtonModule} from "@angular/material/button";
import {PageLayoutModule} from "../../../../../../@vex/components/page-layout/page-layout.module";
import {ActivatedRoute} from '@angular/router';
import {ProductService} from "../service/product.service";
import {saveAs} from 'file-saver';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ErrorStatusModel} from "../../../../../core/enum/error-status.model";
import {ToastrService} from "ngx-toastr";
import {MatIconModule} from "@angular/material/icon";
import {ScrollbarModule} from "../../../../../../@vex/components/scrollbar/scrollbar.module";


declare var pannellum: any;

@Component({
    selector: 'vapp-pannellum',
    templateUrl: './pannellum.component.html',
    styleUrls: ['./pannellum.component.scss'],
    standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    PageLayoutModule,
    MatProgressSpinnerModule,
    NgForOf,
    MatIconModule,
    ScrollbarModule
  ]
})
export class PannellumComponent implements OnInit {
    pan;
    addingHotSpots: boolean = false;
    config: any;
    hotspotCounter: number = 0
    productId: string;
    mediaId: string;
    mediaUrl: string;
    selectedFile;
    loading = false;

    ngOnInit(): void {
        this.productId = this.route.snapshot.paramMap.get('id')!;
        this.mediaId = this.route.snapshot.paramMap.get('media')!;
        this.getMedia()

    }

    getMedia() {
        this.productService.getSingleMedia(this.productId, this.mediaId).subscribe((res) => {
            if (res) {
                this.mediaUrl = res?.['original_url'];
                this.loadPanorama()
            }
        }, error => {
        });
    }

    constructor(@Inject(DOCUMENT) private document: Document,
                private route: ActivatedRoute, private productService: ProductService,
                private dialog: MatDialog, private toastr: ToastrService) {
    }

    addHotSpots() {
        this.addingHotSpots = !this.addingHotSpots
        this.updateHotSpotDebug(this.addingHotSpots)
    }

    loadPanorama(): void {
        this.config = {
            "type": "equirectangular",
            "panorama": this.mediaUrl,
            // "panorama": "https://d26ib0ax9q2qqx.cloudfront.net/products/179/panorama/d34e730e-64dc-4634-b54a-3e44585320db.jpg",
            "sceneFadeDuration": 1000,
            "hotSpotDebug": false,
            "autoLoad": true,
            "hotSpots": []
        };

        this.pan = pannellum.viewer('panorama', this.config);

        this.addClickListener()
    }

    updateHotSpotDebug(debugMode: boolean): void {
        this.pan.destroy();
        this.config.hotSpotDebug = debugMode;
        this.pan = pannellum.viewer('panorama', this.config);
    }

    addClickListener(): void {
        const panoramaElement = document.getElementById('panorama');
        if (panoramaElement) {
            panoramaElement.addEventListener('click', this.onPanoramaClick.bind(this));
        }
    }

    setStartingPosition() {
        const pitch = this.pan.getPitch();
        const yaw = this.pan.getYaw();
        const hfov = this.pan.getHfov();

        this.config.yaw = yaw
        this.config.pitch = pitch
        this.config.hfov = hfov

        this.pan.destroy();
        this.pan = pannellum.viewer('panorama', this.config);
    }

    onPanoramaClick(event: MouseEvent): void {
        if (this.addingHotSpots) {
            const pitch = this.pan.getPitch();
            const yaw = this.pan.getYaw();
            const hotSpotId = this.generateUniqueId();
            this.pan.addHotSpot({
                "pitch": pitch,
                "yaw": yaw,
                "type": "info",
                "cssClass" : "hotspot-dot" ,
                "text": "",
                "clickHandlerFunc": this.onHotSpotClick.bind(this),
                "clickHandlerArgs": {id: hotSpotId , type : 'info'}
            });

        }
    }

    generateUniqueId(): number {
        return ++this.hotspotCounter;
    }

    onHotSpotClick(event: any, args: any): void {
        const hotSpotId = args.id;
        this.updateHotspot(hotSpotId)
    }

    updateHotspot(hotSpotId: any) {
        const dialogRef = this.dialog.open(UpdateHotspotComponent, {
            width: '250px',
            data: {
                description: ""
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            switch (result) {
                case 'delete':
                    this.deleteHotSpot(hotSpotId);
                    break;
                case 'close':
                    this.cancleHotSpot(hotSpotId)
                    break;
                default:
                    this.editHotSpot(hotSpotId, result);
                    break;
            }

        });
    }

    editHotSpot(hotSpotId: number, result: string):
        void {
        const hotspot = this.config.hotSpots.find((hs: any) => hs.clickHandlerArgs.id === hotSpotId);
        if (hotspot && result
        ) {
            hotspot.text = result;
            hotspot.cssClass = "hotspot-dot";
            this.pan.destroy();
            this.pan = pannellum.viewer('panorama', this.config);
        }
    }

    cancleHotSpot(hotSpotId: number): void {
        const hotspot = this.config.hotSpots.find((hs: any) => hs.clickHandlerArgs.id === hotSpotId);
        if (hotspot) {
            // hotspot.clickHandlerFunc = () => {
            //     this.pan.destroy();
            //     this.pan = null;
            // };
            hotspot.clickHandlerArgs.type = 'close';
            hotspot.clickHandlerFunc = ()=> {
                document.getElementById('panorama').style.display = 'none';
            }
            hotspot.cssClass = "hotspot-minus";
            this.pan.destroy();
            this.pan = pannellum.viewer('panorama', this.config);
        }
    }


    deleteHotSpot(hotSpotId: number): void {
        console.log(hotSpotId)
        this.config.hotSpots = this.config.hotSpots.filter((hs: any) => hs.clickHandlerArgs.id !== hotSpotId);
        this.pan.destroy();
        this.pan = pannellum.viewer('panorama', this.config);
    }

    saveJsonFile() {
        console.log(this.config)
        const jsonString = JSON.stringify(this.config, null, 2);
        console.log(jsonString)
        // const blob = new Blob([jsonString], {type: "application/json"});
        // saveAs(blob, "file.json");
    }

    onFileSelected(event: Event): void {
        // @ts-ignore
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            this.selectedFile = input.files[0];

        }
    }

    save(): void {
        this.loading = true;
        // @ts-ignore
        const formData = new FormData();
        formData.append('metadata', this.selectedFile);
        this.productService.uplodePanorama(this.productId, this.mediaId, formData).subscribe((res) => {
            if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
                this.loading = false;
                this.selectedFile = null;
                this.toastr.success('Upload Successfully!');
            }
        }, error => {
            this.loading = false;
            this.toastr.error(error);
        });
    }

}

