<vex-page-layout>

  <vex-page-layout-header class="container pb-16  flex flex-col justify-center items-start">
    <div class="flex items-center">
      <button class="text-secondary" mat-icon-button type="button" (click)="onCancel()">
        <mat-icon svgIcon="mat:arrow_back"></mat-icon>
      </button>
      <h2 *ngIf="!loading" class="headline m-0 flex-auto">Order : {{order.id}}</h2>
    </div>
  </vex-page-layout-header>

  <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
                           [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
                           class="-mt-6">
    <div *ngIf="!loading" class=" md:flex justify-start">
    <div >



      <div class="card overflow-auto -mt-16 ">
        <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Checkbox Column -->
          <ng-container matColumnDef="checkbox" >
            <th *matHeaderCellDef mat-header-cell  >
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            color="primary">
              </mat-checkbox>
            </th>
            <td *matCellDef="let row" class="w-4" mat-cell >
              <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                            (click)="$event.stopPropagation()"
                            [checked]="selection.isSelected(row)"
                            color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Image Column -->
          <ng-container matColumnDef="image">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
              <img [src]="row['image']" class="avatar h-8 w-8 align-middle">
            </td>
          </ng-container>

          <!-- Text Columns -->
          <ng-container *ngFor="let column of columns; trackBy: trackByProperty"  >
            <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
              <th *matHeaderCellDef  mat-header-cell mat-sort-header  style="background-color: #FAFCFE !important"> {{ column.label }}</th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
            </ng-container>
            <ng-container *ngIf="column.type === 'image'" [matColumnDef]="column.property">
              <th *matHeaderCellDef  mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                <img [src]="row[column.property]"  (error)="setDefaultImage($event)" class="avatar h-9 w-9 align-middle my-2">
              </td>
            </ng-container>
          </ng-container>

          <!-- Contact Column -->
          <ng-container matColumnDef="contact">
            <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef="let row" mat-cell>
              <div class="flex">
                <a (click)="$event.stopPropagation()"
                   class="w-8 h-8 p-0 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary/10"
                   mat-icon-button>
                  <mat-icon class="icon-sm" svgIcon="mat:phone"></mat-icon>
                </a>

                <a (click)="$event.stopPropagation()"
                   class="w-8 h-8 p-0 leading-none flex items-center justify-center ml-1 hover:bg-hover text-teal bg-teal/10"
                   mat-icon-button>
                  <mat-icon class="icon-sm" svgIcon="mat:mail"></mat-icon>
                </a>

                <a (click)="$event.stopPropagation()"
                   class="w-8 h-8 p-0 leading-none flex items-center justify-center ml-1 hover:bg-hover text-green bg-green/10"
                   mat-icon-button>
                  <mat-icon class="icon-sm" svgIcon="mat:map"></mat-icon>
                </a>
              </div>
            </td>
          </ng-container>

          <!-- Label Column -->
          <ng-container matColumnDef="labels" >
            <th *matHeaderCellDef  mat-header-cell mat-sort-header>Labels</th>
            <td *matCellDef="let row" mat-cell>
              <div (click)="$event.stopPropagation()" class="flex items-center gap-1">
                <div *ngFor="let label of row.labels"
                     [ngClass]="[label.textClass, label.bgClass]"
                     class="rounded px-2 py-1 font-medium text-xs flex-none ">
                  {{ label.text }}
                </div>
                <div class="text-secondary bg-base text-hint cursor-pointer hover:bg-hover flex-none flex items-center justify-center">
                  <mat-icon class="icon-sm" svgIcon="mat:add"></mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
              <!--            <button (click)="$event.stopPropagation()"-->
              <!--                    [matMenuTriggerData]="{ customer: row }"-->
              <!--                    [matMenuTriggerFor]="actionsMenu"-->
              <!--                    mat-icon-button-->
              <!--                    type="button">-->
              <!--              <mat-icon svgIcon="mat:more_horiz"></mat-icon>-->
              <!--            </button>-->
            </td>
          </ng-container>

          <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
          <tr
            *matRowDef="let row; columns: visibleColumns;"
            @fadeInUp

            class="hover:bg-hover trans-ease-out cursor-pointer"
            mat-row></tr>
        </table>
        <div class="flex justify-end items-end  text-end p-2 pt-5 ">

          <div class="w-full text-end divide-y-2">
            <div class="flex justify-between">
              <p class="font-bold">Sub Total : </p>
              <p>{{order['sub_total']??''}}</p>
            </div>
            <div class="flex justify-between">
              <p class="font-bold">Taxes : </p>
              <p>{{order['taxes']['amount']??''}}</p>
            </div>
            <div class="flex justify-between">
              <p class="font-bold">Discounts : </p>
              <p>{{order['discounts']['amount']??''}}</p>
            </div >
            <div class="flex justify-between ">
              <p class="font-bold">Shipment Cost : </p>
              <p>{{order['shipment_cost']['amount']??''}}</p>
            </div>

            <div class="flex justify-between" style="background-color: #FAFCFE !important">
              <p class="font-bold">Total : </p>
              <p>{{order['total']??''}}</p>
            </div>
          </div>
        </div>
      </div>



<!--      <mat-paginator  [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" class="sticky left-0"></mat-paginator>-->
    </div>
      <div class="md:w-1/4 md:m-3 mt-3 md:!-mt-16 ">
        <div class="card rounded-lg pb-2 mb-2" >
          <h2 class=" m-1.5 font-bold items-center w-full">Order Details</h2>
          <vex-field-details-card [title]="'Status'" [content]="order['status']" class="w-full"></vex-field-details-card>
          <vex-field-details-card [title]="'Create at'" [content]="order['created_at']" class="w-full"></vex-field-details-card>
          <vex-field-details-card [title]="'Notes'" [content]="order['notes']" class="w-full"></vex-field-details-card>
        </div>
        <div class="card rounded-lg pb-2 mb-2">
          <h2 class="m-1.5 pt-1 font-bold items-center w-full">Customer Info</h2>
          <vex-field-details-card [title]="'Full Name'" [content]="order['buyer']['full_name']" class="w-full"></vex-field-details-card>
          <vex-field-details-card [title]="'Phone Number'" [content]="order['buyer']['phone_number']" class="w-full"></vex-field-details-card>
          <vex-field-details-card [title]="'Email'" [content]="order['buyer']['email']" class="w-full"></vex-field-details-card>
        </div>
      </div>
    </div>
    <vex-loading-row *ngIf="loading" [diameter]="25" class="w-3/4 bg-white"></vex-loading-row>
  </vex-page-layout-content>

</vex-page-layout>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
          class="mat-menu-item block">
    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
      {{ column.label }}
    </mat-checkbox>
  </button>
</mat-menu>

