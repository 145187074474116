import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fadeInUp400ms} from "../../../../../../@vex/animations/fade-in-up.animation";
import {stagger40ms} from "../../../../../../@vex/animations/stagger.animation";
import {MatMenuModule} from "@angular/material/menu";
import {CommonModule, NgClass} from "@angular/common";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {BreadcrumbsModule} from "../../../../../../@vex/components/breadcrumbs/breadcrumbs.module";
import {PageLayoutModule} from "../../../../../../@vex/components/page-layout/page-layout.module";
import {MatIconModule} from "@angular/material/icon";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatButtonModule} from "@angular/material/button";
import {MatTabsModule} from "@angular/material/tabs";
import {PageLayoutDemoModule} from "../../../../ui/page-layouts/page-layout-demo/page-layout-demo.module";
import {SecondaryToolbarModule} from "../../../../../../@vex/components/secondary-toolbar/secondary-toolbar.module";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatOptionModule, MatRippleModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {QuillEditorComponent} from "ngx-quill";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ScrollbarModule} from "../../../../../../@vex/components/scrollbar/scrollbar.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgbRating} from "@ng-bootstrap/ng-bootstrap";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {EditorComponent} from "@tinymce/tinymce-angular";
import {UploadSingleMediaComponent} from "../../products/upload-single-media/upload-single-media.component";
import {SvgRendererComponent} from "../../../../../core/components/svg-icon/svg-icon.component";
import {TinyEditorComponent} from "../../../../../core/components/tiny-editor/tiny-editor.component";
import {ProductMediaComponent} from "../../products/product-media/product-media.component";
import {LocalStorageRefService} from "../../../../pages/auth/service/local-storage-ref.service";
import {StorageKeysEnum} from "../../../../../core/enum/storage-keys.enum";
import {ResearchService} from "../service/research.service";
import {GenerationModel} from "../../../models/generation";
import {ErrorStatusModel} from "../../../../../core/enum/error-status.model";

@Component({
  selector: 'vex-feature-generation',
  templateUrl: './feature-generation.component.html',
  styleUrls: ['./feature-generation.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    MatMenuModule,
    NgClass,
    MatCheckboxModule,
    BreadcrumbsModule,
    PageLayoutModule,
    MatIconModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    MatSlideToggleModule,
    CommonModule,
    MatButtonModule,
    MatTabsModule,
    PageLayoutDemoModule,
    SecondaryToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    QuillEditorComponent,
    DragDropModule,
    ScrollbarModule,
    MatTooltipModule,
    NgbRating,
    CKEditorModule,
    EditorComponent,
    UploadSingleMediaComponent,
    SvgRendererComponent,
    TinyEditorComponent,
    ProductMediaComponent,
    MatRippleModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class FeatureGenerationComponent implements OnInit{
  loading:boolean=false;
  loadingData:boolean=true;
  form:FormGroup;
  model;
  locale;
  defLocale;
  generations;
  selectedFirstFile: string = '';
  selectedSecondFile: string = '';
  constructor(
    private fb: FormBuilder,
    private localStorageRefService : LocalStorageRefService,
    private researchService:ResearchService
  ) {
  }

  ngOnInit(): void {
    this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
    this.defLocale = this.locale.filter(loc=>loc.default==true)[0];
    this.getModel();
  }
  getModel(){
    this.researchService.allFeature().subscribe((res)=>{
      this.model = res['data']?.[0]||null;
      this.initForm();
      this.getGeneration();
      this.loadingData=false;
    });

  }
  initForm(){
    this.form = this.fb.group({
      generation_id : [this.model?.generation_id||null,Validators.required],
      id : [this.model?.id||null],
      first : [null],
      second : [null]
    });
    this.locale.forEach(lang => {
      const value = this.model?.translations?.filter(model => model.locale == lang.locale);
      this.form.addControl(lang.locale+'_name', this.fb.control(value?value[0]?.name:'',this.defLocale.locale==lang.locale?[Validators.required]:[]));
      this.form.addControl(lang.locale+'_title', this.fb.control(value?value[0]?.title:'',this.defLocale.locale==lang.locale?[Validators.required]:[]));
      this.form.addControl(lang.locale+'_description', this.fb.control(value?value[0]?.description:'',this.defLocale.locale==lang.locale?[Validators.required]:[]));
    });
    this.selectedFirstFile = this.model?this.model.media.filter((d)=>d.collection_name=='first')?.[0]?.original_url:'assets/img/demo/poster_placeholder.jpg';
    this.selectedSecondFile = this.model?this.model.media.filter((d)=>d.collection_name=='second')?.[0]?.original_url:'assets/img/demo/poster_placeholder.jpg';
  }
  getGeneration(){
    this.researchService.all().subscribe((res)=>{
      this.generations = res['data'];
    });
  }
  onSubmit(){
    this.loading=true;
    const formData=new FormData();
    if(this.model != null) {
      formData.append('id', this.form.value?.id);
    }
    if (this.form.value.first instanceof File) {
      formData.append('first', this.form.value.first);
    }
    if (this.form.value.second instanceof File) {
      formData.append('second', this.form.value.second);
    }

    formData.append('name',this.form.value[this.defLocale?.locale + '_name']);
    formData.append('title',this.form.value[this.defLocale?.locale + '_title']);
    formData.append('description',this.form.value[this.defLocale?.locale + '_description']);
    formData.append('generation_id',this.form.value.generation_id);


    this.locale.filter(lang => lang.default==0 ).forEach(lang =>{

      if(this.form.value[lang?.locale + '_name']!='')
        formData.append(lang?.locale +'[name]',this.form.value[lang?.locale + '_name']);

      if(this.form.value[lang?.locale + '_title']!='')
        formData.append(lang?.locale +'[title]',this.form.value[lang?.locale + '_title']);

      if(this.form.value[lang?.locale + '_description']!='')
        formData.append(lang?.locale +'[description]',this.form.value[lang?.locale + '_description']);

    });
    if (formData) {
      if(this.model==null){
        this.researchService.createFeature(formData).subscribe((res) => {
          if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
            // this.toastr.success('Brand '+this.mode+'d Successfully!');
            this.ngOnInit();
            this.loading=false;

          }
        }, error => {
          this.loading=false;
          // this.toastr.error(error);
        });
      }else{
        this.researchService.updateFeature(this.model.id,formData).subscribe((res) => {
          if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
            // this.toastr.success('Brand '+this.mode+'d Successfully!');
            this.ngOnInit();
            this.loading=false;

          }
        }, error => {
          this.loading=false;
          // this.toastr.error(error);
        });
      }

  }}
  onFirstImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      this.form.patchValue({first: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedFirstFile = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }
  }
  onSecondImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      this.form.patchValue({second: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedSecondFile = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }
  }
  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/poster_placeholder.jpg';
  }
}
