import {SelectionModel} from '@angular/cdk/collections';
import {NgClass, CommonModule} from '@angular/common';
import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {
  ReactiveFormsModule,
  FormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder, Validators
} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatFormFieldDefaultOptions,
    MatFormFieldModule
} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule, MatPaginator} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSort} from '@angular/material/sort';
import {MatTableModule, MatTableDataSource} from '@angular/material/table';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ToastrService} from 'ngx-toastr';
import {ReplaySubject, Observable, of, filter} from 'rxjs';
import {fadeInUp400ms} from 'src/@vex/animations/fade-in-up.animation';
import {stagger40ms} from 'src/@vex/animations/stagger.animation';
import {BreadcrumbsModule} from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import {PageLayoutModule} from 'src/@vex/components/page-layout/page-layout.module';
import {TableColumn} from 'src/@vex/interfaces/table-column.interface';
import {ErrorStatusModel} from 'src/app/core/enum/error-status.model';

import {Router} from '@angular/router';
import {OurTeamService} from './services/our-team.service';
import {OurTeamModel} from '../../models/our-team.model';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgbRating} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationGroupModel} from '../../models/application-group.model';
import {OnlineReviewModel} from '../../models/online-review.model';
import {LoadingRowComponent} from '../../../../core/components/loading-row/loading-row.component';
import {LocalStorageRefService} from '../../../pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../../../../core/enum/storage-keys.enum';
import {MatTabsModule} from '@angular/material/tabs';

@UntilDestroy()
@Component({
  selector: 'vex-our-teams',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ],
  imports: [
    PageLayoutModule,
    BreadcrumbsModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgClass,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    NgbRating,
    LoadingRowComponent,
    MatTabsModule

  ]
})
export class OurTeamComponent implements OnInit, AfterViewInit {
  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<OurTeamModel[]> = new ReplaySubject<OurTeamModel[]>(1);
  data$: Observable<OurTeamModel[]> = this.subject$.asObservable();
  types: OurTeamModel[];

  @Input()
  columns: TableColumn[] = [
    {label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true},
    {label: 'Image', property: 'media', type: 'image', visible: true},
    {label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'name', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'title', property: 'title', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'facebook', property: 'facebook', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'whatsapp', property: 'whatsapp', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'linkedin', property: 'linkedin', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'description', property: 'description', type: 'text', visible: true, cssClasses: ['font-medium']},

    {label: 'Actions', property: 'actions', type: 'button', visible: true}

  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<OurTeamModel> | null;
  selection = new SelectionModel<OurTeamModel>(true, []);
  searchCtrl = new UntypedFormControl();
  form: UntypedFormGroup;
  isPanelOpen = false;
  loading = false;
  getLoading = true;
  defaults ?: ApplicationGroupModel;
  mode: 'create' | 'update' = 'create';
  selectedFile: string = '';
  locale;
  defLocale;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  constructor(
    private service: OurTeamService,
    private toastr: ToastrService,
    private route: Router,
    private fb: UntypedFormBuilder,
    private elementRef: ElementRef,
    private localStorageRefService : LocalStorageRefService

  ) {
  }
  onTabChanged(event){
    this.form.get('facebook').setValue(this.form.value.facebook);
    this.form.get('linkedin').setValue(this.form.value.linkedin);
    this.form.get('whatsapp').setValue(this.form.value.whatsapp);
  }
  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }


  getData() {

    this.service.get().subscribe((res) => {
      of(res['data'].map(type => new OurTeamModel(type))).subscribe(types => {
        this.subject$.next(types);
        this.getLoading=false;
      });

    });
  }

  ngOnInit() {
    this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
    this.defLocale = this.locale.filter(loc=>loc.default==true)[0];
    this.getData();
    this.clearForm();
    this.dataSource = new MatTableDataSource();

    this.data$.pipe(
      filter<OurTeamModel[]>(Boolean)
    ).subscribe(types => {
      this.types = types;
      this.dataSource.data = types;
    });

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  setDefaultImage(event: any) {

    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }
  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
      this.form.patchValue({image: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedFile = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }}
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }

  create(){
    this.clearForm();
    this.isPanelOpen = true;
    this.scrollToForm();
  }
  update(data: OurTeamModel) {
    this.initForm(data);
    this.scrollToForm();
  }
  delete(data: any) {
    this.service.delete(data).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS) {
        this.toastr.success('team Deleted Successfully!');
        this.ngOnInit();
        this.selection.deselect(data);
      }
    }, error => {
      this.toastr.error(error['message']);

    });
  }

  deleteall(brands: OurTeamModel[]) {
    brands.forEach(c => this.delete(c));
  }

  initForm(app:OurTeamModel){
    this.isPanelOpen=true;
    this.mode='update';
    this.selectedFile= app.media.length!=0 ?app.media[0]['original_url']: 'assets/img/demo/missing-picture.jpeg';
    this.form = this.fb.group({
      id: [app.id],
      facebook: [app.facebook || '' ],
      whatsapp: [app.whatsapp || '' ],
      linkedin: [app.linkedin || '' ],
      image: [null]

    });
    this.locale.forEach(lang => {
      const value = app.translations.filter(brand => brand.locale == lang.locale);

      this.form.addControl(lang.locale+'_name', this.fb.control(value[0]?.name??''));
      this.form.addControl(lang.locale+'_title', this.fb.control(value[0]?.title??''));
      this.form.addControl(lang.locale+'_description', this.fb.control(value[0]?.description??''));
    });
  }
  clearForm(){
    this.isPanelOpen = false;
    this.mode='create';
    this.selectedFile= 'assets/img/demo/missing-picture.jpeg';
    this.form = this.fb.group({

      id: [null],
      facebook: [''],
      whatsapp: [''],
      linkedin: [''],
      image: [null]
    });
    this.locale.forEach(lang => {

      this.form.addControl(lang.locale+'_name', this.fb.control(''));
      this.form.addControl(lang.locale+'_title', this.fb.control(''));
      this.form.addControl(lang.locale+'_description', this.fb.control(''));
    });
  }
  scrollToForm() {
    const formElement = this.elementRef.nativeElement.querySelector('#form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  save(){
    this.loading=true;
    const formData=new FormData();
    if(this.mode === 'update') {
      formData.append('id', this.form.value?.id);
    }
    if (this.form.value.image instanceof File)
      formData.append('media', this.form.value.image);
    formData.append('facebook',this.form.value.facebook);
    formData.append('whatsapp',this.form.value.whatsapp);
    formData.append('linkedin',this.form.value.linkedin);

    formData.append('title',this.form.value[this.defLocale.locale + '_title']);
    formData.append('name',this.form.value[this.defLocale.locale + '_name']);
    formData.append('description',this.form.value[this.defLocale.locale + '_description']);


    this.locale.filter(lang => lang.default==0 ).forEach(lang =>{

      if(this.form.value[lang.locale + '_name']!='')
        formData.append(lang.locale +'[name]',this.form.value[lang.locale + '_name']);
      if(this.form.value[lang.locale + '_title']!='')
        formData.append(lang.locale +'[title]',this.form.value[lang.locale + '_title']);
      if(this.form.value[lang.locale + '_description']!='')
        formData.append(lang.locale +'[description]',this.form.value[lang.locale + '_description']);
    });



    if (formData) {
      this.service.create(formData).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Team '+this.mode+'d Successfully!');
          this.ngOnInit();
          this.loading=false;

        }
      }, error => {
        this.loading=false;
        this.toastr.error(error);
      });
    }}





}
