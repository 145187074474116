import {Component, Inject, LOCALE_ID, Renderer2} from '@angular/core';
import {ConfigService} from '../@vex/config/config.service';
import {Settings} from 'luxon';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {NavigationService} from '../@vex/services/navigation.service';
import {LayoutService} from '../@vex/services/layout.service';
import {ActivatedRoute} from '@angular/router';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {SplashScreenService} from '../@vex/services/splash-screen.service';
import {VexConfigName} from '../@vex/config/config-name.model';
import {ColorSchemeName} from '../@vex/config/colorSchemeName';
import {MatIconRegistry, SafeResourceUrlWithIconOptions} from '@angular/material/icon';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ColorVariable, colorVariables} from '../@vex/components/config-panel/color-variables';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'vex-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(private configService: ConfigService,
                private renderer: Renderer2,
                private platform: Platform,
                @Inject(DOCUMENT) private document: Document,
                @Inject(LOCALE_ID) private localeId: string,
                private layoutService: LayoutService,
                private route: ActivatedRoute,
                private navigationService: NavigationService,
                private splashScreenService: SplashScreenService,
                private readonly matIconRegistry: MatIconRegistry,
                private toastrService: ToastrService,
                private readonly domSanitizer: DomSanitizer) {
        Settings.defaultLocale = this.localeId;

        if (this.platform.BLINK) {
            this.renderer.addClass(this.document.body, 'is-blink');
        }

        this.matIconRegistry.addSvgIconResolver(
            (
                name: string,
                namespace: string
            ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
                switch (namespace) {
                    case 'mat':
                        return this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `assets/img/icons/material-design-icons/two-tone/${name}.svg`
                        );

                    case 'logo':
                        return this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `assets/img/icons/logos/${name}.svg`
                        );

                    case 'flag':
                        return this.domSanitizer.bypassSecurityTrustResourceUrl(
                            `assets/img/icons/flags/${name}.svg`
                        );
                }
            }
        );

        /**
         * Customize the template to your needs with the ConfigService
         * Example:
         *  this.configService.updateConfig({
         *    sidenav: {
         *      title: 'Custom App',
         *      imageUrl: '//placehold.it/100x100',
         *      showCollapsePin: false
         *    },
         *    footer: {
         *      visible: false
         *    }
         *  });
         */

        /**
         * Config Related Subscriptions
         * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
         * Example: example.com/?layout=apollo&style=default
         */
        this.route.queryParamMap.subscribe(queryParamMap => {
            if (queryParamMap.has('layout')) {
                this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
            }

            if (queryParamMap.has('style')) {
                this.configService.updateConfig({
                    style: {
                        colorScheme: queryParamMap.get('style') as ColorSchemeName
                    }
                });
            }

            if (queryParamMap.has('primaryColor')) {
                const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

                if (color) {
                    this.configService.updateConfig({
                        style: {
                            colors: {
                                primary: color
                            }
                        }
                    });
                }
            }

            if (queryParamMap.has('rtl')) {
                this.configService.updateConfig({
                    direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
                });
            }
        });

        // @ts-ignore
        /**
         * Add your own routes here
         */
        this.navigationService.items = [
            {
                type: 'subheading',
                label: '',
                children: [
                    {
                        type: 'link',
                        label: 'Analytics',
                        route: '/',
                        perm: '',
                        icon: 'mat:insights',
                        routerLinkActiveOptions: {exact: true}
                    },
                    {
                        type: 'dropdown',
                        label: 'Category Setup',
                        icon: 'mat:category',
                        children: [
                            {
                                type: 'link',
                                label: 'Categories',
                                perm: 'categories.read',
                                route: '/categories',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Sub Categories',
                                perm: 'sub_categories.read',
                                route: '/sub-categories',
                                routerLinkActiveOptions: {exact: true}
                            }
                        ]
                    },
                    {
                        type: 'dropdown',
                        label: 'Auctions & Live shows ',
                        icon: 'mat:slideshow',
                        children: [
                            {
                                type: 'link',
                                label: 'Auction',
                                route: '/auction',
                                icon: 'mat:insights',
                                perm: 'auctions.read',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Auction Promotions',
                                route: '/auction-promotions',
                                icon: 'mat:insights',
                                perm: 'auctions.read',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Live Show',
                                route: '/live-show',
                                icon: 'mat:slideshow',
                                perm: 'live_show.read',
                                routerLinkActiveOptions: {exact: true}
                            }
                        ]
                    },
                    {
                        type: 'dropdown',
                        label: 'Product Setup ',
                        icon: 'mat:layers',
                        children: [
                            {
                                type: 'link',
                                label: 'Specification Group',
                                route: '/specifications-group',
                                perm: 'sub_categories.read',
                                icon: 'mat:group_work',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Specifications',
                                route: '/specifications',
                                perm: 'specifications.read',
                                icon: 'mat:grade',
                                routerLinkActiveOptions: {exact: true}
                            },
                          {
                                type: 'link',
                                label: 'Inspection Group',
                                route: '/inspection-group',
                                // perm: 'specifications.read',
                                icon: 'mat:grade',
                                routerLinkActiveOptions: {exact: true}
                            },
                          {
                                type: 'link',
                                label: 'Inspection Group Part',
                                route: '/inspection-group-part',
                                // perm: 'specifications.read',
                                icon: 'mat:grade',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Units',
                                route: '/units',
                                perm: 'units.read',
                                icon: 'mat:linear_scale',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Unit Types',
                                route: '/unit-types',
                                perm: 'unit_types.read',
                                icon: 'mat:linear_scale',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Application Group',
                                route: '/application-group',
                                icon: 'mat:widgets',
                                perm: 'application_group.read',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Application',
                                route: '/application',
                                perm: 'application.read',
                                icon: 'mat:widgets',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Brands',
                                route: '/brands',
                                perm: 'brands.read',
                                icon: 'mat:local_offer',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Model',
                                route: '/base-product',
                                perm: 'base_product.read',
                                icon: 'mat:local_offer',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Products',
                                route: '/products',
                                icon: 'mat:layers',
                                perm: 'products.read',
                                routerLinkActiveOptions: {exact: true}
                            }
                        ]
                    },
                    {
                        type: 'dropdown',
                        label: 'Content Setup ',
                        icon: 'mat:grid_on',
                        children: [
                            {
                                type: 'link',
                                label: 'Research',
                                route: '/research',
                                perm: 'expert_reviews.read',
                                icon: 'mat:rate_review',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Research Feature',
                                route: '/feature-research',
                                perm: 'expert_reviews.read',
                                icon: 'mat:rate_review',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'News Tags',
                                route: '/news-tags',
                                perm: 'news_category.read',
                                icon: 'mat:grid_on',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'News Category',
                                route: '/news-category',
                                perm: 'news_category.read',
                                icon: 'mat:grid_on',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'News',
                                route: '/news',
                                perm: 'news.read',
                                icon: 'mat:list',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Online Review',
                                route: '/online-review',
                                perm: 'news_letter.read',
                                icon: 'mat:list',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'News Letter',
                                route: '/news-letter',
                                icon: 'mat:move_to_inbox',
                                perm: 'news_letter.read',
                                routerLinkActiveOptions: {exact: true}
                            }
                        ]
                    },
                    {
                        type: 'dropdown',
                        label: 'Users ',
                        icon: 'mat:supervisor_account',
                        children: [
                            {
                                type: 'link',
                                label: 'Roles',
                                route: '/roles',
                                icon: 'mat:supervised_user_circle',
                                perm: 'roles.read',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Customers',
                                route: '/users',
                                perm: 'users.read',
                                icon: 'mat:supervisor_account',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Admins',
                                route: '/admins',
                                perm: 'admins.read',
                                icon: 'mat:perm_identity',
                                routerLinkActiveOptions: {exact: true}
                            }
                        ]
                    },
                    {
                        type: 'dropdown',
                        label: 'Contact ',
                        icon: 'mat:call',
                        children: [
                            {
                                type: 'link',
                                label: 'Contact us',
                                route: '/contact-us',
                                perm: 'contact_us.read',
                                icon: 'mat:call',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Contact Reason',
                                route: '/contact-us-reason',
                                perm: 'contact_us_reason.read',
                                icon: 'mat:live_help',
                                routerLinkActiveOptions: {exact: true}
                            }
                        ]
                    },
                    {
                        type: 'dropdown',
                        label: 'Requests ',
                        icon: 'mat:subdirectory_arrow_left',
                        children: [
                            {
                                type: 'link',
                                label: 'Offer Request',
                                route: '/offer-request',
                                perm: 'offer_request.read',
                                icon: 'mat:layers',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Sell Your Machine',
                                route: '/sell-your-machine',
                                perm: 'sell_your_machine.read',
                                icon: 'mat:attach_money',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Request Callback',
                                route: '/request-callback',
                                perm: 'request_callback.read',
                                icon: 'mat:phone_callback',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Booking Free Tour',
                                route: '/booking-tour',
                                perm: 'booking_free_tour.read',
                                icon: 'mat:directions_car',
                                routerLinkActiveOptions: {exact: true}
                            }

                        ]

                    },
                    {
                        type: 'dropdown',
                        label: 'Financing ',
                        icon: 'mat:attach_money',
                        children: [
                            {
                                type: 'link',
                                label: 'Discounts ',
                                route: '/discounts',
                                icon: 'mat:attach_money',
                                perm: 'discounts.read',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Orders',
                                route: '/orders',
                                icon: 'mat:reorder',
                                perm: 'orders.read',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Currencies',
                                route: '/currencies',
                                perm: 'currencies.read',
                                icon: 'mat:money',
                                routerLinkActiveOptions: {exact: true}
                            }
                            ,
                            {
                                type: 'link',
                                label: 'Finance Request',
                                route: '/finance',
                                icon: 'mat:attach_money',
                                perm: 'finance.read',
                                routerLinkActiveOptions: {exact: true}
                            }
                        ]
                    },
                    {
                        type: 'dropdown',
                        label: 'Shipment',
                        icon: 'mat:local_shipping',
                        children: [
                            {
                                type: 'link',
                                label: 'Zones',
                                route: '/zones',
                                icon: 'mat:list',
                                perm: 'zones.read',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Port',
                                route: '/ports',
                                icon: 'mat:list',
                                perm: 'ports.read',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Base Rate',
                                route: '/base-rate',
                                perm: 'base_rate.read',
                                icon: 'mat:list',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                              type: 'link',
                              label: 'Quote Request',
                              route: '/quote-request',
                              perm: 'ports.read',
                              icon: 'mat:list',
                              routerLinkActiveOptions: {exact: true}
                            }
                        ]
                    },
                    {
                        type: 'dropdown',
                        label: 'Testimonials',
                        icon: 'mat:picture_in_picture',
                        children: [
                            {
                                type: 'link',
                                label: 'Faq Group',
                                route: '/faq-group',
                                perm: 'faq_group.read',
                                icon: 'mat:question_answer',
                                routerLinkActiveOptions: {exact: true}
                            }
                            ,
                            {
                                type: 'link',
                                label: 'Faq',
                                route: '/faq',
                                perm: 'faq.read',
                                icon: 'mat:question_answer',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Terms',
                              //Todo
                                perm: 'brands.read',
                                route: '/term',
                                icon: 'mat:ballot',
                                routerLinkActiveOptions: {exact: true}
                            }, {
                                type: 'link',
                                label: 'Our Team',
                                route: '/our-team',
                                perm: 'our_team.read',
                                icon: 'mat:sentiment_very_satisfied',
                                routerLinkActiveOptions: {exact: true}
                            },
                            // {
                            //     type: 'link',
                            //     label: 'Shop Features',
                            //     route: '/shop-features',
                            //     perm: 'shop_features.read',
                            //     icon: 'mat:add_shopping_cart',
                            //     routerLinkActiveOptions: {exact: true}
                            // },
                            {
                                type: 'link',
                                label: 'Privacy Policy',
                                route: '/privacy-policy',
                                perm: 'privacy_policy.read',
                                icon: 'mat:pan_tool',
                                routerLinkActiveOptions: {exact: true}
                            },


                            {
                                type: 'link',
                                label: 'Testimonials',
                                route: '/testimonials',
                                perm: 'testimonials.read',
                                icon: 'mat:dialpad',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Spotlight',
                                route: '/spotlight',
                                perm: 'spotlight.read',
                                icon: 'mat:location_searching',
                                routerLinkActiveOptions: {exact: true}
                            }

                        ]
                    },
                  {
                    type: 'dropdown',
                    label: 'Banners',
                    icon: 'mat:image',
                    children: [
                      {
                        type: 'link',
                        label: 'Home',
                        route: '/banners/home',
                        perm: 'contact_us_reason.read',
                        icon: 'mat:live_help',
                        routerLinkActiveOptions: {exact: true}
                      },
                      {
                        type: 'link',
                        label: 'Live Show',
                        route: '/banners/liveshow',
                        perm: 'contact_us.read',
                        icon: 'mat:call',
                        routerLinkActiveOptions: {exact: true}
                      },
                      {
                        type: 'link',
                        label: 'Auction',
                        route: '/banners/auction',
                        perm: 'contact_us_reason.read',
                        icon: 'mat:live_help',
                        routerLinkActiveOptions: {exact: true}
                      }
                    ]
                  },
                  {
                    type: 'link',
                    label: 'Setting',
                    route: '/business-setting',
                    icon: 'mat:settings_applications',

                    routerLinkActiveOptions: {exact: true}
                  }



                ]
            }
            // {
            //     type: 'subheading',
            //     label: 'Apps',
            //     children: [
            //         {
            //             type: 'link',
            //             label: 'All-In-One Table',
            //             route: '/apps/aio-table',
            //             icon: 'mat:assignment'
            //         },
            //         {
            //             type: 'dropdown',
            //             label: 'Help Center',
            //             icon: 'mat:contact_support',
            //             children: [
            //                 {
            //                     type: 'link',
            //                     label: 'Getting Started',
            //                     route: '/apps/help-center/getting-started'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Pricing & Plans',
            //                     route: '/apps/help-center/pricing'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'FAQ',
            //                     route: '/apps/help-center/faq'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Guides',
            //                     route: '/apps/help-center/guides'
            //                 }
            //             ]
            //         },
            //         {
            //             type: 'link',
            //             label: 'Calendar',
            //             route: '/apps/calendar',
            //             icon: 'mat:date_range',
            //             badge: {
            //                 value: '12',
            //                 bgClass: 'bg-deep-purple',
            //                 textClass: 'text-deep-purple-contrast'
            //             }
            //         },
            //         {
            //             type: 'link',
            //             label: 'Chat',
            //             route: '/apps/chat',
            //             icon: 'mat:chat',
            //             badge: {
            //                 value: '16',
            //                 bgClass: 'bg-cyan',
            //                 textClass: 'text-cyan-contrast'
            //             }
            //         },
            //         {
            //             type: 'link',
            //             label: 'Mailbox',
            //             route: '/apps/mail',
            //             icon: 'mat:mail'
            //         },
            //         {
            //             type: 'dropdown',
            //             label: 'Social',
            //             icon: 'mat:person_outline',
            //             children: [
            //                 {
            //                     type: 'link',
            //                     label: 'Profile',
            //                     route: '/apps/social',
            //                     routerLinkActiveOptions: {exact: true}
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Timeline',
            //                     route: '/apps/social/timeline'
            //                 }
            //             ]
            //         },
            //         {
            //             type: 'link',
            //             label: 'WYSIWYG Editor',
            //             route: '/apps/editor',
            //             icon: 'mat:chrome_reader_mode'
            //         },
            //         {
            //             type: 'dropdown',
            //             label: 'Contacts',
            //             icon: 'mat:contacts',
            //             children: [
            //                 {
            //                     type: 'link',
            //                     label: 'List - Grid',
            //                     route: '/apps/contacts/grid'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'List - Table',
            //                     route: '/apps/contacts/table'
            //                 }
            //             ]
            //         },
            //         {
            //             type: 'link',
            //             label: 'Scrumboard',
            //             route: '/apps/scrumboard',
            //             icon: 'mat:assessment',
            //             badge: {
            //                 value: 'NEW',
            //                 bgClass: 'bg-primary',
            //                 textClass: 'text-primary-contrast'
            //             }
            //         }
            //     ]
            // },
            // {
            //     type: 'subheading',
            //     label: 'Pages',
            //     children: [
            //         {
            //             type: 'dropdown',
            //             label: 'Authentication',
            //             icon: 'mat:lock',
            //             children: [
            //                 {
            //                     type: 'link',
            //                     label: 'Login',
            //                     route: '/login'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Register',
            //                     route: '/register'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Forgot Password',
            //                     route: '/forgot-password'
            //                 }
            //             ]
            //         },
            //         {
            //             type: 'link',
            //             label: 'Coming Soon',
            //             icon: 'mat:watch_later',
            //             route: '/coming-soon'
            //         },
            //         {
            //             type: 'dropdown',
            //             label: 'Errors',
            //             icon: 'mat:error',
            //             badge: {
            //                 value: '4',
            //                 bgClass: 'bg-green',
            //                 textClass: 'text-green-contrast'
            //             },
            //             children: [
            //                 {
            //                     type: 'link',
            //                     label: '404',
            //                     route: '/pages/error-404'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: '500',
            //                     route: '/pages/error-500'
            //                 }
            //             ]
            //         },
            //         {
            //             type: 'link',
            //             label: 'Pricing',
            //             icon: 'mat:attach_money',
            //             route: '/pages/pricing'
            //         },
            //         {
            //             type: 'link',
            //             label: 'Invoice',
            //             icon: 'mat:receipt',
            //             route: '/pages/invoice'
            //         },
            //         {
            //             type: 'link',
            //             label: 'FAQ',
            //             icon: 'mat:help',
            //             route: '/pages/faq'
            //         },
            //         {
            //             type: 'link',
            //             label: 'Guides',
            //             icon: 'mat:book',
            //             route: '/pages/guides',
            //             badge: {
            //                 value: '18',
            //                 bgClass: 'bg-teal',
            //                 textClass: 'text-teal-contrast'
            //             }
            //         }
            //     ]
            // },
            // {
            //     type: 'subheading',
            //     label: 'UI Elements',
            //     children: [
            //         {
            //             type: 'dropdown',
            //             label: 'Components',
            //             icon: 'mat:bubble_chart',
            //             children: [
            //                 {
            //                     type: 'link',
            //                     label: 'Overview',
            //                     route: '/ui/components/overview'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Autocomplete',
            //                     route: '/ui/components/autocomplete'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Buttons',
            //                     route: '/ui/components/buttons'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Button Group',
            //                     route: '/ui/components/button-group'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Cards',
            //                     route: '/ui/components/cards'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Checkbox',
            //                     route: '/ui/components/checkbox'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Dialogs',
            //                     route: '/ui/components/dialogs'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Grid List',
            //                     route: '/ui/components/grid-list'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Input',
            //                     route: '/ui/components/input'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Lists',
            //                     route: '/ui/components/lists'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Menu',
            //                     route: '/ui/components/menu'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Progress',
            //                     route: '/ui/components/progress'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Progress Spinner',
            //                     route: '/ui/components/progress-spinner'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Radio',
            //                     route: '/ui/components/radio'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Slide Toggle',
            //                     route: '/ui/components/slide-toggle'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Slider',
            //                     route: '/ui/components/slider'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Snack Bar',
            //                     route: '/ui/components/snack-bar'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Tooltip',
            //                     route: '/ui/components/tooltip'
            //                 }
            //             ]
            //         },
            //         {
            //             type: 'dropdown',
            //             label: 'Forms',
            //             icon: 'mat:format_color_text',
            //             children: [
            //                 {
            //                     type: 'link',
            //                     label: 'Form Elements',
            //                     route: '/ui/forms/form-elements'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Form Wizard',
            //                     route: '/ui/forms/form-wizard'
            //                 }
            //             ]
            //         },
            //         {
            //             type: 'dropdown',
            //             label: 'Icons',
            //             icon: 'mat:star',
            //             children: [
            //                 {
            //                     type: 'link',
            //                     label: 'Material Icons',
            //                     route: '/ui/icons/ic'
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'FontAwesome Icons',
            //                     route: '/ui/icons/fa'
            //                 }
            //             ]
            //         },
            //         {
            //             type: 'dropdown',
            //             label: 'Page Layouts',
            //             icon: 'mat:view_compact',
            //             children: [
            //                 {
            //                     type: 'dropdown',
            //                     label: 'Card',
            //                     children: [
            //                         {
            //                             type: 'link',
            //                             label: 'Default',
            //                             route: '/ui/page-layouts/card',
            //                             routerLinkActiveOptions: {exact: true}
            //                         },
            //                         {
            //                             type: 'link',
            //                             label: 'Tabbed',
            //                             route: '/ui/page-layouts/card/tabbed'
            //                         },
            //                         {
            //                             type: 'link',
            //                             label: 'Large Header',
            //                             route: '/ui/page-layouts/card/large-header',
            //                             routerLinkActiveOptions: {exact: true}
            //                         },
            //                         {
            //                             type: 'link',
            //                             label: 'Tabbed & Large Header',
            //                             route: '/ui/page-layouts/card/large-header/tabbed'
            //                         }
            //                     ]
            //                 },
            //                 {
            //                     type: 'dropdown',
            //                     label: 'Simple',
            //                     children: [
            //                         {
            //                             type: 'link',
            //                             label: 'Default',
            //                             route: '/ui/page-layouts/simple',
            //                             routerLinkActiveOptions: {exact: true}
            //                         },
            //                         {
            //                             type: 'link',
            //                             label: 'Tabbed',
            //                             route: '/ui/page-layouts/simple/tabbed'
            //                         },
            //                         {
            //                             type: 'link',
            //                             label: 'Large Header',
            //                             route: '/ui/page-layouts/simple/large-header',
            //                             routerLinkActiveOptions: {exact: true}
            //                         },
            //                         {
            //                             type: 'link',
            //                             label: 'Tabbed & Large Header',
            //                             route: '/ui/page-layouts/simple/large-header/tabbed'
            //                         }
            //                     ]
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Blank',
            //                     icon: 'mat:picture_in_picture',
            //                     route: '/ui/page-layouts/blank'
            //                 }
            //             ]
            //         }
            //     ]
            // },
            // {
            //     type: 'subheading',
            //     label: 'Documentation',
            //     children: [
            //         {
            //             type: 'link',
            //             label: 'Changelog',
            //             route: '/documentation/changelog',
            //             icon: 'mat:update'
            //         },
            //         {
            //             type: 'dropdown',
            //             label: 'Getting Started',
            //             icon: 'mat:book',
            //             children: [
            //                 {
            //                     type: 'link',
            //                     label: 'Introduction',
            //                     route: '/documentation/introduction',
            //                     fragment: 'introduction',
            //                     routerLinkActiveOptions: {exact: true}
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Folder Structure',
            //                     route: '/documentation/folder-structure',
            //                     fragment: 'folder-structure',
            //                     routerLinkActiveOptions: {exact: true}
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Installation',
            //                     route: '/documentation/installation',
            //                     fragment: 'installation',
            //                     routerLinkActiveOptions: {exact: true}
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Development Server',
            //                     route: '/documentation/start-development-server',
            //                     fragment: 'start-development-server',
            //                     routerLinkActiveOptions: {exact: true}
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Build for Production',
            //                     route: '/documentation/build-for-production',
            //                     fragment: 'build-for-production',
            //                     routerLinkActiveOptions: {exact: true}
            //                 }
            //             ]
            //         },
            //         {
            //             type: 'dropdown',
            //             label: 'Customization',
            //             icon: 'mat:book',
            //             children: [
            //                 {
            //                     type: 'link',
            //                     label: 'Configuration',
            //                     route: '/documentation/configuration',
            //                     fragment: 'configuration',
            //                     routerLinkActiveOptions: {exact: true}
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Changing Styling',
            //                     route: '/documentation/changing-styling-and-css-variables',
            //                     fragment: 'changing-styling-and-css-variables',
            //                     routerLinkActiveOptions: {exact: true}
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Using Custom Colors',
            //                     route: '/documentation/using-custom-colors-for-the-primarysecondarywarn-palettes',
            //                     fragment: 'using-custom-colors-for-the-primarysecondarywarn-palettes',
            //                     routerLinkActiveOptions: {exact: true}
            //                 },
            //                 {
            //                     type: 'link',
            //                     label: 'Adding Menu Items',
            //                     route: '/documentation/adding-menu-items',
            //                     fragment: 'adding-menu-items',
            //                     routerLinkActiveOptions: {exact: true}
            //                 }
            //             ]
            //         },
            //         {
            //             type: 'link',
            //             label: 'Further Help',
            //             icon: 'mat:book',
            //             route: '/documentation/further-help',
            //             fragment: 'further-help',
            //             routerLinkActiveOptions: {exact: true}
            //         }
            //     ]
            // },
            // {
            //     type: 'subheading',
            //     label: 'Customize',
            //     children: []
            // },
            // {
            //     type: 'link',
            //     label: 'Configuration',
            //     route: () => this.layoutService.openConfigpanel(),
            //     icon: 'mat:settings'
            // }
        ];
    }
}
