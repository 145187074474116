import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate, CanActivateChild,
    Router, RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {NgxPermissionsService} from 'ngx-permissions';
import {StorageKeysEnum} from '../enum/storage-keys.enum';
import {LocalStorageRefService} from '../../pages/pages/auth/service/local-storage-ref.service';

@Injectable({providedIn: 'root'})
export class PermissionGuard implements CanActivate {

    constructor(private router: Router, private permissionsService: NgxPermissionsService, private localStorageRefService: LocalStorageRefService) {
    }

    CanLoad() {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const requiredPermissions = next.data.permissions;
        const perms = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.PERMISSIONS) )?? [];
        return new Promise((resolve) => {
            this.permissionsService.hasPermission(requiredPermissions).then((hasPermission) => {
                if (hasPermission || perms.find(perm => perm.name === requiredPermissions)) {
                    resolve(true);
                } else {
                    this.router.navigate(['/']);
                    resolve(false);
                }
            });
        });
    }


}
