import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {ExpertReviewsModel} from "../../../models/expert-reviews.model";

@Injectable({
  providedIn: 'root'
})
export class ResearchService {

  constructor(private http:HttpClient) { }

  all(){
    return this.http.get(environment.baseUrl + 'generation/generations');
  }
  get(id){
    return this.http.get(environment.baseUrl + 'generation/generations/'+id);
  }
  create(review:any){
    return this.http.post(environment.baseUrl + 'generation/generations',review);
  }
  update(review:any , id : string){
    return this.http.post(environment.baseUrl + 'generation/generations/' + id ,review);
  }
  delete(id:any){
    return this.http.delete(environment.baseUrl + 'generation/generations/'+id);
  }
  getMedia(id) {
    return this.http.get(environment.baseUrl + 'generation/generation/' + id + '/media');
  }
  getRatingStandard(){
    return this.http.get(environment.baseUrl + 'rating-standard/rating-standards');
  }
  getOwnerReview(expertId:number){
    return this.http.get(environment.baseUrl + 'generation/generation/'+expertId+'/user/rating');
  }
  updateOwnerReview(model:FormData,id){
    return this.http.post(environment.baseUrl + 'generation/generation/user/rating/'+id,model);
  }
  deleteOwnerReview(id){
    return this.http.delete(environment.baseUrl + 'generation/generation/user/rating/'+id);
  }
  createOwnerReview(model:FormData){
    return this.http.post(environment.baseUrl + 'generation/generation/user/rating',model);
  }
  addMedia(media, id) {
    return this.http.post(environment.baseUrl + 'generation/generation/' + id + '/media/all', media);
  }
  allFeature() {
    return this.http.get(environment.baseUrl + 'generation/generation-features');
  }
  createFeature(data) {
    return this.http.post(environment.baseUrl + 'generation/generation-features',data);
  }
  updateFeature(id,data) {
    return this.http.post(environment.baseUrl + 'generation/generation-features/'+id,data);
  }
  deleteFeature(id) {
    return this.http.delete(environment.baseUrl + 'generation/generation-features/'+id);
  }
  getAttachment(id) {
    return this.http.get(environment.baseUrl + 'generation/generation/'+id+'/attachments');
  }
  saveAttachment(data) {
    return this.http.post(environment.baseUrl + 'generation/attachment',data);
  }
  deleteAttachment(data) {
    return this.http.delete(environment.baseUrl + 'generation/attachment/'+data);
  }
  deleteMedia(id, productId) {
    return this.http.delete(environment.baseUrl + 'generation/generation/' + productId + '/media/' + id);
  }
  deleteMediaCollection( productId , name) {
    return this.http.delete(environment.baseUrl + 'generation/generation/' + productId + '/media/all/' + name);
  }
}
