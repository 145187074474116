import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {SelectFilterPipe} from '../../../../../../core/pipes/select-filter.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@Component({
    selector: 'vex-update-hotspot',
    templateUrl: './update-hotspot.component.html',
    styleUrls: ['./update-hotspot.component.scss'],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,


        MatListModule,
        MatIconModule,
        CommonModule,
        MatOptionModule,
        MatSelectModule,

        FormsModule,
        SelectFilterPipe,
        MatProgressSpinnerModule,
        MatSlideToggleModule
    ],
    standalone: true
})
export class UpdateHotspotComponent implements OnInit{

    constructor(
        public dialogRef: MatDialogRef<UpdateHotspotComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: UntypedFormBuilder
    ) {}
    form: UntypedFormGroup;

    ngOnInit(): void {

        this.form = this.fb.group({
            description: [this.data?.description || null],
            isClose: [false]
        });

    }
    save(){
        if(this.form.value.isClose)
        this.dialogRef.close('close');
        else
        this.dialogRef.close(this.form.value.description);
    }
    deleteSpot(){
        this.dialogRef.close('delete');
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
