import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, filter} from 'rxjs';
import {AuthService} from '../../../../pages/auth/service/auth.service';
import {Brand} from '../../../models/brand.model';
import {environment} from '../../../../../../environments/environment';


interface ChatTokenResponse {
  sessionExpirationTime: string;
  token: string;
  tokenExpirationTime: string;
}

interface IvsChatSettings {
  websocketUrl: string;
  apiUrl: string;
  roomId: string;
}

export interface Message {
  id: string;
  content: string;
  avatar: string;
  username: string;
  sendTime: string;
  userId: boolean;
  isPin:string;
  resetPin:string;
}

@Injectable({
  providedIn: 'root'
})
export class IvsChatService {
  admin;
  private _chatSettings?: IvsChatSettings;

  public set chatSettings(v: IvsChatSettings) {
    this._chatSettings = v;
  }

  public get chatSettings(): IvsChatSettings | undefined {
    return this._chatSettings;
  }

  private token?: string;
  private connection?: WebSocket;

  private messagesSource = new BehaviorSubject<Message[]>([]);
  messages$ = this.messagesSource.asObservable();

  constructor(
    private http: HttpClient,
    private authService:AuthService
  ) {

  }

  private joinChatRoom(token: string,sockect) {


    if (!sockect) return;
    // this.messagesSource = new BehaviorSubject<Message[]>([]);
    // this.messagesSource.next([]);
    // this.messages$== this.messagesSource.asObservable();
    this.connection = new WebSocket(sockect, token);

    this.connection.onopen = () => {
      console.info('Connected to the chat room.');

    };

    this.connection.onclose = () => {
      // If the websocket closes, remove the current chat token
      this.token = undefined;
    };

    this.connection.onerror = () => {
      console.error('Chat room websocket error observed:', event);
    };

    this.connection.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const eventType = data['Type'];

      switch (eventType) {
        case 'EVENT':
          console.info('Received event:', data);
          // handleEvent(data);
          break;
        case 'ERROR':
          console.info('Received error:', data);
          break;
        case 'MESSAGE':
          console.info('Received message:', data);
          // if (!data.Attributes?.isPin || data.Attributes?.isPin=='false'){
            // @ts-ignore
            const message: Message = {
              id: data['Id'],
              content: data['Content'],
              username: data['Sender']?.['Attributes']?.['username'],
              avatar: data['Sender']?.['Attributes']?.['avatar'],
              sendTime: data['SendTime'],
              isPin : data['Attributes']?.['isPin'],
              resetPin : data['Attributes']?.['resetPin']??'false',
              userId: Number.parseInt(data['Sender']?.['UserId'])==this.admin.id
            };
            this.messagesSource.next([...this.messagesSource.value, message]);
            // handleMessage(data);
            break;
          // }

        default:
          console.error('Unknown message received:', event);
      }
    };
  }

  async requestChatAccess(apiurl:string,roomId,sockect) {
    // this.messagesSource = new BehaviorSubject<Message[]>([]);
    // this.messagesSource.next([]);
    this.authService.adminData$.pipe(
    ).subscribe(user => {
      this.messagesSource.next([]);
      this.admin = user;
    // @ts-ignore
    this.http
      .post<ChatTokenResponse>(apiurl+'auth', {
        roomIdentifier: roomId,
        userId: this.admin.id,
        // @ts-ignore
       attributes: {
          username: this.admin?.full_name,
          avatar : this.admin.media[0]?.original_url
        },
        capabilities: ['SEND_MESSAGE'],
        durationInMinutes: 60
      })

      .subscribe({
        next: (res) => {
          this.token = res.token;

          // @ts-ignore

          this.joinChatRoom(this.token,sockect);
          this.requestChatHistory(roomId);
        },
        error: (err) => {
          console.error('Error requesting chat token:', err);
        }
      });

    });
  }

  sendMessage(message: string,is_pin:string) {
    const data = {
      action: 'SEND_MESSAGE',
      content: message,
      attributes: {
        // isPinned: 'undefined',
        isAdmin: 'true',
        isPin: is_pin
      }
    };

    if (!this.connection) {
      // this.alertService
      //   .open('no connection...', {
      //     status: 'error',
      //     autoClose: true
      //   })
      //   .subscribe();
      // return;
    }

    this.connection.send(JSON.stringify(data));
  }
  unPinMessage(message: string) {


    const data = {
      action: 'SEND_MESSAGE',
      content: message,
      attributes: {
        isAdmin: 'true',
        isPin: 'false',
        resetPin:'true'
      }
    };

    if (!this.connection) {
      // this.alertService
      //   .open('no connection...', {
      //     status: 'error',
      //     autoClose: true
      //   })
      //   .subscribe();
      // return;
    }

    this.connection.send(JSON.stringify(data));
  }

  disconnect() {
    if (!this.connection) return;
    this.connection.close();
  }
  private requestChatHistory(roomId) {
    if (!roomId) {
      console.error('Chat settings are not properly configured.');
      return;
    }
    

    this.http.get<any>(environment.baseUrl + 'live-show/live-shows/chat/'+roomId.split('/')[1]).subscribe({
      next: (response) => {
        // const messages = response.data.map(item => );
        response.data.map(item => {
          const data = JSON.parse(item.message).payload;
          const message: Message = {
            id: data['Id'],
            content: data['Content'],
            username: data['Sender']?.['Attributes']?.['username'],
            avatar: data['Sender']?.['Attributes']?.['avatar'],
            sendTime: data['SendTime'],
            isPin : data['Attributes']?.['isPin']??'false',
            resetPin : data['Attributes']?.['resetPin']??'false',
            userId: Number.parseInt(data['Sender']?.['UserId'])==this.admin.id
          };
          this.messagesSource.next([...this.messagesSource.value, message]);
        });
        //
        //
        //
        // this.messagesSource.next(messages);
      },
      error: (err) => {
        console.error('Error fetching chat history:', err);
      }
    });
  }
}
