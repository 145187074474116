<vex-page-layout>

  <vex-secondary-toolbar current="Research Feature">
    <mat-spinner *ngIf="loadingData" [diameter]="25" class="mat-spinner-color"></mat-spinner>

    <div class="w-full justify-end flex">
      <button color="primary" class="flex items-center justify-center" mat-flat-button type="submit" (click)="onSubmit()"  ><span  *ngIf="!loading"> Save Changes </span>
        <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>
      </button>
    </div>
  </vex-secondary-toolbar>
  <form class="flex container flex-col my-5 gap-3" [formGroup]="form"  *ngIf="!loadingData" >

    <div class="container rounded-lg shadow bg-white py-5">
      <mat-tab-group>
        <mat-tab  *ngFor="let lang of locale" [label]="lang.locale | uppercase">
     <div class="flex gap-2 w-full">
       <mat-form-field class="flex-auto">
         <mat-label>Name ( {{lang.locale | uppercase }} )</mat-label>
         <input  formControlName="{{lang.locale}}_name" matInput>
       </mat-form-field>
       <mat-form-field class="flex-auto">
         <mat-label>Title ( {{lang.locale | uppercase }} )</mat-label>
         <input  formControlName="{{lang.locale}}_title" matInput>
       </mat-form-field>
     </div>
      <div class="flex w-full">
        <mat-form-field class="flex-auto">
          <mat-label>Description ( {{lang.locale | uppercase }} )</mat-label>
          <textarea  formControlName="{{lang.locale}}_description" matInput></textarea>
        </mat-form-field>
      </div>
        </mat-tab>
      </mat-tab-group>
      <mat-form-field class="w-full">
        <mat-label>Select Research</mat-label>
        <select matNativeControl required formControlName="generation_id">
          <option *ngFor="let item of generations" [ngValue]="item.id" >{{item['name']}}</option>
        </select>
      </mat-form-field>

    <div class="flex w-full">
      <div  class="file-input-wrapper flex flex-col  w-full h-fit justify-center items-center mb-4"  >

        <img  class='image object-contain mb-3 w-[150px] h-[200px] justify-center items-center'  [src]="selectedFirstFile" (error)="setDefaultImage($event)">
        <div class="relative flex items-center cursor-pointer select-none rounded-full px-4  hover:bg-blue-100 trans-ease-out"
             matRipple>
          <div class="p-2 bg-blue-100 text-blue-600 rounded-full ltr:mr-4 rtl:ml-4">
            <mat-icon class="block" svgIcon="mat:add_a_photo"></mat-icon>
          </div>
          <input id="imageInput" type="file" #filePicker (change)="onFirstImagePicked($event)" />
          <p for="imageInput" class="font-medium text-blue-600">Select First Image</p>
        </div>

      </div>
      <div  class="file-input-wrapper flex flex-col  w-full h-fit justify-center items-center mb-4"  >

        <img  class='image object-contain mb-3 w-[150px] h-[200px] justify-center items-center'  [src]="selectedSecondFile" (error)="setDefaultImage($event)">
        <div class="relative flex items-center cursor-pointer select-none rounded-full px-4  hover:bg-blue-100 trans-ease-out"
             matRipple>
          <div class="p-2 bg-blue-100 text-blue-600 rounded-full ltr:mr-4 rtl:ml-4">
            <mat-icon class="block" svgIcon="mat:add_a_photo"></mat-icon>
          </div>
          <input id="secondImageInput" type="file" #filePicker (change)="onSecondImagePicked($event)" />
          <p for="imageInput" class="font-medium text-blue-600">Select Second Image</p>
        </div>

      </div>
    </div>
    </div>
  </form>
  </vex-page-layout>
