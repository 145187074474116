import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {DiscountModel} from '../../../models/discount.model';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  constructor(private http:HttpClient) { }

  get(){
    return this.http.get(environment.baseUrl + 'discount/discounts');
  }
  getProduct(id){
    return this.http.get(environment.baseUrl + 'discount/discounts/'+id);
  }
  create(type:any){
    return this.http.post(environment.baseUrl + 'discount/discounts',type);
  }
  update(type:DiscountModel , id : number){
    return this.http.put(environment.baseUrl + 'discount/discounts'+id,type);
  }
  delete(type:DiscountModel){
    return this.http.delete(environment.baseUrl + 'discount/discounts/'+type.id);
  }

  addProductDiscount(id,type:any){
    return this.http.post(environment.baseUrl + 'discount/discounts/'+id+'/products',type);
  }
}
