<div class="container p-5">

  <form (ngSubmit)="save()" [formGroup]="form" class="flex flex-col gap-3">
    <div class="flex items-center" mat-dialog-title>

      <button class="text-secondary" mat-icon-button type="button" (click)="onCancel()">
        <mat-icon svgIcon="mat:arrow_back"></mat-icon>
      </button>
      <h2 *ngIf="form.get('en_title').value || form.get('en_title').value"
          class="headline m-0 flex-auto">{{ form.get('en_title').value }}</h2>
      <h2 *ngIf="!form.get('en_title').value && !form.get('en_title').value"
          class="headline m-0 flex-auto">New Privacy</h2>

    </div>
    <mat-divider class="text-border"></mat-divider>

    <div class="flex flex-col bg-white p-4">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab  *ngFor="let lang of locale" [label]="lang.locale | uppercase">

          <div class="flex flex-col sm:flex-col">
            <div class="flex gap-3 items-center">
              <mat-form-field class="flex-auto">
                <mat-label>Title ( {{lang.locale | uppercase }} )</mat-label>
                <input cdkFocusInitial formControlName="{{lang.locale}}_title" matInput>
              </mat-form-field>
              <mat-form-field class="flex-auto">
                <mat-label>Order</mat-label>
                <select matNativeControl required formControlName="order">
                  <option *ngFor="let number of numbers" >{{number}}</option>
                </select>
              </mat-form-field>
              <mat-slide-toggle formControlName="status">Active</mat-slide-toggle>
            </div>
            <div class="card">
              <quill-editor  @fadeInUp formControlName="{{lang.locale}}_description" [styles]="{ flex: '1 1 auto' }"></quill-editor>
            </div>

          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div align="end">
      <button mat-button type="button" (click)="onCancel()">Cancel</button>
      <button *ngIf="isCreateMode()" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
        Create
      </button>
      <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
        Update
      </button>
    </div>
  </form>
</div>
