import {Component, Input} from '@angular/core';

@Component({
  selector: 'vex-field-details-card',
  templateUrl: './field-details-card.component.html',
  styleUrls: ['./field-details-card.component.scss'],

  standalone:true
})
export class FieldDetailsCardComponent {

@Input() title:string;
@Input() content:string;
}
