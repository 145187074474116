import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {ExpertReviewsModel} from '../../../models/expert-reviews.model';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  constructor(private http:HttpClient) { }

  get(){
    return this.http.get(environment.baseUrl + 'expert-review/expert-review');
  }
  getRelated(id,expert_id){
    let query='';
    if(expert_id){
      query = '?expert_review_id='+expert_id;
    }else{
      query ='';
    }
    return this.http.get(environment.baseUrl + 'expert-review/expert-review/base-product/'+id+query);
  }
  getReview(id : string){
    return this.http.get(environment.baseUrl + 'expert-review/expert-review/' + id);
  }
  create(review:ExpertReviewsModel){
    return this.http.post(environment.baseUrl + 'expert-review/expert-review',review);
  }
  update(review:ExpertReviewsModel , id : string){
    return this.http.post(environment.baseUrl + 'expert-review/expert-review/' + id ,review);
  }
  delete(review:ExpertReviewsModel){
    return this.http.delete(environment.baseUrl + 'expert-review/expert-review/'+review.id);
  }
  deleteMedia(id, productId) {
    return this.http.delete(environment.baseUrl + 'expert-review/expert-review/' + productId + '/media/' + id);
  }
  deleteMediaCollection( productId , name) {
    return this.http.delete(environment.baseUrl + 'expert-review/expert-review/' + productId + '/media/all/' + name);
  }
  getMedia(id) {
    return this.http.get(environment.baseUrl + 'expert-review/expert-review/' + id + '/media');
  }
  addMedia(media, id) {
    return this.http.post(environment.baseUrl + 'expert-review/expert-review/' + id + '/media/all', media);
  }
  getRatingStandard(){
    return this.http.get(environment.baseUrl + 'rating-standard/rating-standards');
  }
  getOwnerReview(expertId:number){
    return this.http.get(environment.baseUrl + 'expert-review/expert-review/'+expertId+'/user/rating');
  }
  updateOwnerReview(model:FormData,id){
    return this.http.post(environment.baseUrl + 'expert-review/expert-review/user/rating/'+id,model);
  }
  deleteOwnerReview(id){
    return this.http.delete(environment.baseUrl + 'expert-review/expert-review/user/rating/'+id);
  }
  createOwnerReview(model:FormData){
    return this.http.post(environment.baseUrl + 'expert-review/expert-review/user/rating',model);
   }
}
