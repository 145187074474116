import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveshowService {

  constructor(private http:HttpClient) {

  }

  all(){
    return this.http.get(environment.baseUrl + 'live-show/live-shows');
  }
  get(id:number){
    return this.http.get(environment.baseUrl + 'live-show/live-shows/'+id);
  }
  save(brand:FormData){
    return this.http.post(environment.baseUrl + 'live-show/live-shows/',brand);
  }

  delete(id:number){
    return this.http.delete(environment.baseUrl + 'live-show/live-shows/'+id);
  }
  changeLiveStatus(id,data){
    return this.http.put(environment.baseUrl + 'live-show/live-shows/'+id,data);
  }
  changeProductActive(id,data){
    return this.http.post(environment.baseUrl + 'live-show/live-shows/'+id+'/liveable',data);
  }
}
