import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {RatingModel} from '../../../models/expert-reviews.model';

@Injectable({
    providedIn: 'root'
})
export class RatingStandardsService {

    constructor(private http:HttpClient) { }

    get(){
        return this.http.get(environment.baseUrl + 'rating-standard/rating-standards');
    }
    create(rate:FormData){
        return this.http.post(environment.baseUrl + 'rating-standard/rating-standards',rate);
    }
    update(rate:RatingModel , id : number){
        return this.http.put(environment.baseUrl + 'rating-standard/rating-standards/'+id,rate);
    }
    delete(rate:RatingModel){
        return this.http.delete(environment.baseUrl + 'rating-standard/rating-standards/'+rate.rating_standard_id);
    }
}
