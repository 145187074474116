import {Component, Inject, OnInit} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
    FormsModule
} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {SelectFilterPipe} from '../../../../../core/pipes/select-filter.pipe';
import {ToastrService} from 'ngx-toastr';
import {MediaTypeEnum} from '../../../../../core/enum/media-type.enum';
import {MethodsUtils} from '../../../../../core/utils/method-utils';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {ActivatedRoute} from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AuctionService} from '../service/auction.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@Component({
    selector: 'app-create-ring',
    templateUrl: './create-ring.component.html',
    styleUrls: ['./create-ring.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatInputModule,
        MatDialogModule,
        MatListModule,
        MatIconModule,
        CommonModule,
        MatOptionModule,
        MatSelectModule,
        MatButtonModule,
        FormsModule,
        SelectFilterPipe,
        MatProgressSpinnerModule,
        MatSlideToggleModule
    ]
})
export class CreateRingComponent implements OnInit {


    form: UntypedFormGroup;
    mode: 'create' | 'update' = 'create';
    types = [];
    ringId: number;
    auctionId : number;
    loading = false;
    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
                private dialogRef: MatDialogRef<CreateRingComponent>,
                private service: AuctionService,
                private route: ActivatedRoute,
                private methods: MethodsUtils,
                private fb: UntypedFormBuilder,
                private toastr: ToastrService
    ) {
        this.types = this.methods.enumToArray(MediaTypeEnum);

    }

    ngOnInit() {
        this.ringId = this.defaults.id;
        console.log('this.ringId');
        console.log(this.ringId);
        console.log('this.ringId');
        this.auctionId = this.defaults.auctionId;
        this.mode = this.ringId ? 'update' : 'create';
        this.form = this.fb.group({
          name: [this.defaults?.data?.name || '', Validators.required],
          has_auto_closing: [this.defaults?.data?.has_auto_closing || true],
          auto_closing_seconds: [this.defaults?.data?.auto_closing_seconds || 100]

        });
    }



    save() {
        this.loading=true;
        if(this.mode=='update') {
            let ring = new FormData();
            ring.append('name', this.form.value.name);
            ring.append('id', this.ringId.toString());
            ring.append('has_auto_closing',this.form.value.has_auto_closing?'1':'0');
            ring.append('auto_closing_seconds',this.form.value.auto_closing_seconds);
            ring.append('auction_id', this.auctionId.toString());
            this.service.saveRing(ring).subscribe((res) => {
                if (res['status'] == ErrorStatusModel.SUCCESS || ErrorStatusModel.SUCCESSCREATE) {
                    this.toastr.success('Ring Created Successfully!');
                    this.dialogRef.close(true);
                    this.loading= false;
                }
            }, error => {
                this.toastr.error(error);
                this.dialogRef.close(true);
                this.loading= false;

            });
        }
        else {
            let ring = new FormData();
            ring.append('name', this.form.value.name);
          ring.append('has_auto_closing',this.form.value.has_auto_closing?'1':'0');
          ring.append('auto_closing_seconds',this.form.value.auto_closing_seconds);
            ring.append('auction_id', this.auctionId.toString());
            this.service.saveRing(ring).subscribe((res) => {
                if (res['status'] == ErrorStatusModel.SUCCESS || ErrorStatusModel.SUCCESSCREATE) {
                    this.toastr.success('Ring Created Successfully!');
                    this.dialogRef.close(true);
                    this.loading= false;
                }
            }, error => {
                this.toastr.error(error);
                this.dialogRef.close(true);
                this.loading= false;

            });
        }

    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

}

