import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule, NgClass} from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatIconModule} from '@angular/material/icon';
import {
  FormBuilder, FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {PageLayoutDemoModule} from '../../../../ui/page-layouts/page-layout-demo/page-layout-demo.module';
import {SecondaryToolbarModule} from '../../../../../../@vex/components/secondary-toolbar/secondary-toolbar.module';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProductTypeEnum} from '../../../../../core/enum/product-type.enum';
import {MediaTypeEnum} from '../../../../../core/enum/media-type.enum';
import {SpecificationTypeEnum} from '../../../../../core/enum/specification-type.enum';
import {MethodsUtils} from '../../../../../core/utils/method-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from '../../../models/product.model';
import {ProductService} from '../service/product.service';
import {BaseProductsService} from '../../base-products/service/base-products.service';
import {BaseProduct} from '../../../models/baseProduct.model';
import {SpecificationService} from '../../specifications/service/specification.service';
import {QuillEditorComponent} from 'ngx-quill';
import {ApplicationService} from '../../application/service/application.service';
import {UpdateMediaFileComponent} from '../update-media-file/update-media-file.component';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {CreateMediaComponent} from '../create-media/create-media.component';
import {
  ConfirmationDialogComponent
} from '../../../../../core/components/confirmation-dialog/confirmation-dialog.component';
import Swiper from 'swiper';
import {
  CdkDrag,
  CdkDragDrop, CdkDragPlaceholder,
  CdkDropList,
  CdkDropListGroup,
  DragDropModule,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import {ScrollbarModule} from '../../../../../../@vex/components/scrollbar/scrollbar.module';
import {UnitService} from '../../units/service/unit.service';
import {GoogleMap, GoogleMapsModule, MapMarker} from '@angular/google-maps';
import {NgxGpAutocompleteDirective, NgxGpAutocompleteModule} from '@angular-magic/ngx-gp-autocomplete';
import {Loader} from '@googlemaps/js-api-loader';
import {DiscountService} from '../../discount/service/discount.service';
import {AdminsService} from '../../admins/service/admins.service';
import {LocalStorageRefService} from '../../../../pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../../../../../core/enum/storage-keys.enum';
import {ProductMediaComponent} from '../product-media/product-media.component';
import {SvgRendererComponent} from '../../../../../core/components/svg-icon/svg-icon.component';
import {UploadSingleMediaComponent} from '../upload-single-media/upload-single-media.component';
import {ProductTagEnum} from '../../../../../core/enum/product-tag.enum';
import {ProductTagsEnum} from '../../../../../core/enum/product-tags.enum';
import {SaveThreesixtyComponent} from '../component/save-threesixty/save-threesixty.component';
@Component({
  selector: 'vex-product-create-edit',
  templateUrl: './ product-create-edit.component.html',
  styleUrls: ['./product-create-edit.component.scss',
    '../../../../../../../node_modules/quill/dist/quill.snow.css',
    '../../../../../../@vex/styles/partials/plugins/quill/_quill.scss'
  ],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
    imports: [
        MatMenuModule,
        NgClass,
        MatCheckboxModule,
        BreadcrumbsModule,
        PageLayoutModule,
        MatIconModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        FormsModule,
        MatSlideToggleModule,
        CommonModule,
        MatButtonModule,
        MatTabsModule,
        PageLayoutDemoModule,
        SecondaryToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        QuillEditorComponent,
        DragDropModule,
        ScrollbarModule,
        GoogleMapsModule,
        GoogleMap,
        NgxGpAutocompleteModule,
        ProductMediaComponent,
        SvgRendererComponent,
        UploadSingleMediaComponent,
        SaveThreesixtyComponent,
        CdkDropList,
        MatProgressSpinnerModule,
        CdkDropListGroup,
        CdkDrag,
        CdkDragPlaceholder


    ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
 {
   provide: Loader,
     useValue: new Loader({
   apiKey: 'AIzaSyBcCxAm3GEsYkKBd232SR3hJP2RmEjov5Q',
   libraries: ['places']
 })
 }
  ]
})
export class ProductCreateEditComponent implements OnInit {
  productForm: FormGroup;
  types = [];
  productID: string;
  productDetails: Product;
  bases: BaseProduct[];
  specifications = [];
  applications = [];
  offers = [];
  media = [];
  allMedia = [];
  groupedMediaArray: any;
  description = '';
  feature = '';
  form: UntypedFormGroup;
  formFeatureText;
  formFeature: UntypedFormGroup;
  productSpecForm: FormGroup;
  dynamicFormSpec: FormGroup;
  loadingSpecs: boolean = false;
  loadingMedia: boolean = false;
  loading: boolean = false;
  loadingData: boolean = false;
  hasFeatures: boolean = false;
  mode: 'create' | 'update' = 'create';
  createdMedia = [];
  fileArray: string[] = [];
  selectedFiles: File[] = [];
  files = [];
  videos = [];
  units=[];
  allUnits=[];
  swiper?: Swiper;
  secificationTypeEnum=SpecificationTypeEnum;
  display: any;
  selectedDocs: File[] = [];
  productDocs: any[] = [];
  searchQuery: string = '';
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  zoom = 6;
  center;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions = null;
  location = {};
  address ='';
  country ='';
  short_name = '';
  selectedShippingMediaFile : string = '';
  selectedCover : string ;
  assistants:[];
  tags =[];
  locale;
  defLocale;
  tagProduct=[];
  tagsProduct=[];
  videoURL: any;
  readonly MediaTypeEnum = MediaTypeEnum;

  move(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.display = event.latLng.toJSON();
    }

 async addMarker(event: google.maps.MapMouseEvent) {
   this.markerPositions=(event.latLng.toJSON());
   this.center = event.latLng;
   this.getLocationData();
}
  getLocationData(){
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location:this.center }, (results, status) => {
      this.zoom = 8;
      if (status === google.maps.GeocoderStatus.OK) {
        let country=null;
        results.forEach(element => {
          country = element.address_components.find(component =>
            component.types.includes('country')
          );
        });
        this.location ={
          position : this.markerPositions,
          country : country,
          address : results[0].formatted_address
        };
        this.country = country.long_name;
        this.short_name = country.short_name;
        this.address = results[0].formatted_address;
      }else{

      }
    });
  }
  public handleAddressChange(place: google.maps.places.PlaceResult) {
    const location = place.geometry.location;
    // @ts-ignore
    this.center = new google.maps.LatLng(location.lat(), location.lng());
    this.markerPositions = this.center.toJSON();
    this.getLocationData();
  }
  searchLocation() {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: this.searchQuery }, (results, status) => {
       this.zoom = 8;
      if (status === google.maps.GeocoderStatus.OK) {
        const location = results[0].geometry.location;
        // @ts-ignore
        this.center = new google.maps.LatLng(location.lat(), location.lng());
      }
      else {
        console.error('Geocoding failed:', status);
        switch (status) {
          case google.maps.GeocoderStatus.ZERO_RESULTS:
            console.error('Location not found.');
            break;
          case google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
            console.error('Geocoding quota exceeded. Consider implementing rate limiting or upgrading your API plan.');
            break;
        }
      }
    });
  }
  constructor(
    private fb: FormBuilder,
    public methods: MethodsUtils,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private service: ProductService,
    private adminService: AdminsService,
    private unitService: UnitService,
    private baseService: BaseProductsService,
    private specificationService: SpecificationService,
    private appService: ApplicationService,
    private offersService: DiscountService,
    private dialog: MatDialog,
    private localStorageRefService : LocalStorageRefService
  ) {
  }
  control: FormControl<number> = new FormControl(0, { nonNullable: true });
  ngOnInit() {
    this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
    this.defLocale = this.locale.filter(loc=>loc.default==true)[0];
    this.types = this.methods.enumToArray(ProductTypeEnum);
    this.tagProduct = this.methods.enumToArray(ProductTagEnum);
    this.tagsProduct = this.methods.enumToArray(ProductTagsEnum).filter((data)=>data.name!='LIMITED_OFFERS');
    this.productID = this.route.snapshot.paramMap.get('id');
    this.getBaseProduct();
    this.getAssistant();
    this.getApplications();
    this.getOffers();
    this.getAllUnits();
    if (this.productID == 'create') {
      this.productDetails = {} as Product;
      this.center = {lat: 24, lng: 12};
    } else {
      this.mode = 'update';
      this.getData();
    }
    this.productForm = this.fb.group({
      status: [''],
      base_product_id: ['', Validators.required],
      price: [''],
      sku: ['', Validators.required],
      type: ['', Validators.required],
      length: [''],
      length_unit_id: [''],
      width: [''],
      width_unit_id: [''],
      height: [''],
      height_unit_id: [''],
      weight: [''],
      weight_unit_id: [''],
      shipping_media: [''],

      canOffer: [false],
      canFullAmount: [false],
      canDownPayment: [false],
      can_negotiate: [false],
      canAuction: [false],
      isPopular: [false],
      hide_price: [false],
      has_shipping: [false],
      applications: [],
      offers: [null],
      cover:[],
      min_offer_amount:[0],
      assistant_id:[null],
      tagProduct:[null],
      tag : [''],
      tagsProduct : [[]],
      down_payment_amount:[0],
      monthly_lease_amount:[0],
      lease_months:[ 12],
      can_lease:[0]

    });
    this.locale.forEach(lang => {

      this.productForm.addControl(lang.locale+'_title', this.fb.control(''));
      this.productForm.addControl(lang.locale+'_description', this.fb.control(''));
    });
    this.productSpecForm = this.fb.group({
      specifications: []
    });
    this.checkLease();
  }
  initForm() {
    this.productForm=this.fb.group({
      status:[ this.productDetails?.status || ''],
      min_offer_amount:[ this.productDetails?.min_offer_amount['amount'] || 0],
      down_payment_amount:[ this.productDetails?.down_payment_amount['amount'] || 0],
      monthly_lease_amount:[ this.productDetails?.monthly_lease_amount['amount'] || 0],
      lease_months:[ this.productDetails?.lease_months|| 12],
      can_lease:[ this.productDetails?.can_lease|| 0],
      base_product_id: [this.productDetails?.base_product_id || ''],
      price: [this.productDetails?.price['amount'] || ''],
      sku: [this.productDetails?.sku || ''],
      type: [this.productDetails?.type || ''],
      canOffer: [this.productDetails?.can_offer || false],
      canFullAmount: [this.productDetails?.can_full_amount || false],
      canDownPayment:[ this.productDetails?.can_down_payment || false],
      can_negotiate: [this.productDetails?.can_negotiate || false],
      canAuction: [this.productDetails?.can_auction || false],
      isPopular: [this.productDetails?.is_popular || false],
      hide_price: [this.productDetails?.hide_price || false],
      has_shipping:[ this.productDetails?.measurement != undefined ],
      applications: [this.productDetails?.applications.map((app: any) => app.id) ?? []],
      offers: [this.productDetails?.offers.map((app: any) => app.id) ?? null],
      length: [this.productDetails?.measurement?.length?.value || ''],
      length_unit_id:[this.productDetails?.measurement?.length?.unit || ''],
      width: [this.productDetails?.measurement?.width?.value || ''],
      width_unit_id: [this.productDetails?.measurement?.width?.unit || ''],
      height:[this.productDetails?.measurement?.height?.value || ''],
      height_unit_id:[this.productDetails?.measurement?.height?.unit || ''],
      weight:[this.productDetails?.measurement?.weight?.value || ''],
      weight_unit_id: [this.productDetails?.measurement?.weight?.unit || ''],
      shipping_media:[ this.productDetails?.measurement?.media[0]?.original_url ],
      cover: [''],
      assistant_id : [this.productDetails.assistant_id||null],
      tagProduct : [this.productDetails.tag||null],
      external_url : [this.productDetails.external_url||''],

      tag : [''],
      tagsProduct : [this.productDetails.show_in ||null]
    });
    this.selectedShippingMediaFile= this.productDetails?.measurement?.media[0]?.original_url;
    this.description = this.productDetails?.description;
    this.feature = this.productDetails?.description;
    // @ts-ignore
    this.center = {lat: this.productDetails.location?.latitude, lng: this.productDetails.location?.longitude};
    this.markerPositions = ({ lat: this.productDetails.location?.latitude, lng: this.productDetails.location?.longitude });
    this.country = this.productDetails.location?.['country'];
    this.address = this.productDetails.location?.['address'];
    this.short_name = this.productDetails.location?.['short_name'];
    this.productDocs = this.productDetails.documents;
    this.tags = this.productDetails.key_features.length!=0?this.productDetails.key_features:[];

    this.locale.forEach(lang => {
      const value = this.productDetails.translations.filter(brand => brand.locale == lang.locale);

      this.productForm.addControl(lang.locale+'_title', this.fb.control(value[0]?.title??''));
      this.productForm.addControl(lang.locale+'_description', this.fb.control(value[0]?.description??''));
    });
    this.checkLease();
    this.selectOffers();

  }
  selectOffers(){

      if(this.productDetails.discounts?.length!=0){
        this.tagsProduct = this.methods.enumToArray(ProductTagsEnum);
      }else{
        this.tagsProduct = this.methods.enumToArray(ProductTagsEnum).filter((data)=>data.name!='LIMITED_OFFERS');
        this.offers=this.offers.filter((data)=>data!='LIMITED_OFFERS');

    };
  }
  checkLease(){
    this.productForm.get('can_lease').valueChanges.subscribe(value=>{
      if(value==1){
        this.productForm.get('down_payment_amount').setValidators([Validators.required]);
        this.productForm.get('monthly_lease_amount').setValidators([Validators.required]);
        this.productForm.get('lease_months').setValidators([Validators.required]);

      }else{
        this.productForm.get('down_payment_amount').setValidators([]);
        this.productForm.get('monthly_lease_amount').setValidators([]);
        this.productForm.get('lease_months').setValidators([]);
      }
    });
  }
  getData() {
    this.loadingData = true;
    this.service.getProduct(this.productID).subscribe((res) => {
      this.productDetails = res['data'];

      this.loadingData = false;
      this.initForm();
      this.getSpecifications();
      this.getMedia();
    });
  }
  getBaseProduct() {
    this.baseService.get().subscribe((res) => {
      this.bases = res['data'].filter(base=>base['deleted_at']==null);
    });
  }
  getAssistant(){
    this.adminService.getAssistants().subscribe((res) => {
      this.assistants = res['data'];
    });
  }
  getApplications() {
    this.appService.get().subscribe((res) => {
      // @ts-ignore
      this.applications = res?.data;
    });

  }
  getOffers() {
    this.offersService.get().subscribe((res) => {
      // @ts-ignore
      this.offers = res?.data;
    });

  }
  getAllUnits() {
    this.unitService.getAllUnits().subscribe((res) => {
      // @ts-ignore
      this.allUnits = res?.data;
    });

  }
  getSpecifications() {
    this.loadingSpecs = true;
    if (this.productForm?.value?.base_product_id) {
      this.specificationService.getByBase(this.productForm?.value?.base_product_id).subscribe((res) => {
        // @ts-ignore
        this.specifications = res.data;
        const formGroup = {};
        this.specifications?.forEach((item) => {
          const value = this.productDetails?.specifications?.find(obj => obj.specification_id === item.id)?.value || null;
          const unit = this.productDetails?.specifications?.find(obj => obj.specification_id === item.id)?.['unit_id'] || null;
          const order =this.productDetails?.specifications?.find(obj => obj.specification_id === item.id)?.['order'];
          item['product_order']=order;
          if (value && item?.['key'] !='Features') {
            formGroup[item?.['key']] = [value, item?.['options']?.required ? Validators.required : ''];
          }
          else if (value && item?.['key'] =='Features') {
            this.hasFeatures=true;
            formGroup[item?.['key']] = [value, item?.['options']?.required ? Validators.required : ''];
            this.formFeatureText = new UntypedFormControl(value);
          }
          else if(!value && item?.['key'] =='Features'){
            this.hasFeatures=true;
            formGroup[item?.['key']] = ['', item?.['options']?.required ? Validators.required : ''];
          }
          else {
            formGroup[item?.['key']] = ['', item?.['options']?.required ? Validators.required : ''];
          }
         // if(item?.['unit_type_id']) {
         //   // this.unitService.getByUnitIType(item?.['unit_type_id']).subscribe((res) => {
         //     // @ts-ignore
         //    const units = this.allUnits.filter(unit=> unit?.['type']?.['id'] == item?.['unit_type_id']);
         //     this.units.push(units);
         //   // });;
         //   formGroup[item?.['key']+'_unit_id'] = [unit ];
         //
         // }else {
         //   this.units.push([]);
         //   formGroup[item?.['key']+'_unit_id'] = ['' ];
         // }
        });

        this.specifications.sort((a, b) => a.product_order - b.product_order);

        this.dynamicFormSpec = this.fb.group(formGroup);
      });
      this.loadingSpecs = false;

    }
  }
  onContentChanged(event) {
    this.description = event.html;
  }
  onContentFeatureChanged(event) {
    this.feature = event.html;
  }
  getMedia() {
    this.loadingMedia = true;
    this.service.getMedia(this.productDetails?.id).subscribe((res) => {
      // @ts-ignore
      this.media = res.filter(data => data['collection_name']!='cover');
      // @ts-ignore
      this.allMedia = res;
      this.selectedCover = this.allMedia.length!=0?this.allMedia.filter(coll=>
        coll['collection_name']=='cover'
      )[0]?.['original_url']:'assets/img/demo/missing-picture.jpeg';
      this.groupedMedia();
    });

  }
  groupedMedia() {
    const grouped = this.media?.reduce((acc, med) => {
      const key = med.collection_name;
      const existingCollection = acc.find(collection => collection.collection_name === key);
      if (existingCollection) {
        existingCollection.media.push(med);
      } else {
        acc.push({id: med.id, collection_name: key, media: [med] , type:med.mime_type.substring(0,med.mime_type.lastIndexOf('/'))});
      }
      return acc;
    }, []);
    this.groupedMediaArray = grouped;
    this.loadingMedia = false;
  }
  EditMedia(id, alt) {
    this.dialog.open(UpdateMediaFileComponent, {
      data: {productID: this.productID, id: id, alt: alt}
    }).afterClosed().subscribe((type: any) => {
    });
  }
  AddMedia360() {
    this.dialog.open(CreateMediaComponent, {
      data: {name: 'view_360'}
    }).afterClosed().subscribe((product: any) => {
      if (product)
        this.createdMedia.push(product);
    });
  }
  AddMedia() {
      this.dialog.open(CreateMediaComponent, {
        data: {
          id: this.productID,
          name: '',
          mode:'create'

        }
      }).afterClosed().subscribe((res: any) => {
        if (res)
          this.getMedia();
      });
  }
  addTag(){
    if(this.productForm.value.tag != ''){
      this.tags.push(this.productForm.value.tag);
      this.productForm.patchValue({
        tag: ''
      });
    }
  }
  removeTag(index : number){
    this.tags.splice(index,1);
  }
  onSubmit() {
    this.loading = true;
    let product = new FormData();
    product.append('base_product_id', this.productForm.value.base_product_id);
    product.append('type', this.productForm.value.type);
    product.append('title', this.productForm.value[this.defLocale?.locale + '_title']);
    product.append('description', this.productForm.value[this.defLocale?.locale + '_description']);
    product.append('sku', this.productForm.value.sku);
    if(this.productForm.value.assistant_id!=null)
    product.append('assistant_id', this.productForm.value.assistant_id);
    if(this.productForm.value.tagProduct!=null)
    product.append('tag', this.productForm.value.tagProduct);
    product.append('external_url', this.productForm.value.external_url);

    product.append('price', this.productForm.value.price.toString().replace(/,/g, ''));
    product.append('can_offer', this.productForm.value.canOffer ? '1' : '0');
    product.append('can_auction', this.productForm.value.canAuction ? '1' : '0');
    product.append('is_popular', this.productForm.value.isPopular ? '1' : '0');
    product.append('hide_price', this.productForm.value.hide_price ? '1' : '0');
    product.append('can_negotiate', this.productForm.value.can_negotiate ? '1' : '0');
    product.append('can_full_amount', this.productForm.value.canFullAmount ? '1' : '0');
    product.append('can_down_payment', this.productForm.value.canDownPayment ? '1' : '0');
    product.append('can_lease', this.productForm.value.can_lease ? '1' : '0');
    if(this.productForm.value.can_lease){
      product.append('lease_months', this.productForm.value.lease_months);
      // @ts-ignore
      product.append('monthly_lease_amount', Number.parseInt(this.productForm.value.monthly_lease_amount));
      // @ts-ignore
      product.append('down_payment_amount', Number.parseInt(this.productForm.value.down_payment_amount));
    }



    if(this.productForm.value.canOffer){
      // @ts-ignore
      product.append('min_offer_amount', Number.parseInt(this.productForm.value.min_offer_amount));
    }
    this.locale.filter(lang => lang.default==0 ).forEach(lang =>{

      if(this.productForm.value[lang.locale + '_title']!='')
        product.append(lang.locale +'[title]',this.productForm.value[lang.locale + '_title']);
      if(this.productForm.value[lang.locale + '_description']!='')
        product.append(lang.locale +'[description]',this.productForm.value[lang.locale + '_description']);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars


    });
    if(this.productForm.value.has_shipping) {
      const mm=this.allUnits.filter((model)=>model.symbol=='mm');

      const kg=this.allUnits.filter((model)=>model.symbol=='kg');

      product.append('measurement[length]', this.productForm.value.length);
      product.append('measurement[length_unit_id]', mm[0].id);
      product.append('measurement[width]', this.productForm.value.width);
      product.append('measurement[width_unit_id]', mm[0].id);
      product.append('measurement[height]', this.productForm.value.height);
      product.append('measurement[height_unit_id]', mm[0].id);
      product.append('measurement[weight]', this.productForm.value.weight);
      product.append('measurement[weight_unit_id]', kg[0].id);
      if(this.productForm.value.shipping_media instanceof File)
      product.append('measurement[media][file]', this.productForm.value.shipping_media);
    }
    if(this.markerPositions?.['lat'] && this.markerPositions?.['lng']) {
      product.append('location[latitude]', this.markerPositions?.['lat'].toString()??null);
      product.append('location[longitude]', this.markerPositions?.['lng'].toString()??null);
      product.append('location[country]', this.country??null);
      product.append('location[short_name]', this.short_name.toString()??null);
      product.append('location[address]', this.address??null);
    }

    this.selectedDocs?.forEach((file, index) => {
      product.append(`documents[${index}][name]`, file?.name);
      product.append(`documents[${index}][description]`, file?.name);
      product.append(`documents[${index}][file]`, file);
      product.append(`documents[${index}][document_type_id]`, '1');
    });

    this.productForm.value.applications?.forEach((item, index) => {
      product.append(`applications[${index}][application_id]`, item.toString());
    });
    this.productForm.value.offers?.forEach((item, index) => {
      product.append(`offers[${index}][offer_id]`, item.toString());
    });

      this.productForm.value.tagsProduct?.forEach((item, index) => {
        product.append(`show_in[${index}]`, item.toString());
      });


    if(this.tags.length==0){
      product.append('key_features', null);
    }else{
      this.tags?.forEach((item, index) => {
        product.append(`key_features[${index}]`, item.toString());
      });
    }

    this.specifications.forEach((item, index) => {
      if (this.dynamicFormSpec?.value[item?.['key']]!='' && item?.['key'] !='Features') {
        product.append(`specifications[${index}][specification_id]`, item.id);
        if(item?.['type'] == SpecificationTypeEnum.BOOLEAN)
          product.append(`specifications[${index}][value]`, this.dynamicFormSpec.value[item?.['key']]);

        else
        product.append(`specifications[${index}][value]`, this.dynamicFormSpec.value[item?.['key']]);

        if(item?.['pivot']?.['unit_id']!=null) {
          product.append(`specifications[${index}][unit_id]`, item?.['pivot']?.['unit_id']);
        }
        product.append(`specifications[${index}][order]`, `${index}`);
    }
      if( item?.['key'] =='Features' && this.feature ) {
        product.append(`specifications[${index}][specification_id]`, item.id);
        product.append(`specifications[${index}][value]`, this.feature);
      }
    });

    if (this.mode == 'create' ) {
      let index =0 ;
      if (this.videos.length > 0) {
      product.append(`media[${index}][collection_name]`, 'collection_videos');
      product.append(`media[${index}][type]`, 'video');
      for (let j = 0; j < this.videos.length; j++) {
        product.append(`media[${index}][files][${j}][file]`, this.videos[j]);
      }
      index++;
    }
    if (this.files.length > 0) {
      product.append(`media[${index}][collection_name]`, 'view_360');
      product.append(`media[${index}][type]`, 'image');

      for (let j = 0; j < this.files.length; j++) {
        product.append(`media[${index}][files][${j}][file]`, this.files[j]);
      }
      index++;
    }if (this.selectedCover) {
      product.append(`media[${index}][collection_name]`, 'cover');
      product.append(`media[${index}][type]`, 'image');
      product.append(`media[${index}][files][0][file]`, this.productForm.value.cover);
      index++;
    }

    }

    if (this.mode == 'update' ) {
      product.append('id', this.productDetails.id.toString());
      product.append('_method', 'put');
      let ind=0;
      if(this.groupedMediaArray?.length>0) {
        this.groupedMediaArray.forEach((collection, index) => {
          product.append(`media_order[${index}][collection_name]`, collection.collection_name);
          for (let j = 0; j < collection.media.length; j++) {
            product.append(`media_order[${index}][orders][${j}][id]`, collection.media[j]?.id );
            product.append(`media_order[${index}][orders][${j}][order]`, (j+1).toString() );
            product.append(`media_order[${index}][orders][${j}][description]`, collection.media[j]?.['custom_properties']['description'] );

          }
        });
      }
      if ( this.productForm.value.cover instanceof File) {
        product.append(`media[${ind}][collection_name]`, 'cover');
        product.append(`media[${ind}][type]`, 'image');
        product.append(`media[${ind}][files][0][file]`, this.productForm.value.cover);
        ind++;
      }
      this.update(product);
    } else {

      this.create(product);
    }

  }
  update(product) {
    this.service.update(product, this.productDetails.id).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
        this.toastr.success('Product Updated Successfully!');
      }
    }, error => {
      this.loading = false;
      this.toastr.error(error);
    });
    this.loading = false;
  }
  create(product) {
    this.service.create(product).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS || ErrorStatusModel.SUCCESSCREATE) {
        this.toastr.success('Product Created Successfully!');
        this.router.navigate(['/products/', res?.['data'].id]).then(() => {
          this.loading = false;
          location.reload();
        });
      }

    }, error => {
      this.loading = false;
      this.toastr.error(error);

    });
  }
  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
    this.uploadFiles();
    this.fileArray=[];
    const files = (event.target as HTMLInputElement).files; // Get all selected files
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileArray.push(
              // @ts-ignore
              e.target.result
          );
        };
        reader.readAsDataURL(file);
      }

      this.swiper = new Swiper('.swiper-container', {
        slidesPerView: 1,
        speed: 0,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        nested: true
      });
      this.control.valueChanges.subscribe((val) => {

        this.swiper?.slideTo(val ?? 0, undefined, false);
      });
      this.swiper.on('slideChange', () => {
        this.control.setValue(this.swiper?.realIndex ?? 0, { emitEvent: false });
      });
    }
  }
  click(event){
  }
  uploadFiles() {
    this.files = [];
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      if (file) {
        this.files.push(file);
      }
    }
  }
  drop(event: CdkDragDrop<any> , index) {
      moveItemInArray(this.groupedMediaArray[index].media, event.previousContainer.data.index, event.container.data.index);
  }
  onImagePicked(event: Event) {

    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
      this.productForm.patchValue({shipping_media: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedShippingMediaFile = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }

  }
  getCover(event: any){
    this.productForm.patchValue({cover: event});

  }
  getVideos(event: any){
    this.videos=event;
  }
  DeleteMedia(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: 'Delete Media Image',
        message: 'Are you sure you want to perform this action?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.deleteMedia(id, this.productID).subscribe((res) => {
          if (res['status'] == ErrorStatusModel.SUCCESS) {
            this.toastr.success('media Deleted Successfully!');
            this.getMedia();
          }
        }, error => {
          this.toastr.error(error);
        });
      }else {

      }});


    }
  DeleteMediaCollection( name) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: 'Delete Media Image',
        message: 'Are you sure you want to perform this action?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.deleteMediaCollection(this.productID , name).subscribe((res) => {
          if (res['status'] == ErrorStatusModel.SUCCESS) {
            this.toastr.success('media Deleted Successfully!');
            this.getMedia();
          }
        }, error => {
          this.toastr.error(error);
        });
      }else {

      }});


    }
  handleImageError(event: any) {
    event.target.src = 'assets/img/demo/no-img.jpeg';
  }
  onDocFileSelected(event: any) {
    this.selectedDocs = [...this.files, ...Array.from(event.target.files)];
    this.productDocs = [...this.productDocs, ...Array.from(event.target.files)];
  }
  onCoverPicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
      this.productForm.patchValue({cover: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedCover = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }


  }
  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }
  deleteFiles() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: 'Delete Documents',
        message: 'Are you sure you want to perform this action?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(this.productDetails.documents?.length>0) {
            this.service.deleteDocs(this.productID).subscribe((res) => {
                if (res['status'] == ErrorStatusModel.SUCCESS) {
                    this.toastr.success('Documents Deleted Successfully!');
                    this.ngOnInit();
                }
            }, error => {
                this.toastr.error(error);
            });
            this.productDocs = [];
        }else{
          this.productDocs=[];
        }



      }else {

      }});


  }
  drops(event: CdkDragDrop<any> , index) {
    moveItemInArray(this.specifications, event.previousContainer.data.index, event.container.data.index);
  }

}
