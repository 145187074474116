<form (ngSubmit)="save()" [formGroup]="form">
    <div class="flex items-center" mat-dialog-title>

        <h2
                class="headline m-0 flex-auto">New Inspection Report</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-divider class="text-border"></mat-divider>

    <mat-dialog-content class="flex flex-col">
        <form [formGroup]="form" class="w-full">
            <mat-form-field class="flex-auto">
                <mat-label> Select a Date</mat-label>
                <input (click)="formDatepickerRef.open()"
                       (focus)="formDatepickerRef.open()"
                       [matDatepicker]="formDatepickerRef"
                       matInput formControlName="inspection_date"
                >
                <mat-datepicker-toggle [for]="formDatepickerRef" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #formDatepickerRef></mat-datepicker>
            </mat-form-field>

        </form>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">Cancel</button>
        <button  color="primary" mat-flat-button type="submit" [disabled]="form.invalid" class="flex items-center justify-center">
            <span  *ngIf="!loading"> Add Report</span>
            <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>

        </button>
    </mat-dialog-actions>
</form>


