<h2 mat-dialog-title>Update hotspot text</h2>
<mat-dialog-content>
    <form (ngSubmit)="save()" [formGroup]="form">
        <mat-form-field class="w-full">
            <mat-label>Description</mat-label>
            <input  formControlName="description" matInput>
        </mat-form-field>
        <mat-slide-toggle formControlName="isClose">set as closing hotspot</mat-slide-toggle>

    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button color="primary" mat-flat-button (click)="save()" >save</button>
    <button  mat-flat-button (click)="deleteSpot()" >Delete the hotspot</button>
</mat-dialog-actions>
