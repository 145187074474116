

export class AttachmentModel {
  id: number;
  title: string;
  generation_id: string;
  translations : [
    {
      locale : string ;
      title : string ;
    }
  ];


    constructor(obj) {
        this.id = obj.id;
        this.title = obj.title;
        this.generation_id = obj.generation_id;
        this.translations = obj.translations;
    }
}
