<div class="container p-2">
  <div class="p-1 flex justify-between items-center " >

      <div class="flex items-center">
        <button class="text-secondary" mat-icon-button type="button" (click)="onCancel()">
          <mat-icon svgIcon="mat:arrow_back"></mat-icon>
        </button>
        <h2 class="headline m-0 flex-auto">Finance Request</h2>
      </div>
    <div class="flex gap-2" *ngIf="!loading">
      <button *ngIf="finance.status=='PENDING'" class="p-2 rounded bg-green-500 hover:bg-green-600 text-white font-bold rounded m-1.5 " (click)="accept()">Accept</button>
      <button *ngIf="finance.status=='PENDING'" class="p-2 rounded bg-red-500 hover:bg-red-600 text-white font-bold rounded m-1.5 " (click)="reject()">Reject</button>
      <p class="p-2 rounded bg-sky-50 m-1.5">{{finance.status}}</p>
    </div>

  </div>

  <vex-loading-row *ngIf="loading" [diameter]="50"></vex-loading-row>

  <div *ngIf="!loading" class=" w-full">
    <div class="flex flex-col gap-2 p-2">


      <div class="flex gap-3 pb-1 w-full">
        <vex-field-details-card [title]="'First Name'" [content]="finance.first_name" class="w-full"></vex-field-details-card>
        <vex-field-details-card [title]="'Last Name'" [content]="finance.last_name" class="w-full"></vex-field-details-card>
        <vex-field-details-card [title]="'City'" [content]="finance.city" class="w-full"></vex-field-details-card>
      </div>
      <div class="flex gap-3 pb-1">
        <vex-field-details-card [title]="'Code'" [content]="finance.code" class="w-full"></vex-field-details-card>
        <vex-field-details-card [title]="'Company'" [content]="finance.company" class="w-full"></vex-field-details-card>
        <vex-field-details-card [title]="'Company Address'" [content]="finance.company_address" class="w-full"></vex-field-details-card>
      </div>
      <div class="flex gap-3 pb-1">
        <vex-field-details-card [title]="'Company Size'" [content]="finance.company_size" class="w-full"></vex-field-details-card>
        <vex-field-details-card [title]="'Email'" [content]="finance.email" class="w-full"></vex-field-details-card>
        <vex-field-details-card [title]="'Licence Expiry at'" [content]="finance.licence_expiry_at" class="w-full"></vex-field-details-card>
      </div>
      <div class="flex gap-3 pb-1">
        <vex-field-details-card [title]="'Phone Number'" [content]="finance.phone_number" class="w-full"></vex-field-details-card>
        <vex-field-details-card [title]="'Postal Code'" [content]="finance.postal_code" class="w-full"></vex-field-details-card>
        <vex-field-details-card [title]="'Years Business'" [content]="finance.years_business" class="w-full"></vex-field-details-card>
      </div>
    </div>
    <div class="grid grid-cols-3  gap-3 pb-1">
      <div *ngFor="let file of finance.media" class="flex-col gap-1 bg-white ">
        <h3 class=" p-2.5">{{file.collection_name.split('_').join(' ')}}</h3>
        <a target="_blank" [href]="file?.['original_url']" class=" p-2  border-1 m-3 flex justify-center items-center">
          <app-svg-renderer [svgContent]="'PDF'" />
        </a>
      </div>
    </div>


  </div>
</div>
