import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {ApplicationModel} from '../../../models/application.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private http:HttpClient) {

  }

  get(){
    return this.http.get(environment.baseUrl + 'application/application');
  }
  create(app:any){
    return this.http.post(environment.baseUrl + 'application/application',app);
  }
  update(app:any){
    return this.http.put(environment.baseUrl + 'application/application/'+app.get('id'),app);
  }
  delete(app:ApplicationModel){
    return this.http.delete(environment.baseUrl + 'application/application/'+app.id);
  }
}
