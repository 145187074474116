import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {SellYourMachoneModel} from '../../../models/sell-your-machone.model';

@Injectable({
    providedIn: 'root'
})
export class SellYourMachineService {

    constructor(private http: HttpClient) { }

    get() {
        return this.http.get(environment.baseUrl + 'sell-machine/sell-machines');
    }
    getById(id:any) {
        return this.http.get(environment.baseUrl + 'sell-machine/sell-machines/' + id);
    }
    accept(sell) {
        return this.http.post(environment.baseUrl + 'sell-machine/sell-machines/accept' , sell);
    }
  delete(prod:SellYourMachoneModel){
    return this.http.delete(environment.baseUrl + 'sell-machine/sell-machines/'+prod.id);
  }

}
