import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, RouterLinkActive} from '@angular/router';
import {LiveshowService} from '../service/liveshow.service';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LoadingRowComponent} from '../../../../../core/components/loading-row/loading-row.component';
import {MatTabsModule} from '@angular/material/tabs';
import {PageLayoutDemoModule} from '../../../../ui/page-layouts/page-layout-demo/page-layout-demo.module';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {UntilDestroy} from '@ngneat/until-destroy';
import { ScrollingModule} from '@angular/cdk/scrolling';
import {Clipboard} from '@angular/cdk/clipboard';
import {IvsChatService} from '../service/isv.service';
import {AuctionService} from '../../auction/service/auction.service';
import {LiveShowChatComponent} from '../component/live-show-chat/live-show-chat.component';
import {OngoingDetailsComponent} from '../component/ongoing-details/ongoing-details.component';
import {create, isPlayerSupported, MediaPlayer, PlayerEventType, PlayerState} from 'amazon-ivs-player';
import {AuctionStatus} from '../../../../../core/enum/auctions/auction-status.enum';
import {LiveShowTypeEnum} from '../../../../../core/enum/live-show-type.enum';
import {BidsHistoryComponent} from '../component/bids-history/bids-history.component';
import {PusherChannels} from '../../../../../core/enum/pusher-channels.enum';
import {PusherEvents} from '../../../../../core/enum/pusher-events.enum';
import {ListingStatusUpdatedEventModel} from '../../../models/listing-status-updated-event.model';
import {PusherService} from '../service/pusher.service';
import Echo from 'laravel-echo';
import {AutoClosingAuctionComponent} from '../component/auto-closing-auction/auto-closing-auction.component';
import {ProxyBidComponent} from "../component/proxy-bid/proxy-bid.component";


// @ts-ignore
@UntilDestroy()

@Component({
  selector: 'vex-show-details',
  templateUrl: './show-details.component.html',
  styleUrls: ['./show-details.component.scss'],
  standalone:true,
  imports: [
    PageLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    NgClass,
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbsModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    LoadingRowComponent,
    MatTabsModule,
    PageLayoutDemoModule,
    RouterLinkActive,
    MatRippleModule,
    MatOptionModule,
    MatSelectModule,
    ScrollingModule,
    LiveShowChatComponent,
    OngoingDetailsComponent,
    BidsHistoryComponent,
    AutoClosingAuctionComponent,
    ProxyBidComponent
  ]
})
export class ShowDetailsComponent implements OnInit , AfterViewInit{

  id;
  model;
  ongoingModel;
  items=[];
  loading=true;
  activeClass='border-blue-600';
  @ViewChild('videoPlayer') videoPlayerRef!: ElementRef;

  private player: MediaPlayer;
  constructor(
    private router:ActivatedRoute,
    private service:LiveshowService,
    private clipboard: Clipboard,
    private ivsService : IvsChatService,
    private auctionService : AuctionService,
    private pusherService : PusherService

  ) {
    this.echo = this.pusherService.getSockets();
  }
  echo: Echo;
  ngAfterViewInit(): void {
    this.getData();

  }







  getData(){
    this.id=this.router.snapshot.paramMap.get('id');
   this.service.get(this.id).subscribe((res)=>{

     this.model = res['data'];
     if(this.model.type=='AUCTION'){
       this.getItemRing();

     }
     this.loadStream(this.model?.channel?.url);

     // setTimeout(()=>{
     //   this.loadStream('https://3d26876b73d7.us-west-2.playback.live-video.net/api/video/v1/us-west-2.913157848533.channel.xJ2tVekwmMGd.m3u8');
     // },1000);
     this.ivsService.requestChatAccess(this.model.chat.api_url,this.model.chat.room_id,this.model.chat.websocket_url);
     this.listingStatusUpdatedListener(this.model.ring_id);
     this.listingItemsStatusListener(this.model.ring_id);
     this.loading=false;

   });
  }
  getItemRing(){
    this.auctionService.getItems(this.model.ring_id).subscribe((res)=>{
    // this.auctionService.getItems(1).subscribe((res)=>{
      this.items=res['data'];
      this.ongoingModel = this.items.filter((v)=>v?.product?.listing?.listing_status=='ONGOING')[0];
      if(!this.ongoingModel){
        this.ongoingModel = this.items[0];

      }
      });
  }
  ngOnInit(): void {

  }
  startLive(){
    this.service.changeLiveStatus(this.id,{'status':'LIVE'}).subscribe((res)=>{
      this.ngOnInit();
    });
  }
  endLive(){
    this.service.changeLiveStatus(this.id,{'status':'ENDED'}).subscribe((res)=>{
      this.ngOnInit();
    });
  }
  activeProduct(id:number){
    const formdata = new FormData();
    // const data= {};
    this.model.products.map((product,index)=>{
      if(product.id==id){
        product.status='ACTIVE';
      }else{
        product.status='INACTIVE';
      }

      formdata.append(`liveables[${index}][liveable_id]`,product.id);
      formdata.append(`liveables[${index}][order]`,product.order);
      formdata.append(`liveables[${index}][status]`,product.status);
    });
    formdata.append('_method','PUT');
    this.service.changeProductActive(this.id,formdata).subscribe((res)=>{
      this.ngOnInit();
    });
  }
  copy(text:string) {
    this.clipboard.copy(text);
  }


  isAuction(){
    return this.model.type=='AUCTION';
  }
  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/poster_placeholder.jpg';
  }
  loadStream(playbackUrl: string) {

    if (!isPlayerSupported) {
      alert('your browser does not support ivs player...');
      return;
    }

    this.player = create({
      wasmBinary: '/assets/ivs/amazon-ivs-wasmworker.min.wasm',
      wasmWorker: '/assets/ivs/amazon-ivs-wasmworker.min.js'
    });
    const videoPlayer = document.getElementById('video-player');



    if (!videoPlayer || !this.player) return;

    this.player.addEventListener(PlayerEventType.ERROR, (error) => {

    });

    this.player.addEventListener(PlayerState.ENDED, () => {

    });
    // this.player.addEventListener(PlayerState.READY, () => {
    //
    // });
    this.player.addEventListener(PlayerState.PLAYING, () => {

    });
    // this.player.addEventListener(PlayerState.IDLE, () => {
    //
    // });
    // this.player.addEventListener(PlayerState.BUFFERING, () => {
    //
    // });

    this.player.attachHTMLVideoElement(videoPlayer as HTMLVideoElement);
    // Setup stream and play
    this.player.setAutoplay(true);
    this.player.load(playbackUrl);
    // Set volume
    this.player.setVolume(1);
  }
  changeStatus(){
    this.auctionService.changeItemsStatus({
      'id': 2,
      'status': 'ONGOING'
    }).subscribe((res)=>{
      this.ngOnInit();
    });
  }

  protected readonly LiveShowTypeEnum = LiveShowTypeEnum;
  protected readonly AuctionStatus = AuctionStatus;

  selectActiveItems(id:number){
    // let temp=
    this.items.filter((item)=>item.id==this.ongoingModel.id)[0]=this.ongoingModel;
    this.ongoingModel = this.items.filter((item)=>item.id==id)[0];

  }
  listingStatusUpdatedListener(ring_id: string) {
    this.echo
      .channel(PusherChannels.RINGS + '.' + ring_id)
      .listen(
        PusherEvents.BID_PLACED,
        (listingStatusUpdatedData: ListingStatusUpdatedEventModel) =>{

          this.handleBidStatusUpdated(listingStatusUpdatedData);

        });


  }
  listingItemsStatusListener(ring_id) {
    this.echo
      .channel(PusherChannels.RINGS + '.' + ring_id)
      .listen(
        PusherEvents.LISTING_STATUS_UPDATED,
        (listingStatusUpdatedData: ListingStatusUpdatedEventModel) =>{

          this.handleListingStatusUpdated(listingStatusUpdatedData);

        });


  }
  handleListingStatusUpdated(data){
    const current = this.items.filter((item)=>item.product.listing.id==data?.['listing_id'])[0];
    current.product.listing.listing_status=data?.['status'];

    if(data?.['status']=='ONGOING'){
      this.ongoingModel = current;
    }
  }
  handleBidStatusUpdated(data){
    // this.bids_count = data?.['bids_count'];
    // this.current_price = data?.['last_bid']?.['amount'];
    const bid={
      amount : data?.['last_bid'],
      bidder:{
        name: data?.['last_bidder']?.['name'],
        cover:data?.['last_bidder']?.['avatar']
      },
      datetime :data?.['bade_at'],
      listing : data?.['listing_id'],
      type : data?.['type']
    };
    const current = this.items.filter((item)=>item.product.listing.id==data?.['listing_id'])[0];
    if (current) {
      if (current.product.listing) {
        // Update current_price amount
        if (current.product.listing.current_price) {
          current.product.listing.current_price.amount = data?.['last_bid']?.['amount'];
        }

        // Update bids_count
        current.product.listing.bids_count = data?.['bids_count'];
        current.can_be_sold = data?.['can_be_sold'];
        current.can_be_ended = data?.['can_be_ended'];
      }
      if (Array.isArray(current.bids)) {
        current.bids.unshift(bid);
      }

    this.ongoingModel = this.items.filter((item)=>item.id==this.ongoingModel.id)[0];


  }}

}
