import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomerModel } from '../../../models/customer.model';

@Injectable({
  providedIn: 'root'
})
export class AdminsService {


  constructor(private http:HttpClient) { }

  get(){
    return this.http.get(environment.baseUrl + 'user/admins');
   }
   getAssistants(){
    return this.http.get(environment.baseUrl + 'user/assistants');
   }
   create(admin:FormData){
    return this.http.post(environment.baseUrl + 'user/users',admin);
   }
   update(id:number,admin:any){
    return this.http.post(environment.baseUrl + 'user/users/'+id,admin);
   }

}
