<h2 mat-dialog-title>Update Media</h2>
<mat-dialog-content>
  <form (ngSubmit)="save()" [formGroup]="form">
    <mat-form-field class="w-full">
      <mat-label>Description</mat-label>
      <input  formControlName="description" matInput>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button  mat-flat-button (click)="onNoClick()" >Cancel</button>
  <button color="primary" mat-flat-button (click)="save()" >Set</button>
</mat-dialog-actions>
