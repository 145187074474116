import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrivacyPolicyService {

 
  constructor(private http: HttpClient) {
  }

  get() {
      return this.http.get(environment.baseUrl + 'static-page/privacy-policy');
  }

  create(term: any) {
      return this.http.post(environment.baseUrl + 'static-page/privacy-policy', term);
  }

  update(term: any, id) {
      return this.http.post(environment.baseUrl + 'static-page/privacy-policy/' + id, term);
  }

  delete(model: any) {
      return this.http.delete(environment.baseUrl + 'static-page/privacy-policy/' + model.id);
  }

  getTerm(id) {
      return this.http.get(environment.baseUrl + 'static-page/privacy-policy/' + id);
  }
}
