<h3 matDialogTitle>
  {{ event.title | capitalize}}
</h3>
<mat-dialog-content>
    <div class="flex flex-col">
        <div >
          <p>Email : {{event.email}}</p>
        </div>
      <div >
          <h2>Start Date :</h2>
          <p>{{event.start | date:'EEE MMM dd yyyy HH:mm:ss (z)'}}</p>
        </div>
      <div >
        <h2>End Date :</h2>
        <p>{{event.end | date:'EEE MMM dd yyyy HH:mm:ss (z)'}}</p>
      </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="flex justify-end">

  <button mat-button mat-dialog-close>Close</button>
  <button mat-button class="bg-rose-600 text-white" *ngIf="event.status=='PENDING'" (click)="reject()">Reject</button>
  <button mat-button class="bg-blue-600 text-white"  *ngIf="event.status=='PENDING'" (click)="accept()">Accept</button>
</mat-dialog-actions>
