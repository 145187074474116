import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {NgForOf, NgIf, UpperCasePipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {QuillEditorComponent} from 'ngx-quill';
import {EditorComponent} from '@tinymce/tinymce-angular';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {ZoneService} from '../service/zone.service';
import {GoogleMap} from '@angular/google-maps';
import {LocalStorageRefService} from '../../../../pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../../../../../core/enum/storage-keys.enum';
import {MatTabsModule} from '@angular/material/tabs';

@Component({
  selector: 'vex-zone-details',
  templateUrl: './zone-details.component.html',
  styleUrls: ['./zone-details.component.scss'],
  imports: [
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgForOf,
    MatButtonModule,
    NgIf,
    MatProgressSpinnerModule,
    PageLayoutModule,
    MatSlideToggleModule,
    QuillEditorComponent,
    EditorComponent,
    GoogleMap,
    MatTabsModule,
    UpperCasePipe
  ],
  standalone: true
})
export class ZoneDetailsComponent implements OnInit{
  zoneId;
  static id = 100;
  numbers: number[] = Array.from({ length: 10 }, (_, i) => i + 1);
  form: UntypedFormGroup;
  classForm: UntypedFormGroup;
  mode: 'create' | 'update' = 'create';
  groups = [];
  loading=false;
  private description: any;
  formDesc;
  defaults;
  points: {latitude:string,longitude:string}[] = [];
  classes: {from,to,cost}[] = [];
  locale;
  defLocale;
  constructor(
    private fb: UntypedFormBuilder,
    private service:ZoneService,
    private toastr: ToastrService,
    private router:Router,
    private route:ActivatedRoute,
    private storage: AngularFireStorage,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private localStorageRefService : LocalStorageRefService

  ) {
  }

  path: google.maps.MVCArray<google.maps.LatLng>;
  ngOnInit() {
    this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
    this.defLocale = this.locale.filter(loc=>loc.default==true)[0];

    this.zoneId = this.route.snapshot.paramMap.get('id');
    if (this.zoneId) {
      this.getData();
      this.mode = 'update';
    } else {
      this.initMap();
      this.mode = 'create';
    }

    this.form = this.fb.group({
      id: [null],
      status: [1]
    });
    this.locale.forEach(lang => {

      this.form.addControl(lang.locale+'_name', this.fb.control(''));
    });
    this.classForm = this.fb.group({
      from: [0,Validators.required],
      to: [0 , Validators.required],
      cost: [0,Validators.required]
    });

  }
  getData() {
    this.service.get(this.zoneId).subscribe((res) => {
      this.defaults = res['data'];
      this.initForm();

    });
  }
  initForm() {

    this.form = this.fb.group({
      id: [this.defaults.id],
      status: [this.defaults.status]

    });
    this.locale.forEach(lang => {
      const value = this.defaults.translations.filter(brand => brand.locale == lang.locale);

      this.form.addControl(lang.locale+'_name', this.fb.control(value[0]?.name??''));
    });
    this.defaults.weight_classes.map(t=>{
      this.classes.push({
        to:t.to,
        from:t.from,
        cost:t.cost
      });
    });

    this.initMap();
    this.defaults.points.map((t)=>{
      // @ts-ignore
      this.addLatLng(null,new google.maps.LatLng(t.latitude, t.longitude));
      // this.path.push();
    });




  }

  addClassess(){
    this.classes.push({
      to:this.classForm.value.to,
      cost:this.classForm.value.cost,
      from:this.classForm.value.from
    });

    this.classForm.reset();

    this.cdr.detectChanges();

  }

  deleteClassRow(index){
    this.classes.splice(index,1);
  }



  save() {


    this.loading=true;
    const formData = new FormData();
    if(this.mode == 'update')
      formData.append('id',this.form.value.id);
      for (let i = 0; i < this.path.getLength(); i++) {
        // @ts-ignore
        formData.append(`points[${i}][latitude]`,this.path.getAt(i).lat() );
        // @ts-ignore
        formData.append(`points[${i}][longitude]`,this.path.getAt(i).lng() );
        // this.points.push();
      }
    formData.append('name',this.form.value[this.defLocale.locale + '_name']);
    formData.append('status',this.form.value.status);
      // @ts-ignore
    this.locale.filter(lang => lang.default==0 ).forEach(lang =>{

      if(this.form.value[lang.locale + '_name']!='')
        formData.append(lang.locale +'[name]',this.form.value[lang.locale + '_name']);
    });
    for (let i = 0; i < this.classes.length; i++) {
      // @ts-ignore
      formData.append(`weight_classes[${i}][from]`,this.classes[i].from);
      formData.append(`weight_classes[${i}][to]`,this.classes[i].to);
      formData.append(`weight_classes[${i}][cost]`,this.classes[i].cost);
      // this.points.push();

    }


    if (formData) {
      this.service.create(formData).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Zone '+this.mode+'d Successfully!');
          this.onCancel();

        }

      }, error => {
        this.loading=false;
        this.toastr.error(error['errors']['message']);
      });
    }




  }

  create() {

    const type = {
      'en':{
        answer:this.form.value.answer,
        question:this.form.value.question
      },

      'order': this.form.value.order,
      'faq_group_id': this.form.value.faq_group_id


    };
  }
  onContentChanged(event) {
    this.description = event.html;
  }
  onCancel(){
    this.router.navigate(['/zones']).then();
  }


  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
  //Map

   poly: google.maps.Polyline;
   map: google.maps.Map;
   firstPoint;
   async initMap(): Promise<void> {
    // if(this.mode=='update'){
    //   this.defaults.points.map(t=>{
    //     // @ts-ignore
    //     this.points.push({lat : Number.parseFloat(t.lat) , lng : Number.parseFloat(t.lng)});
    //   });
    // }
    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
       zoom: 7,
       center: this.defaults? {lat:Number.parseFloat(this.defaults.points[0].latitude),lng: Number.parseFloat(this.defaults.points[0].longitude)}:{ lat: 23.8516074, lng: 54.04710 } // Center the map on Chicago, USA.
     });
     //  if(this.mode=='update'){
     // this.poly = new google.maps.Polygon({
     //   strokeColor: '#000000',
     //   strokeOpacity: 1.0,
     //   strokeWeight: 3.5,
     //    paths : this.points,
     //   fillColor: '#76a4e5',
     //   fillOpacity: 0.35
     //
     // });
     //  }else{
        this.poly = new google.maps.Polygon({
          strokeColor: '#000000',
          strokeOpacity: 1.0,
          strokeWeight: 3.5,
          fillColor: '#76a4e5',
          fillOpacity: 0.35

        });
      // }
     this.poly.setMap(this.map);

     // Add a listener for the click event
     this.map.addListener('click', (event: any) => {
       this.zone.run(() => this.addLatLng(event));

       // this.handleMapClick(event.latLng);
       // this.firstPoint = event.latLng;
     });
   }

  addLatLng(event: any=null,latlag:any=null) {
     this.path = this.poly.getPath();
    const mapdata = event==null?latlag:event.latLng;
    this.path.push(mapdata);

    // Add a new marker at the new plotted point on the polyline.
    new google.maps.Marker({
      position: mapdata,
      title: '#' + this.path.getLength(),
      map: this.map

    });

  }

  resetPoint(){
    // if(this.mode=='update'){
    //   this.defaults.points=[];
    //   this.points = [];
    // }else{
    //   this.path.clear();
    // }
    //
    //
    // this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
    //   zoom: 7,
    //   center: { lat: 23.8516074, lng: 54.04710 }, // Center the map on Chicago, USA.
    // });
    //
    // this.poly = new google.maps.Polygon({
    //   strokeColor: '#000000',
    //   strokeOpacity: 1.0,
    //   strokeWeight: 3.5,
    //   fillColor: '#76a4e5',
    //   fillOpacity: 0.35,
    //   paths: this.mode=='update'?this.points:this.path
    //
    // });
    // this.poly.setMap(this.map);
    // this.map.addListener('click', (event: any) => {
    //   this.zone.run(() => this.addLatLng(event));
    //
    //   // this.handleMapClick(event.latLng);
    //   // this.firstPoint = event.latLng;
    // });
    // this.cdr.detectChanges();
  }
}

