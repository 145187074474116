// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class FinanceModel {
  annual_turnover: number;
  city: string;
  code: string;
  company: string;
  company_address: string;
  company_size: string;
  created_at: string;
  deleted_at: string | null;
  email: string;
  first_name: string;
  id: number;
  keep_informed: number;
  last_name: string;
  licence_expiry_at: string;
  phone_number: string;
  postal_code: string;
  role: string;
  updated_at: string;
  user_id: number | null;
  years_business: string;
  status: string;
  media: any;

  constructor(data) {
    this.annual_turnover = data.annual_turnover;
    this.city = data.city;
    this.code = data.code;
    this.company = data.company;
    this.company_address = data.company_address;
    this.company_size = data.company_size;
    this.created_at = data.created_at;
    this.deleted_at = data.deleted_at;
    this.email = data.email;
    this.first_name = data.first_name;
    this.id = data.id;
    this.keep_informed = data.keep_informed;
    this.last_name = data.last_name;
    this.licence_expiry_at = data.licence_expiry_at;
    this.phone_number = data.phone_number;
    this.postal_code = data.postal_code;
    this.role = data.role;
    this.updated_at = data.updated_at;
    this.user_id = data.user_id;
    this.years_business = data.years_business;
    this.status = data.status;
    this.media = data.media;
  }
}
