import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FaqModel } from '../../../models/faq.model';

@Injectable({
    providedIn: 'root'
})
export class NewsService {

    constructor(private http:HttpClient) { }

    get(){
        return this.http.get(environment.baseUrl + 'news/news/');
    }
    getTags(){
        return this.http.get(environment.baseUrl + 'news/news/tags');
    }
    getNews(id:any){
        return this.http.get(environment.baseUrl + 'news/news/'+ id);
    }
    create(model:any){
        return this.http.post(environment.baseUrl + 'news/news/save',model);
    }
    delete(model:FaqModel){
        return this.http.delete(environment.baseUrl + 'news/news/'+model.id);
    }
}
