import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {PageLayoutModule} from '../../../../../../../@vex/components/page-layout/page-layout.module';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BreadcrumbsModule} from '../../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LoadingRowComponent} from '../../../../../../core/components/loading-row/loading-row.component';
import {MatTabsModule} from '@angular/material/tabs';
import {PageLayoutDemoModule} from '../../../../../ui/page-layouts/page-layout-demo/page-layout-demo.module';
import {RouterLinkActive} from '@angular/router';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ErrorStatusModel} from '../../../../../../core/enum/error-status.model';
import {AuctionService} from '../../../auction/service/auction.service';
import {PusherChannels} from '../../../../../../core/enum/pusher-channels.enum';
import {PusherEvents} from '../../../../../../core/enum/pusher-events.enum';
import {ListingStatusUpdatedEventModel} from '../../../../models/listing-status-updated-event.model';
import Echo from 'laravel-echo';
import {PusherService} from '../../service/pusher.service';

@Component({
  selector: 'vex-auto-closing-auction',
  templateUrl: './auto-closing-auction.component.html',
  styleUrls: ['./auto-closing-auction.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    PageLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    NgClass,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbsModule,
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    LoadingRowComponent,
    MatTabsModule,
    PageLayoutDemoModule,
    RouterLinkActive,
    MatRippleModule,
    MatOptionModule,
    MatSelectModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class AutoClosingAuctionComponent implements OnInit{
  form: UntypedFormGroup;
  @Input() ring_id;
  closing_after=null;
  echo: Echo;
  constructor(
    private dialog: MatDialog ,
    private service:AuctionService ,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder ,
    private cdr: ChangeDetectorRef,
    private pusherService : PusherService

  ) {
    this.echo = this.pusherService.getSockets();
  }

  getData() {

    this.service.getRingsAuctoClosing(this.ring_id).subscribe((res)=>{
      this.initData(res);

    });
  }

  ngOnInit() {
    this.listingAutoCloseListener();
    this.form = this.fb.group({
      has_auto_closing: [true]

    });
    this.getData();


  }
  initData(res){
    this.form.get('has_auto_closing').setValue(res['has_auto_closing']);
    this.cdr.detectChanges();
  }
  save(){

    const formData=new FormData();


   formData.append('has_auto_closing',this.form.value.has_auto_closing?'1':'0');


    if (formData) {
      this.service.saveRingsAuctoClosing(this.ring_id,formData).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Updated Successfully!');
          this.ngOnInit();

        }
      }, error => {
        // this.loading=false;
        this.toastr.error(error);
      });
    }


  }

  listingAutoCloseListener() {
    this.echo
      .channel(PusherChannels.RINGS + '.' + this.ring_id)
      .listen(
        PusherEvents.AUTO_CLOSE,
        (listingStatusUpdatedData: ListingStatusUpdatedEventModel) =>{

          this.closing_after = listingStatusUpdatedData?.['autoClosingInSeconds'];

        });


  }


}
