<div class="container p-2">

  <div class="flex flex-col gap-3">
    <div class="flex items-center" mat-dialog-title>
      <button class="text-secondary" mat-icon-button type="button" (click)="onCancel()">
        <mat-icon svgIcon="mat:arrow_back"></mat-icon>
      </button>
      <h2
          class="headline m-0 flex-auto">Sell Machine Review</h2>
    </div>
    <mat-divider class="text-border"></mat-divider>

    <mat-dialog-content class="flex flex-col">
      <div class="flex flex-col sm:flex-col" >

        <div  class="p-6 m-4 flex flex-col gap-1 rounded-lg">
          <mat-spinner [diameter]="50" class="mat-spinner-color" *ngIf="loading"></mat-spinner>
          <div  *ngIf="!loading">
          <div class="flex gap-3 pb-1">
            <div class="flex-col gap-1 w-full ">
              <h3 class=" p-2.5">Full Name</h3>
              <p class="bg-white  p-2.5">{{product?.full_name}}</p>
            </div>
            <div class="flex-col gap-3 w-full">
              <h3 class=" p-2.5">Email</h3>
              <p class="bg-white  p-2.5">{{product?.email}}</p>
            </div>
            <div class="flex-col gap-3 w-full">
              <h3 class=" p-2.5">Phone Number</h3>
              <p class="bg-white  p-2.5">{{product?.phone_number}}</p>
            </div>

          </div>
            <div class="flex gap-3  pb-1">
              <div class="flex-col gap-3 w-full">
                <h3 class=" p-2.5">Company</h3>
                <p class="bg-white  p-2.5">{{product?.company}}</p>
              </div>
              <div class="flex-col gap-3 w-full">
                <h3 class="  p-2.5">Location</h3>
                <p class="bg-white  p-2.5">{{product?.location}}</p>
              </div>
              <div class="flex-col gap-3 w-full">
                <h3 class=" p-2.5">Status</h3>
                <p class="bg-white  p-2.5">{{product?.status}}</p>
              </div>

            </div>
            <div *ngIf="product?.category.length<2" class="flex gap-3 pb-1">
              <div class="flex-col gap-3 w-full">
                <h3 class=" p-2.5">Year Of Manufacturing</h3>
                <p  class="bg-white  p-2.5">{{product?.year_of_manufacturing}}</p>
              </div>
              <div class="flex-col gap-3 w-full">
                <h3 class=" p-2.5">Working Hours</h3>
                <p  class="bg-white  p-2.5">{{product?.working_hours}}</p>
              </div>
              <div class="flex-col gap-3 w-full">
                <h3 class=" p-2.5">Brand</h3>
                <p  class="bg-white  p-2.5">{{product?.brand!=null?product?.brand.name:''}}</p>
              </div>


            </div>
            <div class="flex gap-3  pb-1">
              <div class="flex-col gap-3 w-full">
                <h3 class="p-2.5">Category</h3>
                <div class="bg-white flex p-1.5">
                  <p *ngFor="let category of product?.category" class="p-1 rounded bg-sky-50 m-0.5"> {{ category.name }} </p>
                </div>
              </div>


            </div>


          <div  *ngFor="let collection of groupedMediaArray" class="my-4">
            <h3 class=" p-2.5">Collection name : {{ collection.collection_name }}</h3>
            <div class="flex gap-3 flex-col bg-white p-8"
                 *ngFor="let med of collection?.media">
              <video width="640" height="360" *ngIf="med?.['data']?.[0]?.type == 'video'">
                <source [src]="med?.['thumbnail']" type="video/mp4">
                <source [src]="med?.['thumbnail']" type="video/webm">
                <source [src]="med?.['thumbnail']" type="video/ogg">
                <source [src]="med?.['thumbnail']" type="video/mov">
                Your browser does not support the video tag.
              </video>
              <img class="w-[200px] border-2" [src]="med?.['thumbnail']" *ngIf="med?.['data']?.[0]?.type != 'video'"/>
              <div class="flex gap-3">
              </div>
            </div>
          </div>

        </div>
          <button *ngIf="product.status != SellStatusEnum.Accepted" (click)="accept()"  color="primary" mat-flat-button class="w-1/4 self-center">
            Accept
          </button>
        </div>
      </div>

    </mat-dialog-content>


  </div>
</div>
