export class BookingTourModel {
  id: number;
  product_id: string;
  user_id: string;
  previous_machine_test_tour_id: string;
  status: string;
  full_name: string;
  email: string;
  passport_number: string;
  type: string;
  reminded_at: string;
  timezone: string;
  invitee_url: string;
  event_url: string;
  reschedule_url: string;
  cancel_url: string;
  rescheduled: string;
  reason: string;
  scheduled_at:string;
  url:string;
  duration_by_minutes:string;
  end_time:string;




    constructor(obj) {
        this.id = obj.id;
        this.product_id = obj.product_id;
        this.user_id = obj.user_id;
        this.previous_machine_test_tour_id = obj.previous_machine_test_tour_id;
        this.status = obj.status;
        this.full_name = obj.full_name;
        this.email = obj.email;
        this.passport_number = obj.passport_number;
        this.type = obj.type;
        this.reminded_at = obj.reminded_at;
        this.timezone = obj.timezone;
        this.invitee_url = obj.invitee_url;
        this.event_url = obj.event_url;
        this.reschedule_url = obj.reschedule_url;
        this.cancel_url = obj.cancel_url;
        this.rescheduled = obj.rescheduled;
        this.reason = obj.reason;
        this.scheduled_at=obj.scheduled_at;
        this.url=obj.url;
        this.duration_by_minutes=obj.duration_by_minutes;
        this.end_time=obj.end_time;

    }
}
