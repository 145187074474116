import {Component, Input} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {fadeInUp400ms} from '../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../@vex/animations/stagger.animation';
import {NgClass} from '@angular/common';

@Component({
  selector: 'vex-loading-row',
  templateUrl: './loading-row.component.html',
  styleUrls: ['./loading-row.component.scss'],
  imports: [
    MatProgressSpinnerModule,
    NgClass
  ],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ]
})
export class LoadingRowComponent {

  @Input() diameter:number;
}
