import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private http:HttpClient) { }

  get(){
    return this.http.get(environment.baseUrl + 'machine-test-tour');
  }
  accept(id){
    return this.http.get(environment.baseUrl + 'machine-test-tour/accept/'+id);
  }
  reject(id){
    return this.http.get(environment.baseUrl + 'machine-test-tour/reject/'+id);
  }
}
