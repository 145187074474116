<div class="flex flex-col gap-3 bg-blue-50 px-3 py-3 rounded-lg" *ngIf="model">
    <div class="flex gap-3 w-full ">
        <img src="{{this.model?.product?.cover?.url}}" class="max-w-[100px] h-full mb-1">
        <div class=" flex flex-col gap-1 w-full">
            <span class="font-bold ">{{this.model?.product?.title}}</span>
            <p>Lot : {{this.model?.product?.listing?.lot_identifier}}</p>
            <p>Bids Count : {{this.model?.product?.listing?.bids_count}}</p>
            <p>Current Price : {{this.model?.product?.listing?.current_price?.amount}} {{this.model?.product?.listing?.current_price?.currency}}</p>

        </div>
    </div>
    <div class="flex flex-wrap" *ngIf="model?.bids.length!=0">
        <h2> Latest Bider : <span class="font-semibold">{{model?.bids?.[0].bidder?.name}} </span> with
            bid
            amount <span
                    class="font-semibold"> {{model?.bids?.[0].amount?.amount}}  {{model?.bids?.[0].amount?.currency}}</span>
        </h2>
    </div>

    <div class="flex justify-content-between gap-1 flex-wrap w-full ">
        <vex-add-bids class="w-full" [current]="model?.product?.listing?.current_price?.amount"
                      [listing_id]="this.model?.product?.listing?.id"
                      *ngIf="this.model?.product?.listing?.listing_status=='ONGOING'"></vex-add-bids>

        <div class="flex   flex-wrap gap-1 w-full"
             *ngIf="model?.['product']?.['listing']?.['listing_status']== AuctionStatus.ONGOING">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold px-3 h-10 rounded-lg mx-0.5 w-full"
                    *ngIf="model?.can_be_sold"
                    (click)="changeListingStatus('SOLD')">
                <span *ngIf="!loading">Sold</span>
                <mat-progress-spinner *ngIf="loading" diameter="30" mode="indeterminate"></mat-progress-spinner>
            </button>
            <button class="bg-red-500 hover:bg-red-700 text-white font-bold px-3 h-10 rounded-lg mx-0.5 w-full"
                    *ngIf="model?.can_be_ended"
                    (click)="changeListingStatus('ENDED')">
                <span *ngIf="!loading">End</span>
                <mat-progress-spinner *ngIf="loading" diameter="30" mode="indeterminate"></mat-progress-spinner>
            </button>
        </div>
    </div>

</div>
