import {Component, Inject, OnInit} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  FormsModule
} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {SelectFilterPipe} from '../../../../../core/pipes/select-filter.pipe';
import {ToastrService} from 'ngx-toastr';
import {ProductService} from '../service/product.service';
import {MediaTypeEnum} from '../../../../../core/enum/media-type.enum';
import {MethodsUtils} from '../../../../../core/utils/method-utils';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {ActivatedRoute} from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SpecificationTypeEnum} from '../../../../../core/enum/specification-type.enum';
import {QuillEditorComponent} from 'ngx-quill';
import {ReviewsService} from '../../expert-reviews/service/reviews.service';
import {ResearchService} from "../../research/service/research.service";

@Component({
  selector: 'vex-collection-media-create-update',
  templateUrl: './create-media.component.html',
  styleUrls: ['./create-media.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatListModule,
    MatIconModule,
    CommonModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    SelectFilterPipe,
    MatProgressSpinnerModule,
    QuillEditorComponent
  ]
})
export class CreateMediaComponent implements OnInit {


  form: UntypedFormGroup;
  mode: 'create' | 'update' = 'create';
  selectedFiles: File[] = [];
  files = [];
  types = [];
  dynamicForm: FormGroup;
  service;
  productID;
  type;
  loading = false;
  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private dialogRef: MatDialogRef<CreateMediaComponent>,
              private serviceProduct: ProductService,
              private serviceExpert: ResearchService,
              private route: ActivatedRoute,
              private methods: MethodsUtils,
              private fb: UntypedFormBuilder, private toastr: ToastrService) {
    this.types = this.methods.enumToArray(MediaTypeEnum);

  }

  ngOnInit() {
    this.productID = this.defaults.id;
    this.type = this.defaults.type;
    this.mode = this.defaults.mode;
    this.form = this.fb.group({
      type: ['image'],
      name: [this.defaults?.name || '', Validators.required]

    });
  }

  fileArray: { name: string, dataURL: string }[] = [];

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
    this.uploadFiles();
    const files = (event.target as HTMLInputElement).files; // Get all selected files
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileArray.push({
            name: file.name,
            dataURL: e.target.result as string
          });
        };
        reader.readAsDataURL(file);

      }
    }
  }

  uploadFiles() {
    this.files = [];
    const formGroup = {};
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      if (file) {
        this.files.push(file);
        formGroup['alt_' + i] = this.fb.control(this.selectedFiles[i].name.substring(0, this.selectedFiles[i].name.lastIndexOf('.')) || '');
        formGroup['description_' + i] = this.fb.control('');

      }
    }
    this.dynamicForm = this.fb.group(formGroup);
  }


  save() {
    this.loading=true;
    if(this.type=='EXPERT'){
      this.service = this.serviceExpert;
    }else{
      this.service = this.serviceProduct;
    }
    if(this.productID!=null) {
      let product = new FormData();
      product.append('media[0][collection_name]', this.form.value.name);
      product.append('media[0][type]', this.form.value.type);
      if (this.files.length > 0) {
        for (let j = 0; j < this.files.length; j++) {
          product.append(`media[0][files][${j}][file]`, this.files[j]);

          if(this.dynamicForm.value['description_'+ j]) {
            product.append(`media[0][files][${j}][description]`, this.dynamicForm.value?.['description_' + j]);
          } }
      }


      this.service.addMedia(product, this.productID).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Media Created Successfully!');
          this.dialogRef.close(true);
          this.loading= false;
        }
      }, error => {
        this.toastr.error(error);
        this.dialogRef.close(true);
        this.loading= false;

      });

    }
    else {
      let product = {
        'media[0][collection_name]': this.form.value.name,
        'media[0][type]': this.form.value.type,
        'count':this.files.length
      };

      if (this.files.length > 0) {
        for (let j = 0; j < this.files.length; j++) {
          product[`media[0][files][${j}][file]`] = this.files[j];
          product[`media[0][files][${j}][description]`] = this.dynamicForm.value?.['description_' + j];
        }
      }
      this.dialogRef.close(product);
    }

  }



  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }


  protected readonly MediaTypeEnum = MediaTypeEnum;
  protected readonly SpecificationTypeEnum = SpecificationTypeEnum;
}

