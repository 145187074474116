import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vex-page-layout-demo',
  templateUrl: './page-layout-demo.component.html',
  styleUrls: ['./page-layout-demo.component.scss']
})
export class PageLayoutDemoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
