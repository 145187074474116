import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private http:HttpClient) {

  }

  get(){
    return this.http.get(environment.baseUrl + 'business-setting/business-setting');
  }
  create(data){
    return this.http.post(environment.baseUrl + 'business-setting/business-setting',data);
  }
  update(data){
    return this.http.post(environment.baseUrl + 'business-setting/business-setting/'+data.get('id'),data);
  }
  delete(id:number){
    return this.http.get(environment.baseUrl + 'business-setting/business-setting/'+id);
  }
}
