<div class="flex container flex-col my-5 gap-3 mx-5 ">

    <form (ngSubmit)="save()" [formGroup]="form"  class="container rounded-lg shadow bg-white  p-5 flex flex-col gap-5 mt-12">
        <div class="flex items-center" mat-dialog-title>

            <button class="text-secondary" mat-icon-button type="button" (click)="onCancel()">
                <mat-icon svgIcon="mat:arrow_back"></mat-icon>
            </button>
            <h2 *ngIf="form.get('title')?.value || form.get('title')?.value"
                class="headline m-0 flex-auto">{{ form.get('title')?.value }}</h2>
            <h2 *ngIf="!form.get('title')?.value && !form.get('title')?.value"
                class="headline m-0 flex-auto">New News</h2>
            <mat-dialog-actions align="end">
                <button mat-button type="button" (click)="onCancel()">Cancel</button>
                <button *ngIf="isCreateMode()" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                    <span *ngIf="!loading">  Create </span>
                    <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>
                </button>
                <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">
                    <span *ngIf="!loading"> Update </span>
                    <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>
                </button>
            </mat-dialog-actions>
        </div>

        <mat-divider class="text-border"></mat-divider>
      <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab  *ngFor="let lang of locale" [label]="lang.locale | uppercase">

            <div class="grid md:grid-cols-2 grid-cols-1  gap-5 w-full justify-center">
              <div class="flex flex-col   ">

                <div class="flex gap-3 items-center">
                    <mat-form-field class="flex-auto">
                        <mat-label>Title ( {{lang.locale | uppercase }} )</mat-label>
                        <input cdkFocusInitial formControlName="{{lang.locale}}_title" matInput>
                    </mat-form-field>

                </div>
                <div class="flex gap-3 items-center">
                <mat-form-field class="flex-auto">
                        <mat-label>Reading Duration</mat-label>
                        <input type="number" cdkFocusInitial formControlName="reading_duration" matInput>
                    </mat-form-field>
                    <mat-form-field class="flex-auto">
                        <mat-label>News Category </mat-label>
                        <select matNativeControl  formControlName="news_category_id">
                            <option *ngFor="let item of categories" [ngValue]="item.id" >{{item['name']}}</option>
                        </select>
                    </mat-form-field>
                </div>
                <mat-form-field class="flex-auto">
                  <mat-label>Tags</mat-label>
                  <mat-select matNativeControl multiple  formControlName="tag">
                    <mat-option *ngFor="let item of tags" [value]="item['id']">{{item['name']}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="flex gap-3 items-center mt-3">
                    <mat-form-field class="flex-auto">
                        <mat-label>Description ( {{lang.locale | uppercase }} )</mat-label>
                        <input cdkFocusInitial formControlName="{{lang.locale}}_description" matInput>
                    </mat-form-field>

                </div>
                <mat-form-field class="flex-auto">
                  <mat-label>Related with </mat-label>
                  <mat-select matNativeControl multiple  formControlName="related_news">
                    <mat-option *ngFor="let item of news" [value]="item['id'].toString()">{{item['title']}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-slide-toggle formControlName="show_home">Show in home</mat-slide-toggle>

              </div>
              <div class="flex flex-col  items-center justify-center">
                <img class='image mb-4 max-w-xs' [src]="selectedFile" (error)="setDefaultImage($event)" >

                <div class="file-input-wrapper mb-4 flex-col">
                  <input type="file" id="imageInput"#filePicker (change)="onImagePicked($event)" />
                  <label for="imageInput" class="file-input-label">Select Image</label>
                </div>
              </div>
            </div>

    <div class="card">
      <h3 class="mb-2 pt-2 pl-2">Content ( {{lang.locale | uppercase }} )</h3>
      <app-tiny-editor (fieldVal)="setEditorControl($event,lang.locale , 'content')" field="content" [lang]="lang.locale"
                       [values]="transValues" [editorLoading]="loadingForm"></app-tiny-editor>
    </div>
        </mat-tab>
      </mat-tab-group>
    </form>
</div>
