import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {Brand} from '../../../models/brand.model';

@Injectable({
  providedIn: 'root'
})
export class AuctionService {

  constructor(private http:HttpClient) {

  }
  reset(){
    return this.http.get(environment.baseUrl + 'reset-auction');
  }
  all(){
    return this.http.get(environment.baseUrl + 'auction/auctions');
  }
  get(id){
    return this.http.get(environment.baseUrl + 'auction/auctions/'+id);
  }
  save(auction:FormData){
    return this.http.post(environment.baseUrl + 'auction/auctions',auction);
  }

  delete(id:number){
    return this.http.delete(environment.baseUrl + 'auction/auctions/'+id);
  }
  getRings(id:number){
    return this.http.get(environment.baseUrl + 'auction/rings?auction_id='+ id);
  }
  getRingsAuctoClosing(id:number){
    return this.http.get(environment.baseUrl + 'auction/rings/'+ id+'/auto-closing');
  }
  saveRingsAuctoClosing(id:number,data){
    return this.http.post(environment.baseUrl + 'auction/rings/'+ id+'/set-auto-closing',data);
  }

  saveRing(ring:FormData){
    return this.http.post(environment.baseUrl + 'auction/rings',ring);
  }
  deleteRing(id:number){
    return this.http.delete(environment.baseUrl + 'auction/rings/'+id);
  }

  getItems(id){
    return this.http.get(environment.baseUrl + 'auction/listings?ring_id='+ id);
  }
  // @ts-ignore
  saveItems(item:FormData){
    return this.http.post(environment.baseUrl + 'auction/listings',item);
  }
  changeItemsStatus(item){
    return this.http.post(environment.baseUrl + 'auction/listings/update-status',item);
  }
  // @ts-ignore

  deleteItems(id:number){
    return this.http.delete(environment.baseUrl + 'auction/listings/'+id);
  }
  addBid(data){
    return this.http.post(environment.baseUrl + 'auction/bids/save',data);
  }
}
