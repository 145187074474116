// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl:'https://development.makana.com/api/v1/admin/',
  generalUrl:'https://development.makana.com/api/',
  firebase: {
    apiKey: 'AIzaSyCvEvi_R-JqRwCfOFmqp7VJqH2X2F7odSo',
    authDomain: 'makana-dahs.firebaseapp.com',
    projectId: 'makana-dahs',
    storageBucket: 'makana-dahs.appspot.com',
    messagingSenderId: '849576753547',
    appId: '1:849576753547:web:9f261f1dafb4da3dd5d78e'
  },
  pusherKey : '517098c62fbb357cbaf0',
  awsBucket:'makana-web-assets-bucket',
  awsAccessKeyId:'AKIAUEZ5M56OQVFLMN2D',
  awsSecretAccessKey:'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'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
