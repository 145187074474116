import {Injectable} from '@angular/core';
import {
   CanActivate, CanActivateChild,
  Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../pages/pages/auth/service/auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {
  }

  CanLoad() {
  }

  canActivate():
    boolean | Observable<boolean> | Promise<boolean> {
    if (this.authService.isLoggedIn()) {
      return true;
    }
    // Redirect to login
    this.router.navigate(['login']).then();
    return false;
  }

  canActivateChild():
    boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate();
  }
}
