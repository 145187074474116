
export class MediaObjectModel {
  id: number;
  model_type: string;
  model_id: number;
  uuid: string;
  collection_name: string;
  name: string;
  file_name: string;
  mime_type: string;
  disk: string;
  conversions_disk: string;
  size: number;
  manipulations: any[]; // You may want to specify the correct type for manipulations
  custom_properties: {
    alt: string;
  };
  generated_conversions: {
    webp: boolean;
  };
  responsive_images: any[]; // You may want to specify the correct type for responsive_images
  order_column: number;
  created_at: string;
  updated_at: string;
  original_url: string;
  preview_url: string;

  constructor(obj: any) {
    this.id = obj.id;
    this.model_type = obj.model_type;
    this.model_id = obj.model_id;
    this.uuid = obj.uuid;
    this.collection_name = obj.collection_name;
    this.name = obj.name;
    this.file_name = obj.file_name;
    this.mime_type = obj.mime_type;
    this.disk = obj.disk;
    this.conversions_disk = obj.conversions_disk;
    this.size = obj.size;
    this.manipulations = obj.manipulations || [];
    this.custom_properties = obj.custom_properties || { alt: '' };
    this.generated_conversions = obj.generated_conversions || { webp: false };
    this.responsive_images = obj.responsive_images || [];
    this.order_column = obj.order_column;
    this.created_at = obj.created_at;
    this.updated_at = obj.updated_at;
    this.original_url = obj.original_url;
    this.preview_url = obj.preview_url || '';
  }
}
