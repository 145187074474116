
import {Component, Inject, OnInit} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
    FormsModule
} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {SelectFilterPipe} from '../../../../../core/pipes/select-filter.pipe';
import {ToastrService} from 'ngx-toastr';
import {MediaTypeEnum} from '../../../../../core/enum/media-type.enum';
import {MethodsUtils} from '../../../../../core/utils/method-utils';
import {ActivatedRoute} from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {InspectionReportService} from '../service/inspection-report.service';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';

@Component({
    selector: 'app-inspection-report-create',
    templateUrl: './inspection-report-create.component.html',
    styleUrls: ['./inspection-report-create.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatInputModule,
        MatDialogModule,
        MatListModule,
        MatIconModule,
        CommonModule,
        MatOptionModule,
        MatSelectModule,
        MatButtonModule,
        FormsModule,
        SelectFilterPipe,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule

    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            } as MatFormFieldDefaultOptions
        }
    ]
})
export class InspectionReportCreateComponent implements OnInit {
    form: UntypedFormGroup;
    mode: 'create' | 'update' = 'create';
    files = [];
    types = [];
    productID;
    loading = false;
    constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
                private dialogRef: MatDialogRef<InspectionReportCreateComponent>,
                private service: InspectionReportService, private route: ActivatedRoute,
                private methods: MethodsUtils,
                private fb: UntypedFormBuilder, private toastr: ToastrService) {
        this.types = this.methods.enumToArray(MediaTypeEnum);
    }

    ngOnInit() {
        this.productID = this.defaults.productID;

        // this.mode = this.productID ? 'update' : 'create';
        this.form = this.fb.group({
            inspection_date: [this.defaults?.inspection_date || '', Validators.required]
        });
    }

    save() {
        this.loading=true;
        if(this.mode=='update') {

        }
        else {
            const report = new FormData();
            let inspection_date = new Date(this.form.value.inspection_date);
            let formattedDate = this.methods.getYMDformat(inspection_date.toDateString());
            report.append('inspection_date', formattedDate|| '');
            this.service.create(report , this.productID).subscribe((res) => {
                if (res['status'] == ErrorStatusModel.SUCCESS || ErrorStatusModel.SUCCESSCREATE) {
                    this.toastr.success('Inspection Report Created Successfully!');
                    this.dialogRef.close(report);
                }

            }, error => {
                this.loading = false;
                this.toastr.error(error);
                this.dialogRef.close(false);

            });

        }

    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }
}

