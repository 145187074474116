<form (ngSubmit)="save()" [formGroup]="form">
    <div class="flex items-center" mat-dialog-title>
        <h2 class="headline m-0 flex-auto">New Ring</h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <mat-dialog-content class="flex flex-col">
        <div class="flex flex-col sm:flex-col">
            <mat-form-field class="flex-auto">
                <mat-label>Ring name</mat-label>
                <input formControlName="name" matInput>
            </mat-form-field>
          <mat-form-field class="flex-auto">
            <mat-label>Auto Closing Seconds</mat-label>
            <input formControlName="auto_closing_seconds" matInput>
          </mat-form-field>
          <mat-slide-toggle formControlName="has_auto_closing">Auto Closing</mat-slide-toggle>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">Cancel</button>
        <button  color="primary" mat-flat-button type="submit" [disabled]="form.invalid" class="flex items-center justify-center">
            <span  *ngIf="!loading"> Save Ring</span>
            <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>

        </button>
    </mat-dialog-actions>
</form>


