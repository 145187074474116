import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {FinanceService} from '../service/finance.service';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {FinanceModel} from '../../../models/financy.model';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgForOf, NgIf} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {
  FieldDetailsCardComponent
} from '../../../../../core/components/field-details-card/field-details-card.component';
import {ToastrService} from 'ngx-toastr';
import {LoadingRowComponent} from '../../../../../core/components/loading-row/loading-row.component';
import {SvgRendererComponent} from '../../../../../core/components/svg-icon/svg-icon.component';


@Component({
  selector: 'vex-finance-details',
  templateUrl: './finance-details.component.html',
  standalone: true,
    imports: [
        MatIconModule,
        MatFormFieldModule,
        NgForOf,
        MatProgressSpinnerModule,
        NgIf,
        MatButtonModule,
        FieldDetailsCardComponent,
        LoadingRowComponent,
        SvgRendererComponent
    ],
  styleUrls: ['./finance-details.component.scss']
})
export class FinanceDetailsComponent implements OnInit{

  financeID=null;
  finance:FinanceModel;
  loading:boolean=true;
  constructor(
    private activeRoute: ActivatedRoute,
    private router:Router,
    private service : FinanceService,
    private toastr:ToastrService
  ) {
  }
  ngOnInit(): void {
    this.financeID = this.activeRoute.snapshot.paramMap.get('id');

    this.service.get(this.financeID).subscribe(
      (res)=>{
        if(res['status']==ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE){
            this.finance=res['data'];
            this.loading=false;
        }error=>{
          // @ts-ignore
          this.toastr.error(error['message']);
        };
      }
    );
    // this.loading=false;
  }
  onCancel(){
    this.router.navigate(['/finance']);
  }
  accept(){
    const formData =new FormData();
    // @ts-ignore
    formData.append('id',this.finance?.id);
    formData.append('status','APPROVED');
    this.service.changeStatus(formData).subscribe(
      (res)=>{
        if(res['status']==ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE){
          this.toastr.success('Updated Successfully');
          this.ngOnInit();
        }error=>{
          // @ts-ignore
          this.toastr.error(error['message']);
        };
      }
    );
  }
  reject(){
    const formData =new FormData();
    // @ts-ignore
    formData.append('id',this.finance?.id);
    formData.append('status','DECLINED');
    this.service.changeStatus(formData).subscribe(
      (res)=>{
        if(res['status']==ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE){
          this.toastr.success('Updated Successfully');
          this.ngOnInit();
        }error=>{
          // @ts-ignore
          this.toastr.error(error['message']);
        };
      }
    );
  }
  protected readonly console = console;
}
