export class FaqModel {
  id: number;
  question: string;
  answer: string;
  faq_group_id : number;
  order : number;
  group_name : string;

  translations :[
    {
      locale:string;
      question:string;
      answer:string;
    }
  ];


  constructor(app) {
    this.id = app.id;
    this.question = app.question;
    this.answer = app.answer;
    this.faq_group_id = app.faq_group_id;
    this.order = app.order;
    this.group_name = app.faq_group.name;
    this.translations = app.translations;

  }
}



