<table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
  <tr>
    <th scope="col" class="px-6 py-3">
      Name
    </th>
    <th scope="col" class="px-6 py-3">
      Bid
    </th>
    <th scope="col" class="px-6 py-3">
      Type
    </th>
    <th scope="col" class="px-6 py-3">
      Date
    </th>
  </tr>
  </thead>
  <tbody>
  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" *ngFor="let bid of bids">
    <th scope="row" class="px-6 py-4 font-medium ">
      {{bid?.bidder?.name}}
    </th>
    <td class="px-6 py-4">
      {{bid?.amount?.amount}} {{bid?.amount?.currency}}
    </td>
    <td class="px-6 py-4">
      {{bid?.type}}
    </td>
    <td class="px-6 py-4">
      {{bid?.datetime | date:'EEE MMM dd yyyy HH:mm:ss (z)'}}
    </td>

  </tr>


  </tbody>
</table>

