import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UnitTypeService {

  constructor(private http:HttpClient) { }

  get(){
    return this.http.get(environment.baseUrl + 'unit/unit-types');
  }

  create(unit:any){
    return this.http.post(environment.baseUrl + 'unit/unit-types',unit);
  }
  delete(id){
    return this.http.delete(environment.baseUrl + 'unit/unit-types/'+id);
  }

}
