export class ZoneModel {
  id: number;
  name: string;
  status: number;
  point: any;
  weight_classes: any;
  translations:[
    {
      locale:string;
      name:string;
    }
  ];



    constructor(obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.status = obj.status;
        this.point = obj.point;
        this.weight_classes = obj.weight_classes;
        this.translations = obj.translations;

    }
}
