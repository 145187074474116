import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {DatePipe, JsonPipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {LiveShowChatComponent} from '../live-show-chat/live-show-chat.component';
import {LoadingRowComponent} from '../../../../../../core/components/loading-row/loading-row.component';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {OngoingDetailsComponent} from '../ongoing-details/ongoing-details.component';

@Component({
  selector: 'vex-bids-history',
  templateUrl: './bids-history.component.html',
  styleUrls: ['./bids-history.component.scss'],
  imports: [
    JsonPipe,
    CdkFixedSizeVirtualScroll,
    CdkVirtualScrollViewport,
    LiveShowChatComponent,
    LoadingRowComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    NgForOf,
    NgIf,
    OngoingDetailsComponent,
    NgClass,
    BidsHistoryComponent,
    DatePipe
  ],
  standalone: true
})
export class BidsHistoryComponent implements OnInit{

  @Input() bids;
  current;
  constructor() {
  }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {

    if(changes.bids){
      this.current = this.bids;
    }
  }
}
