
   <div class="card container pt-3">
     <div class="flex items-center  " mat-dialog-title>

       <button class="text-secondary" mat-icon-button type="button" (click)="onCancel()">
         <mat-icon svgIcon="mat:arrow_back"></mat-icon>
       </button>
       <h2 *ngIf="form.get('en_name')?.value || form.get('en_name')?.value"
           class="headline m-0 flex-auto">{{ form.get('en_name')?.value }}</h2>
       <h2 *ngIf="!form.get('en_name')?.value && !form.get('en_name')?.value"
           class="headline m-0 flex-auto">New Auction</h2>

     </div>
     <div class=" p-6 h-fit border-b sticky left-0 flex items-center">
       <form [formGroup]="form" class="w-full">
         <mat-tab-group (selectedTabChange)="onTabChanged($event)">
           <mat-tab *ngFor="let lang of locale" [label]="lang.locale | uppercase">
         <div class="flex gap-5 w-full">

           <div class="flex flex-col  w-full h-fit">

             <div class="flex justify-content-between gap-2 items-center w-full">

                   <div class="py-6">
                     <mat-form-field class="w-full">
                       <mat-label> Name ( {{lang.locale | uppercase }} )</mat-label>
                       <input cdkFocusInitial formControlName="{{lang.locale}}_name"
                              matInput>
                     </mat-form-field>
                     <mat-form-field class="w-full">
                       <mat-label> Description ( {{lang.locale | uppercase }})
                       </mat-label>
                       <textarea cdkFocusInitial
                                 formControlName="{{lang.locale}}_description"
                                 matInput></textarea>
                     </mat-form-field>
                     <mat-form-field class="w-full">
                       <mat-label> Location ( {{lang.locale | uppercase }})
                       </mat-label>
                       <input cdkFocusInitial
                              formControlName="{{lang.locale}}_location" matInput>
                     </mat-form-field>
                   </div>


               <!--                    <mat-form-field class="w-full">-->
               <!--                      <mat-label> Name</mat-label>-->
               <!--                      <input cdkFocusInitial formControlName="name" matInput>-->
               <!--                    </mat-form-field>-->
             </div>
             <div class="flex justify-content-between gap-2 items-center w-full">
               <mat-form-field class="flex-auto">
                 <mat-label>Type</mat-label>
                 <select matNativeControl required formControlName="type">
                   <option *ngFor="let item of types"
                           [ngValue]="item.id">{{item['name']}}</option>
                 </select>
               </mat-form-field>

             </div>
             <div class="flex justify-content-between gap-3 items-center w-full">
               <mat-form-field>
                 <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date"
                        formControlName="start_datetime"
                        [min]="minDate"
                        [max]="maxDate"
                        [disabled]="disabled">
                 <ngx-mat-datepicker-toggle matSuffix
                                            [for]="picker"></ngx-mat-datepicker-toggle>
                 <ngx-mat-datetime-picker [color]="color" #picker [showSpinners]="false"
                 >
                 </ngx-mat-datetime-picker>
               </mat-form-field>
               <mat-form-field>
                 <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Choose a date"
                        formControlName="end_datetime"
                        [min]="minDate"
                        [max]="maxDate"
                        [disabled]="disabled">
                 <ngx-mat-datepicker-toggle matSuffix
                                            [for]="picker2"></ngx-mat-datepicker-toggle>
                 <ngx-mat-datetime-picker [color]="color" #picker2 [showSpinners]="false">
                 </ngx-mat-datetime-picker>
               </mat-form-field>
             </div>
             <div class="flex justify-content-between  items-center w-full gap-5" *ngIf="form.value.type=='TIMED'">
               <mat-form-field class="flex-auto">
                 <mat-label>Sniper Protection</mat-label>
                 <input cdkFocusInitial formControlName="sniper_protection_seconds"
                        type="number" matInput>
                 <span class="text-xs" matSuffix>Minutes</span>
               </mat-form-field>
               <mat-form-field class="flex-auto">
                 <mat-label>Bid Extension Time</mat-label>
                 <input cdkFocusInitial formControlName="bid_extension_time" type="number"
                        matInput>
                 <span class="text-xs" matSuffix>Minutes</span>
               </mat-form-field>
             </div>
           </div>
           <div class="flex flex-col  w-full h-fit justify-center items-center">

             <img class='image mb-4 w-[300px] h-[300px] justify-center items-center'
                  [src]="selectedFile" (error)="setDefaultImage($event)">
             <div class="file-input-wrapper mb-4">
               <input type="file" id="imageInput" #filePicker
                      (change)="onImagePicked($event)"/>
               <label for="imageInput" class="file-input-label">Select Image</label>
             </div>


           </div>
         </div>
             <div class="card">
               <h3 class="mb-2 pt-2 pl-2">Details ( {{lang.locale | uppercase }} )</h3>
               <app-tiny-editor (fieldVal)="setEditorControl($event,lang.locale , 'details')" field="details" [lang]="lang.locale"
                                [values]="transValues" [editorLoading]="loadingForm"></app-tiny-editor>
             </div>
         </mat-tab>
         </mat-tab-group>
         <div class="flex gap-3 w-full items-center justify-end ">
           <button (click)="onCancel()"
                   class="  h-fit  flex items-center justify-center bg-black text-white px-3 py-1 rounded-sm w-fit">
             Cancel
           </button>
           <button type="submit" (click)="save()" [disabled]="form.invalid"
                   class="  h-fit  flex items-center justify-center bg-[#d7e512] text-black px-3 py-1 rounded-sm w-fit">
             <span *ngIf="!loading && isCreateMode() "> Add  </span>
             <span *ngIf="!loading && !isCreateMode() "> Update  </span>
             <mat-progress-spinner *ngIf="loading" diameter="30"
                                   mode="indeterminate"></mat-progress-spinner>
           </button>
         </div>
       </form>


     </div>
   </div>
