import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup, Validators
} from '@angular/forms';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LoadingRowComponent} from '../../../../../core/components/loading-row/loading-row.component';
import {MatTabsModule} from '@angular/material/tabs';
import {PageLayoutDemoModule} from '../../../../ui/page-layouts/page-layout-demo/page-layout-demo.module';
import {ActivatedRoute, Router, RouterLinkActive} from '@angular/router';
import {MatRippleModule} from '@angular/material/core';
import {filter, Observable, of, ReplaySubject} from 'rxjs';
import {TableColumn} from '../../../../../../@vex/interfaces/table-column.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageRefService} from '../../../../pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../../../../../core/enum/storage-keys.enum';
import {untilDestroyed} from '@ngneat/until-destroy';
import {AttachmentModel} from '../../../models/attachment.model';
import {ResearchService} from "../service/research.service";
import {ErrorStatusModel} from "../../../../../core/enum/error-status.model";

@Component({
  selector: 'vex-generation-attachment',
  templateUrl: './generation-attachment.component.html',
  styleUrls: ['./generation-attachment.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    PageLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    NgClass,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbsModule,
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    LoadingRowComponent,
    MatTabsModule,
    PageLayoutDemoModule,
    RouterLinkActive,
    MatRippleModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class GenerationAttachmentComponent implements OnInit, AfterViewInit {

  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<AttachmentModel[]> = new ReplaySubject<AttachmentModel[]>(1);
  data$: Observable<AttachmentModel[]> = this.subject$.asObservable();
  customers: AttachmentModel[];

  @Input()
  columns: TableColumn[] = [
    {label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true},
    {label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Title', property: 'title', type: 'text', visible: true},
    {label: 'Actions', property: 'actions', type: 'button', visible: true}

  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<AttachmentModel> | null;
  selection = new SelectionModel<AttachmentModel>(true, []);
  searchCtrl = new UntypedFormControl();
  form: UntypedFormGroup;
  isPanelOpen = false;
  loading = false;
  getLoading = true;
  defaults ?: AttachmentModel;
  mode: 'create' | 'update' = 'create';
  selectedFile;
  locale;
  defLocale;
  generationId;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private service: ResearchService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private router : Router,
    private route : ActivatedRoute,
    private localStorageRefService : LocalStorageRefService
  ) {

  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }


  getData() {
    this.generationId = this.route.snapshot.paramMap.get('id');
    this.service.getAttachment(this.generationId).subscribe((res) => {

      of(res['data'].map(brand => new AttachmentModel(brand))).subscribe(brands => {
        this.subject$.next(brands);
        this.getLoading=false;
      });

    });
  }

  ngOnInit() {
    this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
    this.defLocale = this.locale.filter(loc=>loc.default==true)[0];
    this.getData();
    this.clearForm();
    this.dataSource = new MatTableDataSource();
    this.data$.pipe(
      filter<AttachmentModel[]>(Boolean)
    ).subscribe(customers => {
      this.customers = customers;
      this.dataSource.data = this.customers;
    });

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  createBrand() {
    this.clearForm();
    this.isPanelOpen = true;
    this.scrollToForm();
  }
  initForm(app:AttachmentModel){
    this.selectedFile=null;
    this.isPanelOpen=true;
    this.mode='update';
    this.form = this.fb.group({
      id: [app?.id || null],
      file: [null]
    });
    this.locale.forEach(lang => {
      const value = app.translations.filter(brand => brand.locale == lang.locale);
      this.form.addControl(lang.locale+'_title', this.fb.control(value[0]?.title??'',this.defLocale.locale==lang.locale?[Validators.required]:[]));
    });
  }

  clearForm(){
    this.selectedFile=null;
    this.isPanelOpen = false;
    this.mode='create';
    this.form = this.fb.group({
      id: [ null],
      file: [null,[Validators.required]]
    });
    this.locale.forEach(lang => {
      this.form.addControl(lang.locale+'_title', this.fb.control('',this.defLocale.locale==lang.locale?[Validators.required]:[]));
    });
  }
  updateBrand(brand: AttachmentModel) {
    this.initForm(brand);
    this.scrollToForm();
  }
  isCreateMode() {
    return this.mode === 'create';
  }
  scrollToForm() {
    const formElement = this.elementRef.nativeElement.querySelector('#form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  deleteBrand(brand: AttachmentModel) {
    this.service.deleteAttachment(brand.id).subscribe((res) => {
      if(res['status'] == ErrorStatusModel.SUCCESS) {
        this.toastr.success('Attachment Deleted Successfully!');
        this.ngOnInit();
        this.selection.deselect(brand);
      }
    },error =>
    {
      this.toastr.error(error['message']);

    });
  }

  deleteBrands(brands: AttachmentModel[]) {
    brands.forEach(c => this.deleteBrand(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }

  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }
  save(){
    this.loading=true;
    const formData=new FormData();
    if(this.mode === 'update') {
      formData.append('id', this.form.value?.id);
    }
    formData.append('generation_id', this.generationId);
    formData.append('title', this.form.value.en_title);
    if (this.form.value.file instanceof File)
      formData.append('document', this.form.value.file);

    this.locale.filter(lang => lang.default==0 ).forEach(lang =>{

      if(this.form.value[lang?.locale + '_title']!='')
        formData.append(lang?.locale +'[title]',this.form.value[lang?.locale + '_title']);
    });
    if (formData) {
      this.service.saveAttachment(formData).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Attachment '+this.mode+'d Successfully!');
          this.loading=false;
          this.ngOnInit();

        }
      }, error => {
        this.loading=false;
        this.toastr.error(error);
      });
    }
}
  activeTab = 'en';
  setActiveTab(tabNumber: string) {
    this.activeTab = tabNumber;
    this.cdr.detectChanges();
  }
  back(){
    this.router.navigate(['/research']);
  }
  onFileSelected(event: Event): void {
    // @ts-ignore
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      this.form.patchValue({file:input.files[0]});
      this.selectedFile = input.files[0];

    }
  }
}
