import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LocalStorageRefService} from '../../pages/pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../enum/storage-keys.enum';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionsLoadingService {

  constructor(private http:HttpClient , private localStorageRefService : LocalStorageRefService,
              private permissionsService: NgxPermissionsService) { }

  getPerms(){
    return JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.PERMISSIONS) )?? [];
  }
  loadPerms(perms : []){
    this.permissionsService.loadPermissions(perms);
  }
  hasPerm(perm : string){
    this.permissionsService.hasPermission(perm).then((hasPermission) => {
      return true;
    });
    return false;
    }

  getRole(){
    return JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.ROLE) )?? [];
  }


}
