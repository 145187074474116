import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FaqModel } from '../../../models/faq.model';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http:HttpClient) { }

  all(){
    return this.http.get(environment.baseUrl + 'static-page/faq/');
   }
   get(id:number){
     return this.http.get(environment.baseUrl + 'static-page/faq/'+id);

   }

  create(model:FormData){
    return this.http.post(environment.baseUrl + 'static-page/faq/',model);
   }
  delete(model:FaqModel){
    return this.http.delete(environment.baseUrl + 'static-page/faq/'+model.id);
   }
}
