import {Product} from './product.model';

export class OrderItemsModel {
  id: number;
  product_id: number;
  order_id: number;
  unit_price: number;
  discounted_price: number;
  qty: number;
  shipment_id: number;
  shipment_type: string;
  shipment_user_id: number;
  type: string;
  amount: number;
  full_amount: number;
  discount: number;
  shipment_cost: number;
  name:string;
  sku:string;
  product: Product;


    constructor(obj) {
        this.id = obj.id;
        this.product_id = obj.product_id;
        this.name = obj.orderable.title;
        this.sku = obj.orderable.sku;
        this.order_id = obj.order_id;
        this.unit_price = obj.unit_price;
        this.discounted_price = obj.discounted_price;
        this.qty = obj.qty;
        this.shipment_id = obj.shipment_id;
        this.shipment_type = obj.shipment_type;
        this.shipment_user_id = obj.shipment_user_id;
        this.type = obj.type;
        this.amount = obj.amount.amount;
        this.full_amount = obj.full_amount.amount;
        this.shipment_cost = obj.shipment_cost.amount;
        this.discount = obj.discount.amount;
        this.product = obj.product;

    }
}
