
export enum ErrorExceptionType {
  NO_TOKEN = 'noToken',
  VALIDATION = 'validation',
  FORBIDDEN = 'forbidden',
  CLIENT_ERROR = 'clientError',
  NOT_FOUND = 'notFound',
  INTERNAL_SERBER_ERROR = 'internalServerError',
  UNKNOWN_ERROR = 'unknownError',
  CONFLICT = 'conflict',
}
