import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {EditorComponent} from '@tinymce/tinymce-angular';
import {finalize, last} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';
import {UploadFileAwsService} from "../../services/upload-file-aws.service";
import {resolve} from "@angular/compiler-cli";


@Component({
    selector: 'app-tiny-editor',
    templateUrl: './tiny-editor.component.html',
    styleUrls: ['./tiny-editor.component.scss'],
    imports: [
        EditorComponent,
        ReactiveFormsModule,
        NgIf

    ],
    standalone: true
})
export class TinyEditorComponent {
    @Input() lang: string ='';
    @Input() value: string;
    @Input() values = [];
    @Input() editorLoading: boolean = false;
    @Input() field: string ='';
    @Output() fieldVal = new EventEmitter<any>();
    @Output() getfieldVal = new EventEmitter<any>();
    form: FormGroup;

    config: EditorComponent['init'] = {
        selector: '#editor',
        plugins: 'preview  casechange importcss tinydrive searchreplace autolink autosave save ' +
            'visualblocks visualchars code fullscreen image link media mediaembed codesample' +
            ' table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist ' +
            'wordcount  editimage help formatpainter permanentpen pageembed charmap' +
            ' quickbars linkchecker emoticons advtable export footnotes mergetags  code' +
            '  advtemplate',
        toolbar:
            'undo redo | aidialog aishortcuts | blocks fontsizeinput | bold italic |' +
            ' align numlist bullist | link image | table media pageembed | lineheight  outdent indent | ' +
            'strikethrough forecolor backcolor formatpainter removeformat | charmap emoticons checklist | ' +
            'code fullscreen preview |save print export | pagebreak anchor codesample footnotes mergetags |' +
            ' addtemplate inserttemplate  | ltr rtl casechange |  code',
        file_picker_types: 'image',
        image_advtab: false,
        image_description: false,
        image_dimensions: false,
        block_unsupported_drop: true,
        placeholder: 'Once upon a time...',
        content_css: 'writer',
        content_style: 'img{max-width:100%;height:auto;}',
        images_reuse_filename: true,
        paste_data_images: false,
        height: 'calc(100vh - 88px)',
        images_upload_handler: (blobInfo) => {
          const file = blobInfo.blob();
          return this.awsService.uploadfile(file);
        }

    };
    constructor(
      private storage: AngularFireStorage,
      private fb: FormBuilder,
      private awsService : UploadFileAwsService
      ) {
    }
    ngOnInit(): void {
        this.form = this.fb.group({ });
        if(this.values) {
            const value = this.values?.find(obj => obj?.['lang'] === this.lang)?.['value'];
            this.form.addControl(this.lang + '_' + this.field, this.fb.control(value));
            this.form.controls?.[this.lang + '_' + this.field].valueChanges.subscribe(value => {
                this.fieldVal.emit(value);
            });
        }

    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.editorLoading) {
            const newValue = changes.editorLoading.currentValue;
            const value = this.values?.find(obj => obj?.['lang'] === this.lang)?.['value'];
            this.form?.get(this.lang+ '_' + this.field ).setValue(value);
            this.editorLoading =true;
            setTimeout(()=>{
                this.editorLoading =false;
            },50);

        }
        if(changes.values){
          const newValues = changes.value?.currentValue;
          if(newValues)
            {
                const value = newValues?.find(obj => obj?.['lang'] === this.lang)?.['value'];
                this.form?.get(this.lang + '_' + this.field).setValue(value);
            }
        }
    }


}
