import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CustomLayoutComponent} from './custom-layout/custom-layout.component';
import {VexRoutes} from '../@vex/interfaces/vex-route.interface';
import {QuicklinkModule, QuicklinkStrategy} from 'ngx-quicklink';
import {AuthGuard} from './core/guards/auth.guard';

import {CategoriesComponent} from './pages/project/cruds/categories/categories.component';
import {ProductsComponent} from './pages/project/cruds/products/products.component';
import {TermsComponent} from './pages/project/cruds/terms/terms.component';
import {TermCreateUpdateComponent} from './pages/project/cruds/terms/term-create-update/term-create-update.component';
import {PrivacyPolicyComponent} from './pages/project/cruds/privacy-policy/privacy-policy.component';
import {
  PrivacyCreateUpdateComponent
} from './pages/project/cruds/privacy-policy/privacy-create-update/privacy-create-update.component';
import {OurTeamComponent} from './pages/project/cruds/our-team/our-team.component';

import {NewsComponent} from './pages/project/cruds/news/news.component';
import {NewsCreateUpdateComponent} from './pages/project/cruds/news/news-create-update/news-create-update.component';
import {SellYourMachineComponent} from './pages/project/cruds/sell-your-machine/sell-your-machine.component';
import {
  SellYourMachineDetailsComponent
} from './pages/project/cruds/sell-your-machine/sell-your-machine-details/sell-your-machine-details.component';
import {
  ProductCreateEditComponent
} from './pages/project/cruds/products/product-create-edit/product-create-edit.component';
import {ExpertReviewsComponent} from './pages/project/cruds/expert-reviews/expert-reviews.component';
import {
  ExpertReviewsCreateEditComponent
} from './pages/project/cruds/expert-reviews/expert-reviews-create-edit/expert-reviews-create-edit.component';
import {FinanceComponent} from './pages/project/cruds/finance/finance.component';
import {FinanceDetailsComponent} from './pages/project/cruds/finance/finance-details/finance-details.component';
import {FaqComponent} from './pages/project/cruds/faq/faq.component';
import {FaqCreateUpdateComponent} from './pages/project/cruds/faq/faq-create-update/faq-create-update.component';
import {InspectionReportComponent} from './pages/project/cruds/inspection-report/inspection-report.component';
import {OrdersComponent} from './pages/project/cruds/orders/orders.component';
import {OrderDetailsComponent} from './pages/project/cruds/orders/order-details/order-details.component';
import {OwnerReviewComponent} from './pages/project/cruds/expert-reviews/owner-review/owner-review.component';
import {AuctionComponent} from './pages/project/cruds/auction/auction.component';
import {RingsComponent} from './pages/project/cruds/auction/rings/rings.component';
import {AuctionItemsComponent} from './pages/project/cruds/auction/auction-items/auction-items.component';
import {BookingFreeTourComponent} from './pages/project/cruds/booking-free-tour/booking-free-tour.component';
import {ZonesComponent} from './pages/project/cruds/zones/zones.component';
import {ZoneDetailsComponent} from './pages/project/cruds/zones/zone-details/zone-details.component';
import {LiveShowComponent} from './pages/project/cruds/live-show/live-show.component';
import {ShowDetailsComponent} from './pages/project/cruds/live-show/show-details/show-details.component';
import {PermissionGuard} from './core/guards/permission.guard';
import {RingDetailsComponent} from './pages/project/cruds/auction/rings/component/ring-details/ring-details.component';
import {
  AuctionCreateUpdateComponent
} from './pages/project/cruds/auction/auction-create-update/auction-create-update.component';
import {InspectionGroupComponent} from './pages/project/cruds/inspection-group/inspection-group.component';
import {ResearchComponent} from "./pages/project/cruds/research/research.component";
import {SaveGenerationComponent} from "./pages/project/cruds/research/save-generation/save-generation.component";
import {PannellumComponent} from "./pages/project/cruds/products/pannellum/pannellum.component";
import {
  FeatureGenerationComponent
} from "./pages/project/cruds/research/feature-generation/feature-generation.component";
import {
  GenerationAttachmentComponent
} from "./pages/project/cruds/research/generation-attachment/generation-attachment.component";



// @ts-ignore
const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule)
  },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule)
      },
      {
        path: 'business-setting',
        loadComponent: () => import('./pages/project/cruds/business-setting/business-setting.component').then(m => m.BusinessSettingComponent)
      },
      {
        path: 'base-product',
        loadComponent: () => import('./pages/project/cruds/base-products/base-products.component').then(m => m.BaseProductsComponent),
        canActivate: [PermissionGuard],
        data: {
          permissions: 'models.read'
        }
      },
      {
        path: 'banners/liveshow',
        loadComponent: () => import('./pages/project/cruds/banners/banners.component').then(m => m.BannersComponent),
        canActivate: [PermissionGuard],
        data: {
          permissions: 'models.read'
        }
      },
      {
        path: 'banners/auction',
        loadComponent: () => import('./pages/project/cruds/banners/banners.component').then(m => m.BannersComponent),
        canActivate: [PermissionGuard],
        data: {
          permissions: 'models.read'
        }
      },
      {
        path: 'banners/home',
        loadComponent: () => import('./pages/project/cruds/banners/banners.component').then(m => m.BannersComponent),
        canActivate: [PermissionGuard],
        data: {
          permissions: 'models.read'
        }
      },
      {
        path: 'brands',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'brands.read'
        },
        loadComponent: () => import('./pages/project/cruds/brands/brands.component').then(m => m.BrandsComponent)
      },
      {
        path: 'sub-categories',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'categories.read'
        },
        loadComponent: () => import('./pages/project/cruds/categories/categories.component').then(m => m.CategoriesComponent)
      },
      {
        path: 'categories',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'categories.read'
        },
        children: [

          {path: '', component: CategoriesComponent}

        ]
      },
        // {path: 'panorama', component: PannellumComponent},

        {
        path: 'products',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'products.read'
        },
        children: [

          {path: '', component: ProductsComponent},
          {path: ':id', component: ProductCreateEditComponent},
          {path: 'create', component: ProductCreateEditComponent},
          {path: ':id/inspection-report', component: InspectionReportComponent},
          {path: ':id/:media/panorama', component: PannellumComponent}

        ]
      },
      {
        path: 'expert-reviews',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'expert_reviews.read'
        },
        children: [

                    {path: '', component: ExpertReviewsComponent},

                    {path: ':id/owner-review', component: OwnerReviewComponent}
                ]
            },
      {
        path: 'feature-research',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'expert_reviews.read'
        },
        children: [
          {path: '', component: FeatureGenerationComponent},

        ]
      },
      {
        path: 'research',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'expert_reviews.read'
        },
        children: [

          {path: '', component: ResearchComponent},
          {path: ':id', component: SaveGenerationComponent},
          {path: 'create', component: SaveGenerationComponent},
          {path: ':generation/expert-reviews/:id', component: ExpertReviewsCreateEditComponent},
          {path: ':generation/expert-reviews/create', component: ExpertReviewsCreateEditComponent},
          {path: ':id/attachment', component: GenerationAttachmentComponent}
          // {
          //   path: ':generation',
          //   canActivate: [PermissionGuard],
          //   data: {
          //     permissions: 'expert_reviews.read'
          //   },
          //   children: [
          //
          //
          //   ]
          // },
        ]
      },
            {
                path: 'specifications',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'specifications.read'
                },
                loadComponent: () => import('./pages/project/cruds/specifications/specifications.component').then(m => m.SpecificationsComponent)
            },
            {
              path: 'assets',
              canActivate: [PermissionGuard],
              data: {
                //Todo change permission
                permissions: 'specifications.read'
              },
              loadComponent: () => import('./pages/project/cruds/assets/assets.component').then(m => m.AssetsComponent)
            },
            {
                path: 'specifications-group',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'specification_groups.read'
                },
                loadComponent: () => import('./pages/project/cruds/specifications-group/specifications-group.component').then(m => m.SpecificationsGroupComponent)
            },
        {
            path: 'inspection-group',
            // canActivate: [PermissionGuard],
            // data: {
            //     permissions: 'specification_groups.read'
            // },
            loadComponent: () => import('./pages/project/cruds/inspection-group/inspection-group.component').then(m => m.InspectionGroupComponent)
        },

        {
            path: 'inspection-group-part',
            // canActivate: [PermissionGuard],
            // data: {
            //     permissions: 'specification_groups.read'
            // },
            loadComponent: () => import('./pages/project/cruds/inspection-part/inspection-part.component').then(m => m.InspectionPartComponent)
        },
            {
                path: 'roles',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'roles.read'
                },
                loadComponent: () => import('./pages/project/cruds/roles/roles.component').then(m => m.RolesComponent)
            },
            {
                path: 'discounts',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'offers.read'
                },
                loadComponent: () => import('./pages/project/cruds/discount/discount.component').then(m => m.DiscountComponent)
            },
            {
              path: 'discount/:id/products',
              canActivate: [PermissionGuard],
              data: {
                permissions: 'offers.read'
              },
              loadComponent: () => import('./pages/project/cruds/discount/product-discounts/product-discounts.component').then(m => m.ProductDiscountsComponent)
            },
            {
                path: 'users',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'customers.read'
                },
                loadComponent: () => import('./pages/project/cruds/users/users.component').then(m => m.CustomerComponent)
            },
            {
                path: 'admins',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'admins.read'
                },
                loadComponent: () => import('./pages/project/cruds/admins/admins.component').then(m => m.AdminsComponent)
            },
            {
                path: 'currencies',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'currencies.read'
                },
                loadComponent: () => import('./pages/project/cruds/currencies/currencies.component').then(m => m.CurrenciesComponent)
            },
            {
                path: 'application-group',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'application_groups.read'
                },
                loadComponent: () => import('./pages/project/cruds/application-group/application-group.component').then(m => m.ApplicationGroupComponent)
            },
            {
                path: 'application',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'applications.read'
                },
                loadComponent: () => import('./pages/project/cruds/application/application.component').then(m => m.ApplicationComponent)
            },
            {
                path: 'news-letter',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'news_letter.read'
                },
                loadComponent: () => import('./pages/project/cruds/newsletter/newsletter.component').then(m => m.NewsletterComponent)
            },
            {
                path: 'request-callback',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'request_callback.read'
                },
                loadComponent: () => import('./pages/project/cruds/call-back/call-back.component').then(m => m.CallBackComponent)
            },
            {
                path: 'offer-request',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'product_offers.read'
                },
                loadComponent: () => import('./pages/project/cruds/offer-request/offer-request.component').then(m => m.OfferRequestComponent)
            },
            {
                path: 'auction-promotions',
                loadComponent: () => import('./pages/project/cruds/auction-promotions/auction-promotions.component').then(m => m.AuctionPromotionsComponent)
            },
            {
                path: 'faq',
                canActivate: [PermissionGuard],
                data: {
                    permissions: 'faq.read'
                },
                children: [

          {path: '', component: FaqComponent},
          {path: 'save', component: FaqCreateUpdateComponent},
          {path: ':id', component: FaqCreateUpdateComponent}
        ]
      },
      {

        path: 'auction',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'auctions.read'
        },
        children: [

          {path: '', component: AuctionComponent},
          {path: ':id', component: AuctionCreateUpdateComponent},
          {path: 'save', component: AuctionCreateUpdateComponent},
          {path: ':id/ring', component: RingsComponent},
          {path: ':id/ring-details', component: RingDetailsComponent},
          {path: ':id/items', component: AuctionItemsComponent}

        ]
      },
      {
        path: 'zones',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'zones.read'
        },
        children: [

          {path: '', component: ZonesComponent},
          {path: 'save', component: ZoneDetailsComponent},
          {path: ':id', component: ZoneDetailsComponent}
        ]
      },
      {
        path: 'live-show',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'live_show.read'
        },
        children: [

          {path: '', component: LiveShowComponent},
          {path: ':id', component: ShowDetailsComponent}
        ]
      },
      {
        path: 'ports',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'ports.read'
        },
        loadComponent: () => import('./pages/project/cruds/shipment/port/port.component').then(m => m.PortComponent)
      },
      {
        path: 'quote-request',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'ports.read'
        },
        loadComponent: () => import('./pages/project/cruds/shipment/quote-request/quote-request.component').then(m => m.QuoteRequestComponent)
      },
      {
        path: 'base-rate',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'base_rates.read'
        },
        loadComponent: () => import('./pages/project/cruds/shipment/base-rate/base-rate.component').then(m => m.BaseRateComponent)
      },
      {
        path: 'faq-group',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'faq_group.read'
        },
        loadComponent: () => import('./pages/project/cruds/faq-group/faq-group.component').then(m => m.FaqGroupComponent)
      },
      // {
      //   path: 'term',
      //   canActivate: [PermissionGuard],
      //   data: {
      //     permissions: 'terms.read'
      //   },
      //   loadComponent: () => import('./pages/project/cruds/terms/terms.component').then(m => m.TermsComponent)
      // },
      {
        path: 'units',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'units.read'
        },
        loadComponent: () => import('./pages/project/cruds/units/units.component').then(m => m.UnitsComponent)
      },
      {
        path: 'unit-types',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'unit_type.read'
        },
        loadComponent: () => import('./pages/project/cruds/unit-type/units-type.component').then(m => m.UnitsTypeComponent)
      },
      {
        path: 'privacy-policy',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'privacy_policy.read'
        },
        loadComponent: () => import('./pages/project/cruds/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent)
      },
      {
        path: 'news-category',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'news_categories.read'
        },
        loadComponent: () => import('./pages/project/cruds/category-news/category-news.component').then(m => m.NewsCategoryModelComponent)
      },
      {
        path: 'news-tags',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'news_categories.read'
        },
        loadComponent: () => import('./pages/project/cruds/news-tags/news-tags.component').then(m => m.NewsTagsComponent)
      },
      {
        path: 'online-review',
        canActivate: [PermissionGuard],
        data: {

        },
        loadComponent: () => import('./pages/project/cruds/online-review/online-review.component').then(m => m.OnlineReviewComponent)
      },
      {
        path: 'testimonials',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'testimonials.read'
        },
        loadComponent: () => import('./pages/project/cruds/testimonials/testimonials.component').then(m => m.TestimonialsComponent)
      },
      {
        path: 'spotlight',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'spotlight.read'
        },
        loadComponent: () => import('./pages/project/cruds/spotlight/spotlight.component').then(m => m.SpotlightComponent)
      },
      {
        path: 'contact-us',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'contact_us.read'
        },
        loadComponent: () => import('./pages/project/cruds/contact-us/contact-us.component').then(m => m.ContactUsComponent)
      },
      {
        path: 'contact-us-reason',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'contact_reason.read'
        },
        loadComponent: () => import('./pages/project/cruds/contact-us-reason/contact-us-reason.component').then(m => m.ContactUsReasonComponent)
      },
      {
        path: 'sell-your-machine',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'sell_machine.read'
        },
        children: [
          {path: '', component: SellYourMachineComponent},
          {path: ':id', component: SellYourMachineDetailsComponent}
        ]
      },
      {
        path: 'finance',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'finance.read'
        },
        children: [

          {path: '', component: FinanceComponent},
          {path: ':id', component: FinanceDetailsComponent}
        ]
      },
      {
        path: 'term',
        canActivate: [PermissionGuard],
        data: {
          //Todo
          permissions: 'brands.read'
        },
        children: [

          {path: '', component: TermsComponent},
          {path: 'save', component: TermCreateUpdateComponent},
          {path: ':id', component: TermCreateUpdateComponent}
        ]
      },
      {
        path: 'our-team',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'our_team.read'
        },
        children: [

          {path: '', component: OurTeamComponent}
        ]
      }, {
        path: 'news',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'news.read'
        },
        children: [

          {path: '', component: NewsComponent},
          {path: 'save', component: NewsCreateUpdateComponent},
          {path: ':id', component: NewsCreateUpdateComponent}
        ]
      },
      {
        path: 'privacy-policy',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'privacy_policy.read'
        },
        children: [

          {path: '', component: PrivacyPolicyComponent},
          {path: 'save', component: PrivacyCreateUpdateComponent},
          {path: ':id', component: PrivacyCreateUpdateComponent}
        ]
      },
      {
        path: 'orders',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'orders.read'
        },
        children: [

          {path: '', component: OrdersComponent},
          {path: 'save', component: OrderDetailsComponent},
          {path: ':id', component: OrderDetailsComponent}
        ]
      },
      {
        path: 'shop-features',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'shop_features.read'
        },
        loadComponent: () => import('./pages/project/cruds/shop-features/shop-features.component').then(m => m.ShopFeaturesComponent)
      },
      {
        path: 'booking-tour',
        canActivate: [PermissionGuard],
        data: {
          permissions: 'machine_test_tour.read'
        },
        component: BookingFreeTourComponent
      },
      {
        path: 'apps',
        children: [
          {
            path: 'chat',
            loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'mail',
            loadChildren: () => import('./pages/apps/mail/mail.module').then(m => m.MailModule),
            data: {
              toolbarShadowEnabled: true,
              scrollDisabled: true
            }
          },
          {
            path: 'social',
            loadChildren: () => import('./pages/apps/social/social.module').then(m => m.SocialModule)
          },
          {
            path: 'contacts',
            loadChildren: () => import('./pages/apps/contacts/contacts.module').then(m => m.ContactsModule)
          },
          {
            path: 'calendar',
            loadChildren: () => import('./pages/apps/calendar/calendar.module').then(m => m.CalendarModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'aio-table',
            loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule)
          },
          {
            path: 'help-center',
            loadChildren: () => import('./pages/apps/help-center/help-center.module').then(m => m.HelpCenterModule)
          },
          {
            path: 'scrumboard',
            loadChildren: () => import('./pages/apps/scrumboard/scrumboard.module').then(m => m.ScrumboardModule)
          },
          {
            path: 'editor',
            loadChildren: () => import('./pages/apps/editor/editor.module').then(m => m.EditorModule)
          }
        ]
      },
      {
        path: 'pages',
        children: [
          {
            path: 'pricing',
            loadChildren: () => import('./pages/pages/pricing/pricing.module').then(m => m.PricingModule)
          },
          {
            path: 'faq',
            loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
          },
          {
            path: 'guides',
            loadChildren: () => import('./pages/pages/guides/guides.module').then(m => m.GuidesModule)
          },
          {
            path: 'invoice',
            loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
          },
          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
          }
        ]
      },
      {
        path: 'ui',
        children: [
          {
            path: 'components',
            loadChildren: () => import('./pages/ui/components/components.module').then(m => m.ComponentsModule)
          },
          {
            path: 'forms/form-elements',
            loadChildren: () => import('./pages/ui/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'forms/form-wizard',
            loadChildren: () => import('./pages/ui/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'icons',
            loadChildren: () => import('./pages/ui/icons/icons.module').then(m => m.IconsModule)
          },
          {
            path: 'page-layouts',
            loadChildren: () => import('./pages/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule)
          }
        ]
      },
      {
        path: 'documentation',
        loadChildren: () => import('./pages/documentation/documentation.module').then(m => m.DocumentationModule)
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
      //Project

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
