
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {OurTeamModel} from '../../../models/our-team.model';

@Injectable({
  providedIn: 'root'
})
export class OurTeamService {

  constructor(private http:HttpClient) { }

  get(){
    return this.http.get(environment.baseUrl + 'static-page/our-team/');
  }
  getTeam(id) {
    return this.http.get(environment.baseUrl + 'static-page/our-team/' + id);
  }
  create(team:any){
    return this.http.post(environment.baseUrl + 'static-page/our-team/',team);
  }
  delete(team:OurTeamModel){
    return this.http.delete(environment.baseUrl + 'static-page/our-team/'+team.id);
  }
}
