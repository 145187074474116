import { SelectionModel } from '@angular/cdk/collections';
import { NgClass, CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, FormsModule, UntypedFormControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Observable, of, filter } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { ErrorStatusModel } from 'src/app/core/enum/error-status.model';
import { FaqCreateUpdateComponent } from './faq-create-update/faq-create-update.component';
import { FaqService } from './service/faq.service';
import { FaqModel } from '../../models/faq.model';
import {LoadingRowComponent} from '../../../../core/components/loading-row/loading-row.component';
import {Router} from '@angular/router';
@UntilDestroy()
@Component({
  selector: 'vex-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ],
  imports: [
    PageLayoutModule,
    BreadcrumbsModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgClass,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    CommonModule,
    LoadingRowComponent
  ]
})
export class FaqComponent implements OnInit, AfterViewInit{
  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<FaqModel[]> = new ReplaySubject<FaqModel[]>(1);
  data$: Observable<FaqModel[]> = this.subject$.asObservable();
  types: FaqModel[];

  @Input()
  columns: TableColumn[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'question', property: 'question', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'answer', property: 'answer', type: 'text', visible: false, cssClasses: ['font-medium'] },
    { label: 'order', property: 'order', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'group name', property: 'group_name', type: 'text', visible: true, cssClasses: ['font-medium'] },
    {label: 'Actions', property: 'actions', type: 'button', visible: true}

  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<FaqModel> | null;
  selection = new SelectionModel<FaqModel>(true, []);
  searchCtrl = new UntypedFormControl();
  getLoading:boolean=true;



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(


    private dialog: MatDialog,
    private service:FaqService,
    private toastr: ToastrService,
    private route : Router
    ) {
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }


  getData() {

    this.service.all().subscribe((res)=>{
      of(res['data'].map(type=>new FaqModel(type))).subscribe(types=>{
        this.subject$.next(types);
        this.getLoading=false;
      });

    });
  }

  ngOnInit() {
    this.getData();

    this.dataSource = new MatTableDataSource();

    this.data$.pipe(
        filter<FaqModel[]>(Boolean)
    ).subscribe(types => {
      this.types = types;
      this.dataSource.data = types;
    });

    this.searchCtrl.valueChanges.pipe(
        untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }






  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }

  create(){
   this.route.navigate(['/faq/save']).then();
  }
  update(data: any) {
    this.route.navigate(['/faq/' + data.id]).then();
}
delete(data: any){
  this.service.delete(data).subscribe((res) => {
    if(res['status'] == ErrorStatusModel.SUCCESS) {
      this.toastr.success('Faq Deleted Successfully!');
      this.ngOnInit();
      this.selection.deselect(data);
    }
  },error =>
  {
    this.toastr.error(error['errors']['message']);

  });
}
deleteall(brands: FaqModel[]) {
  brands.forEach(c => this.delete(c));
}
}
