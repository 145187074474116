import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {fadeInUp400ms} from '../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../@vex/animations/stagger.animation';
import {PageLayoutModule} from '../../../../../@vex/components/page-layout/page-layout.module';
import {BreadcrumbsModule} from '../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LoadingRowComponent} from '../../../../core/components/loading-row/loading-row.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {filter, Observable, of, ReplaySubject} from 'rxjs';
import {TableColumn} from '../../../../../@vex/interfaces/table-column.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {OrderModel} from '../../models/order.model';
import {OrderService} from './service/order.service';

@UntilDestroy()
@Component({
  selector: 'vex-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    PageLayoutModule,
    BreadcrumbsModule,
    MatMenuModule,
    MatIconModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgClass,
    MatSlideToggleModule,
    MatPaginatorModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    MatProgressSpinnerModule,
    LoadingRowComponent
  ],
  standalone: true,
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class OrdersComponent implements OnInit, AfterViewInit {

  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<OrderModel[]> = new ReplaySubject<OrderModel[]>(1);
  data$: Observable<OrderModel[]> = this.subject$.asObservable();
  customers: OrderModel[];

  @Input()
  columns: TableColumn[] = [
    // { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Customer', property: 'user_name', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Total', property: 'total', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Taxes', property: 'taxes', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Discounts', property: 'discounts', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Items Count', property: 'items_count', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Status', property: 'status', type: 'text', visible: true, cssClasses: ['font-medium'] }


  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<OrderModel> | null;
  selection = new SelectionModel<OrderModel>(true, []);
  searchCtrl = new UntypedFormControl();
  loading:boolean = true;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private service:OrderService,
    private router:Router
  ) {
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }


  getData() {
    this.service.all().subscribe((res)=>{
      of(res['data'].map(perm=>new OrderModel(perm))).subscribe(perms=>{
        this.subject$.next(perms);
        this.loading=false;
      });
    });
  }

  ngOnInit() {
    this.getData();
    this.dataSource = new MatTableDataSource();
    this.data$.pipe(
      filter<OrderModel[]>(Boolean)
    ).subscribe(customers => {
      this.customers = customers;
      this.dataSource.data = customers;
    });
    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  createPermission() {

  }

  updatePermission(model: OrderModel) {
    this.router.navigate(['/orders/',model.id]);
  }

  deletePermission(brand: OrderModel) {
    // this.service.delete(brand).subscribe(()=>{
    //   this.ngOnInit();
    //   this.selection.deselect(brand);
    // });
  }

  deletePermissions(brands: OrderModel[]) {
    brands.forEach(c => this.deletePermission(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }

  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }

}

