import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Currency } from '../../../models/currency.model';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {

  constructor(private http:HttpClient) { }

  get(){
    return this.http.get(environment.baseUrl + 'currency/currency');
   }
  create(model:FormData){
    return this.http.post(environment.baseUrl + 'currency/currency',model);
   }
  delete(model:Currency){
    return this.http.delete(environment.baseUrl + 'currency/currency/'+model.id);
   }
}
