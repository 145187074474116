<vex-page-layout>

  <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
    <div [class.container]="layoutCtrl.value === 'boxed'"
         [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
         class="w-full flex flex-col sm:flex-row justify-between">
      <div *ngIf="isCategories()">
        <h1  class="title mt-0 mb-1">Category Table</h1>
        <vex-breadcrumbs [crumbs]="[ 'categories']"></vex-breadcrumbs>
      </div>
      <div *ngIf="!isCategories()">

        <h1 class="title mt-0 mb-1">Sub Category Table</h1>
        <vex-breadcrumbs [crumbs]="[ 'sub-categories']"></vex-breadcrumbs>
      </div>


    </div>
  </vex-page-layout-header>

  <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
                           [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
                           class="-mt-6">

                           <div class="card overflow-auto -mt-16" id="form">
                            <mat-accordion>
                              <mat-expansion-panel  [expanded]="isPanelOpen">
                                  <mat-expansion-panel-header >
                                      <mat-panel-title *ngIf="isCategories()" class="flex justify-between w-full">Add-Edit Category</mat-panel-title>
                                      <mat-panel-title *ngIf="!isCategories()" class="flex justify-between w-full">Add-Edit Sub Category</mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <div class=" p-6 h-fit border-b sticky left-0 flex items-center" >
                                      <form [formGroup]="form" class="w-full">
                                          <div class="flex gap-5 w-full">
                                              <div class="flex flex-col  w-full h-fit"  >
                                                  <div class="flex justify-content-between gap-2 items-center w-full" >
                                                    <mat-tab-group class="flex-auto">
                                                      <mat-tab  *ngFor="let lang of locale" [label]="lang.locale | uppercase">
                                                        <div class="">
                                                          <mat-form-field class="w-full" >
                                                            <mat-label > Name ( {{lang.locale | uppercase }} )</mat-label>
                                                            <input cdkFocusInitial formControlName="{{lang.locale}}_name" matInput>
                                                          </mat-form-field>
                                                        </div>
                                                      </mat-tab>
                                                    </mat-tab-group>
                                                      <mat-slide-toggle formControlName="is_popular">Popular</mat-slide-toggle>
                                                      <mat-slide-toggle *ngIf="isCategories()" formControlName="is_featured">Featured</mat-slide-toggle>
                                                  </div>
                                                  <mat-form-field class="flex-auto">
                                                    <mat-label>Type</mat-label>
                                                  <select matNativeControl required formControlName="type">
                                                        <option *ngFor="let item of categoryType" [ngValue]="item.id" >{{item['name']}}</option>
                                                  </select>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="!isCategories()" class="flex-auto">
                                                  <mat-label>Parent Category</mat-label>
                                                  <select matNativeControl  formControlName="parent_id">
                                                    <option [ngValue]=""></option>
                                                    <option *ngFor="let item of categories" [ngValue]="item.id" >{{item['name']}}</option>
                                                  </select>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="!isCategories()" class="flex-auto">
                                                  <mat-label>Specifications</mat-label>
                                                  <mat-select matNativeControl multiple required formControlName="specifications" >
                                                    <mat-option *ngFor="let item of specs" [value]="item['id']">{{item['key']}}</mat-option>
                                                  </mat-select>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="!isCategories()" class="flex-auto">
                                                  <mat-label>Groups</mat-label>
                                                  <mat-select matNativeControl multiple required formControlName="groups" >
                                                    <mat-option *ngFor="let item of groups" [value]="item['id']">{{item['name']}}</mat-option>
                                                  </mat-select>
                                                </mat-form-field>
                                              </div>
                                              <div class="flex flex-col w-full">
                                                <div class="flex flex-col  w-full h-fit justify-center items-center"  >

                                                  <img  class='image mb-4 w-[150px] h-[150px] justify-center items-center'  [src]="selectedFile" (error)="setDefaultImage($event)">
                                                  <div class="file-input-wrapper mb-4">
                                                    <input type="file" id="imageInput"#filePicker (change)="onImagePicked($event)" />
                                                    <label for="imageInput" class="file-input-label">Select Image</label>
                                                  </div>


                                                </div>
                                                <div class="flex flex-col  w-full h-fit justify-center items-center"  *ngIf="form.value?.['is_popular']!=0" >

                                                  <img  class='image mb-4 w-[300px] h-[150px] justify-center items-center'  [src]="selectedPopularFile" (error)="setDefaultImage($event)">
                                                  <div class="file-input-wrapper mb-4">
                                                    <input type="file" id="popularImageInput"#filePicker (change)="onPopularImagePicked($event)" />
                                                    <label for="imageInput" class="file-input-label">Select Popular Image</label>
                                                  </div>


                                                </div>
                                              </div>
                                          </div>
                                               <div class="flex mt-4" *ngIf="!isCategories()">
                                                   <form [formGroup]="dynamicFormSpec"
                                                         *ngIf="selectedSpecs?.length>0">
                                                       <div class="flex flex-wrap gap-2 ">
                                                           <ng-container *ngFor="let item of selectedSpecs; let i = index" >
                                                               <div class="flex " *ngIf="item.unit_type_id != null" >
                                                                   <mat-form-field  >
                                                                       <mat-label>{{item?.['key']}} Unit</mat-label>
                                                                       <mat-select placeholder="unit" matNativeControl  [formControlName]="item?.['key']" >
                                                                           <mat-option [value]="null">-</mat-option>
<!--                                                                         *ngIf="unit.unit_type_id == item.unit_type_id"-->
                                                                           <mat-option *ngFor="let unit of units" [value]="unit['id']">{{unit['name']}} ({{unit['symbol']}})</mat-option>
                                                                       </mat-select>
                                                                   </mat-form-field>
                                                               </div>
                                                               <ng-container >
                                                               </ng-container>
                                                           </ng-container>
                                                       </div>

                                                   </form>
                                               </div>
                                            <div class="flex mt-4" *ngIf="!isCategories()">
                                                   <form [formGroup]="dynamicFormGroups"
                                                         *ngIf="selectedGroups">
                                                       <div class="flex flex-wrap gap-2 ">
                                                           <ng-container *ngFor="let item of selectedGroups; let i = index" >
                                                               <div class="flex " >
                                                                   <mat-form-field  >
                                                                       <mat-label>{{item?.['name']}} parts </mat-label>
<!--                                                                       <mat-select placeholder="part" matNativeControl  [formControlName]="item?.['name']" >-->
<!--                                                                           <mat-option [value]="null">-</mat-option>-->
<!--                                                                           <mat-option *ngFor="let part of parts" [value]="part['id']">{{part['name']}} </mat-option>-->
<!--                                                                       </mat-select>-->
                                                                     <mat-select placeholder="part" matNativeControl multiple required [formControlName]="item?.['name']" >
                                                                       <mat-option *ngFor="let part of parts" [value]="part['id']">{{part['name']}} </mat-option>
                                                                     </mat-select>
                                                                   </mat-form-field>
                                                               </div>
                                                               <ng-container >
                                                               </ng-container>
                                                           </ng-container>
                                                       </div>

                                                   </form>
                                               </div>
                                        <div class="flex gap-3 w-full items-center justify-end">
                                          <button  (click)="clearForm()" class="  h-fit  flex items-center justify-center bg-black text-white px-3 py-1 rounded-sm w-fit" >Cancel</button>
                                          <button  type="submit" (click)="save()" [disabled]="form.invalid"
                                                   class="h-fit  flex items-center justify-center bg-[#d7e512] text-black px-3 py-1 rounded-sm w-fit" >
                                            <span *ngIf="!loading && isCreateMode() "> Add  </span>
                                            <span *ngIf="!loading && !isCreateMode() "> Update  </span>
                                            <mat-progress-spinner *ngIf="loading"  diameter="30" mode="indeterminate"></mat-progress-spinner>
                                          </button>
                                        </div>
                                      </form>


                                  </div>
                              </mat-expansion-panel>
                          </mat-accordion>
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
        <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
          <span *ngIf="selection.isEmpty()"></span>
          <span *ngIf="selection.hasValue()">{{ selection.selected.length }}
            Row<span *ngIf="selection.selected.length > 1">s</span> selected</span>
        </h2>

        <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
          <button (click)="deleteAll(selection.selected)"
                  color="primary"
                  mat-icon-button
                  matTooltip="Delete selected"
                  type="button">
            <mat-icon svgIcon="mat:delete"></mat-icon>
          </button>

          <button color="primary" mat-icon-button matTooltip="Another action" type="button">
            <mat-icon svgIcon="mat:folder"></mat-icon>
          </button>
        </div>

        <div
          class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
          <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
          <input [formControl]="searchCtrl"
                 class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                 placeholder="Search..."
                 type="search">
        </div>

        <span class="flex-1"></span>

        <button [matMenuTriggerFor]="columnFilterMenu"
                class="ml-4 flex-none"
                mat-icon-button
                matTooltip="Filter Columns"
                type="button">
          <mat-icon svgIcon="mat:filter_list"></mat-icon>
        </button>
        <button (click)="create()"
                class="ml-4 flex-none"
                color="primary"
                mat-mini-fab
                matTooltip="Add Specification Group"
                type="button">
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>

      </div>

      <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort *ngIf="!getLoading">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Checkbox Column -->
        <ng-container matColumnDef="checkbox">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          color="primary">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" class="w-4" mat-cell>
            <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                          (click)="$event.stopPropagation()"
                          [checked]="selection.isSelected(row)"
                          color="primary">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="image">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
            <!-- <img [src]="row['image']" class=" w-8 align-middle"> -->
          </td>
        </ng-container>

        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'text'&& column.property!='is_popular'" [matColumnDef]="column.property">
            <th *matHeaderCellDef  mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'image'" [matColumnDef]="column.property">
            <th *matHeaderCellDef  mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <img [src]="row[column.property]" (error)="setDefaultImage($event)"
                   class=" w-9 align-middle my-2">
            </td>
          </ng-container>
          <ng-container *ngIf="column.type === 'text' && column.property=='is_popular'"
                        [matColumnDef]="column.property">
            <th *matHeaderCellDef  mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses"
                mat-cell>{{ row[column.property] ? 'yes' : 'no' }}</td>
          </ng-container>
        </ng-container>

        <!-- Label Column -->
        <ng-container matColumnDef="labels">
          <th *matHeaderCellDef  mat-header-cell mat-sort-header>Labels</th>
          <td *matCellDef="let row" mat-cell>
            <div (click)="$event.stopPropagation()" class="flex items-center gap-1">
              <div *ngFor="let label of row.labels"
                   [ngClass]="[label.textClass, label.bgClass]"
                   class="rounded px-2 py-1 font-medium text-xs flex-none">
                {{ label.text }}
              </div>
              <div
                class="text-secondary bg-base text-hint cursor-pointer hover:bg-hover flex-none flex items-center justify-center">
                <mat-icon class="icon-sm" svgIcon="mat:add"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
          <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
            <button (click)="$event.stopPropagation()"
                    [matMenuTriggerData]="{ customer: row }"
                    [matMenuTriggerFor]="actionsMenu"
                    mat-icon-button
                    type="button">
              <mat-icon svgIcon="mat:more_horiz"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr (click)="update(row)"
            *matRowDef="let row; columns: visibleColumns;"
            @fadeInUp
            class="hover:bg-hover trans-ease-out cursor-pointer"
            mat-row></tr>
      </table>
      <vex-loading-row *ngIf="getLoading" [diameter]="25" class=" bg-white"></vex-loading-row>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" class="sticky left-0"></mat-paginator>
    </div>

  </vex-page-layout-content>

</vex-page-layout>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
          class="mat-menu-item block">
    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
      {{ column.label }}
    </mat-checkbox>
  </button>
</mat-menu>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <button (click)="update(customer)" mat-menu-item>
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>Modify</span>
    </button>
      <button *ngIf="!isCategories()" (click)="addFeaturedProducts(customer)" mat-menu-item>
      <mat-icon svgIcon="mat:view_week"></mat-icon>
      <span>Add Featured Products</span>
    </button>
    <button (click)="delete(customer)" mat-menu-item>
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>Delete</span>
    </button>
  </ng-template>
</mat-menu>
