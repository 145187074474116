import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter, take } from 'rxjs/operators';
import { animate, AnimationBuilder, style } from '@angular/animations';
import {AuthService} from '../../app/pages/pages/auth/service/auth.service';
import {ErrorStatusModel} from '../../app/core/enum/error-status.model';
import {StorageKeysEnum} from '../../app/core/enum/storage-keys.enum';
import {LocalStorageRefService} from '../../app/pages/pages/auth/service/local-storage-ref.service';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {

  splashScreenElem: HTMLElement;
  language=[];
  constructor(private router: Router,
              @Inject(DOCUMENT) private document: Document,
              private animationBuilder: AnimationBuilder,
              private authService : AuthService,
              private localStorageRefService : LocalStorageRefService
              ) {
    this.splashScreenElem = this.document.body.querySelector('#vex-splash-screen');

    if (this.splashScreenElem) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      ).subscribe(() => this.hide());
    }
  }

  hide() {
    const player = this.animationBuilder.build([
      style({
        opacity: 1
      }),
      animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
        opacity: 0
      }))
    ]).create(this.splashScreenElem);

    player.onDone(() => this.splashScreenElem.remove());
    if(this.authService.isLoggedIn()){
      // @ts-ignore
      this.authService
        .getLanguage()
        .subscribe(async res => {
          if (res['status'] == ErrorStatusModel.SUCCESS) {
            const locales = res['data'].sort((a, b) => (a.default === b.default) ? 0 : (a.default ? -1 : 1));
            if (locales.filter(loc => loc.default == true).length == 0) {
              locales[0]['default'] = true;
            }
            this.localStorageRefService.setData(StorageKeysEnum.LANGUAGES, JSON.stringify(locales));

             this.authService.admin().subscribe(value => {
               this.authService.messagesSource.next(value['data']);
               this.localStorageRefService.setData(StorageKeysEnum.PERMISSIONS, JSON.stringify(value['data']?.['role']?.['permissions']));
               player.play();
             });



          }

        }),error=>{
        player.play();
      };

    }else{
      player.play();
    }


  }

  getLanguage(){
    this.authService
      .getLanguage()
      .subscribe((res) => {

        if(res['status'] == ErrorStatusModel.SUCCESS) {
          this.language = res['data'];
        }
      },error =>
      {

      });
  }
}
