import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup, Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from '@angular/material/form-field';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';
import { UntilDestroy } from '@ngneat/until-destroy';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import {BreadcrumbsModule} from '../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {PageLayoutModule} from '../../../../../@vex/components/page-layout/page-layout.module';
import {ToastrService} from 'ngx-toastr';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {LoadingRowComponent} from '../../../../core/components/loading-row/loading-row.component';
import {InspectionReportService} from './service/inspection-report.service';
import {ActivatedRoute, RouterLink, RouterLinkActive} from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatOptionModule, MatRippleModule} from '@angular/material/core';
import {
  InspectionGroup,
  InspectionGroupPart,
  InspectionReportModel,
  InspectionReportTemplate
} from '../../models/inspection-report.model';
import {MethodsUtils} from '../../../../core/utils/method-utils';
import {InspectionGroupCreateComponent} from './inspection-group-create/inspection-group-create.component';
import {trackById} from '../../../../../@vex/utils/track-by';
import {ScrollbarModule} from '../../../../../@vex/components/scrollbar/scrollbar.module';
import {NgbRating} from '@ng-bootstrap/ng-bootstrap';
import {MediaModel} from '../../models/media.model';
import {CdkDrag, CdkDropList, CdkDropListGroup} from '@angular/cdk/drag-drop';
import {MatButtonModule} from '@angular/material/button';
import {InspectionTypeEnum} from '../../../../core/enum/inspection-type.enum';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {MediaTypeEnum} from '../../../../core/enum/media-type.enum';
import {ErrorStatusModel} from '../../../../core/enum/error-status.model';
import {
  ConfirmationDialogComponent
} from '../../../../core/components/confirmation-dialog/confirmation-dialog.component';
import {ReportPdfComponent} from './report-pdf/report-pdf.component';
import {environment} from '../../../../../environments/environment';
import {ProductService} from '../products/service/product.service';
import {Product} from '../../models/product.model';
import {CategoryService} from '../categories/service/category.service';
import {TinyEditorComponent} from '../../../../core/components/tiny-editor/tiny-editor.component';
import {SafeHtmlPipe} from '../../../../core/pipes/safe-html.pipe';
import {EditorComponent} from '@tinymce/tinymce-angular';
import {finalize, last} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {CreateMediaComponent} from '../products/create-media/create-media.component';
import {UploadFileAwsService} from "../../../../core/services/upload-file-aws.service";
import {UpdateMediaComponent} from "../products/component/update-media/update-media.component";


@UntilDestroy()
@Component({
  selector: 'vex-inspection-report',
  templateUrl: './inspection-report.component.html',
  styleUrls: ['./inspection-report.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    BreadcrumbsModule,
    MatCheckboxModule,
    NgClass,
    MatSlideToggleModule,
    MatMenuModule,
    MatIconModule,
    MatPaginatorModule,
    FormsModule,
    MatTableModule,
    ReactiveFormsModule,
    PageLayoutModule,
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    LoadingRowComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatOptionModule,
    MatRippleModule,
    RouterLinkActive,
    ScrollbarModule,
    RouterLink,
    NgbRating,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    MatButtonModule,
    AutocompleteLibModule,
    ReportPdfComponent,
    TinyEditorComponent,
    SafeHtmlPipe,
    EditorComponent
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class InspectionReportComponent implements OnInit, AfterViewInit {

  layoutCtrl = new UntypedFormControl('boxed');
  report:InspectionReportModel ;
  productId : string;
  form: UntypedFormGroup;
  loading=false;
  activeGroup : any = null;
  activePart = null;
  activeGroupName='';
  activeMedia : any;
  activeMediaPart = '';
  groupedMediaArray: any;
  addingPart : boolean = false;
  loadingAddPart : boolean = false;
  starRating=0;
  types =[];
  notes =[];
  note = '';
  keyword = 'name';
  videoURL='';
  selectedFiles: File[] = [];
  deleteMedia = [];
  files = [];
  videos = [];
  fileArray = [];
  product : Product;
  template : InspectionReportTemplate[];
  desc ='';
  isTemplate = false;
  loadingAddGroup = false;
  newGroup = new FormControl(null, [Validators.required]);
  avilableGroup = [] ;
  avilablePart = [] ;
  createdMedia;
  config: EditorComponent['init'] = {
    selector: '#editor',
    plugins: 'preview  casechange importcss tinydrive searchreplace autolink autosave save ' +
      'visualblocks visualchars code fullscreen image link media mediaembed codesample' +
      ' table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist ' +
      'wordcount  editimage help formatpainter permanentpen pageembed charmap' +
      ' quickbars linkchecker emoticons advtable export footnotes mergetags  code' +
      '  advtemplate',
    toolbar:
      'undo redo | aidialog aishortcuts | blocks fontsizeinput | bold italic |' +
      ' align numlist bullist | link image | table media pageembed | lineheight  outdent indent | ' +
      'strikethrough forecolor backcolor formatpainter removeformat | charmap emoticons checklist | ' +
      'code fullscreen preview |save print export | pagebreak anchor codesample footnotes mergetags |' +
      ' addtemplate inserttemplate  | ltr rtl casechange |  code',
    file_picker_types: 'image',
    image_advtab: false,
    image_description: false,
    image_dimensions: false,
    block_unsupported_drop: true,
    placeholder: 'Once upon a time...',
    content_css: 'writer',
    content_style: 'img{max-width:100%;height:auto;}',
    images_reuse_filename: true,
    paste_data_images: false,
    height: 'calc(100vh - 88px)',
    images_upload_handler: (blobInfo) => {
      const file = blobInfo.blob();
      return this.awsService.uploadfile(file);
    }
  };
  constructor(
    private dialog: MatDialog,
    public methods: MethodsUtils,
    private service:InspectionReportService,
    private productService:ProductService,
    private categoryService:CategoryService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private storage: AngularFireStorage,
    private awsService : UploadFileAwsService
  ) {
    this.types = this.methods.enumToArray(InspectionTypeEnum);
  }

  getData() {
    this.productService.getInspectionReport(this.productId).subscribe((res)=>{
      // @ts-ignore
       this.report = res.data;
       this.activeGroup = null;
       this.activePart = null;
      if(this.report.groups.length !=0 ) {

        // this.isTemplate=true;
      }
      this.getProduct();
    });
  }
  getProduct() {
    this.productService.getProduct(this.productId).subscribe((res)=>{
      // @ts-ignore
       this.product = res.data;
      this.getTemplatByProduct(this.product?.['category_id']);
    });
  }
  getTemplatByProduct(catId:any) {

    this.categoryService.getTemplate(catId).subscribe((res)=>{
       // @ts-ignore
      this.template = res;
      this.avilableGroup =[];
      this.template.map((tempGroup)=>{
      const isFound = this.report.groups.filter((group)=>group.inspection_report_group_id==tempGroup.id);
      if(isFound.length==0){

        this.avilableGroup.push(tempGroup);
      }
      });
      this.setActiveGroup(this.report.groups[0]);
    });


  }

  ngOnInit() {
      this.productId = this.route.snapshot.paramMap.get('id');
      this.getData();
      this.clearForm();
  }
  clearForm(){
    this.form = this.fb.group({
      name: ['', Validators.required],
      note: [''],
      type: [''],
      part_id: [''],
      description: [''],
      inspection_type: [''],
      inspection_description: ['' ,Validators.required],
      has_inline_images: [true ,Validators.required],
      has_video: [false ,Validators.required]
    });
    this.notes=[];
    this.desc ='';
    this.deleteMedia=[];
  }
  ngAfterViewInit() {

  }

  save(){
  }

  editGroup(group){
    this.dialog.open(InspectionGroupCreateComponent, {
      data: {group: group }
    }).afterClosed().subscribe((group: any) => {
      if(group)
        this.getData();
    });
  }
  addGroup(){
    this.loadingAddGroup=true;
    const formData=new FormData();

      formData.append('inspection_report_group_id', this.newGroup.value);

    if (formData) {
      this.service.addGroup(this.report.id,formData).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Added Successfully!');
          this.loadingAddGroup=false;
          this.ngOnInit();
        }
      }, error => {
        this.loadingAddGroup=false;

        this.toastr.error(error);
      });
    }


  }
  setActiveGroup(group:any){
    this.activeGroup = group;
    this.activePart = null;
    this.avilablePart = [];
    this.deleteMedia = [];
    this.selectedFiles = [];
    this.activeGroupName = group.name;
    // @ts-ignore
    this.template.filter((data)=>data.id==group.inspection_report_group_id)[0].parts?.map((tempGroup)=>{
      // @ts-ignore
      const isFound = this.activeGroup.parts.filter((group)=>group.inspection_report_part_id==tempGroup.id);
      if(isFound.length==0){

        this.avilablePart.push(tempGroup);
      }
    });

  }
  updateEditor=false;
  setActivePart(part:any){
    console.log('part');
    console.log(part);
    console.log('part');
    this.updateEditor=true;
    this.createdMedia=null;
    this.activePart = part;
    this.desc =part.description;
    this.form = this.fb.group({
      name: ['', Validators.required],
      id:[part.id],
      type: [part.type],
      note:[],
      description:[part.description],
      part_id: [part.inspection_report_part_id],
      inspection_type: [part.inspection_type],
      inspection_description: [part.inspection_description ,Validators.required],
      has_inline_images: [!part.has_video ,Validators.required],
      has_video: [part.has_video ,Validators.required]
    });
    this.starRating = part.rating;
    this.notes=part.notes;

    setTimeout(()=>{
      this.updateEditor=false;
    },100);
  }
  showImages(part : InspectionGroupPart){
    this.activeMedia = part.media;
    this.activeMediaPart = part?.name || '';
  }

  handleImageError(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }
  deleteProductPart(id){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: 'Delete Part',
        message: 'Are you sure you want to Delete this Part?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.deleteProductPart(id).subscribe((res) => {
          if (res['status'] == ErrorStatusModel.SUCCESS || ErrorStatusModel.SUCCESSCREATE) {
            this.toastr.success('Deleted Successfully!');
            this.ngOnInit();
          }

        }, error => {
          this.loading = false;
          this.toastr.error(error);
        });
      }else {

      }});


  }
  deleteGroup(id){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: 'Delete Group',
        message: 'Are you sure you want to Delete this Group?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.deleteProductGroup(id).subscribe((res) => {
          if (res['status'] == ErrorStatusModel.SUCCESS || ErrorStatusModel.SUCCESSCREATE) {
            this.toastr.success('Deleted Successfully!');
            this.ngOnInit();
          }

        }, error => {
          this.loading = false;
          this.toastr.error(error);
        });
      }else {

      }});


  }
  addPart(){
    this.loadingAddPart=true;
    const part = new FormData();
    // part.append('name', this.form.value.name|| '');
    if(this.activePart){
      part.append('id', this.activePart.id|| '');
      this.activePart?.media?.forEach((file,index)=>{
        part.append(`media_order[${index}][id]`, file.id);
        part.append(`media_order[${index}][order]`, index);
        part.append(`media_order[${index}][description]`, file?.custom_properties?.description);
        //part.append(`media_order[${index}][alt]`, file?.custom_properties?.alt);
      });
      this.activePart?.video?.forEach((file,index)=>{
        part.append(`media_order[${this.activePart?.media.length+index}][id]`, file.id);
        part.append(`media_order[${this.activePart?.media.length+index}][order]`, index);
        part.append(`media_order[${this.activePart?.media.length+index}][description]`, file?.custom_properties?.description);
        //part.append(`media_order[${index}][alt]`, file?.custom_properties?.alt);
      });

    }
    part.append('inspection_report_part_id', this.form.value.part_id|| '');
    part.append('product_inspection_group_id', this.activeGroup.id|| '');

    part.append('description', this.form.value.description|| '');
    part.append('inspection_type', this.form.value.inspection_type|| '');
    part.append('inspection_description', this.form.value.inspection_description|| '');
    part.append('has_inline_images', this.form.value.has_inline_images ? '1' : '0');
    part.append('has_video', this.form.value.has_video ? '1' : '0');
    if(this.createdMedia!=null){
      part.append('media[0][collection_name]',this.createdMedia['media[0][collection_name]']);
      for(let i =0;i<this.createdMedia['count'];i++){
        part.append(`media[0][files][${i}][file]`,this.createdMedia[`media[0][files][${i}][file]`]);
        part.append(`media[0][files][${i}][description]`,this.createdMedia[`media[0][files][${i}][description]`]);
      }
    }

    if (this.starRating > 0) {
      part.append('rating', this.starRating.toString());
    }
    this.notes.forEach((item, index) => {
      part.append(`notes[${index}]`, item);
    });
    this.deleteMedia.forEach((item, index) => {
      part.append(`deleted_media[${index}]`, item);
    });
    let index = 0;
    if (this.files.length > 0) {
      for(let index=0 ; index<this.files.length; index++)
        part.append(`media[${index}]`, this.files[index]);
    }

    this.service.createPartFromReport(part , this.activeGroup.id).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS || ErrorStatusModel.SUCCESSCREATE) {
        this.toastr.success('Inspection Report Group part Created Successfully!');
        this.getData();
        this.clearForm();
        this.files=[];
        this.fileArray=[];
        this.updateEditor=true;
        this.activePart = null;
        this.createdMedia=null;
        this.loadingAddPart=false;
        setTimeout(()=>{
          this.updateEditor=false;
        },100);
      }

    }, error => {
      this.loading = false;
      this.toastr.error(error);
    });
  }
  addNote(){
    if(this.keyword != ''){
      this.notes.push(this.form.value.note);
    }
  }
  removeNote(index : number){
    this.notes.splice(index,1);
  }
  uploadFiles() {
    this.files = [];
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      if (file) {
        this.files.push(file);
      }
    }
  }
  onFileSelected(event:any) {
    this.dialog.open(CreateMediaComponent, {
      data: {
        id: null,
        name: 'inspection',
        mode:'update'

      }
    }).afterClosed().subscribe((res: any) => {
      this.createdMedia=res;




      // if (res)
      // this.getMedia();
    });
    // this.selectedFiles = event.target.files;
    // this.uploadFiles();
    // this.fileArray = [];
    // const files = (event.target as HTMLInputElement).files; // Get all selected files
    // if (files && files.length > 0) {
    //   for (let i = 0; i < files.length; i++) {
    //     const file = files[i];
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.fileArray.push(
    //           // @ts-ignore
    //           e.target.result
    //       );
    //     };
    //     reader.readAsDataURL(file);
    //   }
    //
    // }
  }
  deleteMediaFile(id){
    this.deleteMedia.push(id);
   this.activePart.media= this.activePart.media.filter((m)=>m.id!=id);
  }
  deletevideo(id){
    this.deleteMedia.push(id);
    this.activePart.video=null;
  }
  hasVideos(checked){
    if(checked)
    this.form.get('has_inline_images').setValue(false);
  }
  hasImages(checked){
    if(checked)
      this.form.get('has_video').setValue(false);
  }
  deletePart(part:InspectionGroupPart){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: 'Delete Inspection Group Part',
        message: 'Are you sure you want to perform this action?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.service.deletePart(this.activeGroup?.id, part?.id).subscribe((res) => {
        //   if (res['status'] == ErrorStatusModel.SUCCESS) {
        //     this.toastr.success('part Deleted Successfully!');
        //     this.getData();
        //   }
        // }, error => {
        //   this.toastr.error(error);
        // });
      }else {

      }});
  }
  openPdf(){

    window.open(environment.baseUrl+'product/products/'+this.productId+'/inspection/download');
  }
  setEditorControl(event) {
    this.desc = event;
  }
  updateMedia(media){

    const dialogRef = this.dialog.open(UpdateMediaComponent, {
      width: '250px',
      data: {
        description: media['custom_properties']['description']
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

       this.activePart.media.forEach((m)=>{
         if(m.id==media.id){
           // @ts-ignore
           m['custom_properties']['description']=result;
         }
       });
        this.activePart.video.forEach((m)=>{
          if(m.id==media.id){
            // @ts-ignore
            m['custom_properties']['description']=result;
          }
        });

      }else {

      }});
  }
}

