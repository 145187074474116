import { Component, Input } from '@angular/core';
import {MethodsUtils} from '../../utils/method-utils';
import {svgList} from '../../utils/svg-list';
import {SafeHtmlPipe} from '../../pipes/safe-html.pipe';

@Component({
    selector: 'app-svg-renderer',
    templateUrl: './svg-icon.component.html',
    styleUrls: ['./svg-icon.component.scss'],
    imports: [
        SafeHtmlPipe
    ],
    standalone: true
})
export class SvgRendererComponent {
    @Input() svgContent: string;
    innerHtml: string;
    constructor() {
    }
    ngOnInit(): void {
        this.innerHtml = svgList.find(item => item.name == this.svgContent)?.content;
    }

}
