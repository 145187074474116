
export class AuctionModel {
  id: number;
  name: string;
  description: string;
  start_datetime: string;
  end_datetime: string;
  duration: string;
  type: string;
  location: string;
  cover : string;
  details : string;
  rings : any;
  bid_extension_time:string;
  sniper_protection_seconds:string;
  status:number;
  translations :[
    {
      details: string;
      locale : string ;
      name : string ;
      description : string ;
      location : string ;
    }
  ];

    constructor(obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.description = obj.description;
        this.details = obj.details;
        this.start_datetime = obj.start_datetime;
        this.end_datetime = obj.end_datetime;
        this.duration = obj.duration;
        this.type = obj.type;
        this.location = obj.location;
        this.cover = obj.cover;
        this.rings = obj.rings;
        this.status = obj.status;
        this.translations = obj.translations;
        this.sniper_protection_seconds = obj.sniper_protection_seconds;
        this.bid_extension_time = obj.bid_extension_time;

    }
}
