import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import {TokenInterceptor} from './core/interceptors/token.interceptor';
import {ErrorInterceptor} from './core/interceptors/error.interceptorr';
import {AuthGuard} from './core/guards/auth.guard';
import {provideToastr, ToastrModule} from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {AngularFireModule} from '@angular/fire/compat';
import {environment} from '../environments/environment';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {LoadingRowComponent} from './core/components/loading-row/loading-row.component';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BookingFreeTourComponent } from './pages/project/cruds/booking-free-tour/booking-free-tour.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {NgxPermissionsModule} from 'ngx-permissions';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxMatDatetimePickerModule} from '@angular-material-components/datetime-picker';
import { UpdateMediaComponent } from './pages/project/cruds/products/component/update-media/update-media.component';
import { NewsTagsComponent } from './pages/project/cruds/news-tags/news-tags.component';
import {BookingFreeTourModule} from "./pages/project/cruds/booking-free-tour/booking-free-tour.module";
import {
  BooingTourTableComponent
} from "./pages/project/cruds/booking-free-tour/booing-tour-table/booing-tour-table.component";
import { CurrencyExchangePercentageComponent } from './pages/project/cruds/business-setting/component/currency-exchange-percentage/currency-exchange-percentage.component';
import { ShipmentPercentageComponent } from './pages/project/cruds/business-setting/component/shipment-percentage/shipment-percentage.component';
import { ProxyBidComponent } from './pages/project/cruds/live-show/component/proxy-bid/proxy-bid.component';
import { ResearchComponent } from './pages/project/cruds/research/research.component';
import { SaveGenerationComponent } from './pages/project/cruds/research/save-generation/save-generation.component';
import {ProductMediaComponent} from "./pages/project/cruds/products/product-media/product-media.component";
import {QuillEditorComponent} from "ngx-quill";
import {SecondaryToolbarModule} from "../@vex/components/secondary-toolbar/secondary-toolbar.module";
import {SvgRendererComponent} from "./core/components/svg-icon/svg-icon.component";
import {TinyEditorComponent} from "./core/components/tiny-editor/tiny-editor.component";
import {
  UploadSingleMediaComponent
} from "./pages/project/cruds/products/upload-single-media/upload-single-media.component";
import { GenerationAttachmentComponent } from './pages/project/cruds/research/generation-attachment/generation-attachment.component';







@NgModule({
  declarations: [
    AppComponent,
    BookingFreeTourComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        VexModule,
        CustomLayoutModule,
        PageLayoutModule,
        MatSlideToggleModule,
        BreadcrumbsModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        FormsModule,
        MatTooltipModule,
        MatSelectModule,
        MatButtonToggleModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatRadioModule,
        MatDividerModule,
        ToastrModule.forRoot(),
        MatProgressSpinnerModule,
        NgbModule,
        AngularFireModule.initializeApp(environment.firebase),
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        LoadingRowComponent,
        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        NgxPermissionsModule.forRoot(),
        MatSnackBarModule,
        LoadingRowComponent,
        MatTabsModule,
        NgxMatDatetimePickerModule,
        BookingFreeTourModule,
        BooingTourTableComponent,
        ProductMediaComponent,
        QuillEditorComponent,
        SecondaryToolbarModule,
        SvgRendererComponent,
        TinyEditorComponent,
        UploadSingleMediaComponent,
        MatRippleModule

    ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    AuthGuard,
    provideAnimations(),
    provideToastr()
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
