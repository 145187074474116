<vex-page-layout>
    <div class="grid grid-cols-1 sm:grid-cols-12 gap-4">
        <div class="col-span-12 sm:col-span-8 px-3">
            <div id="panorama" class="self-center"></div>
            <div class="flex mt-5">
                <input type="file" id="fileInput" accept="application/json" (change)="onFileSelected($event)" hidden>
                <label for="fileInput" class="file-upload-label">Select File</label>
                <div  class="file-details flex-auto border">
                    <p><strong>Selected File:</strong> {{ selectedFile?.name??'' }}</p>
                </div>
            </div>

            <div class="p-4 flex justify-end">
                <button color="primary" class="flex items-center justify-center" mat-flat-button type="submit" (click)="save()" [disabled]="selectedFile==null" ><span  *ngIf="!loading">Upload</span>
                    <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>
                </button>
            </div>
        </div>
        <div class="col-span-12 sm:col-span-4 px-2">
            <div class="flex flex-col  gap-3 mt-4 ">
                <button color="primary" mat-flat-button (click)="addHotSpots()">
                    <span *ngIf="!addingHotSpots">add hotspots</span>
                    <span *ngIf="addingHotSpots">cancel adding</span>
                </button>
                <div class="flex justify-content-between gap-2 ">
                    <button *ngIf="!addingHotSpots" class="bg-black text-white w-full  md:w-1/2" mat-flat-button
                            (click)="setStartingPosition()">
                        Initial position
                    </button>
                    <button class="bg-black text-white w-full  md:w-1/2" mat-flat-button
                            (click)="saveJsonFile()">
                        Download json
                    </button>
                </div>
            </div>

            <h2 class="mt-4">Hotspots </h2>
          <div *ngIf="config?.['hotSpots'].length>0" class="spots-container h-[500px] overflow-scroll">
          <ng-container *ngFor="let spot of config.hotSpots;let i=index" class="flex flex-col gap-3">
                <div class="border-2  rounded-sm flex flex-col gap-2 mt-2 p-3 ">
                    <div class="flex justify-content-between">
                        <div class="flex flex-col">
                            <span> <span class="font-bold"> text : </span> {{spot.text}}</span>
                            <span> <span class="font-bold"> type : </span> {{spot.clickHandlerArgs.type}}</span>
                        </div>
                        <div class="flex flex-col">
                            <div class='flex-auto' >
                                <mat-icon svgIcon="mat:edit" (click)="updateHotspot(spot.clickHandlerArgs.id)"></mat-icon>
                            </div>
                            <div class='flex-auto' >
                                <mat-icon svgIcon="mat:cancel" (click)="deleteHotSpot(spot.clickHandlerArgs.id)" ></mat-icon>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>
          </div>
        </div>
    </div>
</vex-page-layout>
