
import {HttpClient, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import {BehaviorSubject, Subject, tap} from 'rxjs';
import {TokenModel} from '../../../project/models/token.model';
import {Router} from '@angular/router';
import {LocalStorageRefService} from './local-storage-ref.service';
import {ResponseModel} from '../../../project/models/response.model';
import {StorageKeysEnum} from '../../../../core/enum/storage-keys.enum';
import {SplashScreenService} from '../../../../../@vex/services/splash-screen.service';
import {Message} from '../../../project/cruds/live-show/service/isv.service';
import {User} from '../../../project/models/user.model';
import {AdminModel} from '../../../project/models/admin.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loginUpdate = new Subject<any>();
  token$: BehaviorSubject<TokenModel | null> = new BehaviorSubject<TokenModel | null>(null);
  apiUrl = environment.baseUrl;

  // redirectUri = environment.redirectUri;
  redirectUri = '';
  // @ts-ignore
  messagesSource = new BehaviorSubject<AdminModel>();
  adminData$ = this.messagesSource.asObservable();
  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageRefService: LocalStorageRefService
    // private splashScreenService:SplashScreenService
  ) {
    this.token$ = new BehaviorSubject<TokenModel | null>(this.loadFromStorage());
    // this.admin();
  }

  public get currentUser() {
    return this.token$.value?.profile;
  }

  login(email: string, password: string) {
    return this.http.post<ResponseModel>(`${this.apiUrl}authentication/login`,
      { 'email': email, 'password': password }).pipe(
      tap(async (response) => {
        this.handleAuthentication(response.data);
      })
    );
  }
   admin(){
   return this.http.get(this.apiUrl + 'user/profile');
    //  .subscribe(res=>{
    //   this.messagesSource.next(res['data']);
    //
    // });


  }

  getAccessToken(code: string) {
    const data = { code: code };
    return this.http.post<any>(`auth/get-access-token/?redirect_uri=${this.redirectUri}`, data).pipe(
      tap(async (response) => {
        this.handleAuthentication(response.data);
      })
    );
  }

  logout() {
    // const user = this.token$.value;
    // const headers = new HttpHeaders({
    //   Authorization: `Bearer ${user?.refresh_token},
    //         cod: Bearer ${user?.refresh_token}`
    // });
    this.unAuth();
    return this.http
      .get<ResponseModel>('authentication/logout', {
      })
      .subscribe(res=>{
        // this.splashScreenService.hide();
      });
  }

  async unAuth() {
    this.localStorageRefService.removeData(StorageKeysEnum.USER);
    this.localStorageRefService.removeData(StorageKeysEnum.LANGUAGES);
    this.localStorageRefService.removeData(StorageKeysEnum.PERMISSIONS);
    this.token$.next(null);
    await this.router.navigate(['login']);
  }

  isLoggedIn() {
    return this.token$.value !== null;
  }

  addAuthHeader(request: HttpRequest<any>, user: TokenModel): any {
    if (user && user.access_token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user.access_token}`,
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });
    }
    return request;
  }

  loadPermissions() { }

  private loadFromStorage() {
    return JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.USER)) as TokenModel;
  }

  private handleAuthentication(token: TokenModel) {
    this.token$.next(token);
    this.localStorageRefService.setData(StorageKeysEnum.USER, JSON.stringify(token));
  }

  getLanguage(){
    return this.http.get(this.apiUrl + 'locales');
  }
}
