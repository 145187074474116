<form (ngSubmit)="save()" [formGroup]="form">
    <div class="flex items-center" mat-dialog-title>

        <h2
                class="headline m-0 flex-auto">Edit Inspection Group</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-divider class="text-border"></mat-divider>

    <mat-dialog-content >
        <form [formGroup]="form" class="w-full flex flex-col gap-2">
            <app-tiny-editor (fieldVal)="setEditorControl($event)"
            ></app-tiny-editor>
        </form>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">Cancel</button>
        <button  color="primary" mat-flat-button type="submit" [disabled]="form.invalid" class="flex items-center justify-center">
            <span  *ngIf="!loading"> Add Group</span>
            <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>

        </button>
    </mat-dialog-actions>
</form>


