<form (ngSubmit)="save()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>



    <h2
      class="headline m-0 flex-auto">Update Media</h2>



    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">
    <div class="flex flex-col sm:flex-col">
      <mat-form-field class="flex-auto">
        <mat-label>Media Alt</mat-label>
        <input formControlName="alt" matInput>
      </mat-form-field>

    </div>
  </mat-dialog-content>


  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button color="primary" mat-flat-button type="submit">update Media</button>
  </mat-dialog-actions>
</form>


