import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, Observable, of, filter } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import {BreadcrumbsModule} from '../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {PageLayoutModule} from '../../../../../@vex/components/page-layout/page-layout.module';
import {CommonModule} from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatLegacyCheckboxModule} from '@angular/material/legacy-checkbox';
import {ReviewsService} from './service/reviews.service';
import {ExpertReviewsModel} from '../../models/expert-reviews.model';
import {Router} from '@angular/router';
import {LoadingRowComponent} from '../../../../core/components/loading-row/loading-row.component';


@UntilDestroy()
@Component({
  selector: 'vex-expert-reviews',
  templateUrl: './expert-reviews.component.html',
  styleUrls: ['./expert-reviews.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    BreadcrumbsModule,
    PageLayoutModule,
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatLegacyCheckboxModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    LoadingRowComponent
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class ExpertReviewsComponent implements OnInit, AfterViewInit {

  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<ExpertReviewsModel[]> = new ReplaySubject<ExpertReviewsModel[]>(1);
  data$: Observable<ExpertReviewsModel[]> = this.subject$.asObservable();
  types: ExpertReviewsModel[];

  @Input()
  columns: TableColumn[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Name', property: 'expert_name', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Title', property: 'title', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Years', property: 'years', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<ExpertReviewsModel> | null;
  selection = new SelectionModel<ExpertReviewsModel>(true, []);
  searchCtrl = new UntypedFormControl();
  getLoading:boolean=true;



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private service:ReviewsService, private route: Router) {
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }


  getData() {

    this.service.get().subscribe((res)=>{
      of(res['data'].map(review=>new ExpertReviewsModel(review))).subscribe(reviews=>{
        this.subject$.next(reviews);
        this.getLoading=false;
      });

    });
  }

  ngOnInit() {
    this.getData();
    this.dataSource = new MatTableDataSource();
    this.data$.pipe(
      filter<ExpertReviewsModel[]>(Boolean)
    ).subscribe(types => {
      this.types = types.filter((data)=> data.deleted_at==null);
      this.dataSource.data = this.types;
    });

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  create() {
    this.route.navigate(['/expert-reviews/create' ]).then();

  }

  update(review: ExpertReviewsModel) {

    this.route.navigate(['/expert-reviews/' , review.id]).then();

  }

  delete(review: ExpertReviewsModel) {
    this.service.delete(review).subscribe(()=>{
      this.ngOnInit();
      this.selection.deselect(review);
    });
  }

  deleteAll(types: ExpertReviewsModel[]) {
    types.forEach(c => this.delete(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }
  addOwnerReview(review:number){
    this.route.navigate(['expert-reviews', review?.['id'], 'owner-review'], { queryParams: { productId: review?.['id'] }});

  }

}

