<div class="container rounded-lg shadow bg-white  p-5 " *ngIf="type==MediaTypeEnum.VIDEO">
    <div class=" flex gap-2 items-center mb-4">
        <mat-icon svgIcon="mat:movie"></mat-icon>
        <h2 class="font-semibold ">Product {{type}}</h2>
    </div>
    <label for="file-upload-video" class="flex flex-col justify-center items-center p-3 border-dashed border-gray rounded-lg border cursor-pointer hover:shadow-lg">
        <app-svg-renderer [svgContent]="'Upload'" />
        <h4 class="mt-2 text-center">Upload</h4>
        <input id="file-upload-video" type="file" (change)="onVideoSelected($event)" multiple style="display: none;" />
        <video [src]="videoURL" *ngIf=" videoURL"  class="h-[100px]" height="50" autoplay [muted]="true"></video>
    </label>

</div>
<div class="container rounded-lg shadow bg-white  p-5 " *ngIf="type==MediaTypeEnum.IMAGE">
    <div class=" flex gap-2 items-center mb-4">
        <mat-icon svgIcon="mat:image"></mat-icon>
        <h2 class="font-semibold ">Product  {{title}} </h2>
    </div>
    <label [for]="title" class="flex flex-col justify-center items-center p-3 border-dashed border-gray rounded-lg border cursor-pointer hover:shadow-lg">
        <app-svg-renderer [svgContent]="'Upload'" />
        <h4 class="mt-2 text-center">Upload</h4>
        <input type="file" [id]="title" #filePicker (change)="onImagePickedCover($event)" style="display: none"  />
        <img class='image mb-4 justify-center items-center max-w-full' [src]="selectedCover" (error)="setDefaultImage($event)">
    </label>

</div>
