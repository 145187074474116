import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthService} from '../../pages/pages/auth/service/auth.service';
import {ErrorExceptionModel} from '../../pages/project/models/error-exception.model';
import {ErrorExceptionType} from '../enum/error-exception-type.enum';
import {HttpErrorModel} from '../../pages/project/models/http-error.model';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((httpErrorResponse) => {
        return this.handleResponseError(httpErrorResponse);
      })
    );
  }

  handleResponseError(httpErrorResponse: any) {
    let errorException: ErrorExceptionModel;
    let errorMessages: string[] = [];
    if (httpErrorResponse.error instanceof ErrorEvent) {
      errorMessages.push('Something went wrong, please try again');
      errorException = new ErrorExceptionModel(ErrorExceptionType.CLIENT_ERROR, errorMessages);
    } else {
      switch (httpErrorResponse.status) {
        case 401: {
          errorMessages.push(httpErrorResponse.error.message);
          errorException = new ErrorExceptionModel(ErrorExceptionType.NO_TOKEN, errorMessages);
          this.authService.logout();
          break;
        }
        case 403: {
          errorMessages.push(httpErrorResponse.error.message);
          errorException = new ErrorExceptionModel(ErrorExceptionType.FORBIDDEN, errorMessages);
          break;
        }
        case 404: {
          errorMessages.push(httpErrorResponse.error.message);
          errorException = new ErrorExceptionModel(ErrorExceptionType.NOT_FOUND, errorMessages);
          break;
        }
        case 409: {
          errorMessages.push(httpErrorResponse.error.message);
          errorException = new ErrorExceptionModel(ErrorExceptionType.CONFLICT, errorMessages);
          break;
        }
        case 422: {
          const errorResponse = httpErrorResponse.error;
          if (errorResponse && errorResponse.errors && typeof errorResponse.errors === 'object') {
            for (const key in errorResponse.errors) {
              if (Object.prototype.hasOwnProperty.call(errorResponse.errors, key)) {
                const errorMessage = errorResponse.errors[key];
                if (Array.isArray(errorMessage)) {
                  errorMessages.push(...errorMessage);
                } else {
                  errorMessages.push(errorMessage);
                }
              }
            }
          } else {
            errorMessages.push('Unprocessable Entity');
          }
          errorException = new ErrorExceptionModel(ErrorExceptionType.VALIDATION, errorMessages);
          break;
        }
        case 500: {
          errorMessages.push(httpErrorResponse.error.message);
          errorException = new ErrorExceptionModel(ErrorExceptionType.INTERNAL_SERBER_ERROR, errorMessages);
          break;
        }
        default: {
          errorMessages.push('Something went wrong, please try again');
          break;
        }
      }
    }
    return throwError(() => errorMessages);
  }

  getErrorMessage422(errorMessages: any) {
    const errorObj = errorMessages.error;

    const errorData = errorObj.data;

    const errorMessage = errorObj.message ?? 'Oh Snap!';
    const errorList: Array<string> = [];
    const errorStatusCode = errorObj.status_code;

    for (const item in errorData) {
      if (item) {
        errorList.push(errorData[item]);
      }
    }

    return new HttpErrorModel(errorMessage, errorList, errorStatusCode);
  }

  getErrorMessage403(errorMessages: any) {
    const errorObj = errorMessages.error;

    const errorData = errorObj?.data;

    const errorMessage = errorObj.message ?? 'Oh Snap!';
    const errorList: Array<string> = [];
    const errorStatusCode = errorObj.status_code;

    for (const item in errorData) {
      if (item) {
        errorList.push(errorData[item]);
      }
    }

    return new HttpErrorModel(errorMessage, errorList, errorStatusCode);
  }

  getErrorMessage409(errorMessages: any) {
    const errorObj = errorMessages.error;

    const errorData = errorObj?.data;

    const errorMessage = errorObj.message ?? 'Oh Snap!';
    const errorList: Array<string> = [];
    const errorStatusCode = errorObj.status_code;

    for (const item in errorData) {
      if (item) {
        errorList.push(errorData[item]);
      }
    }

    return new HttpErrorModel(errorMessage, errorList, errorStatusCode);
  }
}
