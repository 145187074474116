import { Component, OnInit, TemplateRef, ViewChild , ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CalendarView, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import {
  startOfDay,
  isSameMonth,
  isSameDay,
  endOfDay
} from 'date-fns';
import { Subject } from 'rxjs';
import {BookingService} from './service/booking.service';
import {ShowEventComponent} from './show-event/show-event.component';
import {Brand} from "../../models/brand.model";
import {BookingTourModel} from "../../models/booking-tour.model";
const colors: any = {
  blue: {
    primary: '#5c77ff',
    secondary: '#FFFFFF'
  },
  yellow: {
    primary: '#ffc107',
    secondary: '#FDF1BA'
  },
  red: {
    primary: '#f44336',
    secondary: '#FFFFFF'
  }
};
@Component({
  selector: 'vex-booking-free-tour',
  templateUrl: './booking-free-tour.component.html',
  styleUrls: ['./booking-free-tour.component.scss'],
  encapsulation: ViewEncapsulation.None

})

export class BookingFreeTourComponent implements OnInit{
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  loading= true;
  models=[];
  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  number = [1,2,3,4,5];
  refresh: Subject<any> = new Subject();
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  events: CalendarEvent[] = [];
  activeDayIsOpen = false;

  constructor(private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private service : BookingService
              ) {
              }
   ngOnInit(): void {
   this.getData();

  }
  async getData() {
    this.events=[];
     this.service.get().subscribe((res => {
       this.models = res['data'];

      res['data'].forEach(t => {
        const data = {
          // start: new Date('2024-05-07T21:00:00.000000Z'),
          start: new Date(t.scheduled_at),
          end: new Date(t.end_time),
          title: 'meeting with ' + t.full_name,
          color: t.status!='PENDING'?colors.primary:t.status!='ACCEPTED'?colors.blue:colors.red,
          actions: this.actions,
          allDay: false,
          resizable: {
            beforeStart: true,
            afterEnd: true
          },
          draggable: true,
          id : t.id,
          status : t.status,
          email : t.email

        };
        this.events.push(data);
      });
       this.loading=false;
    }));




  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0);
      this.viewDate = date;
    }
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    console.log('event');
    console.log(event);
    console.log('event');
    const dialogRef = this.dialog.open(ShowEventComponent, {
      data: event
    });

    dialogRef.afterClosed().subscribe(result => {



      if (result) {
        this.loading = true;
          this.ngOnInit();
      }
    });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
