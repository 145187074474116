import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {CalendarEvent} from 'angular-calendar';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder} from '@angular/forms';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LoadingRowComponent} from '../../../../../core/components/loading-row/loading-row.component';
import {CapitalizePipe} from '../../../../../core/pipes/capitalize.pipe';
import {BookingService} from '../service/booking.service';

@Component({
  selector: 'vex-show-event',
  templateUrl: './show-event.component.html',
  styleUrls: ['./show-event.component.scss'],
  standalone : true,
  imports: [
    MatIconModule,
    ReactiveFormsModule,
    MatTableModule,
    MatCheckboxModule,
    NgClass,
    PageLayoutModule,
    BreadcrumbsModule,
    MatMenuModule,
    FormsModule,
    MatPaginatorModule,
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    LoadingRowComponent,
    MatDialogModule,
    CapitalizePipe
  ]
})
export class ShowEventComponent {


  constructor(
    private service : BookingService,
    private dialogRef: MatDialogRef<ShowEventComponent>,
    @Inject(MAT_DIALOG_DATA) public event: any,
    private fb: UntypedFormBuilder) {

  }

  accept() {
    this.service.accept(this.event.id).subscribe((res => {
      this.dialogRef.close(true);
    }));
  }

  reject() {
    this.service.reject(this.event.id).subscribe((res => {
      this.dialogRef.close(true);
    }));
  }
}
