import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from '../../pages/pages/auth/service/auth.service';

// const API_URL = environment.apiUrl;
const API_URL = '';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {
    }

    private static appendApiUrl(request: HttpRequest<any>) {
        if (!request.url.endsWith('.json')) {
            request = request.clone({url: API_URL + request.url,setHeaders:{'x-timezone': this.getTimeZoneOffsetAsString()}});
        }
        return request;
    }
  private static getTimeZoneOffsetAsString() {
         const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
         return timeZoneString;
       }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const user = this.authService.token$.value;

        // Append API URL
        request = TokenInterceptor.appendApiUrl(request);

        // Request not requires access token
      if (!user ||
        request.url.includes('/auth/get-access-token') ||
        request.url.includes('get-access-token')
      ) {
       

        return next.handle(request);
      }

        // Handle response
      return next.handle(this.authService.addAuthHeader(request, user)).pipe(
        map((event: HttpEvent<any>) => {
          return event;
        }),
        catchError((error) => this.handleResponseError(error, request))
      );
    }

    handleResponseError(error: any, request: HttpRequest<any>) {
        if (request.url.includes('/auth/logout')) {
            return throwError(error);
        }

        if (error.status === 401 || error.errorType == 'noToken') {
            this.authService.logout();
        }
        return throwError(error);

    }

}
