import {MediaObjectModel} from './media-object.model';

export class Category {
    id: number;
    name: string;
    description: string;
    slug: string;
    img: string;
    popular_img: string;
    image_alt: string;
    type: string;
    is_popular: string;
    is_featured: string;
    parent_name: string;
    parent_id: string;
    popular_image: any;
    image: any;
    media : MediaObjectModel;
    parent:string;
    specifications:any;
    template:any;
    deleted_at:string;
    translations : [
      {
        locale:string;
        name:string;
      }
    ];
    constructor(obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.description = obj.description;
        this.slug = obj.slug;
        this.img = obj.image? obj.image.original_url : null;
        this.popular_img = obj.popular_image? obj.popular_image.original_url : null;
        this.image_alt = obj.media && obj.media.length > 0 ? obj.media[0].custom_properties.alt : null;
        this.media = obj.media && obj.media.length > 0 ? new MediaObjectModel(obj.media[0]) : null;
        this.parent=obj.parent;
        this.type = obj.type;
        this.parent_id = obj.parent_id;
        this.parent_name=obj.parent?obj.parent.name:null;
        this.is_popular = obj.is_popular;
        this.is_featured = obj.is_featured;
        this.specifications = obj.specifications;
        this.template = obj.template;
        this.deleted_at = obj.deleted_at;
        this.translations = obj.translations;
    }
}




