export class BusinessModel {
  id: number;
  key:string;
  value:any;

    constructor(obj) {
        this.id = obj.id;
        this.key = obj.key;
        this.value = obj.value;

    }
}
