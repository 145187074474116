<form (ngSubmit)="save()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>

    <h2 *ngIf="mode=='create'" class="headline m-0 flex-auto">New Collection</h2>
    <h2 *ngIf="mode!='create'" class="headline m-0 flex-auto">Update Collection</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">
    <div class="flex flex-col sm:flex-col">
      <div *ngIf="mode=='create'">
        <mat-form-field class="w-full">
          <mat-label>Collection name</mat-label>
          <input formControlName="name" matInput>
        </mat-form-field>
        <mat-form-field class="w-full">
          <mat-label>Media Type</mat-label>
          <select matNativeControl required formControlName="type">
            <option *ngFor="let item of types" [ngValue]="item['id']">{{item['name']}}</option>
          </select>
        </mat-form-field>
      </div>
      <input type="file" (change)="onFileSelected($event)" multiple/>
      <form [formGroup]="dynamicForm"
            *ngIf="files?.length>0">
        <div class="flex  flex-wrap gap-1 ">
          <ng-container *ngFor="let item of files; let i = index" >
            <div class="flex flex-col gap-3  items-center pt-2 px-2" >
              <img *ngIf="form.value?.['type']== MediaTypeEnum.IMAGE" class="w-[150px]" [src]="fileArray[i]?.['dataURL']" />
              <video *ngIf="form.value?.['type']== MediaTypeEnum.VIDEO"  [src]="fileArray[i]?.['dataURL']"   class="h-[100px]" height="50" autoplay muted></video>
              <mat-form-field class="flex-auto " >
                <mat-label>Description</mat-label>
                <input [type]="'text'"  matInput [formControlName]="'description_'+i" >
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </form>


    </div>

  </mat-dialog-content>



  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button  color="primary" mat-flat-button type="submit" [disabled]="form.invalid" class="flex items-center justify-center">
        <span  *ngIf="!loading && mode=='create'"> Add Collection</span>
        <span  *ngIf="!loading && mode!='create'"> Update Collection</span>
        <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>

    </button>
  </mat-dialog-actions>
</form>


