import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { SelectionModel } from '@angular/cdk/collections';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, Observable, of, filter } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import {SellYourMachineService} from './service/sell-your-machine.service';
import {SellYourMachoneModel} from '../../models/sell-your-machone.model';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {BreadcrumbsModule} from '../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {PageLayoutModule} from '../../../../../@vex/components/page-layout/page-layout.module';
import {CommonModule, NgClass} from '@angular/common';
import {LoadingRowComponent} from '../../../../core/components/loading-row/loading-row.component';



@UntilDestroy()
@Component({
  selector: 'vex-sell-your-machine',
  templateUrl: './sell-your-machine.component.html',
  styleUrls: ['./sell-your-machine.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    MatPaginatorModule,
    MatMenuModule,
    MatCheckboxModule,
    MatIconModule,
    FormsModule,
    BreadcrumbsModule,
    PageLayoutModule,
    ReactiveFormsModule,
    MatTableModule,
    NgClass,
    CommonModule,
    LoadingRowComponent
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class SellYourMachineComponent implements OnInit, AfterViewInit {

    layoutCtrl = new UntypedFormControl('boxed');

    /**
     * Simulating a service with HTTP that returns Observables
     * You probably want to remove this and do all requests in a service with HTTP
     */
    subject$: ReplaySubject<SellYourMachoneModel[]> = new ReplaySubject<SellYourMachoneModel[]>(1);
    data$: Observable<SellYourMachoneModel[]> = this.subject$.asObservable();
    types: SellYourMachoneModel[];

    @Input()
    columns: TableColumn[] = [
        { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
        { label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium'] },
        { label: 'Full Name', property: 'full_name', type: 'text', visible: true, cssClasses: ['font-medium'] },
        { label: 'Email', property: 'email', type: 'text', visible: true, cssClasses: ['font-medium'] },
        { label: 'Phone Number', property: 'phone_number', type: 'text', visible: true, cssClasses: ['font-medium'] },
        { label: 'Company', property: 'company', type: 'text', visible: true, cssClasses: ['font-medium'] },
        { label: 'Status', property: 'status', type: 'text', visible: true, cssClasses: ['font-medium'] },
        { label: 'Actions', property: 'actions', type: 'button', visible: true }
    ];
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 20, 50];
    dataSource: MatTableDataSource<SellYourMachoneModel> | null;
    selection = new SelectionModel<SellYourMachoneModel>(true, []);
    searchCtrl = new UntypedFormControl();
    getLoading:boolean=true;



    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private dialog: MatDialog,private service:SellYourMachineService,private route:Router) {
    }

    get visibleColumns() {
        return this.columns.filter(column => column.visible).map(column => column.property);
    }


    getData() {
        this.service.get().subscribe((res)=>{
            of(res['data'].map(type=>new SellYourMachoneModel(type))).subscribe(types=>{
                this.subject$.next(types);
                this.getLoading=false;
            });

        });
    }
  review(product : any) {
    this.route.navigate(['/sell-your-machine/' , product.id]).then();

  }

    ngOnInit() {
        this.getData();

        this.dataSource = new MatTableDataSource();

        this.data$.pipe(
            filter<SellYourMachoneModel[]>(Boolean)
        ).subscribe(types => {
            this.types = types;
            this.dataSource.data = types;
        });

        this.searchCtrl.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(value => this.onFilterChange(value));
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }






    onFilterChange(value: string) {
        if (!this.dataSource) {
            return;
        }
        value = value.trim();
        value = value.toLowerCase();
        this.dataSource.filter = value;
    }

    toggleColumnVisibility(column, event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        column.visible = !column.visible;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    trackByProperty<T>(index: number, column: TableColumn) {
        return column.property;
    }

    showSpecification(category:SellYourMachoneModel){
        this.route.navigate(['/categories/specification',category.id]);
    }
  delete(sell: SellYourMachoneModel) {
    this.service.delete(sell).subscribe(()=>{
      this.ngOnInit();
      this.selection.deselect(sell);
    });
  }
}

