import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule, NgClass} from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LoadingRowComponent} from '../../../../../core/components/loading-row/loading-row.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatFormFieldDefaultOptions,
    MatFormFieldModule
} from '@angular/material/form-field';
import {
  ConfirmationDialogComponent
} from '../../../../../core/components/confirmation-dialog/confirmation-dialog.component';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {
    CdkDrag,
    CdkDragDrop,
    CdkDragPlaceholder,
    CdkDropList,
    CdkDropListGroup,
    moveItemInArray
} from '@angular/cdk/drag-drop';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialog} from '@angular/material/dialog';
import {ProductService} from '../service/product.service';
import {ToastrService} from 'ngx-toastr';
import {ReviewsService} from '../../expert-reviews/service/reviews.service';
import {environment} from '../../../../../../environments/environment';
import {UpdateMediaComponent} from '../component/update-media/update-media.component';
import {CreateMediaComponent} from '../create-media/create-media.component';
import {ResearchService} from "../../research/service/research.service";
import {Router} from "@angular/router";
import {MatInputModule} from "@angular/material/input";
import {MatTabsModule} from "@angular/material/tabs";

@Component({
  selector: 'app-product-media',
  templateUrl: './product-media.component.html',
  styleUrls: ['./product-media.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
    imports: [
        MatMenuModule,
        NgClass,
        MatCheckboxModule,
        BreadcrumbsModule,
        PageLayoutModule,
        MatIconModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        FormsModule,
        MatSlideToggleModule,
        CommonModule,
        LoadingRowComponent,
        MatDatepickerModule,
        MatNativeDateModule,
        CdkDropList,
        MatProgressSpinnerModule,
        CdkDropListGroup,
        CdkDrag,
        CdkDragPlaceholder,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule
    ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class ProductMediaComponent implements OnInit {
  @Input() groupedMediaArray;
  @Input() productID ;
  @Input() type = 'Product' ;
  @Input() loadingMedia = false ;
  @Output() loadMedia = new EventEmitter<string>();
  service;
    ngOnInit() {
    if(this.type=='Product'){
      this.service = this.serviceProduct;
    }else{
      this.service = this.serviceExpert;
    }
  }
  constructor(
    private dialog: MatDialog,
    private serviceProduct: ProductService,
    private serviceExpert: ResearchService,
    private toastr: ToastrService,
    private route: Router

  ) {
  }
  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }
  DeleteMedia(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: 'Delete Media Image',
        message: 'Are you sure you want to perform this action?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.deleteMedia(id, this.productID).subscribe((res) => {
          if (res['status'] == ErrorStatusModel.SUCCESS) {
            this.toastr.success('media Deleted Successfully!');
            this.loadMedia.emit('loadMedia');
          }
        }, error => {
          this.toastr.error(error);
        });
      }else {

      }});


  }
  updateMediaHotspots(media , collection){
    this.route.navigate(['/products' ,this.productID , media.id , 'panorama']).then();
  }
  updateMedia(media){

    const dialogRef = this.dialog.open(UpdateMediaComponent, {
      width: '250px',
      data: {
        description: media['custom_properties']['description']
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.groupedMediaArray.forEach((coll)=>{
          coll.media.forEach((m)=>{
            if(m.id==media.id){
              // @ts-ignore
              m['custom_properties']['description']=result;
            }
          });
        });
      }else {

      }});
  }
  DeleteMediaCollection( name) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: 'Delete Media Image',
        message: 'Are you sure you want to perform this action?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.deleteMediaCollection(this.productID , name).subscribe((res) => {
          if (res['status'] == ErrorStatusModel.SUCCESS) {
            this.toastr.success('media Deleted Successfully!');
              this.loadMedia.emit('loadMedia');
          }
        }, error => {
          this.toastr.error(error);
        });
      }else {

      }});


  }
  downloadImageCollection(){

  }
  handleImageError(event: any) {
    event.target.src = 'assets/img/demo/no-img.jpeg';
  }
  drop(event: CdkDragDrop<any> , index) {
    moveItemInArray(this.groupedMediaArray[index].media, event.previousContainer.data.index, event.container.data.index);
  }

  protected readonly environment = environment;
  AddMedia(collection) {
    this.dialog.open(CreateMediaComponent, {
      data: {
        id: this.productID,
        name: collection,
        mode:'update',
        type:this.type

      }
    }).afterClosed().subscribe((res: any) => {
      this.loadMedia.emit('loadMedia');
      // if (res)
        // this.getMedia();
    });
  }
  isVideo(mime){
    return mime.startsWith('video');
  }
}
