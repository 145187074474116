import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatIconModule} from '@angular/material/icon';
import {PageLayoutModule} from '../../../../../../../@vex/components/page-layout/page-layout.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BreadcrumbsModule} from '../../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LoadingRowComponent} from '../../../../../../core/components/loading-row/loading-row.component';
import {MatTabsModule} from '@angular/material/tabs';
import {PageLayoutDemoModule} from '../../../../../ui/page-layouts/page-layout-demo/page-layout-demo.module';
import {RouterLinkActive} from '@angular/router';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {AuctionService} from '../../../auction/service/auction.service';
import {PusherService} from '../../service/pusher.service';
import Echo from 'laravel-echo';
import {PusherChannels} from '../../../../../../core/enum/pusher-channels.enum';
import {PusherEvents} from '../../../../../../core/enum/pusher-events.enum';
import {ListingStatusUpdatedEventModel} from '../../../../models/listing-status-updated-event.model';
import {AppModule} from '../../../../../../app.module';
import {AddBidsComponent} from '../add-bids/add-bids.component';
import {AuctionStatus} from '../../../../../../core/enum/auctions/auction-status.enum';

@Component({
  selector: 'vex-ongoing-details',
  templateUrl: './ongoing-details.component.html',
  styleUrls: ['./ongoing-details.component.scss'],
  standalone: true,
  imports: [
    ScrollingModule,
    MatIconModule,
    PageLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    NgClass,
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbsModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    LoadingRowComponent,
    MatTabsModule,
    PageLayoutDemoModule,
    RouterLinkActive,
    MatRippleModule,
    MatOptionModule,
    MatSelectModule,
    AddBidsComponent
  ]
})

export class OngoingDetailsComponent {
  @Input() model;
  @Input() from_details;
  @Input() ring_id;
  @Output() getData = new EventEmitter<boolean>();
  @Output() getBids = new EventEmitter<any>();
  bids_count ;
  current_price;
  loading=false;
  activeBids;
constructor(
  private auctionService : AuctionService
) {
}

  ngOnInit() {
  }






  // ngOnChanges(changes: SimpleChanges): void {
  //
  //   if(changes.model){
  //     this.current_price = this.model?.listing?.current_price?.amount;
  //     this.bids_count = this.model?.listing?.bids_count;
  //   }
  // }
  changeListingStatus(status:string) {
    this.loading=true;
    const formdata = new FormData();
    formdata.append('id',this.model.product?.listing.id);
    formdata.append('status',status);
    this.auctionService.changeItemsStatus(formdata).subscribe((res)=>{
      this.loading = false;
    });
  }

  protected readonly AuctionStatus = AuctionStatus;
}
