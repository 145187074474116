import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {NewsTagsModel} from '../../../models/news-tags.model';

@Injectable({
  providedIn: 'root'
})
export class NewsTagsService {

  constructor(private http:HttpClient) { }

  get(){
    return this.http.get(environment.baseUrl + 'tag/tags/');
  }
  create(model:any){
    return this.http.post(environment.baseUrl + 'tag/tags/save',model);
  }
  delete(model:NewsTagsModel){
    return this.http.delete(environment.baseUrl + 'tag/tags/'+model.id);
  }
}
