import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  constructor(private http:HttpClient) { }

  all(){
    return this.http.get(environment.baseUrl + 'shipment/zones/');
  }
  get(id:number){
    return this.http.get(environment.baseUrl + 'shipment/zones/'+id);

  }

  create(model:FormData){
    return this.http.post(environment.baseUrl + 'shipment/zones/save',model);
  }
  delete(id:number){
    return this.http.delete(environment.baseUrl + 'shipment/zones/'+id);
  }
}
