<form (ngSubmit)="save()" [formGroup]="form" style="width: 655px">
    <div class="flex items-center" mat-dialog-title>
      <h2 class="headline m-0 flex-auto"> Category Featured Products </h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <mat-dialog-content class="flex flex-col">
              <mat-form-field class="flex-auto w-full ">
                  <mat-label>Products</mat-label>
                  <mat-select matNativeControl multiple  formControlName="products" >
                      <mat-option *ngFor="let item of products" [value]="item['id']">{{item['title']}}</mat-option>
                  </mat-select>
              </mat-form-field>
      <div class="flex flex-wrap gap-3 justify-center" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <div *ngFor="let item of selectedProducts; let i=index" [cdkDragData]="item" cdkDrag>
          <div class="media-card relative cursor-grab m-2 flex flex-col justify-content-center items-center w-fit" >
            <div class=" " *cdkDragPlaceholder></div>
            <div class="overlay"></div>
            <img [src]="getCover(item)"
                 (error)="setDefaultImage($event)"
                 class="w-[175px] max-h-[100px] align-middle my-2"
                 alt="{{item?.title}}">
            <span class="max-w-[175px] text-center">{{item?.title}}</span>
          </div>
        </div>
      </div>
      </mat-dialog-content>
  <mat-dialog-actions align="end" >
<!--    <button mat-button mat-dialog-close type="button" [disabled]="loading">Cancel</button>-->
    <button *ngIf="isCreateMode() && !loading" color="primary" mat-flat-button type="submit" [disabled]="form.invalid">Save</button>
    <button *ngIf="isUpdateMode() &&  !loading " color="primary" mat-flat-button type="submit" [disabled]="form.invalid">Save</button>
    <button mat-flat-button [disabled]="true" *ngIf="loading ">
      <mat-progress-spinner diameter="30" mode="indeterminate"></mat-progress-spinner>
    </button>
  </mat-dialog-actions>



  </form>


