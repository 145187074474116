import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {ApplicationModel} from '../../../models/application.model';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private http:HttpClient) {

  }

  get(location){
    return this.http.get(environment.baseUrl + `banner/banner?filters[location]=${location}`);
  }
  save(app:any){
    return this.http.post(environment.baseUrl + 'banner/banner/save',app);
  }
  delete(id:number){
    return this.http.delete(environment.baseUrl + 'banner/banner/'+id);
  }
}
