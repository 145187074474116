import {Component, OnInit, SecurityContext, ViewEncapsulation} from '@angular/core';
import {NgForOf, NgIf, UpperCasePipe} from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    UntypedFormControl
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {QuillEditorComponent} from 'ngx-quill';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {ToastrService} from 'ngx-toastr';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NewsService} from '../service/news.service';
import {CategoryNewsService} from '../../category-news/service/category-news.service';
import {NewsModel} from '../../../models/news.model';
import {Editor, NgxEditorModule, Toolbar} from 'ngx-editor';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {EditorModule} from '@tinymce/tinymce-angular';
import {AngularFireStorage, AngularFireStorageModule} from '@angular/fire/compat/storage';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {StorageKeysEnum} from '../../../../../core/enum/storage-keys.enum';
import {LocalStorageRefService} from '../../../../pages/auth/service/local-storage-ref.service';
import {MatTabsModule} from '@angular/material/tabs';
import {TinyEditorComponent} from '../../../../../core/components/tiny-editor/tiny-editor.component';
import {NewsTagsService} from '../../news-tags/service/news-tags.service';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';


@Component({
    selector: 'vex-news-create-update',
    templateUrl: './news-create-update.component.html',
    styleUrls: ['./news-create-update.component.scss',
        '../../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../../@vex/styles/partials/plugins/quill/_quill.scss'],
  imports: [
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgForOf,
    MatButtonModule,
    NgIf,
    QuillEditorComponent,
    PageLayoutModule,
    MatSlideToggleModule,
    NgxEditorModule,
    EditorModule, AngularFireStorageModule, MatProgressSpinnerModule,
    AutocompleteLibModule, MatTabsModule, UpperCasePipe, TinyEditorComponent, MatOptionModule, MatSelectModule

  ],
    encapsulation: ViewEncapsulation.None,
    animations: [fadeInUp400ms],
    standalone: true
})
export class NewsCreateUpdateComponent implements OnInit {
    newsId;
    numbers: number[] = Array.from({length: 10}, (_, i) => i + 1);
    formDesc;
    form: UntypedFormGroup;
    mode: 'create' | 'update' = 'create';
    categories = [];
    news = [];
    tags = [];
    content = '';
    defaults: NewsModel;

    tag = '';
    selectedFile: string = 'assets/img/demo/missing-picture.jpeg';
    editor: Editor;
    toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify']
  ];
  keyword = 'name';
  prevTags = [
  ];
  loading =false;
  source: string = '';
  title = 'images-tinymce';
  locale;
  defLocale;
  loadingForm=false;
  transValues =[];
  editorVal='';
    constructor(
      private sanitizer: DomSanitizer,
      private storage: AngularFireStorage,
      private route: ActivatedRoute,
      private router: Router,
      private fb: UntypedFormBuilder,
      private service: NewsService,
      private toastr: ToastrService,
      private categoryNewsService: CategoryNewsService,
      private newsTagsService: NewsTagsService,
      private localStorageRefService : LocalStorageRefService
    ) {
    }
  onTabChanged(event){
    this.loadingForm =true;
    this.form.get('news_category_id').setValue(this.form.value.news_category_id);
    this.form.get('reading_duration').setValue(this.form.value.reading_duration);
    this.form.get('tag')?.setValue(this.form.value.tag);
    setTimeout(()=>{
      this.editorVal = this.getTranslationField(this.locale[event.index]?.locale);
      this.loadingForm =false;
    },200);
  }
  ngOnInit() {
      this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
      this.defLocale = this.locale.filter(loc=>loc.default==true)[0];

      this.editor = new Editor();
        this.newsId = this.route.snapshot.paramMap.get('id');
        if (this.newsId ) {
            this.getData();
            this.mode = 'update';

        } else {
            this.defaults = {} as NewsModel;
            this.selectedFile='assets/img/demo/missing-picture.jpeg';

        }

        this.form = this.fb.group({
            id: [],
            news_category_id:['', Validators.required],
            reading_duration: [''],
            media: ['', Validators.required],
            show_home : [],
            related_news : [],
          tag:[[]]

        });
      this.locale.forEach(lang => {

        this.form.addControl(lang.locale+'_title', this.fb.control(''));
        this.form.addControl(lang.locale+'_description', this.fb.control(''));
        this.form.addControl(lang.locale+'_content', this.fb.control(''));
      });
        this.getCats();
        this.getTags();
        this.getNews();
    }

    getTags(){
      this.newsTagsService.get().subscribe((res) => {
        this.tags = res['data'];
      });
    }
    onImagePicked(event: Event) {
        const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
        if (file) {
            this.form.patchValue({media: file});
            const reader = new FileReader();
            reader.onload = (e) => {
                this.selectedFile = e.target.result as string;
            };
            reader.readAsDataURL(file);
        }

    }
    getData() {
        this.service.getNews(this.newsId).subscribe((res) => {
            this.defaults = res['data'];
            this.initForm();
        });
    }
  getCats() {
        this.categoryNewsService.get().subscribe((res) => {
            this.categories = res['data'];
        });
    }
    // getTags() {
    //     this.service.getTags().subscribe((res) => {
    //         this.prevTags = res['data'];
    //     });
    // }
  getNews(){
    this.service.get().subscribe((res) => {
      this.news = res['data'];
    });
  }
    initForm() {
         this.form=this.fb.group({
            id: [this.defaults?.id],
            news_category_id: [this.defaults?.news_category_id ],
            title: [this.defaults?.title],
            content: [this.defaults?.content],
            description: [this.defaults?.description],
            reading_duration: [this.defaults?.reading_duration],
            show_home: [this.defaults?.show_home],
            related_news: [this.defaults?.related_news],
            // tags:[this.defaults?.tags],
            media:[this.defaults?.media],
            tag:[this.defaults?.tags.map((app: any) => app.id)]
        });
      this.locale.forEach(lang => {
        const value = this.defaults.translations.filter(brand => brand.locale == lang.locale);

        this.form.addControl(lang.locale+'_title', this.fb.control(value[0]?.title??''));
        this.form.addControl(lang.locale+'_content', this.fb.control(value[0]?.content??''));
        this.form.addControl(lang.locale+'_description', this.fb.control(value[0]?.description??''));

          const textValues = {
              lang : lang.locale,
              value : value[0]?.content??' '
          };
          this.transValues.push(textValues);
      });
        this.loadingForm =true;
        setTimeout(()=>{
            this.editorVal = this.getTranslationField(this.locale[0]?.['locale']);
            this.loadingForm =false;
        },50);

      // @ts-ignore
      // this.tags = this.defaults.tags.map((d) => d['en']['name']);

      // this.tags = this.defaults.tags;
        this.content = this.defaults?.content;
        this.formDesc = new UntypedFormControl(this.defaults?.content);
        this.selectedFile = this.defaults?.media?.['url'];


    }

    save() {
        this.loading=true;
        if (this.mode === 'create') {
            this.create();
        } else if (this.mode === 'update') {
            this.update();
        }
    }

    onContentChanged(event) {
        this.content = event.html;
    }

    addTag(){
        if(this.keyword != ''){
            this.tags.push(this.keyword);
        }
    }
    removeTag(index : number){
        this.tags.splice(index,1);
    }
  sanitizeHtmlContent(htmlstring): SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, htmlstring);
  }

    create() {
        const formData = new FormData();
        formData.append('title', this.form.value[this.defLocale.locale +'_title']);
        formData.append('content', this.form.value[this.defLocale.locale +'_content']);
        formData.append('description', this.form.value[this.defLocale.locale +'_description']);
        formData.append('reading_duration', this.form.value.reading_duration);
        formData.append('news_category_id', this.form.value.news_category_id);
        formData.append('media[0]', this.form.value.media);
        formData.append('show_home', this.form.value?.show_home?'1':'0');
      this.form.value.related_news?.forEach((item, index) => {
        formData.append(`related_news[${index}]`, item.toString());
      });
        // this.tags.forEach((item, index) => {
        //     formData.append(`tags[${index}]`, item);
        // });
      // this.form.get('tag').value.map((t,index)=>{
      //   formData.append(`tags[${index}]`, t.id);
      // });
      this.form.value.tag?.forEach((item, index) => {
        formData.append(`tags[${index}]`, item.toString());
      });
        this.locale.filter(lang => lang.default==0 ).forEach(lang =>{
        if(this.form.value[lang.locale + '_content']!='')
          formData.append(lang.locale +'[content]',this.form.value[lang.locale + '_content']);

          formData.append(lang.locale +'[title]',this.form.value[lang.locale + '_title']);
          formData.append(lang.locale +'[description]',this.form.value[lang.locale + '_description']);
      });
        this.service.create(formData).subscribe((res) => {
            if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
                this.toastr.success('News Created Successfully!');
                this.loading = false;
                this.router.navigate(['/news/' + res?.['data']?.['id']]).then();
            }

        }, error => {
            this.toastr.error(error);
            this.loading = false;
        });
    }

    update() {
      const formData = new FormData();
        formData.append('id', this.newsId);
        formData.append('title', this.form.value[this.defLocale.locale +'_title']);
        formData.append('content', this.form.value[this.defLocale.locale +'_content']);
        formData.append('description', this.form.value[this.defLocale.locale +'_description']);
        formData.append('reading_duration', this.form.value.reading_duration);
        formData.append('news_category_id', this.form.value.news_category_id);
        formData.append('show_home', this.form.value?.show_home?'1':'0');
        this.form.value.related_news?.forEach((item, index) => {
          formData.append(`related_news[${index}]`, item.toString());
        });
        if (this.form.value.media instanceof File){
            formData.append('media[0]', this.form.value.media);
        }
      // this.form.get('tag').value.map((t,index)=>{
      //   formData.append(`tags[${index}]`, t.id);
      // });
      this.form.value.tag?.forEach((item, index) => {
        formData.append(`tags[${index}]`, item.toString());
      });
        // this.tags.forEach((item, index) => {
        //     formData.append(`tags[${index}]['en']['name']`, item);
        //     formData.append(`tags[${index}]['ar']['name']`, item);
        // });
        this.locale.filter(lang => lang.default==0 ).forEach(lang =>{
        if(this.form.value[lang.locale + '_content']!='')
          formData.append(lang.locale +'[content]',this.form.value[lang.locale + '_content']);
          if(this.form.value[lang.locale + '_title']!='')
        formData.append(lang.locale +'[title]',this.form.value[lang.locale + '_title']);
          if(this.form.value[lang.locale + '_description']!='')
        formData.append(lang.locale +'[description]',this.form.value[lang.locale + '_description']);
        });
        this.service.create(formData).subscribe((res) => {
            if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
                this.toastr.success('News Updated Successfully!');
                this.router.navigate(['/news/' + res?.['data']?.['id']]).then();
                this.loading = false;

            }

        }, error => {
            this.toastr.error(error);
            this.loading = false;

        });
    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }
    onCancel(){
        this.router.navigate(['/news']).then();
    }
  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }



  selectEvent(item) {
    this.keyword = item;
  }

  onChangeSearch(val: string) {
    this.keyword = val;
  }

  onFocused(e){

  }
  setEditorControl(event:any , lang:string , field : string){
    this.form.get(lang + '_' + field).setValue(event);
      const index = this.transValues.findIndex(item => item.lang === lang);
      if (index !== -1) {
          this.transValues[index] = { ...this.transValues[index], value: event };
      } else {
          this.transValues.push({ lang, value: event });
      }
  }
  getTranslationField(locale : string){
      return this.transValues?.find(obj => obj?.['lang'] === locale)?.['value'];
  }
}
