import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AuctionService} from '../../../service/auction.service';
import {CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {LiveShowChatComponent} from '../../../../live-show/component/live-show-chat/live-show-chat.component';
import {LoadingRowComponent} from '../../../../../../../core/components/loading-row/loading-row.component';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {OngoingDetailsComponent} from '../../../../live-show/component/ongoing-details/ongoing-details.component';
import {ActivatedRoute} from '@angular/router';
import {LiveshowService} from '../../../../live-show/service/liveshow.service';
import {AppModule} from '../../../../../../../app.module';
import {BidsHistoryComponent} from '../../../../live-show/component/bids-history/bids-history.component';
import {PusherChannels} from '../../../../../../../core/enum/pusher-channels.enum';
import {PusherEvents} from '../../../../../../../core/enum/pusher-events.enum';
import {ListingStatusUpdatedEventModel} from '../../../../../models/listing-status-updated-event.model';
import Echo from 'laravel-echo';
import {PusherService} from '../../../../live-show/service/pusher.service';
import {
    AutoClosingAuctionComponent
} from '../../../../live-show/component/auto-closing-auction/auto-closing-auction.component';
import {ProxyBidComponent} from "../../../../live-show/component/proxy-bid/proxy-bid.component";

@Component({
  selector: 'vex-ring-details',
  templateUrl: './ring-details.component.html',
  styleUrls: ['./ring-details.component.scss'],
  standalone: true,
    imports: [
        CdkFixedSizeVirtualScroll,
        CdkVirtualScrollViewport,
        LiveShowChatComponent,
        LoadingRowComponent,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        NgForOf,
        NgIf,
        OngoingDetailsComponent,
        NgClass,
        BidsHistoryComponent,
        AutoClosingAuctionComponent,
        ProxyBidComponent
    ]
})
export class RingDetailsComponent implements OnInit , AfterViewInit{

  id;
  model;
  activeModel;
  items=[];
  loading=true;
  activeClass='border-blue-600';
  echo: Echo;
  constructor(
    private router:ActivatedRoute,
    private service:LiveshowService,
    private auctionService : AuctionService,
    private pusherService : PusherService

) {
    this.echo = this.pusherService.getSockets();
  }

  ngAfterViewInit(): void {
    this.getData();
  }
  getData(){
    this.id=this.router.snapshot.paramMap.get('id');
    this.auctionService.getItems(this.id).subscribe((res)=>{
      // this.auctionService.getItems(1).subscribe((res)=>{
      this.items=res['data'];
        this.activeModel = this.items[0];
      this.listingStatusUpdatedListener();
      this.listingItemsStatusListener();

      this.loading=false;
    });
  }
  selectActiveItems(id:number){
    // let temp=
    this.items.filter((item)=>item.id==this.activeModel.id)[0]=this.activeModel;
    this.activeModel = this.items.filter((item)=>item.id==id)[0];

  }
  listingStatusUpdatedListener() {
    this.echo
      .channel(PusherChannels.RINGS + '.' + this.id)
      .listen(
        PusherEvents.BID_PLACED,
        (listingStatusUpdatedData: ListingStatusUpdatedEventModel) =>{
          this.handleBidStatusUpdated(listingStatusUpdatedData);

        });


  }
  listingItemsStatusListener() {
    this.echo
      .channel(PusherChannels.RINGS + '.' + this.id)
      .listen(
        PusherEvents.LISTING_STATUS_UPDATED,
        (listingStatusUpdatedData: ListingStatusUpdatedEventModel) =>{
          this.handleListingStatusUpdated(listingStatusUpdatedData);

        });


  }
  handleListingStatusUpdated(data){
    const current = this.items.filter((item)=>item.product.listing.id==data?.['listing_id'])[0];
    current.product.listing.listing_status=data?.['status'];

    if(data?.['status']=='ONGOING'){
      this.activeModel = current;
    }
  }
  handleBidStatusUpdated(data){
    // this.bids_count = data?.['bids_count'];
    // this.current_price = data?.['last_bid']?.['amount'];
    const bid={
      amount : data?.['last_bid'],
      bidder:{
        name: data?.['last_bidder']?.['name'],
        cover:data?.['last_bidder']?.['avatar']
      },
      datetime :data?.['bade_at'],
      listing : data?.['listing_id'],
      type : data?.['type']
    };
    const current = this.items.filter((item)=>item.product.listing.id==data?.['listing_id'])[0];
    if (current) {
      if (current.product.listing) {
        // Update current_price amount
        if (current.product.listing.current_price) {
          current.product.listing.current_price.amount = data?.['last_bid']?.['amount'];
        }

        // Update bids_count
        current.product.listing.bids_count = data?.['bids_count'];
        current.can_be_sold = data?.['can_be_sold'];
        current.can_be_ended = data?.['can_be_ended'];
      }
      if (Array.isArray(current.bids)) {
        current.bids.unshift(bid);
      }

      this.activeModel = this.items.filter((item)=>item.id==this.activeModel.id)[0];


    }}

  ngOnInit(): void {
    //
    // this.getData();
  }

  insertToBids($event: any){
    this.activeModel = this.items.filter((item)=>item.id==this.activeModel.id)[0];

  }









}
