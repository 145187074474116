import {Component, OnInit, ViewChild} from '@angular/core';
import {IvsChatService} from '../../service/isv.service';
import {CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport, ScrollingModule} from '@angular/cdk/scrolling';
import {MatIconModule} from '@angular/material/icon';
import {PageLayoutModule} from '../../../../../../../@vex/components/page-layout/page-layout.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BreadcrumbsModule} from '../../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LoadingRowComponent} from '../../../../../../core/components/loading-row/loading-row.component';
import {MatTabsModule} from '@angular/material/tabs';
import {PageLayoutDemoModule} from '../../../../../ui/page-layouts/page-layout-demo/page-layout-demo.module';
import {RouterLinkActive} from '@angular/router';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {AppModule} from '../../../../../../app.module';
import {ChatCardComponent} from './component/chat-card/chat-card.component';

@Component({
  selector: 'vex-live-show-chat',
  templateUrl: './live-show-chat.component.html',
  styleUrls: ['./live-show-chat.component.scss'],
  imports: [
    ScrollingModule,
    MatIconModule,
    PageLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    NgClass,
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbsModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    LoadingRowComponent,
    MatTabsModule,
    PageLayoutDemoModule,
    RouterLinkActive,
    MatRippleModule,
    MatOptionModule,
    MatSelectModule,
    ChatCardComponent
  ],
  standalone: true
})
export class LiveShowChatComponent implements OnInit{

  messages=[];
  pinMessage;
  listClass='example-viewport-chat';
  constructor(
    private ivsService:IvsChatService
  ) {
  }
  @ViewChild(CdkVirtualScrollViewport)
  public virtualScrollViewport?: CdkVirtualScrollViewport;
  ngOnInit(): void {
    this.ivsService.messages$.pipe(

    ).subscribe(customers => {
      customers.map((data,index) =>{
        if(data.resetPin=='true'){
          for(let i =index-1;i>0;i--){
           if( customers[i].isPin=='true'){
             customers.splice(i, 1);
           };
          }
        }
      });
      const pinMessage = customers.filter(data=>data.isPin=='true');
      this.pinMessage =pinMessage? pinMessage[pinMessage.length - 1]:null;
      if(this.pinMessage){
        this.listClass = 'example-viewport-chat';
      }else{
        this.listClass = 'example-viewport-chat-full';
      }

      this.messages = customers.filter(data=>data.isPin=='false').filter(data => data.resetPin=='false');
      setTimeout(()=>{
        this.virtualScrollViewport.scrollTo({bottom: -50});
      });
    });
    }

  sendMessage(element:HTMLInputElement,message,is_pin){
    this.ivsService.sendMessage(is_pin=='true'?message:element.value,is_pin);
    if(is_pin!='true'){
      element.value='';
    }

  }
  unPinMessage(element:HTMLInputElement,message){

    this.ivsService.unPinMessage(message.content);


  }
}


