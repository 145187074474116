import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { BaseProduct } from '../../../models/baseProduct.model';

@Injectable({
  providedIn: 'root'
})
export class BaseProductsService {

  constructor(private http:HttpClient) { }

  get(){
    return this.http.get(environment.baseUrl + 'base-product/base-products');
   }
   create(base:FormData){
     return this.http.post(environment.baseUrl + 'base-product/base-products',base);
    }
    update(base:BaseProduct){
     return this.http.put(environment.baseUrl + 'base_product/baseProduct/'+base.id,base);
    }
    delete(base:BaseProduct){
     return this.http.delete(environment.baseUrl + 'base-product/base-products/'+base.id);
    }
}
