import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  constructor(private http:HttpClient) { }

  all(){
    return this.http.get(environment.baseUrl + 'financing-application/financing-application');
  }

  get(model:any){
    return this.http.get(environment.baseUrl + 'financing-application/financing-application/'+model);
  }
  changeStatus(body:any){
    return this.http.post(environment.baseUrl + 'financing-application/financing-application/change/status',body);
  }
}
