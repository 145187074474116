
  <cdk-virtual-scroll-viewport appendOnly itemSize="30" [ngClass]="listClass">

    <div class=" m-2 w-full flex flex-col gap-2 pr-3 " *ngFor="let message of messages">
      <vex-chat-card [message]="message" (clickPin)="sendMessage(null,message.content,'true')" [isPin]="false"></vex-chat-card>

    </div>
  </cdk-virtual-scroll-viewport>
  <div class="m-2 w-full flex flex-col gap-2 pr-3" *ngIf="pinMessage">
    <vex-chat-card [message]="pinMessage" (unPin)="unPinMessage(null,pinMessage)" [isPin]="true"></vex-chat-card>
  </div>



  <div class="relative flex">
    <input #messageInput class="border-2 w-full border-gray  p-2 rounded" placeholder="Write Message" (keyup.enter)="sendMessage(messageInput,null,'false')">
    <button class="absolute right-2 top-1/2 transform -translate-y-1/2" (click)="sendMessage(messageInput,null,'false')">
      <mat-icon svgIcon="mat:send"></mat-icon>

    </button>
  </div>


