<vex-page-layout>

    <vex-secondary-toolbar current="Expert Reviews">
        <mat-spinner *ngIf="loadingData" [diameter]="25" class="mat-spinner-color"></mat-spinner>

        <div class="w-full justify-end flex">
            <button color="primary" class="flex items-center justify-center" mat-flat-button type="submit" (click)="onSubmit()" [disabled]="reviewsForm.invalid" ><span  *ngIf="!loading"> Save Changes </span>
                <mat-spinner *ngIf="loading" [diameter]="25" class="mat-spinner-color"></mat-spinner>

            </button>
          <button color="warn" class="flex items-center justify-center mx-1" mat-flat-button (click)="cancel()" ><span> Cancel </span>

          </button>
        </div>
    </vex-secondary-toolbar>
        <div class="container">
    <form class="flex  flex-col gap-3 my-2 " [formGroup]="reviewsForm" >

        <div class="rounded-lg shadow bg-white p-5  ">
          <mat-tab-group  [selectedIndex]="activeTabIndex" (selectedTabChange)="onTabChanged($event)">
            <mat-tab  *ngFor="let lang of locale" [label]="lang.locale | uppercase">

            <div class=" flex flex-col gap-2">
                <div class="flex gap-3 justify-between">
                    <div class="flex w-full items-start">
                        <mat-form-field class=" w-2/3">
                            <mat-label>Expert Name ( {{lang.locale | uppercase }} )</mat-label>
                            <input cdkFocusInitial formControlName="{{lang.locale}}_expert_name" matInput>
                        </mat-form-field>
                      <div  class="file-input-wrapper flex flex-col  w-1/3 h-fit justify-center items-center"  >

                        <img  class='image object-contain mb-3 w-[150px] h-[150px] justify-center items-center border border-gray-400'  [src]="selectedFileExpert" (error)="setDefaultImage($event)">
                        <div class="relative flex items-center cursor-pointer select-none rounded-full px-4  hover:bg-blue-100 trans-ease-out"
                             matRipple>
                          <div class="p-2 bg-blue-100 text-blue-600 rounded-full ltr:mr-4 rtl:ml-4">
                            <mat-icon class="block" svgIcon="mat:add_a_photo"></mat-icon>
                          </div>
                          <input id="imageInput" type="file" #filePicker (change)="onImagePicked($event)" />
                          <p for="imageInput" class="font-medium text-blue-600">Select Expert Image</p>
                        </div>

                      </div>

                    </div>

                </div>
                    <h3 class="mb-2 pt-2 pl-2">Review Text ( {{lang.locale | uppercase }} )</h3>
                    <app-tiny-editor (fieldVal)="setEditorControl($event,lang.locale , 'text')" field="text" [lang]="lang.locale"
                             [values]="transValues" [editorLoading]="editorLoading"></app-tiny-editor>
            </div>
            </mat-tab>
          </mat-tab-group>
        </div>
<!--        <div class="grid grid-cols-12  gap-3">-->

<!--            <div class="col-span-12 md:col-span-6 w-full">-->
<!--                <app-upload-single-media   [selectedCover]="selectedFileExpert" (getFile)="getExpertImage($event)"  [type]="MediaTypeEnum.IMAGE" title=""></app-upload-single-media>-->
<!--            </div>-->
<!--        </div>-->
    </form>
        </div>

</vex-page-layout>

