import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, ReactiveFormsModule, FormsModule} from '@angular/forms';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {SelectFilterPipe} from '../../../../../core/pipes/select-filter.pipe';
import {ToastrService} from 'ngx-toastr';
import {SellYourMachineService} from '../service/sell-your-machine.service';
import {SellYourMachoneModel} from '../../../models/sell-your-machone.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SellStatusEnum} from '../../../../../core/enum/sell-status.enum';
import {
  ConfirmationDialogComponent
} from '../../../../../core/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'vex-sell-your-machine-details',
  templateUrl: './sell-your-machine-details.component.html',
  styleUrls: ['./sell-your-machine-details.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatListModule,
    MatIconModule,
    CommonModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    SelectFilterPipe,
    MatProgressSpinnerModule
  ]
})
export class SellYourMachineDetailsComponent implements OnInit {


  form: UntypedFormGroup;
  mode: 'create' | 'update' = 'create';
  product : SellYourMachoneModel;
  productID;
  loading = false;
  groupedMediaArray: any;

  constructor(
              private service: SellYourMachineService,
              private toastService: ToastrService,
              private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              public dialog: MatDialog,
              private router: Router
  ) {


  }

  ngOnInit() {
    this.productID = this.route.snapshot.paramMap.get('id');
     this.getData();
  }
  groupedMedia() {
    const grouped = this.product.media?.reduce((acc, med) => {
      const key = med.collection_name;
      const existingCollection = acc.find(collection => collection.collection_name === key);

      if (existingCollection) {
        existingCollection.media.push(med);
      } else {
        acc.push({id: med.id, collection_name: key, media: [med]});
      }
      return acc;
    }, []);
    this.groupedMediaArray = grouped;
    this.loading = false;
  }
  getData() {
    this.loading = true;
    this.service.getById(this.productID).subscribe((res) => {
      // @ts-ignore
      this.product = res.data;
      this.groupedMedia();
    });
  }
  accept(){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to perform this action?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const sellProduct = {
          id: this.product.id
        };
        this.service.accept(sellProduct).subscribe(() => {
          this.ngOnInit();
        });
      }else {

      }});

  }
  onCancel(){
    this.router.navigate(['/sell-your-machine']).then();
  }
  protected readonly SellStatusEnum = SellStatusEnum;
}

