<div *ngIf="loading==false" class="flex">
  <div class="w-1/2 z-50">
  <vex-booing-tour-table [models]="models"></vex-booing-tour-table>

  </div>

  <div class="h-full w-1/2 flex flex-col" >
    <div class="">
      <div class=" opacity-25 absolute top-0 left-0 right-0 bottom-0 z-0 w-full h-full"></div>
      <div class="container z-10 relative flex items-end">
        <div class="flex-auto flex flex-col sm:flex-row justify-between items-center">
          <div class="headline">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</div>

          <div class="actions flex flex-col sm:flex-row justify-end items-center">
            <div class="chevrons">
              <button [(viewDate)]="viewDate" [view]="view" mat-icon-button mwlCalendarPreviousView>
                <mat-icon svgIcon="mat:chevron_left"></mat-icon>
              </button>
              <button [(viewDate)]="viewDate" [view]="view" mat-icon-button mwlCalendarNextView>
                <mat-icon svgIcon="mat:chevron_right"></mat-icon>
              </button>
            </div>
            <div class="flex gap-1">
              <button (click)="view = CalendarView.Month" mat-button>Month</button>
              <button (click)="view = CalendarView.Week" mat-button>Week</button>
              <button (click)="view = CalendarView.Day" mat-button>Day</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vex-scrollbar [ngSwitch]="view" class="px-0 shadow flex-auto relative container">
      <mwl-calendar-month-view
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        *ngSwitchCase="'month'"
        [activeDayIsOpen]="activeDayIsOpen"
        [events]="events"
        [refresh]="refresh"
        [viewDate]="viewDate">
      </mwl-calendar-month-view>
      <mwl-calendar-week-view
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        *ngSwitchCase="'week'"
        [events]="events"
        [refresh]="refresh"
        [viewDate]="viewDate">
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        *ngSwitchCase="'day'"
        [events]="events"
        [refresh]="refresh"
        [viewDate]="viewDate">
      </mwl-calendar-day-view>
    </vex-scrollbar>
  </div>
</div>

<vex-loading-row [diameter]="40" *ngIf="loading==true"></vex-loading-row>
