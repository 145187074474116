export class OurTeamModel {
  id: number;
  title: string;
  name: string;
  description : string;
  facebook : string;
  whatsapp : string;
  linkedin : string;
  media : any;
  translations : [
    {
      name:string;
      title:string;
      description:string;
      locale:string;
    }
  ];


  constructor(team) {
    this.id = team.id;
    this.title = team.title;
    this.description = team.description;
    this.name = team.name;
    this.facebook = team.facebook;
    this.whatsapp = team.whatsapp;
    this.media = team.media;
    this.translations= team.translations;


  }
}



