
export class ExpertReviewsModel {
  id: any;
  base_product_id: number;
  currency_id:number;
  product_id:number;
  makana_rating:string;
  expert_name: string;
  deleted_at: string;
  text: string;
  price: number;
  external_url: string;
  slug: string;
  media : any[];
  years : any[];
  market_average : string;
  expert_reviewer_image : string;
  compare_with_experts : any;
  title : string;
  rating : RatingModel[];
  specifications : [];
  featured_specifications : [];
  translations : [
    {
      expert_name:string;
      text:string;
      locale:string;
    }
  ];
  constructor(review : ExpertReviewsModel ) {
    this.id = review.id;
    this.base_product_id = review.base_product_id;
    this.product_id = review.product_id;
    this.expert_name = review.expert_name;
    this.slug = review.slug;
    this.deleted_at = review.deleted_at;
    this.currency_id = review.currency_id;
    this.makana_rating = review.makana_rating;
    this.expert_reviewer_image = review.expert_reviewer_image;
    this.compare_with_experts = review.compare_with_experts;
    this.price = Number.parseInt(String(review.price));
    this.market_average = review.market_average;
    this.rating = review.rating;
    this.media = review.media;
    this.years = review.years;
    this.text = review.text;
    this.title = review.title;
    this.specifications= review.specifications;
    this.external_url= review.external_url;
    this.featured_specifications= review.featured_specifications;
    this.translations = review.translations;

  }
}



export class RatingModel {
  rating_standard_id: number;
  value: number;

  constructor(rating :RatingModel ) {
    this.rating_standard_id = rating.rating_standard_id;
    this.value = rating.value;

  }
}

