<vex-page-layout>

    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div [class.container]="layoutCtrl.value === 'boxed'"
             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
             class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class="title mt-0 mb-1">Auction Table</h1>
                <vex-breadcrumbs [crumbs]="[ 'auction Table']"></vex-breadcrumbs>
            </div>


        </div>
    </vex-page-layout-header>

    <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
                             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
                             class="-mt-6">

        <div class="card overflow-auto -mt-16" id="form">

            <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
                <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
                    <span *ngIf="selection.isEmpty()"></span>
                    <span *ngIf="selection.hasValue()">{{ selection.selected.length }}
                        Row<span *ngIf="selection.selected.length > 1">s</span> selected</span>
                </h2>

                <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
                    <button (click)="deleteAll(selection.selected)"
                            color="primary"
                            mat-icon-button
                            matTooltip="Delete selected"
                            type="button">
                        <mat-icon svgIcon="mat:delete"></mat-icon>
                    </button>

                    <button color="primary" mat-icon-button matTooltip="Another action" type="button">
                        <mat-icon svgIcon="mat:folder"></mat-icon>
                    </button>
                </div>

                <div class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
                    <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
                    <input [formControl]="searchCtrl"
                           class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                           placeholder="Search..."
                           type="search">
                </div>

                <span class="flex-1"></span>

                <button [matMenuTriggerFor]="columnFilterMenu"
                        class="ml-4 flex-none"
                        mat-icon-button
                        matTooltip="Filter Columns"
                        type="button">
                    <mat-icon svgIcon="mat:filter_list"></mat-icon>
                </button>
                <button (click)="create()"
                        class="ml-4 flex-none"
                        color="primary"
                        mat-mini-fab
                        matTooltip="Add Specification Group"
                        type="button">
                    <mat-icon svgIcon="mat:add"></mat-icon>
                </button>
              <button (click)="resetAuction()"
                      class="  h-fit  flex items-center justify-center bg-[#d7e512] text-black px-3 py-1 rounded-sm w-fit"
                      color="primary"

                      type="button">
                <p>Reset Auction</p>
              </button>
            </div>

            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
                <ng-container [matColumnDef]="column" *ngFor="let column of columnsToDisplay">
                    <th mat-header-cell *matHeaderCellDef> {{column | uppercase | replaceUnderscores}} </th>
                    <div *ngIf="column!='actions' && column!='cover'">
                        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                    </div>
                    <div *ngIf=" column=='cover'">
                        <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
                            <img [src]="row['cover']" class="avatar h-8 w-8 align-middle"
                                 (error)="setDefaultImage($event)">
                        </td>
                    </div>
                    <div *ngIf="column=='actions'">
                        <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                            <button (click)="$event.stopPropagation()"
                                    [matMenuTriggerData]="{ customer: row }"
                                    [matMenuTriggerFor]="actionsMenu"
                                    mat-icon-button
                                    type="button">
                                <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                            </button>
                        </td>
                    </div>
                </ng-container>
                <!-- Image Column -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                        <div class="example-element-detail" [@detailExpand]="element?.expanded"
                             *ngIf="element?.expanded">
                            <div style="width: 100%;">
                                <table class="bg-blue-50"  *ngIf="element.type=='LIVE'"  mat-table [dataSource]="element.rings" multiTemplateDataRows
                                       matSort>
                                    <ng-container matColumnDef="{{innerColumn}}"
                                                  *ngFor="let innerColumn of innerDisplayedColumns">

                                        <th class="text-blue-600 " mat-header-cell
                                            *matHeaderCellDef> {{innerColumn | replaceUnderscores}} </th>
                                        <div *ngIf="innerColumn != 'actions'">
                                            <td mat-cell *matCellDef="let ring"> {{ring[innerColumn]}} </td>
                                        </div>
                                        <div *ngIf="innerColumn == 'actions'">
                                            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                                                <button (click)="$event.stopPropagation()"
                                                        [matMenuTriggerData]="{ customer: row }"
                                                        [matMenuTriggerFor]="ringMenu"
                                                        mat-icon-button
                                                        type="button">
                                                    <mat-icon svgIcon="mat:more_vert"></mat-icon>
                                                </button>
                                            </td>
                                        </div>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let ring; columns: innerDisplayedColumns;"
                                        [class.example-element-row]="ring.comments?.length"
                                        [class.example-expanded-row]="ring?.expanded"
                                        (click)="ring.expanded = !ring?.expanded">
                                    </tr>
                                </table  >
                            </div>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    [class.example-element-row]="element.rings?.length" [class.example-expanded-row]="element?.expanded"
                    (click)="element.expanded = !element?.expanded">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>


            <vex-loading-row *ngIf="getLoading" [diameter]="25" class=" bg-white"></vex-loading-row>
            <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                           class="sticky left-0"></mat-paginator>
        </div>

    </vex-page-layout-content>

</vex-page-layout>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
    <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
            class="mat-menu-item block">
        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
            {{ column.label }}
        </mat-checkbox>
    </button>
</mat-menu>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-customer="customer" matMenuContent>
        <button (click)="update(customer)" mat-menu-item>
            <mat-icon svgIcon="mat:edit"></mat-icon>
            <span>Modify</span>
        </button>
        <button (click)="delete(customer)" mat-menu-item>
            <mat-icon svgIcon="mat:delete"></mat-icon>
            <span>Delete</span>
        </button>
        <button *ngIf="customer.type=='LIVE'" (click)="addRing(customer)" mat-menu-item>
            <mat-icon svgIcon="mat:panorama_fish_eye"></mat-icon>
            <span>Add Ring</span>
        </button>
      <button *ngIf="customer.type!='LIVE'" (click)="getItems(customer?.rings[0]?.id)" mat-menu-item>
        <mat-icon svgIcon="mat:menu"></mat-icon>
        <span>Show Item</span>
      </button>
      <button  *ngIf="customer.type!='LIVE'" (click)="goToRingDetails(customer?.rings[0]?.id)" mat-menu-item>
        <mat-icon svgIcon="mat:rate_review"></mat-icon>
        <span>Details</span>
      </button>
    </ng-template>
</mat-menu>
<mat-menu #ringMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-customer="customer" matMenuContent>
        <button (click)="editRing(customer)" mat-menu-item>
            <mat-icon svgIcon="mat:edit"></mat-icon>
            <span>Modify</span>
        </button>
        <button (click)="deleteRing(customer)" mat-menu-item>
            <mat-icon svgIcon="mat:delete"></mat-icon>
            <span>Delete</span>
        </button>
        <button (click)="getItems(customer?.id)" mat-menu-item>
            <mat-icon svgIcon="mat:menu"></mat-icon>
            <span>Show Item</span>
        </button>
        <button (click)="goToRingDetails(customer?.id)" mat-menu-item>
            <mat-icon svgIcon="mat:rate_review"></mat-icon>
            <span>Details</span>
        </button>
    </ng-template>
</mat-menu>
