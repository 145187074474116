<div class="grid grid-cols-12 ">
  <div class="col-span-12  md:col-span-8 h-full  card p-3 m-3 ">
    <div class="grid grid-cols-12 gqp-2">
      <!--    <img src="{{model?.cover?.url}}" class="w-[200px] h-[400px]" (error)="setDefaultImage($event)">-->
      <!--    <video id="video-player" #videoPlayer playsinline class="w-full h-full object-cover" ></video>-->
      <video #videoPlayer id="video-player"
             class="col-span-12  md:col-span-6 aspect-[9/16] w-fit md:w-full max-w-full  self-start bg-black"
             controls></video>
      <!--     <button type="button" (click)="changeStatus()" color="primary'">hiii</button>-->
      <div class=" col-span-12  md:col-span-6  w-full p-2 m-2" *ngIf="!loading">
        <div class="flex justify-between  w-full">
          <p class="m-1 font-bold ">{{model?.name}}</p>
          <button mat-button color="accent"
                  *ngIf="(model?.status=='UPCOMING' || model?.status=='ENDED') && !isAuction()" (click)="startLive()">
            Start Live
          </button>
          <button mat-button color="warn" *ngIf="model?.status=='LIVE'" (click)="endLive()">End Live</button>
        </div>
        <div class="p-1">
          <p>Connection Details</p>
          <mat-form-field class="w-full">
            <mat-label>Server Link</mat-label>
            <input value="rtmps://{{model?.channel?.endpoint}}" matInput>
            <button matSuffix (click)="copy('rtmps://'+model?.channel?.endpoint)">Copy</button>

          </mat-form-field>


          <mat-form-field class="w-full">
            <mat-label>Stream Key</mat-label>
            <input value="{{model?.channel?.stream_key}}" matInput>
            <button matSuffix (click)="copy(model?.channel?.stream_key)">Copy</button>

          </mat-form-field>
          <div *ngIf="isAuction()">

            <vex-auto-closing-auction [ring_id]="model.ring_id"></vex-auto-closing-auction>
          </div>
          <div *ngIf="!isAuction()">
            <h1 class="font-bold">Chat</h1>
            <vex-live-show-chat></vex-live-show-chat>
          </div>
          <mat-tab-group *ngIf="isAuction()" class="max-w-full">
            <mat-tab label="Details">

              <vex-ongoing-details  [model]="ongoingModel"
                                    ></vex-ongoing-details>
            </mat-tab>
            <mat-tab label="Chat">
              <vex-live-show-chat></vex-live-show-chat>
            </mat-tab>
            <mat-tab label="Bids">
              <vex-bids-history [bids]="ongoingModel?.bids"></vex-bids-history>
            </mat-tab>
            <mat-tab label="Proxy Bid">
              <vex-proxy-bid [bids]="ongoingModel?.settings"></vex-proxy-bid>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <vex-loading-row [diameter]="40" *ngIf="loading"></vex-loading-row>
    </div>
  </div>


  <div *ngIf="!loading" class="col-span-12  md:col-span-4">
    <div *ngIf="!isAuction()">
      <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport">
        <div class="card p-3 m-3 w-[350px] " *ngFor="let item of model?.products">
          <div class="flex  rounded w-full">
            <img src="{{item?.cover?.url}}" class="w-[75px] h-[75px]">
            <div class="w-full">
              <p>{{item.title}}</p>
              <div class="flex justify-end w-full">
                <button mat-button color="accent" (click)="activeProduct(item.id)" *ngIf="item.status=='INACTIVE'">
                  Active
                </button>

              </div>

            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
    <div *ngIf="isAuction() && !loading" >
      <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport mt-1.5">
        <div class="card p-3 m-3 lg:w-[350px] " [ngClass]="ongoingModel?item.product.listing.id==ongoingModel.product.listing.id?activeClass:'':''"
             *ngFor="let item of items">
          <div class="flex gap-2 rounded w-full" (click)="selectActiveItems(item['id'])">
            <img src="{{item?.product.cover?.url}}" class="w-[100px] h-full ">
            <div class="flex flex-col justify-content-between">
              <h2 class="font-medium	">{{item.product.title}}</h2>
              <label
                [ngClass]="{
                 'bg-[#E8F1FF] text-[#0B62E4]': item?.product?.listing?.listing_status === AuctionStatus.SOLD,
                 'bg-gray-100 text-black': item?.product?.listing?.listing_status === AuctionStatus.UPCOMING,
                 'bg-green-100 text-green-800': item?.product?.listing?.listing_status === AuctionStatus.ONGOING
                   }"
                class="self-end flex px-4 py-1 rounded-xl"
              >
                {{ item?.product?.listing?.listing_status }}
              </label>

            </div>

          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>

</div>



