import { Injectable } from '@angular/core';

import * as S3 from 'aws-sdk/clients/s3';
import { BehaviorSubject } from 'rxjs';
import {environment} from "../../../environments/environment";



@Injectable({
  providedIn: 'root'
})
export class UploadFileAwsService {


  FOLDER = '/';

  imageUrl = '';

  resData: BehaviorSubject<any> = new BehaviorSubject(null);

  data = { message: '', data: '' };

  constructor() { }

  validateandUploadFile(file, Iheight, Iwidth) {

    let fileToUpload = file;
    if (fileToUpload.type == 'image/jpeg' || fileToUpload.type == 'image/png' || fileToUpload.type == 'image/jpeg') {
      //Show image preview
      let reader = new FileReader();
      reader.onload = (event: any) => {
        let img = new Image();
        img.onload = () => {
          let width = img.width;

          let height = img.height;
          if (width <= Iwidth && height <= Iheight) {
            this.imageUrl = event.target.result;

            this.uploadfile(file);
          } else {

            this.data.message = 'You can maximum upload ' + Iheight + ' * ' + Iwidth + ' File';
            this.data.data = '';
            this.resData.next(this.data);
            return this.resData;
          }
        };

        img.src = event.target.result;
      };
      reader.readAsDataURL(fileToUpload);
    } else {
      this.data.message = 'You can\'t be able to upload file except JPG and PNG format';
      this.data.data = '';
      this.resData.next(this.data);
      return this.resData;
    }
  }


  // @ts-ignore
  uploadfile(file) {

    const AWS = require('aws-sdk');
    AWS.config.update({
      region: 'us-east-1',
      accessKeyId: environment.awsAccessKeyId,
      secretAccessKey: environment.awsSecretAccessKey
    });

    const s3 = new AWS.S3();
    const fileName = `${Date.now()}${file.name}`;
    const params = {
      Bucket: 'makana-web-assets-bucket',
      Key: fileName,
      Body: file
    };
    return new Promise<string>((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('There was an error uploading your file: ', err);
         return reject(err);
        } else {
         return resolve(`https://d1u142t8abtknh.cloudfront.net/${fileName}`);
        }
      });
    });
  }
  deleteFile(fileName) {

    const bucket = new S3(
      {
        accessKeyId: '*****************',
        secretAccessKey: '*********************',
        region: 'us-east-2'
      }
    );
    let params = {
      Bucket: '***************',
      Key: fileName
      /*
         where value for 'Key' equals 'pathName1/pathName2/.../pathNameN/fileName.ext'
         - full path name to your file without '/' at the beginning
      */
    };
    let that = this;
    bucket.deleteObject(params, function (err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else console.log(data);

    });
  }
  public getFile() {
    return this.resData.asObservable();
  }

}
