import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {NewsCategoryModel} from '../../../models/news-category.model';

@Injectable({
    providedIn: 'root'
})
export class CategoryNewsService {

    constructor(private http:HttpClient) { }

    get(){
        return this.http.get(environment.baseUrl + 'news/news-category/');
    }
    create(model:any){
        return this.http.post(environment.baseUrl + 'news/news-category/save',model);
    }
    delete(model:NewsCategoryModel){
        return this.http.delete(environment.baseUrl + 'news/news-category/'+model.id);
    }
}
