export class LiveShowModel {
  id: number;
  name: string;
  description: string;
  type: string;
  status: string;
  start_datetime: string;
  end_datetime: string;
  estimated_duration: string;
  products:any;
  cover:any;
  media:any;
  is_trending:any;
  is_featured:any;
  translations : [
    {
      locale : string ;
      name : string ;
      description : string ;
    }
    ];



    constructor(obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.description = obj.description;
        this.is_trending = obj.is_trending;
        this.is_featured = obj.is_featured;
        this.type = obj.type;
        this.status = obj.status;
        this.start_datetime = obj.start_datetime;
        this.end_datetime = obj.end_datetime;
        this.estimated_duration = obj.estimated_duration;
        this.products = obj.products;
        this.cover = obj.cover!=null?obj.cover.url:'';

        this.translations = obj.translations;

    }
}
