import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TermModel} from '../../../models/term.model';
import {NgForOf, NgIf, UpperCasePipe} from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormGroup,
    UntypedFormBuilder,
    UntypedFormControl
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {QuillEditorComponent} from 'ngx-quill';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {TermsService} from '../service/terms.service';
import {ToastrService} from 'ngx-toastr';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LocalStorageRefService} from '../../../../pages/auth/service/local-storage-ref.service';
import {StorageKeysEnum} from '../../../../../core/enum/storage-keys.enum';
import {MatTabsModule} from '@angular/material/tabs';

@Component({
    selector: 'vex-term-create-update',
    templateUrl: './term-create-update.component.html',
    styleUrls: ['./term-create-update.component.scss',
        '../../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../../@vex/styles/partials/plugins/quill/_quill.scss'],
  imports: [
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgForOf,
    MatButtonModule,
    NgIf,
    QuillEditorComponent,
    PageLayoutModule,
    MatSlideToggleModule,
    UpperCasePipe,
    MatTabsModule
  ],
    encapsulation: ViewEncapsulation.None,
    animations: [fadeInUp400ms],
    standalone: true
})
export class TermCreateUpdateComponent implements OnInit {
    termId;
    numbers: number[] = Array.from({length: 10}, (_, i) => i + 1);
    formDesc;
    form: UntypedFormGroup;
    mode: 'create' | 'update' = 'create';

    description = '';
    defaults: TermModel;
  locale;
  defLocale;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private service: TermsService,
        private toastr: ToastrService,
        private localStorageRefService : LocalStorageRefService
    ) {
    }
  onTabChanged(event){
    // this.cdr.detectChanges();
    // this.loadingForm =true;

    this.form.get('order').setValue(this.form.value.order);
    this.form.get('status').setValue(this.form.value.status);
    setTimeout(()=>{
      // this.loadingForm =false;
    },200);
  }
    ngOnInit() {
      this.locale = JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.LANGUAGES) )?? [];
      this.defLocale = this.locale.filter(loc=>loc.default==true)[0];
      this.termId = this.route.snapshot.paramMap.get('id');

        if (this.termId) {
            this.getData();
            this.mode = 'update';
        } else {
            this.defaults = {} as TermModel;
        }

        this.form = this.fb.group({
            id: [],
            order: [1],
            status : [false]
        });
      this.locale.forEach(lang => {

        this.form.addControl(lang.locale+'_title', this.fb.control(''));
        this.form.addControl(lang.locale+'_description', this.fb.control(''));
      });

    }

    getData() {
        this.service.getTerm(this.termId).subscribe((res) => {
            this.defaults = res['data'];
            this.initForm();
        });
    }
    initForm() {
        this.form= this.fb.group({
            id: [this.defaults?.id],
            order: [this.defaults?.order],
            status : [this.defaults.status]
        });
        this.description = this.defaults?.description;
        this.formDesc = new UntypedFormControl(this.defaults?.description);
      this.locale.forEach(lang => {
        const value = this.defaults.translations.filter(brand => brand.locale == lang.locale);

        this.form.addControl(lang.locale+'_title', this.fb.control(value[0]?.title??''));
        this.form.addControl(lang.locale+'_description', this.fb.control(value[0]?.description??''));
      });

    }

    // save() {
    //     if (this.mode === 'create') {
    //         this.create();
    //     } else if (this.mode === 'update') {
    //         this.update();
    //     }
    // }
    onContentChanged(event) {
        this.description = event.html;
    }

    save() {
      const formData = new FormData();
      if(this.mode == 'update'){
        formData.append('id',this.form.value.id);
      }


      formData.append('order',this.form.value.order);
      formData.append('status', this.form.value.status?'1':'0');
      formData.append('title',this.form.value[this.defLocale.locale + '_title']);
      formData.append('description',this.form.value[this.defLocale.locale + '_description']);
      this.locale.filter(lang => lang.default==0 ).forEach(lang =>{

        if(this.form.value[lang.locale + '_title']!='')
          formData.append(lang.locale +'[title]',this.form.value[lang.locale + '_title']);
        if(this.form.value[lang.locale + '_description']!='')
          formData.append(lang.locale +'[description]',this.form.value[lang.locale + '_description']);
      });

        this.service.create(formData).subscribe((res) => {
            if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
                this.toastr.success(`Term ${this.mode}d Successfully!`);
                this.router.navigate(['/term/' + res?.['data']?.['id']]).then();

            }

        }, error => {
            this.toastr.error(error['errors']['message']);
        });
    }

    update() {

        const type = {
            'id': this.termId,
            'en': {
                'title': this.form.value.title.toString(),
                'description': this.description
            },
            'order': this.form.value.order.toString() ,
            'status': this.form.value.status?'1':'0'

        };

        this.service.create(type).subscribe((res) => {
            if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
                this.toastr.success('Term Updated Successfully!');
                this.router.navigate(['/term/' + this.termId]).then();
            }

        }, error => {
            this.toastr.error(error['errors']['message']);
        });
    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }
    onCancel(){
        this.router.navigate(['/term']).then();
    }
}
