<vex-page-layout>
    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div [class.container]="layoutCtrl.value === 'boxed'"
             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
             class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class="title mt-0 mb-1">News Table</h1>
                <vex-breadcrumbs [crumbs]="[ 'news']"></vex-breadcrumbs>
            </div>


        </div>
    </vex-page-layout-header>

    <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
                             [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
                             class="-mt-6">

        <div class="card overflow-auto -mt-16">
            <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
                <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
                    <span *ngIf="selection.isEmpty()"></span>
                    <span *ngIf="selection.hasValue()">{{ selection.selected.length }}
                        Row<span *ngIf="selection.selected.length > 1">s</span> selected</span>
                </h2>

                <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
                    <button
                            (click)="deleteall(selection.selected)"
                            color="primary"
                            mat-icon-button
                            matTooltip="Delete selected"
                            type="button">
                        <mat-icon svgIcon="mat:delete"></mat-icon>
                    </button>

                    <button color="primary" mat-icon-button matTooltip="Another action" type="button">
                        <mat-icon svgIcon="mat:folder"></mat-icon>
                    </button>
                </div>

                <div class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
                    <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
                    <input [formControl]="searchCtrl"
                           class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                           placeholder="Search..."
                           type="search">
                </div>

                <span class="flex-1"></span>



                <button (click)="create()"
                        class="ml-4 flex-none"
                        color="primary"
                        mat-mini-fab
                        matTooltip="Add Specification Group"
                        type="button">
                    <mat-icon svgIcon="mat:add"></mat-icon>
                </button>
            </div>

            <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort *ngIf="!getLoading">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <th *matHeaderCellDef mat-header-cell>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                                      color="primary">
                        </mat-checkbox>
                    </th>
                    <td *matCellDef="let row" class="w-4" mat-cell>
                        <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                                      (click)="$event.stopPropagation()"
                                      [checked]="selection.isSelected(row)"
                                      color="primary">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Image Column -->
                <ng-container matColumnDef="media">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let row" class="min-w-[30px]  pr-0" mat-cell>
                        <img *ngIf="row['media']" [src]="row['media']?.url"  class=" w-full align-middle">
                        <img *ngIf="!row['media']" [src]="'assets/img/demo/missing-picture.jpeg'"  class=" w-full align-middle">
                    </td>
                </ng-container>

                <!-- Text Columns -->
                <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
                    <ng-container *ngIf="column.type === 'text' " [matColumnDef]="column.property">
                        <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
                        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
                    </ng-container>
                </ng-container>

                <!-- Contact Column -->
                <ng-container matColumnDef="compatible">
                    <th *matHeaderCellDef mat-header-cell >COMPATIBLE</th>
                    <td *matCellDef="let row" mat-cell>
                        <div class="flex">

                            <mat-slide-toggle  [checked]="row['compatible']==1?true:false"></mat-slide-toggle>

                        </div>
                    </td>
                </ng-container>

                <!-- Label Column -->
                <ng-container matColumnDef="labels">
                    <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>Labels</th>
                    <td *matCellDef="let row" mat-cell>
                        <div (click)="$event.stopPropagation()" class="flex items-center gap-1">
                            <div *ngFor="let label of row.labels"
                                 [ngClass]="[label.textClass, label.bgClass]"
                                 class="rounded px-2 py-1 font-medium text-xs flex-none">
                                {{ label.text }}
                            </div>
                            <div class="text-secondary bg-base text-hint cursor-pointer hover:bg-hover flex-none flex items-center justify-center">
                                <mat-icon class="icon-sm" svgIcon="mat:add"></mat-icon>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="actions">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
                    <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
                        <button (click)="$event.stopPropagation()"
                                [matMenuTriggerData]="{ customer: row }"
                                [matMenuTriggerFor]="actionsMenu"
                                mat-icon-button
                                type="button">
                            <mat-icon svgIcon="mat:more_horiz"></mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
                <tr (click)="update(row)"
                    *matRowDef="let row; columns: visibleColumns;"
                    @fadeInUp
                    class="hover:bg-hover trans-ease-out cursor-pointer"
                    mat-row></tr>
            </table>
          <vex-loading-row *ngIf="getLoading" [diameter]="25" class=" bg-white"></vex-loading-row>
            <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" class="sticky left-0"></mat-paginator>
        </div>

    </vex-page-layout-content>

</vex-page-layout>
<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
    <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
            class="mat-menu-item block">
        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
            {{ column.label }}
        </mat-checkbox>
    </button>
</mat-menu>
<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
    <ng-template let-customer="customer" matMenuContent>
        <button (click)="update(customer)" mat-menu-item>
            <mat-icon svgIcon="mat:edit"></mat-icon>
            <span>Modify</span>
        </button>
        <button (click)="delete(customer)" mat-menu-item>
            <mat-icon svgIcon="mat:delete"></mat-icon>
            <span>Delete</span>
        </button>
    </ng-template>
</mat-menu>

