import {ProductMetaModel} from './product-meta.model';
import {ProductDiscountModel} from './product-discount.model';
import {LocationModel} from './location.model';
import {MeasurementModel} from './measurement.model';
import {InspectionReportModel} from './inspection-report.model';

export class Product {
  id: number;
  user_id: number;
  base_product_id: number;
  base_product: any;
  product_id: number;
  min_offer_amount: number;
  product_type_id: number;
  added_by_id: number;
  owner_id: number;
  title: string;
  sku: string;
  slug: string;
  tag: string;
  show_in: string;
  description: string;
  discount_type: string;
  discount_value: string;
  image: string;
  price: string;
  status: string;
  deleted_at: string;
  can_offer: boolean;
  can_negotiate: boolean;
  can_auction: boolean;
  can_full_amount: boolean;
  has_discount: boolean;
  can_down_payment: boolean;
  type: string;
  cover: string;
  category: string;
  external_url: string;
  kind: string;
  is_popular: number;
  hide_price: number;
  publish_status: any;
  meta: ProductMetaModel;
  discount: ProductDiscountModel;
  specifications : any[];
  media_order : any[];
  applications : any[];
  offers : any[];
  discounts : any[];
  media : any[];
  location :LocationModel;
  measurement : MeasurementModel;
  documents : [];
  key_features : [];
  assistant_id : number;
  inspectionReport : InspectionReportModel;
  workingHours:string;
  yearmanufacturing:string;
  operatingweight:string;
  translations:[
    {
      title:string;
      description:string;
      locale:string;
    }
  ];
  can_lease:string;
  lease_months:number;
  monthly_lease_amount:number;
  down_payment_amount:number;

  constructor(product) {
    this.id = product.id;
    this.user_id = product.user_id;
    this.kind = product.kind;
    this.tag = product.tag;
    this.show_in = product.show_in;
    this.assistant_id = product.assistant_id;
    this.base_product_id = product.base_product_id;
    this.min_offer_amount = product.min_offer_amount;
    this.description = product.description;
    this.title = product.title;
    this.sku = product.sku;
    this.slug = product.slug;
    this.description = product.description;
    this.discount_type = product.discount_type;
    this.discount_value = product.discount_value;
    this.image = product.image;
    this.price = product.price.amount;
    this.status = product.status;
    this.discounts = product.discounts;
    this.added_by_id = product.added_by_id;
    this.owner_id = product.owner_id;
    this.can_offer = product.can_offer;
    this.can_negotiate = product.can_negotiate;
    this.can_full_amount = product.can_negotiate;
    this.can_down_payment = product.can_down_payment;
    this.hide_price = product.hide_price;
    this.type = product.type;
    this.cover = product.type;
    this.category = product.category;
    this.meta = product.meta;
    this.publish_status = product.publish_status;
    this.has_discount = product.has_discount;
    this.discount = product.discount;
    this.is_popular = product.is_popular;
    this.can_auction = product.can_auction;
    this.specifications = product.specifications;
    this.applications = product.applications;
    this.media = product.media;
    this.media_order = product.media_order;
    this.deleted_at = product.deleted_at;
    this.location = product.location;
    this.measurement=product.measurement;
    this.documents =product.documents;
    this.key_features =product.key_features;
    this.base_product =product.base_product;
    this.inspectionReport = product?.inspectionReport;
    this.external_url = product?.external_url;
    this.translations=product.translations;
    this.can_lease = product.can_lease;
    this.lease_months = product.lease_months;
    this.monthly_lease_amount = product.monthly_lease_amount;
    this.down_payment_amount = product.down_payment_amount;
    this.workingHours = product.specifications.filter((q)=>q.specification_id==2)[0]?.['value'];
    this.operatingweight = product.specifications.filter((q)=>q.specification_id==8)[0]?.['value'];
    this.yearmanufacturing = product.specifications.filter((q)=>q.specification_id==7)[0]?.['value'];
  }
}



