import {Component, Inject, OnInit} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  FormsModule
} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {SelectFilterPipe} from '../../../../../core/pipes/select-filter.pipe';
import {ToastrService} from 'ngx-toastr';
import {ProductService} from '../service/product.service';
import {MethodsUtils} from '../../../../../core/utils/method-utils';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'vex-collection-media-file-update',
  templateUrl: './update-media-file.component.html',
  styleUrls: ['./update-media-file.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatListModule,
    MatIconModule,
    CommonModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    SelectFilterPipe
  ]
})
export class UpdateMediaFileComponent implements OnInit {


  form: UntypedFormGroup;

  productID;
  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private service: ProductService, private fb: UntypedFormBuilder,
              private toastr: ToastrService) {
  }
  ngOnInit() {
    this.productID = this.defaults;
    this.form = this.fb.group({
      id: [this.defaults.id],
      alt: [this.defaults.alt, Validators.required]
    });
  }
  save() {
      this.create();
  }
  create() {
    let media = new FormData();
    media.append('alt', this.form.value.alt);
    media.append('id', this.defaults.id);
    media.append('_method', 'put');
    this.service.updateMedia(media,this.defaults.id, this.defaults.productID).subscribe((res) => {
      if (res['status'] == ErrorStatusModel.SUCCESS || ErrorStatusModel.SUCCESSCREATE) {
        this.toastr.success('Media Updated Successfully!');
      }
    }, error => {
      this.toastr.error(error['errorMessages'][0]);
    });
  }
}

