import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavItemComponent } from './sidenav-item.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import {NgxPermissionsModule} from 'ngx-permissions';

@NgModule({
  declarations: [SidenavItemComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatRippleModule,
        NgxPermissionsModule.forChild()

    ],
  exports: [SidenavItemComponent]
})
export class SidenavItemModule {
}
