<div class="card px-2 " [ngClass]="isPin?'bg-blue-50':''">
  <div class="flex">
    <div class="flex  rounded w-full" >
      <img src="{{message?.avatar}}" class="w-[50px] h-[50px] p-2" (error)="setDefaultImage($event)">
      <div class="w-full">
        <p >{{message?.username}}</p>
        <div class="flex justify-start w-full">
          {{message?.content}}
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <div>{{message?.sendTime | date:' MM dd yyyy HH:mm:ss'}}</div>
      <button class=""  (click)="clickPin.emit()" *ngIf="message.userId && message.isPin=='false'">
        <img class="w-[18px] h-[18px]" src="assets/img/icons/logos/pin.png">
      </button>
      <button class=""  (click)="unPin.emit()" *ngIf="message.userId && message.isPin=='true'">
        <img src="assets/img/icons/logos/unpin.png">
      </button>
    </div>
  </div>

</div>
