import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
    export class OrderService {

      constructor(private http:HttpClient) { }

     all(){
        return this.http.get(environment.baseUrl + 'order/orders');
      }
      // @ts-ignore
     get(id){
        return this.http.get(environment.baseUrl + 'order/orders/' + id);
      }
     delete(id){
        return this.http.delete(environment.baseUrl + 'order/orders' + id);
      }
}
