<form *ngIf="!loading" class="flex flex-col gap-1 justify-center items-center w-full">

    <div class="relative flex items-center w-full">
        <button type="button" id="decrement-button" data-input-counter-decrement="quantity-input"
                (click)="decreaseAmount()"
                class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
            <app-svg-renderer [svgContent]="'Decrement'"/>
        </button>
        <input [formControl]="amount" type="text" id="quantity-input" data-input-counter
               aria-describedby="helper-text-explanation"
               class="bg-gray-50 border border-gray-300  h-[23px] w-full text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block  py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
               placeholder="999" required/>
        <button type="button" id="increment-button" (click)="increaseAmount()"
                data-input-counter-increment="quantity-input"
                class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
            <app-svg-renderer [svgContent]="'Increment'"/>

        </button>
    </div>
    <button type="submit" (click)="bid()"
            class=" flex items-center justify-center bg-[#d7e512] text-contrast-black px-3 h-10 rounded-lg  w-full">
        <span *ngIf="!bidLoading">Bid</span>
        <mat-progress-spinner *ngIf="bidLoading" diameter="30" mode="indeterminate" color="warn"></mat-progress-spinner>
    </button>

</form>
