import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup, Validators
} from '@angular/forms';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {LoadingRowComponent} from '../../../../../core/components/loading-row/loading-row.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {filter, Observable, of, ReplaySubject} from 'rxjs';
import {RingModel} from '../../../models/ring.model';
import {TableColumn} from '../../../../../../@vex/interfaces/table-column.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {AuctionService} from '../service/auction.service';
import {ToastrService} from 'ngx-toastr';
import {MethodsUtils} from '../../../../../core/utils/method-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {AuctionItemModel} from '../../../models/auction-items.model';
import {ProductService} from '../../products/service/product.service';
import {Product} from '../../../models/product.model';
@UntilDestroy()
@Component({
  selector: 'vex-auction-items',
  templateUrl: './auction-items.component.html',
  styleUrls: ['./auction-items.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    BreadcrumbsModule,
    MatCheckboxModule,
    NgClass,
    MatSlideToggleModule,
    MatMenuModule,
    MatIconModule,
    MatPaginatorModule,
    FormsModule,
    MatTableModule,
    ReactiveFormsModule,
    PageLayoutModule,
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSelectModule,
    LoadingRowComponent,
    MatDatepickerModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class AuctionItemsComponent implements OnInit, AfterViewInit {

  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<AuctionItemModel[]> = new ReplaySubject<AuctionItemModel[]>(1);
  data$: Observable<AuctionItemModel[]> = this.subject$.asObservable();
  selectedFile='assets/img/demo/missing-picture.jpeg';
  numbers: number[] = Array.from({ length: 10 }, (_, i) => i + 1);

  @Input()
  columns: TableColumn[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Lot', property: 'lot_identifier', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Product Title', property: 'product_name', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Start Price', property: 'start_price', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Minimum Price', property: 'minimum_price', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }


  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<AuctionItemModel> | null;
  selection = new SelectionModel<AuctionItemModel>(true, []);
  searchCtrl = new UntypedFormControl();
  form: UntypedFormGroup;
  isPanelOpen = false;
  loading = false;
  getLoading = true;
  defaults ?: AuctionItemModel;
  mode: 'create' | 'update' = 'create';
  ringId;
  products;
  allProducts;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private service:AuctionService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private elementRef: ElementRef,
    private methods: MethodsUtils,
    private route : ActivatedRoute,
    private router : Router,
    private productServie : ProductService,
    private cdr: ChangeDetectorRef

  ) {
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }


  getData() {
    this.ringId = this.route.snapshot.paramMap.get('id');

    this.service.getItems(this.ringId).subscribe((res)=>{
      of(res['data'].map(type=>new AuctionItemModel(type))).subscribe(types=>{
        this.subject$.next(types);
        this.getProduct();

      });
    });
  }
  getProduct() {
    this.productServie.get().subscribe((res:any) => {
      of(res.map(type => new Product(type))).subscribe(types => {
        this.allProducts = types.filter(prod=>
          prod?.deleted_at == null && prod.kind ==='PRODUCT' && prod.publish_status ==='PUBLISHED'
        );
        this.clearForm();
      });


    });
  }

  ngOnInit() {
    this.getData();
    this.dataSource = new MatTableDataSource();
    this.data$.pipe(
      filter<AuctionItemModel[]>(Boolean)
    ).subscribe(types => {
      this.dataSource.data = types;
    });
    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cdr.detectChanges();
  }

  create() {
    this.clearForm();
    this.isPanelOpen = true;
    this.scrollToForm();
  }
  initForm(app:AuctionItemModel){
    this.products = this.allProducts.slice(); // Create a shallow copy of allProducts
    this.products.unshift(app.product);
    this.isPanelOpen=true;
    this.mode='update';
    this.form = this.fb.group({
      id: [app?.id || null],
      lot_identifier: [app?.lot_identifier || '',Validators.required],
      start_price: [app?.start_price['amount'] || '',Validators.required],
      minimum_price: [app?.minimum_price['amount'] || '',Validators.required],
      estimated_price_min: [app?.estimated_price_min['amount'] || '',Validators.required],
      estimated_price_max: [app?.estimated_price_max['amount'] || '',Validators.required],
      product_id: [app?.product_id || ''],
      ring_id: [app?.ring_id || this.ringId]

    });

  }
  clearForm(){

    this.products = this.allProducts.slice();
    this.isPanelOpen = false;
    this.mode='create';
    this.form = this.fb.group({
      id: [ null],
      lot_identifier: ['',Validators.required],
      start_price: ['',Validators.required],
      minimum_price: [0,Validators.required],
      estimated_price_min: ['',Validators.required],
      estimated_price_max: ['',Validators.required],
      product_id: [null,Validators.required],
      ring_id: [this.ringId]
    });
    this.getLoading=false;
    this.cdr.detectChanges();

  }

  update(type: AuctionItemModel) {
    this.initForm(type);
    this.scrollToForm();
  }
  scrollToForm() {
    const formElement = this.elementRef.nativeElement.querySelector('#form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  isCreateMode() {
    return this.mode === 'create';
  }
  delete(type: AuctionItemModel) {
    this.service.deleteItems(type.id).subscribe((res)=>{
      if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
        this.toastr.success('Ring Updated Successfully!');
        this.ngOnInit();
        this.selection.deselect(type);
      }
    }, () => {
      this.toastr.error('error !');
    });
  }

  deleteAll(types: AuctionItemModel[]) {
    types.forEach(c => this.delete(c));
  }
  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }
  save(){
    this.loading=true;

    let formData = new FormData();
    if(this.mode === 'update') {
      formData.append('id', this.form.value?.id);
    }
    formData.append('lot_identifier',this.form.value.lot_identifier);
    formData.append('product_id',this.form.value.product_id);
    formData.append('start_price',this.form.value.start_price);
    formData.append('estimated_price_min',this.form.value.estimated_price_min);
    formData.append('estimated_price_max',this.form.value.estimated_price_max);
    formData.append('minimum_price',this.form.value.minimum_price);
    formData.append('ring_id',this.form.value.ring_id);



    if (formData) {
      this.service.saveItems(formData).subscribe((res) => {
        if (res['status'] == ErrorStatusModel.SUCCESS || res['status'] == ErrorStatusModel.SUCCESSCREATE) {
          this.toastr.success('Item '+this.mode+'d Successfully!');
          this.ngOnInit();
          this.getProduct();
          this.loading=false;

        }
      }, error => {
        this.loading=false;
        this.toastr.error(error);
      });
    }}
  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
      this.form.patchValue({cover: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedFile = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }


  }

  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }

  back(){
    this.router.navigate(['auction']);
  }


}



