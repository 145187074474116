export class RingModel {
  id: number;
  name: string;
  description: string;
  start_datetime: string;
  end_datetime: string;
  status: string;
  is_editable: boolean;
  auction_id: number;



    constructor(obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.description = obj.description;
        this.start_datetime = new Date(obj.start_datetime).toDateString();
        this.end_datetime = obj.end_datetime;
        this.auction_id = obj.auction_id;
        this.status = obj.status;
        this.is_editable = obj.is_editable;


    }
}
