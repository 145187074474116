<form  [formGroup]="form" >
        <div *ngIf="!editorLoading">
<editor
        formControlName="{{lang}}_{{field}}"
        apiKey="6tb9ekfidko0nolwuntzi5j5sdmynss8gsee3l8r9rmwu4ee"
        [init]="config"

></editor>
    </div>
</form>
