import {OrderItemsModel} from './order-items.model';
import {User} from './user.model';

export class OrderModel {
  id: number;
  buyer_id: number;
  sub_total: number;
  total: number;
  taxes: number;
  discounts: number;
  shipment_type: string;
  shipment_id: string;
  notes: string;
  billing_address_id: number;
  same_shipment: number;
  shipment_cost : number;
  status:string;
  created_at:string;
  items_count:number;
  buyer:User;
  user_name:string;
  items:OrderItemsModel[];

    constructor(obj) {
        this.id = obj.id;
        this.buyer_id = obj.buyer_id;
        this.sub_total = obj.sub_total.amount;
        this.total = obj.total;
        this.taxes = obj.taxes.amount;
        this.discounts = obj.discounts.amount;
        this.shipment_type = obj.shipment_type;
        this.shipment_id = obj.shipment_id;
        this.notes = obj.notes;
        this.billing_address_id = obj.billing_address_id;
        this.same_shipment = obj.same_shipment;
        this.shipment_cost = obj.shipment_cost.amount;
        this.status = obj.status;
        this.created_at = obj.created_at;
        this.items = obj.items;
        this.buyer = obj.buyer;
        this.user_name = obj.buyer.full_name;
        this.items_count = obj.items.length;
    }
}
