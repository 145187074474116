import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {filter, Observable, of, ReplaySubject} from 'rxjs';
import {BannerModel} from '../../../models/banner.model';
import {TableColumn} from '../../../../../../@vex/interfaces/table-column.interface';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatRippleModule, ThemePalette} from '@angular/material/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {BannerService} from '../../banners/service/banner.service';
import {ToastrService} from 'ngx-toastr';
import {untilDestroyed} from '@ngneat/until-destroy';
import {ErrorStatusModel} from '../../../../../core/enum/error-status.model';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {PageLayoutModule} from '../../../../../../@vex/components/page-layout/page-layout.module';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CommonModule, NgClass} from '@angular/common';
import {BreadcrumbsModule} from '../../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {LoadingRowComponent} from '../../../../../core/components/loading-row/loading-row.component';
import {MatTabsModule} from '@angular/material/tabs';
import {PageLayoutDemoModule} from '../../../../ui/page-layouts/page-layout-demo/page-layout-demo.module';
import {RouterLinkActive} from '@angular/router';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {BookingTourModel} from "../../../models/booking-tour.model";
import {Brand} from "../../../models/brand.model";

@Component({
  selector: 'vex-booing-tour-table',
  templateUrl: './booing-tour-table.component.html',
  styleUrls: ['./booing-tour-table.component.scss'],
  standalone: true,
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  imports: [
    PageLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    NgClass,
    MatPaginatorModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbsModule,
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    LoadingRowComponent,
    MatTabsModule,
    PageLayoutDemoModule,
    RouterLinkActive,
    MatRippleModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class BooingTourTableComponent implements OnInit, AfterViewInit {

  layoutCtrl = new UntypedFormControl('boxed');

  /**
   * Simulating a service with HTTP that returns Observables
   * You probably want to remove this and do all requests in a service with HTTP
   */
  subject$: ReplaySubject<BannerModel[]> = new ReplaySubject<BannerModel[]>(1);
  data$: Observable<BannerModel[]> = this.subject$.asObservable();
  customers: BannerModel[];

  @Input()
  columns: TableColumn[] = [
    {label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true},
    // {label: 'ID', property: 'id', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Full Name', property: 'full_name', type: 'text', visible: true, cssClasses: ['font-medium']},
    {label: 'Start Datetime', property: 'scheduled_at', type: 'text', visible: true},
    {label: 'Status', property: 'status', type: 'text', visible: true},
    {label: 'Actions', property: 'actions', type: 'button', visible: true}

  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<BannerModel> | null;
  selection = new SelectionModel<BannerModel>(true, []);
  searchCtrl = new UntypedFormControl();
  form: UntypedFormGroup;
  isPanelOpen = false;
  loading = false;
  getLoading = true;
  defaults ?: BannerModel;
  mode: 'create' | 'update' = 'create';
  selectedFile: string = '';
  locale;
  defLocale;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  location;
  public color: ThemePalette = 'primary';

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Input() models;
  constructor(
    private service: BannerService,
    private toastr: ToastrService,
    private elementRef: ElementRef
  ) {

  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  ngOnInit() {
    of(this.models.map(brand => new BookingTourModel(brand))).subscribe(brands => {
      this.subject$.next(brands);
      // this.getLoading=false;
    });
    // this.models= this.models.map(brand => {
    //   console.log(brand);
    //   new BookingTourModel(brand);
    // });
    // console.log('this.models');
    // console.log(this.models);
    // console.log('this.models');
    // this.subject$.next(this.models);
    this.dataSource = new MatTableDataSource();
    this.selectedFile='assets/img/demo/missing-picture.jpeg';
    this.data$.pipe(
      filter<BannerModel[]>(Boolean)
    ).subscribe(customers => {
      this.customers = customers;
      this.dataSource.data = this.customers;
    });

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  createBrand() {

  }
  initForm(app:BannerModel){

  }
  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    if (file) {
      this.form.patchValue({img: file});
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedFile = e.target.result as string;
      };
      reader.readAsDataURL(file);
    }

  }
  clearForm(){

  }
  updateBrand(brand: BannerModel) {
    this.initForm(brand);
    this.scrollToForm();
  }
  isCreateMode() {
    return this.mode === 'create';
  }
  isLiveShow(){
    return this.location ==='liveshow';
  }
  scrollToForm() {
    const formElement = this.elementRef.nativeElement.querySelector('#form');
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  deleteBrand(brand: BannerModel) {
    this.service.delete(brand.id).subscribe((res) => {
      if(res['status'] == ErrorStatusModel.SUCCESS) {
        this.toastr.success('Brand Deleted Successfully!');
        this.ngOnInit();
        this.selection.deselect(brand);
      }
    },error =>
    {
      this.toastr.error(error['message']);

    });
  }

  deleteBrands(brands: BannerModel[]) {
    brands.forEach(c => this.deleteBrand(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByProperty<T>(index: number, column: TableColumn) {
    return column.property;
  }

  setDefaultImage(event: any) {
    event.target.src = 'assets/img/demo/missing-picture.jpeg';
  }

}
